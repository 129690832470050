import React, { useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import MaterialTableWithIcons from '@/components/MaterialTableWithIcons';
import { Action, Column, MTableAction, MTableToolbar } from '@material-table/core';
import AddIcon from '@mui/icons-material/Add';
import { Box, Link, Button, FormControlLabel, Switch, Chip, Tooltip, Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import {
  useOrganizationCarePathwaysQueryInternal,
  AdminCarePathwayItemFragmentInternal,
  useOrganizationCarePathwayTemplatesQueryInternal,
} from '@/generated/graphql-internal';

import { useAddCarePathwayModal } from './AddCarePathwayModal';
import { useEditCarePathwayModal } from './EditCarePathwayModal';
import { CarePathwayStatusChip } from '@/components/CarePathway/CarePathwayStatusChip';
import { PageTitle } from '@/components/PageTitle';
import { PageContainer } from '@/components/PageContainer';

export const QUERY_PATHWAYS = gql`
  fragment AdminCarePathwayItem on CarePathway {
    id
    name
    createdAt
    updatedAt
    publishedAt
    deletedAt
    organization {
      id
    }
    numberOfActivePatients: numberOfPatients(includeDischarged: false)
    totalNumberOfAdmissions: numberOfPatients(includeDischarged: true)
  }

  query OrganizationCarePathways($organizationId: ID!, $includeDeleted: Boolean!) {
    carePathways(organizationId: $organizationId, includeDeleted: $includeDeleted) {
      ...AdminCarePathwayItem
    }
  }
`;

export const GET_TEMPLATES_QUERY = gql`
  fragment AdminCarePathwayTemplateItem on CarePathwayTemplate {
    id
    name
  }

  query OrganizationCarePathwayTemplates($organizationId: ID!) {
    carePathwayTemplates(organizationId: $organizationId) {
      ...AdminCarePathwayTemplateItem
    }
  }
`;

export function AdminCarePathways() {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [includeDeleted, setIncludeDeleted] = useState(false);

  const {
    data: pathwaysData,
    loading: pathwaysLoading,
    error: pathwaysError,
    refetch: pathwaysRefetch,
  } = useOrganizationCarePathwaysQueryInternal({
    variables: { organizationId, includeDeleted },
  });

  const {
    data: templatesData,
    loading: templatesLoading,
    error: templatesError,
  } = useOrganizationCarePathwayTemplatesQueryInternal({
    variables: { organizationId },
  });

  const classes = useStyles();

  const { showAddCarePathwayModal } = useAddCarePathwayModal({
    onAdded: () => {
      pathwaysRefetch();
    },
    onCancel: () => {
      pathwaysRefetch();
    },
  });

  const { showEditCarePathwayModal } = useEditCarePathwayModal({
    onUpdated: () => {
      toast.success('Care pathway updated');
      pathwaysRefetch();
    },
    onCancel: () => {
      pathwaysRefetch();
    },
  });

  const actions: Action<AdminCarePathwayItemFragmentInternal>[] = [
    {
      icon: 'ADD_HACK',
      tooltip: 'Add Care Pathway',
      isFreeAction: true,
      onClick: () => {
        showAddCarePathwayModal({
          organizationId,
          templates: templatesData?.carePathwayTemplates,
        });
      },
    },
    {
      icon: () => <EditIcon />,
      tooltip: 'Edit Care Pathway',
      onClick: (_event, rowData) => {
        const row = rowData as AdminCarePathwayItemFragmentInternal;
        showEditCarePathwayModal({
          carePathwayId: row.id,
          name: row.name,
          publishedAt: row.publishedAt ? new Date(row.publishedAt) : null,
          numberOfPatients: row.totalNumberOfAdmissions,
        });
      },
    },
  ];

  const columns = useMemo(
    () =>
      [
        {
          field: 'id',
          title: 'ID',
          render: (rowData) => (
            <Link to={`/admin/${organizationId}/carepathways/${rowData.id}`} component={RouterLink}>
              {rowData.id}
            </Link>
          ),
        },
        {
          title: 'Name',
          field: 'name',
          render: (rowData) => (
            <Stack gap={1} direction="row" alignItems="center">
              {rowData.name}
              <CarePathwayStatusChip
                publishedAt={rowData.publishedAt}
                deletedAt={rowData.deletedAt}
              />
              {rowData.organization === null && (
                <Tooltip title="This is a shared care pathway and so may be used within multiple organizations">
                  <Chip
                    className={classes.sharedIndicator}
                    label="Shared"
                    color="secondary"
                    size="small"
                  />
                </Tooltip>
              )}
            </Stack>
          ),
        },
        {
          field: 'numberOfActivePatients',
          title: 'Active Patients',
          tooltip: 'Number with this care pathway assigned',
        },
        {
          field: 'totalNumberOfAdmissions',
          title: 'Total Admissions',
          tooltip: 'Number of current or past admissions with this care pathway assigned',
        },
        { field: 'createdAt', title: 'Created', type: 'datetime' },
        { field: 'updatedAt', title: 'Updated', type: 'datetime' },
      ] as Column<AdminCarePathwayItemFragmentInternal>[],
    [organizationId, classes],
  );

  return (
    <PageContainer>
      <PageTitle title="Care Pathways" subtitle="Manage an organisation's care pathways" />
      <MaterialTableWithIcons
        title={null}
        columns={columns}
        actions={actions}
        data={pathwaysData?.carePathways ?? []}
        components={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Action: (props: any) => {
            if (props.action.icon === 'ADD_HACK') {
              return (
                <Box marginLeft={2}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={props.action.onClick}>
                    {props.action.tooltip}
                  </Button>
                </Box>
              );
            }
            return (
              <MTableAction
                {...props}
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.stopPropagation();
                  props.onClick();
                }}
              />
            );
          },
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <Box marginLeft={2}>
                <FormControlLabel
                  label="Show archived"
                  control={
                    <Switch
                      checked={includeDeleted}
                      onChange={() => {
                        setIncludeDeleted((is) => !is);
                      }}
                      color="error"
                    />
                  }
                />
              </Box>
            </div>
          ),
        }}
        isLoading={pathwaysLoading || templatesLoading}
        localization={{
          body: {
            emptyDataSourceMessage:
              pathwaysError || templatesError ? 'Something went wrong' : 'No care pathways found',
          },
        }}
      />
    </PageContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  sharedIndicator: {
    color: theme.palette.common.white,
  },
}));
