import React, { useState } from 'react';
import clsx from 'clsx';
import { gql } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Tooltip, IconButton } from '@mui/material';
import { Skeleton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import { toast } from 'sonner';
import { range } from 'lodash';
import { addSeconds } from 'date-fns';

import {
  useGetContinuousMonitoringQuery,
  ContinuousMonitoringItemFragment,
} from '@/generated/graphql';

import localeFormatting, { feebrisFormatter } from '@/helpers/LocaleFormatting';
import { COLOR_BANDS } from '@/styles/NEWSColors';
import { isDefined } from '@/helpers/isDefined';

export const GET_CONTINUOUS_MONITORING = gql`
  fragment ContinuousMonitoringItem on ContinuousMonitoring {
    bucketStartAt
    bucketEndAt
    respiratoryRate {
      value
      median
      min
      max
    }
    spo2 {
      value
      median
      min
      max
    }
    pulseRate {
      value
      median
      min
      max
    }
    heartRate {
      value
      median
      min
      max
    }
    temperature {
      value
      median
      min
      max
    }
    battery {
      ecg
      spo2
      temperature
    }
    thresholdScores {
      RRScore
      SpO2Score
      HRScore # Fyi this is a pulse rate score, legacy NEWSCalculator keys..
      tempScore
    }
  }

  query GetContinuousMonitoring(
    $patientId: ID!
    $numPrevBuckets: Int!
    $currentBucketStartAt: Date
  ) {
    continuousMonitoring(
      patientId: $patientId
      numPrevBuckets: $numPrevBuckets
      currentBucketStartAt: $currentBucketStartAt
    ) {
      data {
        ...ContinuousMonitoringItem
      }
      from
      to
      earliest
      latest
    }
  }
`;

interface ContinuousMonitoringSummaryTableProps {
  patientId: string;
}

const NUM_PREV_BUCKETS = 10;

export default function ContinuousMonitoringSummaryTable({
  patientId,
}: ContinuousMonitoringSummaryTableProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [currentBucketStartAt, setCurrentBucketStartAt] = useState<Date>();

  const { data, loading } = useGetContinuousMonitoringQuery({
    variables: {
      patientId,
      numPrevBuckets: NUM_PREV_BUCKETS,
      currentBucketStartAt: currentBucketStartAt ? currentBucketStartAt.toISOString() : undefined,
    },
    onError: () => toast.error('An error occurred when fetching continuous monitoring data'),
  });

  const previousPage = () => {
    if (data?.continuousMonitoring.from && data?.continuousMonitoring.earliest) {
      const newDate = new Date(new Date(data?.continuousMonitoring.from).getTime() - 900 * 1000);
      if (newDate >= new Date(data?.continuousMonitoring.earliest)) {
        setCurrentBucketStartAt(newDate);
      }
    }
  };
  const isPreviousPageDisabled = (): boolean => {
    if (data?.continuousMonitoring.from && data?.continuousMonitoring.earliest) {
      const newDate = new Date(new Date(data?.continuousMonitoring.from).getTime() - 900 * 1000);
      return newDate < new Date(data?.continuousMonitoring.earliest);
    } else {
      return false;
    }
  };
  const nextPage = () => {
    if (data?.continuousMonitoring.to && data?.continuousMonitoring.latest) {
      const newDate = new Date(
        new Date(data?.continuousMonitoring.to).getTime() + 900 * 1000 * NUM_PREV_BUCKETS,
      );
      if (newDate <= new Date(data?.continuousMonitoring.latest)) {
        setCurrentBucketStartAt(newDate);
      }
    }
  };
  const isNextPageDisabled = (): boolean => {
    if (data?.continuousMonitoring.to && data?.continuousMonitoring.latest) {
      const newDate = new Date(
        new Date(data?.continuousMonitoring.to).getTime() + 900 * 1000 * NUM_PREV_BUCKETS,
      );
      return newDate > new Date(data?.continuousMonitoring.latest);
    } else {
      return false;
    }
  };

  // We convert the sparse array, data.continuousMonitoring.data, into dense row data
  // then we transpose it (90deg left rotation) then mirror it so the newest stuff is on the right
  const rows = transposeAndMirror(
    toRows(
      data?.continuousMonitoring.data ?? [],
      data?.continuousMonitoring.to ? new Date(data?.continuousMonitoring.to) : null,
      NUM_PREV_BUCKETS,
      900, // FIXME: Move toRows into the backend, so we don't need to know bucket size here
    ),
  );

  return (
    <div className={classes.main}>
      <Typography variant="h6">{t('Patient Continuous Monitoring')}</Typography>
      <table className={clsx(classes.table, 'e2e__continuousmonitoringsummarytable')}>
        {rows.length ? (
          <tbody>
            <tr>
              <th>Date</th>
              {rows[0].map((date, i) => (
                <td key={`date-${i}`}>
                  <Tooltip title={localeFormatting.formatCheckupTimeLongWithoutWeekDay(date)}>
                    <>
                      {Moment(date).format('MMM DD')}
                      <br />
                      {Moment(date).format('h:mm a')}
                    </>
                  </Tooltip>
                </td>
              ))}
            </tr>
            <tr>
              <th>Breathing Rate</th>
              {rows[1].map((value, i) => (
                <VitalValueTooltip key={`rr-${i}`} value={value}>
                  <td style={newsColorStyle(value)}>
                    <>{value ? value[0] : null}</>
                  </td>
                </VitalValueTooltip>
              ))}
            </tr>
            <tr>
              <th>Heart Rate</th>
              {rows[2].map((value, i) => (
                <VitalValueTooltip key={`hr-${i}`} value={value}>
                  <td style={newsColorStyle(value)}>
                    <>{value ? value[0] : null}</>
                  </td>
                </VitalValueTooltip>
              ))}
            </tr>
            <tr>
              <th>Pulse Rate</th>
              {rows[3].map((value, i) => (
                <VitalValueTooltip key={`pr-${i}`} value={value}>
                  <td style={newsColorStyle(value)}>
                    <>{value ? value[0] : null}</>
                  </td>
                </VitalValueTooltip>
              ))}
            </tr>
            <tr>
              <th>
                <div className={classes.spo2}>
                  <div>SpO</div>
                  <sub>2</sub>
                </div>
              </th>
              {rows[4].map((value, i) => (
                <VitalValueTooltip key={`spo2-${i}`} value={value}>
                  <td style={newsColorStyle(value)}>
                    <>{value ? value[0] : null}</>
                  </td>
                </VitalValueTooltip>
              ))}
            </tr>
            <tr>
              <th>Temperature</th>
              {rows[5].map((value, i) => (
                <VitalValueTooltip key={`temperature-${i}`} value={value}>
                  <td style={newsColorStyle(value)}>
                    <>{value ? feebrisFormatter.formatTemperatureWithUnits(value[0]) : null}</>
                  </td>
                </VitalValueTooltip>
              ))}
            </tr>
          </tbody>
        ) : (
          <tbody>
            <tr>
              <th>Date</th>
              {range(0, NUM_PREV_BUCKETS).map((i) => (
                <td key={`date-${i}`}>
                  {loading && (
                    <>
                      <Skeleton />
                      <Skeleton />
                    </>
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <th>Breathing Rate</th>
              {range(0, NUM_PREV_BUCKETS).map((i) => (
                <td key={`rr-${i}`}></td>
              ))}
            </tr>
            <tr>
              <th>Heart Rate</th>
              {range(0, NUM_PREV_BUCKETS).map((i) => (
                <td key={`hr-${i}`}></td>
              ))}
            </tr>
            <tr>
              <th>Pulse Rate</th>
              {range(0, NUM_PREV_BUCKETS).map((i) => (
                <td key={`pr-${i}`}></td>
              ))}
            </tr>
            <tr>
              <th>
                <div className={classes.spo2}>
                  <div>SpO</div>
                  <sub>2</sub>
                </div>
              </th>
              {range(0, NUM_PREV_BUCKETS).map((i) => (
                <td key={`spo2-${i}`}></td>
              ))}
            </tr>
            <tr>
              <th>Temperature</th>
              {range(0, NUM_PREV_BUCKETS).map((i) => (
                <td key={`temperature-${i}`}></td>
              ))}
            </tr>
          </tbody>
        )}
      </table>
      <div className={classes.pagination}>
        {/*<Typography display="inline">
          {offset + 1} &ndash; {offset + limit} of {total}
        </Typography>*/}
        {/*<IconButton aria-label="first page" onClick={firstPage} disabled={isFirstPageDisabled}>
          <FirstPage />
        </IconButton>*/}
        <IconButton
          aria-label="previous page"
          onClick={previousPage}
          disabled={isPreviousPageDisabled()}
          size="large">
          <ChevronLeft />
        </IconButton>
        <IconButton
          aria-label="next page"
          onClick={nextPage}
          disabled={isNextPageDisabled()}
          size="large">
          <ChevronRight />
        </IconButton>
        {/*<IconButton aria-label="last page" onClick={lastPage} disabled={isLastPageDisabled}>
          <LastPage />
        </IconButton>*/}
      </div>
    </div>
  );
}

/**
 * Converts a sparse array of buckets into a dense array, with explicit nulls where needed.
 * Yields a row suitable for rendering as a table.
 *
 * TODO: Move this to the backend, because:
 *       - we could let PG do all of this with date_bin, available in PostgreSQL 14.x
 *       - it's sort of crazy to do this core data munging here
 *       - the algorithm of this for loop needs to kind of be a perfect "hit the nail on the head" for
 *         the data to be displayed. I mean this line `const currentBucket = bucketsMap[current];`
 *         the timestamp needs to be a perfect match
 *       However, moving to the backend will change the graphql interface quite a bit.
 */
type ValueTuple = [
  value: number | null,
  median: number | null,
  score: number | null,
  min: number | null,
  max: number | null,
];

type Row = [
  bucketStart: Date,
  respiratoryRate: ValueTuple | null,
  spo2: ValueTuple | null,
  pulseRate: ValueTuple | null,
  heartRate: ValueTuple | null,
  temperature: ValueTuple | null,
];

function toRows(
  buckets: ContinuousMonitoringItemFragment[],
  from: Date | null,
  numPrevBuckets: number,
  bucketSize: number,
): Row[] {
  // We have no starting point to generate from which means there is no continuous monitoring data
  // for this patient in the DB. Must yield zero rows and the caller must render an empty state.
  if (!from) {
    return [];
  }
  const bucketsMap: { [key: number]: ContinuousMonitoringItemFragment } = Object.fromEntries(
    buckets.map((bucket) => [new Date(bucket.bucketStartAt).getTime(), bucket]),
  );
  const rows: Row[] = [];
  for (let i = 0; i < numPrevBuckets; i++) {
    const current: number = from.getTime() - i * bucketSize * 1000;
    const currentBucket = bucketsMap[current];
    if (currentBucket !== undefined) {
      rows.push([
        new Date(currentBucket.bucketEndAt),
        [
          currentBucket?.respiratoryRate?.value ?? null,
          currentBucket?.respiratoryRate?.median ?? null,
          currentBucket?.thresholdScores?.RRScore ?? null,
          currentBucket?.respiratoryRate?.min ?? null,
          currentBucket?.respiratoryRate?.max ?? null,
        ],
        [
          currentBucket?.heartRate?.value ?? null,
          currentBucket?.heartRate?.median ?? null,
          null,
          currentBucket?.heartRate?.min ?? null,
          currentBucket?.heartRate?.max ?? null,
        ],
        [
          currentBucket?.pulseRate?.value ?? null,
          currentBucket?.pulseRate?.median ?? null,
          currentBucket?.thresholdScores?.HRScore ?? null,
          currentBucket?.pulseRate?.min ?? null,
          currentBucket?.pulseRate?.max ?? null,
        ],
        [
          currentBucket?.spo2?.value ?? null,
          currentBucket?.spo2?.median ?? null,
          currentBucket?.thresholdScores?.SpO2Score ?? null,
          currentBucket?.spo2?.min ?? null,
          currentBucket?.spo2?.max ?? null,
        ],
        [
          currentBucket?.temperature?.value ?? null,
          currentBucket?.temperature?.median ?? null,
          currentBucket?.thresholdScores?.tempScore ?? null,
          currentBucket?.temperature?.min ?? null,
          currentBucket?.temperature?.max ?? null,
        ],
      ]);
    } else {
      // current represents the start of the bucket so we add the bucketSize
      // to get what would be the end of the bucket for undefined buckets
      const fillerDate = addSeconds(new Date(current), bucketSize);
      // Filler for missing rows from the sparse array
      rows.push([fillerDate, null, null, null, null, null]);
    }
  }
  return rows;
}

type TransposedRows = [Date[], ...Array<ValueTuple | null>[]];
function transposeAndMirror(rows: Row[]): TransposedRows {
  const copy = [] as unknown as TransposedRows;
  for (let i = 0; i < rows.length; ++i) {
    for (let j = 0; j < rows[i].length; ++j) {
      if (copy[j] === undefined) {
        copy[j] = [];
      }
      copy[j][i] = rows[rows.length - 1 - i][j];
    }
  }
  return copy;
}

function newsColorStyle(valueAndEWSScore: ValueTuple | null) {
  if (!valueAndEWSScore) {
    return;
  }
  const [, ewsScore] = valueAndEWSScore;
  return {
    backgroundColor: ewsScore === null ? 'white' : COLOR_BANDS[ewsScore],
  };
}

const tooltipText = (value: ValueTuple | null) => {
  if (!value || !value[0]) {
    return null;
  }
  const [_value, median, score, min, max] = value;

  return (
    <>
      {isDefined(score) ? (
        <Typography variant="body2">
          <strong>Score:</strong> {score}
        </Typography>
      ) : null}
      <Typography variant="body2">
        <strong>Min:</strong> {min ?? '-'}
      </Typography>
      <Typography variant="body2">
        <strong>Max:</strong> {max ?? '-'}
      </Typography>
      <Typography variant="body2">
        <strong>Median:</strong> {median ?? '-'}
      </Typography>
    </>
  );
};

const VitalValueTooltip = ({
  value,
  children,
}: {
  value: ValueTuple | null;
  children: React.ReactElement;
}) => {
  return (
    <Tooltip
      title={tooltipText(value)}
      arrow
      slotProps={{
        popper: {
          sx: {
            pointerEvents: 'none',
          },
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -8],
              },
            },
          ],
        },
      }}>
      {children}
    </Tooltip>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '12px',
    borderCollapse: 'collapse',
    '& th': {
      fontSize: '12px',
      padding: theme.spacing(1),
      border: 'none',
      textAlign: 'right',
      paddingRight: theme.spacing(2),
      width: theme.spacing(15),
    },
    '& tr': {
      '&:nth-child(1)': {
        whiteSpace: 'nowrap',
        '& td': {
          '&:nth-child(n+2)': {
            '&:nth-last-child(n+1)': {
              border: 'none',
            },
          },
        },
        '& th': {
          '&:nth-child(1)': {
            minWidth: theme.spacing(2),
            maxWidth: theme.spacing(2),
            borderRight: '3px solid black',
          },
        },
      },
      '&:nth-child(2)': {
        borderTop: '3px solid black',
        '& th': {
          '&:nth-child(1)': {
            borderRight: '3px solid black',
          },
        },
      },
      '&:nth-child(9)': {
        borderTop: '3px solid black',
        '& th': {
          '&:nth-child(1)': {
            borderRight: '3px solid black',
          },
        },
      },
      '&:nth-child(10)': {
        borderTop: '3px solid black',
        '& th': {
          '&:nth-child(1)': {
            borderRight: '3px solid black',
          },
        },
      },
      '&:nth-child(11)': {
        '& th': {
          '&:nth-child(1)': {
            borderRight: '3px solid black',
          },
        },
      },
      '&:nth-child(n+2)': {
        '&:nth-child(-n+8)': {
          '& td': {
            border: '1px dotted',
          },
        },
      },
      '&:nth-child(n+3)': {
        '&:nth-child(-n+8)': {
          '& th': {
            '&:nth-child(1)': {
              borderRight: '3px solid black',
            },
          },
        },
      },
    },
    '& td': {
      minWidth: theme.spacing(8),
      maxWidth: theme.spacing(8),
      border: '1px solid #ddd',
      padding: theme.spacing(1),
    },
  },
  spo2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& sub': {
      lineHeight: 2,
    },
  },
  infoIcon: {
    marginRight: theme.spacing(0.5),
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    '& p': {
      padding: 12, // Match sizing of icon buttons
    },
  },
}));
