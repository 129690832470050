import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import { gql } from '@apollo/client';

import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { Alert } from '@mui/material';
import { toast } from 'sonner';

import { ManageWardItemFragment, useDeleteWardMutation } from '@/generated/graphql';

interface DeleteWardModalProps extends DialogProps {
  ward: ManageWardItemFragment | undefined;
  onDeleted: () => void;
  onCancel: () => void;
}

export const DELETE_WARD = gql`
  mutation DeleteWard($id: ID!) {
    deleteWard(id: $id) {
      id
    }
  }
`;

export default function DeleteWardModal({ open, onCancel, onDeleted, ward }: DeleteWardModalProps) {
  return (
    <Dialog open={open} aria-labelledby="delete-ward-modal-dialog__title" fullWidth>
      {ward && <DeleteWardForm onDeleted={onDeleted} onCancel={onCancel} ward={ward} />}
    </Dialog>
  );
}

interface DeleteWardFormProps {
  ward: ManageWardItemFragment;
  onDeleted: () => void;
  onCancel: () => void;
}

function DeleteWardForm({ onDeleted, onCancel, ward }: DeleteWardFormProps) {
  const hasAdmissionsOrAssignments = ward.numberOfPatients > 0 || ward.numberOfStaff > 0;

  const [deleteWardMutation, { loading: isSubmitting }] = useDeleteWardMutation({
    onCompleted: () => {
      onDeleted();
    },
    variables: {
      id: ward.id,
    },
    onError: () => toast.error('An error occurred when deleting the ward'),
  });

  const handleSubmit = () => {
    if (isSubmitting || hasAdmissionsOrAssignments) return;
    deleteWardMutation();
  };

  return (
    <>
      <DialogTitle id="delete-ward-modal-dialog__title">Delete {ward.name}</DialogTitle>
      <DialogContent>
        {hasAdmissionsOrAssignments ? (
          <>
            <Alert severity="error">Ward cannot be deleted</Alert>
            <Box marginTop={2}>
              <Typography gutterBottom>
                This ward currently has <strong>{ward.numberOfPatients}</strong> patient(s) admitted
                and <strong>{ward.numberOfStaff}</strong> staff assigned.
              </Typography>
              <Typography>
                All patients must be discharged and all staff unassigned before attempting to delete
                a ward.
              </Typography>
            </Box>
          </>
        ) : (
          <DialogContentText>
            This action will remove the ward from the platform and you will no longer be able to
            admit patients or assign staff to this ward.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={isSubmitting}>
          {hasAdmissionsOrAssignments ? 'Close' : 'Cancel'}
        </Button>
        {!hasAdmissionsOrAssignments && (
          <Button color="error" variant="contained" onClick={handleSubmit} disabled={isSubmitting}>
            Delete Ward
          </Button>
        )}
      </DialogActions>
    </>
  );
}

interface UseDeleteWardModalProps {
  onDeleted: () => void;
}

export const useDeleteWardModal = ({ onDeleted }: UseDeleteWardModalProps) => {
  const { showModal } = useModal();

  return {
    showDeleteWardModal: (ward: ManageWardItemFragment) => {
      const modal: ShowFnOutput<DeleteWardModalProps> = showModal(
        DeleteWardModal,
        {
          ward: ward,
          onDeleted: () => {
            onDeleted();
            modal.hide();
          },
          onCancel: () => modal.hide(),
        },
        { destroyOnClose: true },
      );
      return modal;
    },
  };
};
