import React, { useMemo } from 'react';
import { Chip } from '@mui/material';

interface CarePathwayStatusChipProps {
  publishedAt: Maybe<Date | string>;
  deletedAt: Maybe<Date | string>;
}

export function CarePathwayStatusChip({ publishedAt, deletedAt }: CarePathwayStatusChipProps) {
  const status = useMemo(() => {
    if (deletedAt) {
      return 'Archived';
    }
    if (!publishedAt) {
      return 'Draft';
    }
    return 'Live';
  }, [publishedAt, deletedAt]);

  const color = useMemo(() => {
    if (deletedAt) {
      return 'error';
    }
    if (!publishedAt) {
      return 'warning';
    }
    return 'success';
  }, [publishedAt, deletedAt]);

  return status ? <Chip color={color} label={status} /> : null;
}
