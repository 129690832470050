import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Grid,
  TextField,
  TextFieldProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useFormik } from 'formik';
import { gql } from '@apollo/client';
import _ from 'lodash';
import * as Yup from 'yup';
import { toast } from 'sonner';
import { ShowFnOutput, useModal } from 'mui-modal-provider';

import { CreateWardMutation, useCreateWardMutation } from '@/generated/graphql';

import { getMutationErrors } from '@/AuthorizedApolloClientProvider';

interface AddWardModalProps extends DialogProps {
  onCancel: () => void;
  onAdded: (values: CreateWardMutation) => void;
}

export const CREATE_WARD = gql`
  mutation CreateWard($name: String!) {
    createWard(name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export interface CreateWardFormValues {
  name: string;
}

export default function AddWardModal({ open, onCancel, onAdded }: AddWardModalProps) {
  return (
    <Dialog open={open} aria-labelledby="add-ward-modal-dialog__title" fullWidth>
      <AddWardForm onAdded={onAdded} onCancel={onCancel} />
    </Dialog>
  );
}

const schema = Yup.object<CreateWardFormValues>().shape({
  name: Yup.string().required('Ward name is required'),
});

interface AddWardFormProps {
  onAdded: (values: CreateWardMutation) => void;
  onCancel: () => void;
}

function AddWardForm({ onAdded, onCancel }: AddWardFormProps) {
  const classes = useStyles();

  const [createWardMutation, { loading: isSubmitting, error: createWardError }] =
    useCreateWardMutation({
      onCompleted: (data) => {
        onAdded(data);
      },
      onError: () => toast.error('An error occurred when creating the ward'),
    });

  const handleSubmit = (values: CreateWardFormValues) => {
    createWardMutation({
      variables: {
        name: values.name.trim(),
      },
    });
  };

  const { argErrors } = getMutationErrors(createWardError);

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const muiFormikGetFieldProps = (name: keyof CreateWardFormValues): TextFieldProps => {
    return {
      name,
      value: _.get(formik.values, name),
      onChange: formik.handleChange,
      error: _.has(formik.errors, name) || _.has(argErrors?.ward, name),
      helperText: _.get(formik.errors, name) || _.get(argErrors?.ward, name),
    };
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogTitle id="add-ward-modal-dialog__title">Add Ward</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.formDescription}>
          Add a new ward to the system. This will allow you to later assign staff and admit patients
          to the ward.
        </DialogContentText>
        <Grid container>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name"
                label="Ward Name"
                placeholder='e.g. "Respiratory Risk Ward 1"'
                variant="standard"
                InputLabelProps={{ shrink: true }}
                fullWidth
                autoFocus
                {...muiFormikGetFieldProps('name')}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
          Add New Ward
        </Button>
      </DialogActions>
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  formDescription: {
    marginBottom: theme.spacing(2),
  },
}));

interface UseAddWardModalProps {
  onAdded: () => void;
}

export const useAddWardModal = ({ onAdded }: UseAddWardModalProps) => {
  const { showModal } = useModal();

  return {
    showAddWardModal: () => {
      const modal: ShowFnOutput<AddWardModalProps> = showModal(
        AddWardModal,
        {
          onAdded: () => {
            onAdded();
            modal.hide();
          },
          onCancel: () => modal.hide(),
        },
        { destroyOnClose: true },
      );

      return modal;
    },
  };
};
