import React from 'react';
import { Typography, Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

interface ErrorDisplayProps {
  heading?: string;
  message: string;
  showIcon?: boolean;
  retry?: () => void;
}

export function ErrorDisplay({
  heading = 'Something went wrong',
  showIcon,
  message,
  retry,
}: ErrorDisplayProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {showIcon && (
        <div>
          <svg
            width="128"
            height="128"
            viewBox="0 0 128 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M128 64C128 99.3462 99.3462 128 64 128C28.6538 128 0 99.3462 0 64C0 28.6538 28.6538 0 64 0C99.3462 0 128 28.6538 128 64Z"
              fill="#E6E6E6"
            />
            <path
              d="M71.0783 24L69.5652 79.4181H57.9304L56.4174 24H71.0783ZM56 96.4949C56 94.3558 56.6957 92.5611 58.087 91.1108C59.513 89.6243 61.4261 88.881 63.8261 88.881C66.2261 88.881 68.1217 89.6243 69.513 91.1108C70.9391 92.5611 71.6522 94.3558 71.6522 96.4949C71.6522 98.5978 70.9391 100.374 69.513 101.825C68.1217 103.275 66.2261 104 63.8261 104C61.4261 104 59.513 103.275 58.087 101.825C56.6957 100.374 56 98.5978 56 96.4949Z"
              fill="#B1B1B1"
            />
          </svg>
        </div>
      )}
      <Typography variant="h5" className={classes.heading}>
        {heading}
      </Typography>

      <Typography variant="body1" className={classes.message}>
        {message}
      </Typography>

      {retry && (
        <Button onClick={() => retry()} variant="outlined">
          Retry
        </Button>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  heading: {
    color: theme.palette.grey[800],
  },
  message: {
    color: theme.palette.grey[800],
  },
}));
