import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { VirtualWardPatientItemFragment } from '@/generated/graphql';
import { NEWS_SCORE_PALETTE } from '@/styles/NEWSColors';

const getRiskLevel = (
  riskScore: number | undefined | null,
): 'neutral' | 'positive' | 'warning' | 'danger' | 'negative' => {
  if (riskScore === undefined || riskScore === null) {
    return 'neutral';
  }
  if (riskScore === 0) {
    return 'positive';
  }

  if (riskScore >= 1 && riskScore <= 2) {
    return 'neutral';
  }

  if (riskScore === 3) {
    return 'warning';
  }
  if (riskScore === 4) {
    return 'danger';
  }

  return 'negative';
};

export const PatientStatusIndicator = (patient: VirtualWardPatientItemFragment) => {
  const classes = useStatusIndicatorStyles();

  const riskLevel = patient.alerts.length
    ? 'negative'
    : getRiskLevel(patient.latestCheckup?.ewsScores?.riskLevel);

  return <div className={clsx(classes.statusLine, classes[riskLevel])} />;
};

const useStatusIndicatorStyles = makeStyles((theme) => ({
  statusLine: {
    flexShrink: 0,
    alignSelf: 'stretch',
    width: 5,
    borderRadius: 5,
    margin: theme.spacing(1.5),
  },
  neutral: {
    backgroundColor: NEWS_SCORE_PALETTE[0].borderColor,
  },
  positive: {
    backgroundColor: theme.palette.success.main,
  },
  warning: {
    backgroundColor: NEWS_SCORE_PALETTE[1].borderColor,
  },
  danger: {
    backgroundColor: NEWS_SCORE_PALETTE[2].borderColor,
  },
  negative: {
    backgroundColor: NEWS_SCORE_PALETTE[3].borderColor,
  },
}));
