import React, { useEffect } from 'react';
import { gql } from '@apollo/client';
import { Action, Column, MTableAction } from '@material-table/core';
import { useTranslation } from 'react-i18next';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { useWardStaffQuery, WardStaffItemFragment } from '@/generated/graphql';
import MaterialTableWithIcons from '@/components/MaterialTableWithIcons';
import auth from '@/controllers/Auth';
import { useRemoveStaffModal } from './RemoveStaffModal';
import { Box, Button } from '@mui/material';
import { useAssignStaffModal } from './AssignStaffModal';
import { ErrorDisplay } from '@/components/ErrorDisplay';

export const QUERY_WARD_STAFF = gql`
  fragment WardStaffItem on WardStaff {
    user {
      id
      firstName
      lastName
      email
    }
    assignedAt
  }

  query WardStaff($wardId: ID!) {
    ward(id: $wardId) {
      id
      wardStaff {
        ...WardStaffItem
      }
    }
  }
`;

interface WardStaffProps {
  wardId: string;
  wardName: string;
  onRemoved: () => void;
  onAssigned: () => void;
}

type ActionWithPermission = Action<WardStaffItemFragment> & { permission?: string };

export function WardStaff({ wardId, wardName, onAssigned, onRemoved }: WardStaffProps) {
  const { data, loading, refetch, error } = useWardStaffQuery({
    variables: { wardId },
  });

  const { t } = useTranslation();

  const { showRemoveStaffModal } = useRemoveStaffModal({
    wardId,
    onRemoved: () => {
      refetch();
      onRemoved();
    },
  });

  const { showAssignStaffModal, updateProps } = useAssignStaffModal({
    wardId,
    wardName,
    wardStaff: data?.ward?.wardStaff || [],
    onAssigned: () => {
      refetch();
      onAssigned();
    },
  });

  /**
   * This is an issue with the modal provider not updating the props when already open.
   * We want this to be up to date so the modal can show the correct staff.
   *
   * TODO: Write our own modal provider with react hook-a-like dependency arrays
   */
  useEffect(() => {
    updateProps({ wardStaff: data?.ward?.wardStaff || [] });
  }, [data?.ward?.wardStaff, updateProps]);

  const columns: Column<WardStaffItemFragment>[] = [
    { field: 'user.id', title: 'ID', hidden: true },
    { field: 'user.firstName', title: 'First Name' },
    { field: 'user.lastName', title: 'Last Name' },
    { field: 'user.email', title: 'Email' },
    {
      field: 'assignedAt',
      title: 'Assigned to Ward',
      render: ({ assignedAt }) =>
        t('DATE_SHORT', {
          val: new Date(assignedAt),
          formatParams: { val: { timeZone: 'UTC' } },
        }),
      type: 'datetime',
    },
  ];

  const actions: ActionWithPermission[] = [
    {
      permission: 'assign_ward_staff',
      icon: () => <RemoveIcon />,
      tooltip: 'Remove staff from ward',
      position: 'row',
      onClick: (_, rowData) => {
        showRemoveStaffModal(rowData as WardStaffItemFragment);
      },
    },
    {
      permission: 'assign_ward_staff',
      icon: 'HACK-assign-staff',
      position: 'toolbar',
      onClick: () => undefined,
    },
  ];

  return (
    <MaterialTableWithIcons
      title="Ward Staff"
      options={{
        tableLayout: 'auto',
        tableWidth: 'full',
      }}
      actions={actions.filter(
        (action) => !action.permission || auth.me(`permissions.${action.permission}`),
      )}
      localization={{
        body: {
          emptyDataSourceMessage: error ? (
            <ErrorDisplay message="Failed to fetch staff member for this ward" retry={refetch} />
          ) : (
            t('Ward has no assigned staff members')
          ),
        },
      }}
      components={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Action: (props: any) => {
          if (props.action.icon === 'HACK-assign-staff') {
            return (
              <Box marginLeft={2}>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<PersonAddIcon />}
                  onClick={() => showAssignStaffModal()}>
                  Assign Staff
                </Button>
              </Box>
            );
          }
          return <MTableAction {...props} />;
        },
      }}
      isLoading={loading}
      columns={columns}
      data={data?.ward.wardStaff ?? []}
    />
  );
}
