import { useMemo } from 'react';
import {
  AdmissionEventOverviewFragment,
  AdmissionEventType,
  Organization,
  FormattableUserFragment,
} from '@/generated/graphql';
import _ from 'lodash';
import { isDefined } from '@/helpers/isDefined';

type WardMembershipUser = FormattableUserFragment | undefined | null;

export type AdmissionEvent = {
  createdAt: string;
  wardName: string;
  carePathwayName?: string;
  organization: Pick<Organization, 'id' | 'name'>;
  createdBy: WardMembershipUser;
  type: AdmissionEventType;
};

export class AdmissionHistory {
  #admissionEvents: AdmissionEvent[];

  get admission() {
    const admission = this.#admissionEvents.find((x) => x.type === AdmissionEventType.Admission);

    // All AdmissionHistories should have an admission
    if (!admission) {
      throw new Error('Initial admission could not be found.');
    }

    return admission;
  }

  get discharge() {
    return this.#admissionEvents.find((x) => x.type === AdmissionEventType.Discharge);
  }

  get transfers() {
    return _.orderBy(
      this.#admissionEvents.filter(
        (x) =>
          x.type === AdmissionEventType.WardTransfer ||
          x.type === AdmissionEventType.CarePathwayTransfer,
      ),
      (x) => new Date(x.createdAt),
    );
  }

  get latest() {
    return _.last(
      _.orderBy(this.#admissionEvents, (admissionEvent) => new Date(admissionEvent.createdAt)),
    );
  }

  constructor(admissionEvents: AdmissionEvent[]) {
    this.#admissionEvents = admissionEvents;
  }
}

export const useAdmissionHistories = (
  admissionEvents: AdmissionEventOverviewFragment[],
  includeCurrentAdmission = false,
): AdmissionHistory[] => {
  return useMemo(() => {
    const groupedByAdmissionId = _.groupBy(admissionEvents, 'admissionId');

    return _.map(groupedByAdmissionId, (admissions) => {
      const admissionEvents = _.orderBy(admissions, (x) => new Date(x.createdAt), ['asc']).map(
        (admissionEvent) => {
          return {
            createdAt: admissionEvent.createdAt,
            createdBy: admissionEvent.createdBy,
            organization: admissionEvent.ward.organization,
            wardName: admissionEvent.ward.name,
            carePathwayName: admissionEvent.carePathway?.name,
            type: admissionEvent.type,
          } satisfies AdmissionEvent;
        },
      );

      const admission = _.find(admissionEvents, (x) => x.type === AdmissionEventType.Admission);

      if (!admission) {
        // Defensiveness - there should always be an Admission present but on the off chance we
        // get back an admission history with no initial admission from the API, we don't include it.
        return null;
      }

      const discharge = _.find(admissionEvents, (x) => x.type === AdmissionEventType.Discharge);

      if (!includeCurrentAdmission && !discharge) {
        return null;
      }

      return new AdmissionHistory(admissionEvents);
    }).filter(isDefined);
  }, [admissionEvents, includeCurrentAdmission]);
};
