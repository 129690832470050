import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
  Stack,
  MenuItem,
} from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { gql } from '@apollo/client';

import { useCreateCarePathwayMutationInternal } from '@/generated/graphql-internal';

import { getMutationErrors } from '@/AuthorizedApolloClientProvider';
import { muiFormikGetFieldProps } from '@/helpers/formik';

export const CREATE_CAREPATHWAY_MUTATION = gql`
  mutation CreateCarePathway(
    $name: String!
    $organizationId: ID
    $templateId: ID
    $published: Boolean!
  ) {
    createCarePathway(
      name: $name
      organizationId: $organizationId
      templateId: $templateId
      published: $published
    ) {
      id
      name
      createdAt
      publishedAt
    }
  }
`;

interface AddCarePathwayModalProps extends DialogProps {
  organizationId: string;
  templates?: { id: string; name: string }[];
  onCancel: () => void;
  onAdded: () => void;
}

const formSchema = Yup.object().shape({
  name: Yup.string().required('Care Pathway name is required'),
  template: Yup.string().optional(),
});

type FormValues = Yup.InferType<typeof formSchema>;

export function AddCarePathwayModal({
  open,
  onCancel,
  onAdded,
  organizationId,
  templates,
}: AddCarePathwayModalProps) {
  const [createCarePathwayMutation, { loading: isSubmitting, error: createCarePathwayError }] =
    useCreateCarePathwayMutationInternal({
      onCompleted: () => {
        onAdded();
      },
      onError: () => undefined,
    });

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      template: undefined,
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      createCarePathwayMutation({
        variables: {
          name: values.name,
          organizationId,
          templateId: values.template,
          published: false,
        },
      });
    },
  });

  const { argErrors } = getMutationErrors(createCarePathwayError);
  const getFieldProps = muiFormikGetFieldProps(formik, argErrors);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      maxWidth="md"
      aria-labelledby="add-carepathway-dialog-title">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle
          id="add-carepathway-dialog-title"
          sx={{ display: 'flex', justifyContent: 'space-between' }}>
          Add Care Pathway{' '}
        </DialogTitle>
        <DialogContent>
          <Stack marginTop={2} spacing={3}>
            <TextField
              id="name"
              label="Care Pathway Name"
              disabled={isSubmitting}
              placeholder='e.g. "COPD Intermittent"'
              variant="standard"
              InputLabelProps={{ shrink: true }}
              fullWidth
              autoFocus
              {...getFieldProps('name')}
            />
            {templates && (
              <TextField
                fullWidth
                select
                SelectProps={{ displayEmpty: true }}
                InputLabelProps={{ shrink: true }}
                name="template"
                label="Select Care Pathway Template"
                onChange={formik.handleChange}>
                <MenuItem>No template</MenuItem>
                {templates.map((template) => (
                  <MenuItem value={template.id} key={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

interface UseAddCarePathwayModalProps {
  onCancel: () => void;
  onAdded: () => void;
}

export function useAddCarePathwayModal({ onCancel, onAdded }: UseAddCarePathwayModalProps) {
  const { showModal } = useModal();

  return {
    showAddCarePathwayModal: ({
      organizationId,
      templates,
    }: Pick<AddCarePathwayModalProps, 'organizationId' | 'templates'>) => {
      const modal = showModal(
        AddCarePathwayModal,
        {
          organizationId,
          onCancel: () => {
            onCancel();
            modal.hide();
          },
          onAdded: () => {
            onAdded();
            modal.hide();
          },
          templates,
        },
        { destroyOnClose: true },
      );
    },
  };
}
