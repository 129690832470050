import React from 'react';

import { Route, useRouteMatch } from 'react-router-dom';
import { ManagePathways } from './ManagePathways';
import { ManagePathway } from './ManagePathway';

export function ManagePathwayRoutes() {
  const { path } = useRouteMatch();

  return (
    <>
      <Route path={`${path}/:pathwayId/:tabName?`} component={ManagePathway} />
      <Route exact path={path} component={ManagePathways} />
    </>
  );
}
