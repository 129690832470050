import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Link from '@mui/material/Link';
import HelpIcon from '@mui/icons-material/Help';
import clsx from 'clsx';
import History from '@/helpers/History';
import auth from '@/controllers/Auth';
import PassphraseGenerator from '@/components/PassphraseGenerator';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function FirebaseAuthAction(props) {
  const query = useQuery();

  const mode = query.get('mode');

  if (mode === 'resetPassword') {
    return <ResetPassword actionCode={query.get('oobCode')} />;
  }

  if (mode === 'revertSecondFactorAddition') {
    return <RevokeSecondFactor actionCode={query.get('oobCode')} />;
  }

  // TODO: Handle recoverEmail and verifyEmail actions also

  // TODO: More gracefully handle unsupported actions?
  History.push('/');

  // This is necessary to prevent 'Nothing was returned from render' errors
  return <></>;
}

const useRevokeStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
  },
  confirmButton: {
    marginTop: theme.spacing(1),
  },
}));

function RevokeSecondFactor({ actionCode }) {
  const classes = useRevokeStyles();

  useEffect(() => {
    const applyActionCode = async () => await auth.applyActionCode(actionCode);
    applyActionCode();
  }, [actionCode]);

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h5" gutterBottom>
        Two-factor authentication removed
      </Typography>
      <Typography gutterBottom>
        If you wish to enable two-factor authentication again, you can do so in the Account page
        after logging in.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        className={classes.confirmButton}
        onClick={() => History.push('/login')}>
        Return to login
      </Button>
    </div>
  );
}

const useResetPasswordStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
  },
  confirmButton: {
    marginBottom: theme.spacing(1),
  },
  cancelButton: {
    marginBottom: theme.spacing(2),
  },
  help: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  helpIcon: {
    marginRight: theme.spacing(1),
  },
}));

/**
 * React-ified implementation of boilerplate from Google Firebase Docs.
 * see: https://firebase.google.com/docs/auth/custom-email-handler
 */
function ResetPassword({ actionCode }) {
  const classes = useResetPasswordStyles();
  const [actionCodeValid, setActionCodeValid] = useState(null);
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [passwordResetComplete, setPasswordResetComplete] = useState(false);
  const [backToLoginTimer, setBackToLoginTimer] = useState(null);
  const [helpOpen, setHelpOpen] = useState(false);

  useEffect(() => {
    // componentMount
    async function inner() {
      try {
        const userEmail = await auth.verifyPasswordResetCode(actionCode);
        setEmail(userEmail);
        setActionCodeValid(true);
      } catch (err) {
        setActionCodeValid(false);
      }
    }
    inner();

    // componentWillUnmount, for security we wipe the password state explicitly
    return () => {
      setPassword(null);
    };
  }, [actionCode]);

  const confirmPasswordReset = async () => {
    try {
      await auth.confirmPasswordReset(actionCode, password);
      setPasswordResetComplete(true);
      const timer = setTimeout(() => {
        History.push('/login');
      }, 60 * 5 * 1000);
      setBackToLoginTimer(timer);
    } catch (err) {
      setActionCodeValid(false);
    }
  };

  let content = <CircularProgress />;

  if (actionCodeValid === true) {
    content = (
      <>
        <PassphraseGenerator
          password={password}
          setPassword={setPassword}
          regenerateEnabled={!passwordResetComplete}
        />
        <div>
          {password && !passwordResetComplete && (
            <>
              <Typography paragraph>
                Use the refresh circle on the right to see different combinations. Once you are
                happy with the three words, click on Save Password below.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={clsx(classes.confirmButton, 'e2e__savepasswordbutton')}
                onClick={confirmPasswordReset}>
                Save Password
              </Button>
            </>
          )}
          {passwordResetComplete && (
            <Typography paragraph>
              This will be your new Feebris password. Please memorise it or store it safely.
            </Typography>
          )}
          {passwordResetComplete ? (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className={clsx(classes.cancelButton, 'e2e__returntologinbutton')}
              onClick={() => History.push('/login')}>
              Return to login
            </Button>
          ) : (
            <Button fullWidth href="/login" className={classes.cancelButton}>
              Cancel
            </Button>
          )}
          <Link
            color="primary"
            variant="body2"
            className={classes.help}
            onClick={() => setHelpOpen(true)}>
            <HelpIcon fontSize="small" className={classes.helpIcon} />
            Why use this type of password?
          </Link>
        </div>
        <Dialog
          open={helpOpen}
          onClose={() => setHelpOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            Why does Feebris use this type of password?
          </DialogTitle>
          <DialogContent>
            <Typography paragraph>
              Feebris incorporates best practices for password security from the{' '}
              <Link
                href="https://www.ncsc.gov.uk/collection/passwords/updating-your-approach"
                target="_blank">
                UK&apos;s NCSC guidelines
              </Link>
            </Typography>

            <Typography variant="h6" gutterBottom paragraph>
              Secure
            </Typography>
            <Typography paragraph>
              Machine-generated passwords are more secure; they ensure you have the optimal level of
              characters and your password cannot be easily guessed by hackers.
            </Typography>

            <Typography variant="h6" gutterBottom paragraph>
              Memorable
            </Typography>
            <Typography>
              The{' '}
              <Link
                href="https://www.ncsc.gov.uk/blog-post/three-random-words-or-thinkrandom-0"
                target="_blank">
                three random words
              </Link>{' '}
              technique produces three easy to remember words. You can refresh the combination of
              words until you find one you find easy to remember.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHelpOpen(false)} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  } else if (actionCodeValid === false) {
    content = (
      <>
        <Typography paragraph>
          Password reset code has expired. Try resetting your password again.
        </Typography>
        <Button variant="contained" color="primary" href="/forgot-password" fullWidth>
          Reset Password
        </Button>
      </>
    );
  }

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h5" gutterBottom>
        Reset Your Password
      </Typography>
      {content}
    </div>
  );
}
