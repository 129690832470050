import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { gql } from '@apollo/client';
import { Box, Button, Collapse, Grid, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  PatientSearchItemFragmentInternal,
  useVivalinkTroubleshootingLazyQueryInternalInternal,
} from '@/generated/graphql-internal';
import { Skeleton } from '@mui/material';
import { TimeAgo } from './TimeAgo';

export const VIVALINK_TROUBLESHOOTING_QUERY = gql`
  fragment VivalinkSensorItem on VivalinkSensor {
    sensorId
    sensorType
    lastActive
    topOne {
      recordTime
      patchBattery
    }
  }

  query VivalinkTroubleshooting($kitId: ID!) {
    vivalinkTroubleshooting(kitId: $kitId) {
      ...VivalinkSensorItem
    }
  }
`;

interface VivalinkTroubleshootingProps {
  patient: PatientSearchItemFragmentInternal;
}

const sensorTypes = ['ecg', 'spo2', 'temperature'] as const;

export function VivalinkTroubleshooting(props: VivalinkTroubleshootingProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const [fetch, { data, loading }] = useVivalinkTroubleshootingLazyQueryInternalInternal();
  const kitId = props.patient.latestContinuousMonitoringSession?.kitId;

  useEffect(() => {
    if (expanded && kitId) {
      fetch({
        variables: {
          kitId,
        },
      });
    }
  }, [expanded, kitId, fetch]);

  // Plan:
  // - Get (and display?) sensors associated with Kit
  // - Get (and display?) latest sensor activity for kit sensors
  // - Get (and display?) topOne for kit sensors
  // - Inform if there is a discrepancy between sensor activity and topOne
  // - Inform if there is a discrepancy between our database and topOne
  // - Inform if there are more than one of the same type of device, and which one the poller is choosing?
  // - Inform if they are missing a device?

  const dataBySensorType = data?.vivalinkTroubleshooting
    ? _.chain(data?.vivalinkTroubleshooting)
        .sortBy(
          (item) => new Date(item?.topOne?.recordTime ?? item?.lastActive ?? 0).getTime() * -1,
        )
        .groupBy((item) => item?.sensorType)
        .value()
    : { ecg: [], spo2: [], temperature: [] };

  return (
    <>
      {!data ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setExpanded(!expanded)}
          disabled={loading}>
          Load Vivalink Troubleshooting Info
        </Button>
      ) : null}
      <Collapse in={expanded}>
        {loading ? (
          <Skeleton height="200" />
        ) : (
          <Grid container spacing={2}>
            {sensorTypes.map((sensorType) => (
              <Grid item xs={4} key={sensorType}>
                <Typography variant="subtitle2" className={classes.sensorTypeTitle}>
                  Vivalink {sensorType} Info
                </Typography>

                {dataBySensorType[sensorType] && dataBySensorType[sensorType].length > 0 ? (
                  <>
                    <Typography variant="caption">
                      This kit (ID: {kitId}) has been associated with{' '}
                      {dataBySensorType[sensorType].length} vivalink {sensorType} sensors over time.
                    </Typography>

                    {(dataBySensorType[sensorType] ?? []).map((item) => (
                      <Box key={item?.sensorId} className={classes.vivalinkSensorContainer}>
                        <Typography>
                          <strong>Sensor ID:</strong> {item.sensorId}
                        </Typography>
                        <Typography>
                          <Tooltip title="The timestamp of the latest data point returned by Vivalink's 'topOne' API">
                            <strong>Last data at (topOne) :</strong>
                          </Tooltip>{' '}
                          {item.topOne?.recordTime ? (
                            <TimeAgo dateString={item.topOne?.recordTime}></TimeAgo>
                          ) : (
                            'No data'
                          )}
                        </Typography>
                        <Typography>
                          <Tooltip title="The timestamp returned by Vivalink's 'latest sensor activity' API for this sensor ID">
                            <strong>Last data at (sensor activity):</strong>
                          </Tooltip>{' '}
                          {item.lastActive ? (
                            <TimeAgo dateString={item.lastActive}></TimeAgo>
                          ) : (
                            'No data'
                          )}
                        </Typography>
                        <Typography>
                          <Tooltip title="The raw patchBattery value of the latest data point returned by Vivalink's 'topOne' API">
                            <strong>Last battery (topOne):</strong>
                          </Tooltip>{' '}
                          {item.topOne?.patchBattery
                            ? Math.floor(item.topOne?.patchBattery) + '%'
                            : 'No data'}
                        </Typography>
                      </Box>
                    ))}
                  </>
                ) : (
                  <Typography variant="caption">
                    There are no recorded Vivalink ${sensorType} sensors ever having been used with
                    this kit (ID: {kitId})
                  </Typography>
                )}
              </Grid>
            ))}
          </Grid>
        )}
      </Collapse>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  sensorTypeTitle: { textTransform: 'uppercase' },
  vivalinkSensorContainer: { marginTop: theme.spacing(2) },
}));
