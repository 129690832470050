import { KeyboardEvent } from 'react';

interface CreateTapPropsOptions {
  onTap: () => void;
}

/**
 * Helper function to create onClick and onKeyDown handlers for a component that
 * should be "tappable".
 *
 * This is useful for components that should be clickable
 * but aren't a native button element.
 */
export const createTapProps = (eventHandlers: CreateTapPropsOptions) => {
  return {
    onClick: () => {
      eventHandlers.onTap();
    },
    onKeyDown: (event: KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();

        eventHandlers.onTap();
      }
    },
    tabIndex: 0, // Make the element focusable
    role: 'button', // Add ARIA role for accessibility
  };
};
