import React from 'react';
import * as Sentry from '@sentry/browser';
import { Button, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';

export interface ErrorBoundaryProps {
  children: React.ReactNode;
  /**
   * Content to display when the child tree has thrown an error
   * @default <DefaultErrorFallback />
   */
  fallback?: React.ReactNode;
  onError?: (error: Error, componentStack: string) => void;
}

function DefaultErrorFallback() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4" gutterBottom>
        Something went wrong.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please refresh the page or click below to go to the Home page.
      </Typography>
      <Typography variant="body1" gutterBottom>
        If the issue persists, please{' '}
        <Link href="mailto:support@feebris.com">contact our support team</Link> so they can help you
        solve this problem.
      </Typography>
      <Button
        className={classes.homeButton}
        component={RouterLink}
        to="/"
        color="primary"
        variant="contained">
        Home
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '15svh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.primary.dark,
  },
  homeButton: {
    marginTop: theme.spacing(2),
  },
}));

export interface ErrorBoundaryState {
  error: Error | null;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  override state: ErrorBoundaryState = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  override componentDidCatch(error: Error, { componentStack }: React.ErrorInfo) {
    Sentry.captureException(error, { contexts: { react: { componentStack } } });

    if (this.props.onError) {
      this.props.onError(error, componentStack);
    }
  }

  override render() {
    if (this.state.error) {
      return this.props.fallback || <DefaultErrorFallback />;
    }

    return this.props.children;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const withErrorBoundary = <P extends {}>(
  Component: React.ComponentType<P>,
  fallback?: React.ReactNode,
  onError?: (error: Error, componentStack: string) => void,
): React.FC<P> => {
  const WithErrorBoundary = (props: P) => (
    <ErrorBoundary fallback={fallback} onError={onError}>
      <Component {...props} />
    </ErrorBoundary>
  );

  WithErrorBoundary.displayName = `WithErrorBoundary(${
    Component.displayName || Component.name || 'unknown'
  })`;

  return WithErrorBoundary;
};
