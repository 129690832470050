import React from 'react';

import { IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import ContentCopy from '@mui/icons-material/ContentCopy';

interface LabelledValueProps {
  heading: React.ReactNode;
  value: string;
  showCopyButton?: boolean;
  loading?: boolean;
  skeletonWidth?: number;
}

export function LabelledValue({
  heading,
  value,
  showCopyButton = false,
  loading = false,
  skeletonWidth = 64,
}: LabelledValueProps) {
  const { canCopy, copyToClipboard } = useCopyToClipboard();

  console.log(canCopy);

  return (
    <Stack direction="row" alignItems="flex-end" spacing={0.5}>
      <Stack direction="column">
        <Typography component="span" variant="body2" color="primary" fontWeight="500">
          {heading}
        </Typography>
        <Typography
          component="span"
          variant="body1"
          color="textPrimary"
          aria-label={`${value} ${heading}`}>
          {loading ? <Skeleton width={skeletonWidth} /> : value}
        </Typography>
      </Stack>
      {showCopyButton && canCopy && (
        <IconButton aria-label={`Copy ${value} ${heading}`} onClick={() => copyToClipboard(value)}>
          <ContentCopy fontSize="small" />
        </IconButton>
      )}
    </Stack>
  );
}
