import React, { useMemo, useState } from 'react';

import { toast } from 'sonner';
import { Alert, Box, Checkbox, Chip, CircularProgress, Stack, Typography } from '@mui/material';

import { useAddUserJourneyContext } from '../types';
import { useAssignStaffToWardMutation, useGetWardsQuery } from '@/generated/graphql';
import { JourneyHeading } from '@/components/JourneyModal';
import useId from '@mui/material/utils/useId';
import { isDefined } from '@/helpers/isDefined';

const Skip = Symbol('skip');

export function AssignWardsStep() {
  const {
    handleStep,
    setIsSubmitting,
    currentJourneyState: { user },
    isSubmitting,
  } = useAddUserJourneyContext();

  const { data: wardsData, loading: fetchingWards } = useGetWardsQuery();
  const [assignToWard] = useAssignStaffToWardMutation({
    context: { batch: true },
  });

  const wards = wardsData?.wards || [];

  const [showNoSelectionWarning, setShowNoSelectionWarning] = useState(false);

  const [selectedWardIds, setSelectedWardIds] = useState<string[] | typeof Skip>([]);

  const [assignedWards, setAssignedWards] = useState<string[]>([]);

  const handleRowToggle = (wardId: string) => {
    // Don't allow toggling if we're submitting
    if (isSubmitting) {
      return false;
    }

    setSelectedWardIds((prev) => {
      if (prev === Skip) {
        return [wardId];
      }

      if (prev.includes(wardId)) {
        return prev.filter((id) => id !== wardId);
      }

      return [...prev, wardId];
    });
  };

  const handleSkip = () => {
    if (isSubmitting) {
      return;
    }

    setSelectedWardIds((prev) => (prev === Skip ? [] : Skip));
  };

  const isWardSelected = (wardId: string) =>
    Array.isArray(selectedWardIds) && selectedWardIds.includes(wardId);

  // Disable automatic progression, as we need to handle the form submission
  handleStep(async () => {
    // If the user has specifically skipped this step, we can just return true
    if (selectedWardIds === Skip) {
      return true;
    }

    if (selectedWardIds.length === 0) {
      setShowNoSelectionWarning(true);

      return false;
    }

    setIsSubmitting(true);

    // This should never happen, but just in case
    if (!isDefined(user)) {
      toast.error('No user found to assign to wards');
      setIsSubmitting(false);

      return false;
    }

    const promisePool = selectedWardIds.map(async (wardId) => {
      await assignToWard({ variables: { wardId, staffId: user.id } });

      setAssignedWards((prev) => [...prev, wardId]);
    });

    return Promise.all(promisePool)
      .then(() => {
        return true;
      })
      .catch(() => {
        toast.error('Failed to assign staff to ward');

        return false;
      })
      .finally(() => setIsSubmitting(false));
  });

  return (
    <div>
      <Box marginBottom={2}>
        <JourneyHeading display="flex" alignItems="baseline">
          Assign wards
          {Array.isArray(selectedWardIds) && selectedWardIds.length > 0 ? (
            <Typography marginLeft={0.5} fontWeight={500}>
              ({selectedWardIds.length} selected)
            </Typography>
          ) : null}
        </JourneyHeading>
        <Typography variant="body2" color="textSecondary">
          Users can only access patients in the wards to which they have been assigned. Admins can
          amend ward assignments later via ward management.
        </Typography>
      </Box>
      {showNoSelectionWarning && (
        <Alert severity="warning" sx={{ marginBottom: 2 }}>
          Please select at least one ward to assign the user to, or select{' '}
          <b>&quot;Assign to no wards&quot;</b> to skip this step.
        </Alert>
      )}
      {wards.length === 0 && fetchingWards && (
        <Stack direction="row" spacing={2} marginTop={3} justifyContent="center">
          <Typography color="primary.dark" fontWeight="500">
            Fetching wards
          </Typography>
          <CircularProgress size={20} />
        </Stack>
      )}
      {wards.length === 0 && !fetchingWards && (
        <Alert severity="info" sx={{ marginBottom: 2 }}>
          There are no wards available to assign staff to. Admins can add wards via ward management.
          Select &quot;Assign to no wards&quot; to continue adding this user.
        </Alert>
      )}
      <Stack>
        {wards.map((ward, i) => (
          <WardAssignmentRow
            autoFocus={i === 0}
            key={ward.id}
            numberOfPatients={ward.numberOfPatients}
            wardName={ward.name}
            onRowToggle={() => handleRowToggle(ward.id)}
            isWardSelected={isWardSelected(ward.id)}
            isAssigned={assignedWards.includes(ward.id)}
            isSubmitting={isSubmitting}
          />
        ))}
        <WardAssignmentRow
          numberOfPatients={undefined}
          wardName="Assign to no wards"
          onRowToggle={() => handleSkip()}
          isWardSelected={selectedWardIds === Skip}
          isAssigned={false}
          isSubmitting={isSubmitting}
          activeColor="warning.main"
        />
      </Stack>
    </div>
  );
}

interface WardAssignmentRowProps {
  autoFocus?: boolean;
  onRowToggle: () => void;
  wardName: string;
  numberOfPatients: number | undefined;
  isWardSelected: boolean;
  isSubmitting: boolean;
  isAssigned?: boolean;
  activeColor?: string;
}

function WardAssignmentRow({
  onRowToggle,
  wardName,
  autoFocus,
  numberOfPatients,
  isWardSelected,
  isSubmitting,
  isAssigned,
  activeColor = 'primary.main',
}: WardAssignmentRowProps) {
  const checkBoxRef = React.useRef<HTMLInputElement>(null);

  const handleRowClick = () => {
    if (isSubmitting || isAssigned) {
      return;
    }
    onRowToggle();
    checkBoxRef.current?.focus();
  };

  const id = useId();

  const backgroundColor = useMemo(() => {
    if (isWardSelected) {
      return activeColor;
    }

    return 'transparent';
  }, [isWardSelected, activeColor]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      onClick={() => handleRowClick()}
      sx={{
        cursor: 'pointer',
        marginBottom: 1,
        backgroundColor,
        color: isWardSelected ? 'white' : 'primary.dark',
        borderRadius: 4,
      }}>
      <Checkbox
        inputRef={checkBoxRef}
        sx={{
          '&.Mui-checked': { color: isWardSelected ? 'white' : 'text.primary' },
        }}
        autoFocus={autoFocus}
        checked={isWardSelected || isAssigned || false}
        onClick={(e) => {
          if (isAssigned) {
            return;
          }
          onRowToggle();
          e.stopPropagation();
          e.preventDefault();
        }}
        id={id}
        disabled={isSubmitting}
        inputProps={{ 'aria-labelledby': `${id}-label` }}
      />
      <Typography variant="body1" fontWeight={500} id={`${id}-label`}>
        {wardName}{' '}
        {isDefined(numberOfPatients) && (
          <Typography display="inline-block" variant="body2" fontWeight={500}>
            ({numberOfPatients} patients)
          </Typography>
        )}
      </Typography>
      {isAssigned && <Chip label="Assigned" color="success" sx={{ marginLeft: 1 }} />}
    </Stack>
  );
}
