import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Card, Button } from '@mui/material';
import NotFoundImage from './images/notFound.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(10, 8, 0, 8),
    padding: theme.spacing(5, 0, 5, 0),
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${NotFoundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
  },
  linkToHome: {
    margin: theme.spacing(4, 0, 0, 0),
  },
}));

const NotFound = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.container}>
      <Typography variant="h1">404</Typography>
      <Typography variant="h4">
        Sorry, we&apos;re not able to find what you&apos;re looking for.
      </Typography>
      <Typography style={{ paddingTop: 5 }}>
        Use the menu above to go to the page you are looking for or click below to go to the Home
        page.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.linkToHome}
        onClick={() => props.history.replace('/')}>
        Home
      </Button>
    </Card>
  );
};

export default NotFound;
