import React, { useMemo } from 'react';

import { gql } from '@apollo/client';

import { Column } from '@material-table/core';
import { useTheme } from '@mui/material/styles';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TFunction, useTranslation } from 'react-i18next';

import {
  AdminAlertRulesItemFragmentInternal,
  useGetAlertRulesQueryInternal,
  OrganizationInternal,
} from '@/generated/graphql-internal';

import MaterialTableWithIcons from '@/components/MaterialTableWithIcons';
import { useDeleteAlertRuleModal } from './useDeleteAlertRuleModal';
import { useAlertRuleFormModal } from './AlertRuleFormDialog';

export interface AdminAlertRulesProps {
  carePathwayId: string;
  carePathwayName?: string;
  organizationId: string;
  organizationFeatures: OrganizationInternal['features'];
}

export const GET_ALERT_RULES = gql`
  fragment AdminAlertRulesItem on AlertRule {
    id
    name
    type
    description
    condition
    updatedAt
  }

  query GetAlertRules($carePathwayId: ID!) {
    alertRules(carePathwayId: $carePathwayId) {
      ...AdminAlertRulesItem
    }
  }
`;

export default function AdminAlertRules({
  carePathwayId,
  carePathwayName,
  organizationId,
  organizationFeatures,
}: AdminAlertRulesProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const { data, refetch } = useGetAlertRulesQueryInternal({
    variables: { carePathwayId },
    onError: () => undefined,
  });
  const alertRules = useMemo(() => data?.alertRules ?? [], [data]);

  const { showAlertRuleFormModal } = useAlertRuleFormModal({
    onClose: () => refetch(),
    carePathway: { id: carePathwayId, name: carePathwayName ?? '' },
    organizationId,
    organizationFeatures,
  });
  const { showDeleteAlertRuleModal } = useDeleteAlertRuleModal({ onDeleted: () => refetch() });

  return (
    <MaterialTableWithIcons
      title={`Alert Rules`}
      columns={getColumns({ t })}
      data={alertRules}
      options={{
        search: false,
        pageSize: 20,
        emptyRowsWhenPaging: false,
        rowStyle: { height: theme.spacing(8) },
      }}
      actions={[
        {
          icon: () => <AddAlertIcon />,
          tooltip: t('Create new alert rule'),
          isFreeAction: true,
          onClick: () => {
            showAlertRuleFormModal({ alertRule: null });
          },
        },
        {
          icon: () => <EditIcon />,
          tooltip: t('Edit alert rule'),
          onClick: (_, rowData) => {
            showAlertRuleFormModal({
              alertRule: rowData as AdminAlertRulesItemFragmentInternal,
            });
          },
        },
        {
          icon: () => <DeleteIcon />,
          tooltip: t('Delete alert rule'),
          onClick: (_, rowData) => {
            showDeleteAlertRuleModal({
              alertRule: rowData as AdminAlertRulesItemFragmentInternal,
            });
          },
        },
      ]}
    />
  );
}

function getColumns({ t }: { t: TFunction }): Column<AdminAlertRulesItemFragmentInternal>[] {
  return [
    {
      field: 'id',
      title: t('ID'),
      width: 180,
    },
    {
      field: 'type',
      title: t('Type'),
      width: 160,
    },
    {
      field: 'updatedAt',
      type: 'date',
      title: t('Last Updated'),
      width: 140,
    },
    {
      field: 'name',
      title: t('Name'),
      width: 160,
    },
    {
      field: 'description',
      title: t('Description'),
    },
    {
      field: 'condition',
      title: t('Rule condition'),
      render: (rowData) => <code>{rowData.condition}</code>,
    },
  ];
}
