import React from 'react';
import { Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { gql } from '@apollo/client';
import { WardHeaderInfoFragment } from '@/generated/graphql';
import { LabelledValue } from '@/components/LabelledValue';

interface WardHeaderProps extends WardHeaderInfoFragment {
  isLoading: false;
}

interface WardHeaderLoadingProps extends Partial<WardHeaderInfoFragment> {
  isLoading: true;
}

export const WardHeaderInfoFragmentDef = gql`
  fragment WardHeaderInfo on Ward {
    name
    numberOfPatients
    numberOfStaff
  }
`;

export function WardHeader({
  name,
  numberOfPatients,
  numberOfStaff,
  isLoading,
}: WardHeaderProps | WardHeaderLoadingProps) {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.left}>
          <Typography
            component="h2"
            variant="body1"
            color="primary"
            marginBottom={1}
            fontWeight="500">
            {t('Ward Management')}
          </Typography>
          <Typography component="h3" variant="h5" color="textPrimary" className={classes.wardName}>
            {isLoading ? <Skeleton width={200} /> : name}
          </Typography>
        </div>
        <Stack direction="row" spacing={2} alignItems="end">
          <LabelledValue
            heading={t('Patients')}
            loading={isLoading}
            skeletonWidth={16}
            value={numberOfPatients?.toLocaleString() ?? ''}
          />
          <LabelledValue
            heading={t('Staff Members')}
            loading={isLoading}
            skeletonWidth={16}
            value={numberOfStaff?.toLocaleString() ?? ''}
          />
        </Stack>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2, 3),
    maxWidth: 1440,
    margin: '0 auto',
    width: '100%',
  },
  wardName: {
    color: theme.palette.primary.dark,
    lineHeight: 1,
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    marginRight: theme.spacing(5),
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
    flex: 1,
    marginTop: theme.spacing(1),
  },
}));
