import React from 'react';
import { gql } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import DeviceIcon from '@mui/icons-material/PhoneAndroid';
import CheckIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { toast } from 'sonner';

import { useCreateVideoCallRoomMutation } from '@/generated/graphql';
import { isDefined } from '@/helpers/isDefined';

interface VideoCallModalProps extends DialogProps {
  patientId: string;
  patientTelephone: Maybe<string>;
  canReceivePushNotifications: boolean;
  onClose: () => void;
}

export const QUERY_CREATE_VIDEOCALL = gql`
  mutation createVideoCallRoom($patientId: ID!) {
    createVideoCallRoom(patientId: $patientId) {
      hostRoomUrl
      patientRoomUrl
      sentSms
      sentPushNotification
    }
  }
`;

export const VideoCallModal = ({
  open,
  patientId,
  patientTelephone,
  canReceivePushNotifications,
  onClose,
}: VideoCallModalProps) => {
  const classes = useStyles();

  const [createVideoCallRoomMutation, { data, loading, error }] = useCreateVideoCallRoomMutation({
    variables: { patientId: patientId },
    onError: () => toast.error('An error occurred when trying to start a video call'),
  });

  const onSubmit = () => createVideoCallRoomMutation();

  return (
    <Dialog open={open}>
      <DialogTitle>Start a video call with the patient</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ margin: 1 }}>
          Press the <strong>&quot;Start Call&quot;</strong> button below to start a video call with
          the patient.
        </DialogContentText>
        {!patientTelephone && !canReceivePushNotifications ? (
          <Alert severity="info" className={classes.gutter}>
            The patient does not have a telephone number or a registered device but you can still
            ask them to join by sharing the link provided below.
          </Alert>
        ) : null}
        <DialogContentText sx={{ margin: 1 }}>
          The patient will receive notifications to join the call via:
        </DialogContentText>
        <List sx={{ padding: 0 }}>
          <ContactMethod
            icon={<PhoneIcon />}
            label="SMS"
            status={data?.createVideoCallRoom.sentSms}
            hasContactMethod={isDefined(patientTelephone)}
            missingText="No telephone number found"
          />
          <ContactMethod
            icon={<DeviceIcon />}
            label="Phone Notification"
            status={data?.createVideoCallRoom.sentPushNotification}
            hasContactMethod={canReceivePushNotifications}
            missingText="No device registered"
          />
        </List>
        <div className={classes.gutter}>
          {data && (
            <>
              <Alert severity="success">
                Join the call with the patient here:{' '}
                <a target="_blank" rel="noreferrer" href={data.createVideoCallRoom.hostRoomUrl}>
                  {data.createVideoCallRoom.patientRoomUrl}
                </a>
                <div className={classes.gutter}>
                  Please click this link above before you close this dialog as this link will not be
                  shown again.
                </div>
              </Alert>
            </>
          )}
          {loading && <CircularProgress className={classes.alignCenter} />}
          {!data && !loading && (
            <Button
              onClick={onSubmit}
              color="primary"
              variant="contained"
              className={classes.alignCenter}>
              Start Call
            </Button>
          )}
          {error ? (
            <Alert severity="warning" className={classes.gutter}>
              Something went wrong when trying to start a video call.
            </Alert>
          ) : null}
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

interface ContactMethodProps {
  icon: React.ReactNode;
  label: string;
  status: boolean | undefined;
  hasContactMethod: boolean;
  missingText: string;
}

function ContactMethod({ icon, label, status, hasContactMethod, missingText }: ContactMethodProps) {
  return (
    <ListItem sx={{ opacity: hasContactMethod ? 1 : 0.5 }}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>
        <Stack direction="row" gap={1}>
          <strong>{label}</strong>
          {hasContactMethod && isDefined(status) ? (
            <>
              {status ? (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  - Sent <CheckIcon color="success" />
                </Stack>
              ) : (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  - Failed <ErrorIcon color="error" />
                </Stack>
              )}
            </>
          ) : null}
          {!hasContactMethod && missingText}
        </Stack>
      </ListItemText>
    </ListItem>
  );
}

export const useVideoCallModal = () => {
  const { showModal } = useModal();

  return {
    showVideoCallModal: (
      props: Pick<
        VideoCallModalProps,
        'patientId' | 'patientTelephone' | 'canReceivePushNotifications'
      >,
    ) => {
      const modal: ShowFnOutput<VideoCallModalProps> = showModal(
        VideoCallModal,
        {
          ...props,
          onClose: () => {
            return modal.hide();
          },
        },
        { destroyOnClose: true },
      );
      return modal;
    },
  };
};

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    display: 'block',
    margin: '0 auto',
  },
  gutter: {
    marginTop: theme.spacing(2),
  },
}));
