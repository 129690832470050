import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Chip, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { gql } from '@apollo/client';

import {
  CheckupTileConfig,
  CheckupTileRule,
  CheckupTypeDisplayFragment,
  QuestionnaireSectionType,
} from '@/generated/graphql';

import { isDefined } from '@/helpers/isDefined';
import { formatRRule } from '@/helpers/formatRRule';

interface PathwayCheckupTypesProps {
  checkupTypes: CheckupTypeDisplayFragment[];
}

export function PathwayCheckupTypes({ checkupTypes }: PathwayCheckupTypesProps) {
  const classes = useStyles();

  return (
    <Paper className={classes.paperRoot}>
      <Typography gutterBottom className={classes.subtitle}>
        <AssignmentIcon />
        Check-up Types{' '}
        <Typography component="span" variant="body2">
          ({checkupTypes.length} configured)
        </Typography>
      </Typography>
      {checkupTypes.length ? (
        <>
          {checkupTypes.map((checkupType) => (
            <CheckupTypeDisplay key={checkupType.id} checkupType={checkupType} />
          ))}
        </>
      ) : (
        <Typography variant="body2" gutterBottom>
          No configured check-up types for this pathway.
        </Typography>
      )}
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 500,
  },
  fakeThresholdsContainer: {
    maxWidth: '755px',
  },
}));

export const CHECKUP_TYPE_DISPLAY_FRAGMENT = gql`
  fragment CheckupTypeDisplay on CheckupType {
    id
    name
    heading
    description
    updatedAt
    schedule {
      tolerance {
        early
        late
      }
      rrule
    }
    checkupTiles {
      symptomsQuestionnaire {
        questionnaireSections
        rule
      }
      consciousness {
        rule
      }
      pulseOximeter {
        rule
        manualEntry
        usePlethAI
      }
      respiratoryRate {
        rule
      }
      bloodPressureCuff {
        rule
        manualEntry
      }
      temperature {
        rule
      }
      stethoscope {
        forceOnAbnormalRespiratoryRate
        forceOnAbnormalSpO2
        forceOnRespiratorySoftSigns
        rule
      }
      picture {
        rule
      }
      weight {
        rule
      }
      glucose {
        rule
      }
    }
  }
`;

function CheckupTypeDisplay({ checkupType }: { checkupType: CheckupTypeDisplayFragment }) {
  const { t } = useTranslation();
  const classes = useCheckupTypeDisplayStyles();

  return (
    <Box marginY={2} data-testid={`checkuptype-${checkupType.id}`}>
      <Typography className={classes.title} gutterBottom>
        {checkupType.heading}
        <Typography component="span" variant="body2">
          {' '}
          (Modified{' '}
          {t('DATETIME_SHORT', {
            val: new Date(`${checkupType.updatedAt}`),
            formatParams: { val: { timeZone: 'UTC' } },
          })}
          )
        </Typography>
      </Typography>
      <Typography variant="body2" gutterBottom className={classes.description}>
        {checkupType.description}
      </Typography>
      <Box display="flex" alignItems="center">
        <ScheduleIcon className={classes.scheduleIcon} />
        <span>
          <span className={classes.label}>Schedule:</span>{' '}
          {checkupType.schedule ? (
            <span>
              {formatRRule(checkupType.schedule.rrule)}{' '}
              <span>
                ({formatTolerance(checkupType.schedule.tolerance.early)} early or{' '}
                {formatTolerance(checkupType.schedule.tolerance.early)} late)
              </span>
            </span>
          ) : (
            'Unconfigured'
          )}
        </span>
      </Box>
      <Box marginTop={1}>
        <CheckupTileConfigDisplay
          name="Soft Signs"
          tileConfig={checkupType.checkupTiles?.symptomsQuestionnaire}
          additionalConfig={(config) => (
            <>
              Question sections:
              <ul>
                {config.questionnaireSections?.length ? (
                  config.questionnaireSections?.map((section) => (
                    <li key={section}>
                      {HackToHideCustomerNamesFromQuestionPagesNames[section] || section}
                    </li>
                  ))
                ) : (
                  <li>None</li>
                )}
              </ul>
            </>
          )}
        />
        <CheckupTileConfigDisplay
          name="ACVPU: Level of Consciousness"
          tileConfig={checkupType.checkupTiles?.consciousness}
        />
        <CheckupTileConfigDisplay
          name="Blood Pressure"
          tileConfig={checkupType.checkupTiles?.bloodPressureCuff}
          additionalConfig={(config) => (
            <Box
              display="flex"
              alignItems="center"
              aria-label={`Enable manual entry ${config.manualEntry ? 'true' : 'false'}`}>
              Manual entry{' '}
              {config.manualEntry ? <CheckIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
            </Box>
          )}
        />
        <CheckupTileConfigDisplay
          name="Oxygen Saturation"
          tileConfig={checkupType.checkupTiles?.pulseOximeter}
          additionalConfig={(config) => (
            <>
              <Box
                display="flex"
                alignItems="center"
                aria-label={`Enable manual entry ${config.manualEntry ? 'true' : 'false'}`}>
                Manual entry{' '}
                {config.manualEntry ? (
                  <CheckIcon fontSize="small" />
                ) : (
                  <CloseIcon fontSize="small" />
                )}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                aria-label={`Use PlethAI ${config.usePlethAI ? 'true' : 'false'}`}>
                Use PlethAI{' '}
                {config.usePlethAI ? (
                  <CheckIcon fontSize="small" />
                ) : (
                  <CloseIcon fontSize="small" />
                )}
              </Box>
            </>
          )}
        />
        <CheckupTileConfigDisplay
          name="Breathing Rate"
          tileConfig={checkupType.checkupTiles?.respiratoryRate}
        />
        <CheckupTileConfigDisplay
          name="Breath Sounds (Stethoscope)"
          tileConfig={checkupType.checkupTiles?.stethoscope}
          showDisabled={[
            checkupType.checkupTiles?.stethoscope?.forceOnAbnormalRespiratoryRate,
            checkupType.checkupTiles?.stethoscope?.forceOnAbnormalSpO2,
            checkupType.checkupTiles?.stethoscope?.forceOnRespiratorySoftSigns,
          ].some((b) => b === true)}
          additionalConfig={(config) => (
            <>
              <Box
                display="flex"
                alignItems="center"
                aria-label={`Make stethoscope required on abnormal respiratory rate ${
                  config.forceOnAbnormalRespiratoryRate ? 'true' : 'false'
                }`}>
                Make required on abnormal respiratory rate{' '}
                {config.forceOnAbnormalRespiratoryRate ? (
                  <CheckIcon fontSize="small" />
                ) : (
                  <CloseIcon fontSize="small" />
                )}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                aria-label={`Make stethoscope required on abnormal oxygen saturation (SpO2) ${
                  config.forceOnAbnormalSpO2 ? 'true' : 'false'
                }`}>
                Make required on abnormal oxygen saturation (SpO2)
                {config.forceOnAbnormalSpO2 ? (
                  <CheckIcon fontSize="small" />
                ) : (
                  <CloseIcon fontSize="small" />
                )}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                aria-label={`Make stethoscope required on presence of respiratory soft signs ${
                  config.forceOnRespiratorySoftSigns ? 'true' : 'false'
                }`}>
                Make required on presence of respiratory soft signs
                {config.forceOnRespiratorySoftSigns ? (
                  <CheckIcon fontSize="small" aria-label="yes" titleAccess="yes" />
                ) : (
                  <CloseIcon fontSize="small" aria-label="no" titleAccess="no" />
                )}
              </Box>
            </>
          )}
        />
        <CheckupTileConfigDisplay
          name="Temperature"
          tileConfig={checkupType.checkupTiles?.temperature}
        />
        <CheckupTileConfigDisplay name="Weight" tileConfig={checkupType.checkupTiles?.weight} />
        <CheckupTileConfigDisplay name="Glucose" tileConfig={checkupType.checkupTiles?.glucose} />
        <CheckupTileConfigDisplay name="Picture" tileConfig={checkupType.checkupTiles?.picture} />
      </Box>
    </Box>
  );
}

const useCheckupTypeDisplayStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    color: theme.palette.primary.dark,
  },
  label: {
    fontWeight: 500,
  },
  description: {
    fontStyle: 'italic',
    color: theme.palette.grey[800],
    marginBottom: theme.spacing(1),
  },
  scheduleIcon: {
    fontSize: theme.typography.pxToRem(18),
    marginRight: theme.spacing(0.5),
    color: theme.palette.primary.dark,
  },
}));

interface CheckupTileConfigProps<TConfig extends CheckupTileConfig> {
  name: string;
  tileConfig: TConfig | null | undefined;
  showDisabled?: boolean;
  additionalConfig?: (config: TConfig) => React.ReactNode;
}

function CheckupTileConfigDisplay<TConfig extends CheckupTileConfig>({
  name,
  tileConfig,
  showDisabled,
  additionalConfig,
}: CheckupTileConfigProps<TConfig>) {
  const classes = useCheckupTileConfigStyles();

  const hasConfig = isDefined(tileConfig);

  if ((!hasConfig || tileConfig.rule === CheckupTileRule.Disabled) && !showDisabled) {
    return null;
  }

  const ruleText = getRuleText(tileConfig?.rule);

  return (
    <div className={classes.root}>
      <Typography gutterBottom className={classes.title} aria-label={`${name} tile ${ruleText}`}>
        <Chip
          label={ruleText}
          size="small"
          color={getRuleColor(tileConfig?.rule)}
          className={
            tileConfig?.rule === CheckupTileRule.Optional ? classes.optionalChip : undefined
          }
        />
        <span>{name}</span>
      </Typography>
      {hasConfig && isDefined(additionalConfig) ? (
        <div className={classes.additionalConfigContainer}>{additionalConfig(tileConfig)}</div>
      ) : null}
    </div>
  );
}

const useCheckupTileConfigStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    width: '100%',
    padding: theme.spacing(1),
    '& ul': {
      margin: 0,
      paddingLeft: theme.spacing(4),
    },
  },
  additionalConfigContainer: {
    paddingLeft: theme.spacing(10),
  },
  title: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(1),
  },
  optionalChip: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const getRuleText = (rule: CheckupTileRule | undefined) => {
  switch (rule) {
    case CheckupTileRule.Required:
      return 'Required';
    case CheckupTileRule.Optional:
      return 'Optional';
    case CheckupTileRule.Disabled:
      return 'Disabled';
    default:
      return 'Disabled';
  }
};

const getRuleColor = (rule: CheckupTileRule | undefined) => {
  switch (rule) {
    case CheckupTileRule.Required:
      return 'primary';
    case CheckupTileRule.Optional:
      return 'primary';
    case CheckupTileRule.Disabled:
      return 'default';
    default:
      return 'default';
  }
};

const formatTolerance = (seconds: number) => {
  if (seconds < 60) {
    return `${seconds} second${seconds !== 1 ? 's' : ''}`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return `${hours} hour${hours !== 1 ? 's' : ''}`;
  } else {
    const days = Math.floor(seconds / 86400);
    return `${days} day${days !== 1 ? 's' : ''}`;
  }
};

const HackToHideCustomerNamesFromQuestionPagesNames: Partial<
  Record<QuestionnaireSectionType, string>
> = {
  NCHC_Frailty_CP1_20230703: 'Frailty_CP1_20230703',
  NNUH_IVTherapy_CP1_20230703: 'IVTherapy_CP1_20230703',
  NNUH_CM_CP1_20231113: 'CM_CP1_20231113',
  NNUH_Cardiac_20240401: 'Cardiac_20240401',
  NNUH_Respiratory_20240401: 'Respiratory_20240401',
  NNUH_General: 'General',
  NCHC_Cardiac_CP1_20230918: 'Cardiac_CP1_20230918',
  NCHC_Respiratory_CP1_20230918: 'Respiratory_CP1_20230918',
  PHQ_9_Emotional_Wellbeing: 'Emotional_Wellbeing',
  BARTHEL_Activities_Daily_Living: 'Activities_Daily_Living',
  QEH_Cellulitis_20231012: 'Cellulitis_20231012',
  QEH_HeartFailure_20240110: 'HeartFailure_20240110',
  JPUH_Asthma_20231124: 'Asthma_20231124',
  JPUH_Heart_Failure_20231124: 'Heart_Failure_20231124',
  JPUH_Frailty_20231124: 'Frailty_20231124',
  JPUH_Surgical_20231124: 'Surgical_20231124',
  JPUH_Pneumonia_20240102: 'Pneumonia_20240102',
  JPUH_Osteomyelitis_20240102: 'Osteomyelitis_20240102',
  JPUH_Liver_Decomp_20240823: 'Liver_Decomp_20240823',
  QEH_LRTI_6wk_11mo: 'LRTI_6wk_11mo',
  QEH_LRTI_1y_4y: 'LRTI_1y_4y',
  QEH_LRTI_5y_12y: 'LRTI_5y_12y',
  QEH_AKI_20240415: 'AKI_20240415',
  Holywell_UnwellPatient_20240415: 'UnwellPatient_20240415',
  QEH_Paeds_Gastro_20240429: 'Paeds_Gastro_20240429',
  QEH_Paeds_Generic_6mo_18y: 'Paeds_Generic_6mo_18y',
  QEH_Paeds_Wheeze_6mo_18y_LTRI_13y_18y: 'Paeds_Wheeze_6mo_18y_LTRI_13y_18y',
  SEHSCT_Heart_Failure: 'Heart_Failure',
};
