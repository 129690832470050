import React from 'react';
import { feebrisFormatter } from '@/helpers/LocaleFormatting';

import '@/styles/components/blocks/block-weight.scss';
import { CheckupDetailsFragment } from '@/generated/graphql';

interface Props {
  checkup: CheckupDetailsFragment;
}

export default function BlockWeight({ checkup }: Props) {
  const weight = checkup.weight;

  return (
    <div className="block block--weight">
      <div className="block__title">Weight</div>
      <div className="block__inner">
        <div className="block__cols">
          <div>
            <p className="value">{weight ? feebrisFormatter.formatWeightWithUnits(weight) : '-'}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
