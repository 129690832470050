import React, { useState } from 'react';

import { ApiKeyFragment, useSettingsExpireApiKeyMutation } from '@/generated/graphql';
import { gql } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { toast } from 'sonner';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { addHours, formatDistanceToNow } from 'date-fns';
import { useConfirm } from 'material-ui-confirm';

export const MUTATION_SETTINGS_EXPIRE_API_KEY = gql`
  mutation SettingsExpireApiKey($id: ID!, $expiresAt: Date) {
    expireIntegrationApiKey(id: $id, at: $expiresAt)
  }
`;

interface ExpireApiKeyModalProps {
  open: boolean;
  onClose: () => void;
  refetch: () => Promise<unknown>;
  integrationApiKey: ApiKeyFragment;
}

export default function ExpireApiKeyModal(props: ExpireApiKeyModalProps) {
  const { t } = useTranslation();
  const [expiresAt, setExpiresAt] = useState<string>('null');
  const confirm = useConfirm();
  const [expireApiKeyMutation] = useSettingsExpireApiKeyMutation({
    onError: () => toast.error('An error occurred while expiring API key'),
  });

  const onExpire = async () => {
    const expiresAtDate = expiresAt !== 'null' ? addHours(new Date(), parseInt(expiresAt)) : null;

    let confirmed = false;
    try {
      await confirm({
        content: expiresAtDate
          ? t('Are you sure you want to set this API key to expire in {{dist}}', {
              dist: formatDistanceToNow(expiresAtDate),
            })
          : t('Are you sure you want to set this API key to expire immediately?'),
      });
      confirmed = true;
    } catch {
      //
    }

    if (confirmed) {
      await expireApiKeyMutation({
        variables: {
          id: props.integrationApiKey.id,
          expiresAt: expiresAtDate ? expiresAtDate.toISOString() : null,
        },
      });
      await props.refetch();
    }
    onClose();
  };

  const onClose = () => {
    props.onClose();
  };

  return (
    <Dialog
      className="e2e__remove_api_key_modal"
      open={props.open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="remove-api-key-modal-title"
      aria-describedby="remove-api-key-modal-description">
      <DialogTitle id="remove-api-key-modal-title">{t('Expire API key')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="remove-api-key-modal-description">
          <Typography gutterBottom paragraph>
            <Trans t={t}>
              This action will set the expiry for the{' '}
              <code style={{ fontWeight: 600 }}>{props.integrationApiKey.apiKey}</code> API Key.
            </Trans>
          </Typography>
          <Typography gutterBottom paragraph>
            {t(
              'When this key expires it will no longer be usable for access to the Feebris Integrations API.',
            )}
          </Typography>
          <FormControl>
            <FormLabel id="expires-at">Expires At</FormLabel>
            <RadioGroup
              aria-labelledby="expires-at"
              defaultValue="null"
              onChange={({ target: { value } }) => setExpiresAt(value as string)}>
              <FormControlLabel value="null" control={<Radio />} label="Expire immediately" />
              <FormControlLabel value="1" control={<Radio />} label="Expire in 1 hour" />
              <FormControlLabel value="2" control={<Radio />} label="Expire in 2 hours" />
              <FormControlLabel value="3" control={<Radio />} label="Expire in 3 hours" />
              <FormControlLabel value="6" control={<Radio />} label="Expire in 6 hours" />
              <FormControlLabel value="12" control={<Radio />} label="Expire in 12 hours" />
              <FormControlLabel value="24" control={<Radio />} label="Expire in 24 hours" />
              <FormControlLabel value="48" control={<Radio />} label="Expire in 2 days" />
              <FormControlLabel value="168" control={<Radio />} label="Expire in 7 days" />
            </RadioGroup>
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          {t('Cancel')}
        </Button>
        <Button
          className="e2e_modal_confirm_btn"
          onClick={onExpire}
          color="primary"
          variant="contained">
          {t('Expire')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type UseExpireApiKeyModalProps = Pick<ExpireApiKeyModalProps, 'refetch'>;

export function useExpireApiKeyModal({ refetch }: UseExpireApiKeyModalProps) {
  const { showModal } = useModal();

  return {
    showExpireApiKeyModal: (integrationApiKey: ApiKeyFragment) => {
      const modal: ShowFnOutput<ExpireApiKeyModalProps> = showModal(
        ExpireApiKeyModal,
        {
          refetch,
          onClose: () => modal.hide(),
          integrationApiKey,
        },
        { destroyOnClose: true },
      );

      return modal;
    },
  };
}
