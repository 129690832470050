import { useLayoutEffect, useState } from 'react';

export function useStopwatch(startTimeMs: number | undefined, refreshIntervalMs = 500) {
  const [elapsedTimeMs, setElapsedTimeMs] = useState<number | undefined>(0);

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      startTimeMs ? setElapsedTimeMs(Date.now() - startTimeMs) : setElapsedTimeMs(undefined);
    }, refreshIntervalMs);
    return () => clearInterval(interval);
  }, [startTimeMs, refreshIntervalMs]);

  return elapsedTimeMs;
}
