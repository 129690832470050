import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { MfaSetup } from '@/controllers/Auth';
import { QRCodeCanvas } from 'qrcode.react';
import { Alert } from '@mui/material';

interface EnableTwoFactorAuthModalProps {
  onClose: () => void;
  onSuccess?: () => void;
  onError: () => void;
  open: boolean;
  mfaSetup: MfaSetup;
  email: string;
}

export default function EnableTwoFactorAuthModal({
  onClose,
  onSuccess,
  onError,
  open,
  mfaSetup,
  email,
}: EnableTwoFactorAuthModalProps) {
  const classes = useStyles();
  const [authCode, setAuthCode] = useState<string>();
  const [showSetupCode, setShowSetupCode] = useState(false);
  const [showInvalidCodeError, setShowInvalidCodeError] = useState(false);

  const tfaFormId = 'twoFactorAuthEnrolmentForm';

  const Close = () => {
    setShowSetupCode(false);
    setShowInvalidCodeError(false);
    onClose();
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      if (!authCode) {
        return;
      }

      const result = await mfaSetup.finaliseEnrollment(authCode);

      if (result === 'Success') {
        if (onSuccess) {
          onSuccess();
        }

        Close();
      } else if (result === 'InvalidVerificationCode') {
        setShowInvalidCodeError(true);
      }
    } catch {
      onError();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={Close}
      aria-labelledby="alert-dialog-title"
      maxWidth="sm"
      fullWidth>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        Setup two-factor authentication
      </DialogTitle>

      <DialogContent>
        <Box display="flex">
          <form id={tfaFormId}>
            <ol className={classes.list}>
              <li>Open an authenticator app on your device</li>
              <li>
                <Box display="flex" alignItems="flex-start" flexDirection="column">
                  Scan the QR code below with the app
                  <Box marginY={2}>
                    <QRCodeCanvas value={mfaSetup.qrCodeUrl} size={200} />
                  </Box>
                  <Link
                    role="link"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowSetupCode((current) => !current)}>
                    {"Can't scan this QR code?"}
                  </Link>{' '}
                  {showSetupCode && (
                    <Alert severity="info" icon={false}>
                      Instead of scanning, you can add the code to your authenticator app manually
                      using the following details:
                      <TextField
                        className={classes.textField}
                        fullWidth
                        value={email}
                        inputProps={{
                          'aria-label': 'Account name',
                        }}
                        label="Account name"
                        variant="filled"
                      />
                      <TextField
                        className={classes.textField}
                        fullWidth
                        value={mfaSetup.secretKey}
                        inputProps={{
                          'aria-label': 'Secret key',
                        }}
                        label="Secret key"
                        variant="filled"
                      />
                    </Alert>
                  )}
                </Box>
              </li>
              <li>
                Once you see Feebris in the app, enter the code that is displayed
                <TextField
                  inputProps={{
                    'aria-label':
                      'Once you see Feebris in the app, enter the code that is displayed',
                  }}
                  className={classes.textField}
                  fullWidth
                  error={showInvalidCodeError}
                  onChange={(e) => {
                    setAuthCode(e.target.value);
                  }}
                  variant="outlined"
                  helperText={
                    showInvalidCodeError ? 'Please check your code and try again' : undefined
                  }
                />
              </li>
            </ol>
          </form>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={Close} autoFocus>
          Cancel
        </Button>
        <Button
          type="submit"
          form={tfaFormId}
          disabled={!authCode}
          onClick={async (e) => await handleSubmit(e)}
          variant="contained"
          color="primary">
          Complete setup
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  list: {
    fontSize: theme.typography.body1.fontSize,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: 0,
    '& li': {
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    paddingBottom: 0,
  },
  qrCodeCard: {
    display: 'block',

    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
  },
}));
