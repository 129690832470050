import React from 'react';

import { useVirtualWardContext } from '../VirtualWardContext';
import { Box, Chip } from '@mui/material';
import { formatCheckupsSinceHoursAgo } from '../formatters';
import { withStyles } from '@mui/styles';

const formatNameOrNhsNumber = (v: string | null) => {
  if (v === null) {
    return null;
  }

  const smellsLikeNhsNumber = Boolean(v.match(/^(\d|\W)+$/));

  if (smellsLikeNhsNumber) {
    return `NHS number: ${v}`;
  }

  return `Name: ${v}`;
};

export function FilterTags() {
  const {
    filters: {
      wards: wardFilters,
      nameOrNhsNumber,
      checkupsSinceHoursAgo,
      neighbors: neighborsFilter,
    },
    removeFilter,
    setFilter,
    userWards,
    neighbors,
  } = useVirtualWardContext();

  return (
    <Box display="flex" marginTop={1}>
      {Boolean(nameOrNhsNumber?.length) && (
        <FilterChip
          color="primary"
          label={formatNameOrNhsNumber(nameOrNhsNumber)}
          onDelete={() => removeFilter('nameOrNhsNumber')}
        />
      )}
      {checkupsSinceHoursAgo && (
        <FilterChip
          color="primary"
          label={`${formatCheckupsSinceHoursAgo(checkupsSinceHoursAgo)}`}
          onDelete={() => removeFilter('checkupsSinceHoursAgo')}
        />
      )}
      {wardFilters === 'all' && (
        <FilterChip color="primary" label="Your Wards" onDelete={() => setFilter('wards', [])} />
      )}
      {Array.isArray(wardFilters) &&
        wardFilters.map((wardId) => {
          const ward = userWards.find((w) => w.id === wardId);
          /**
           * Something the user has selected is not in their wards.
           * There is handling in the VirtualWardContext to remove these, but it's possible
           * that for a short time we'll have a filter that doesn't match a ward.
           */
          if (!ward) {
            return null;
          }

          return (
            <FilterChip
              key={wardId}
              color="primary"
              label={`Ward: ${ward.name}`}
              onDelete={() =>
                setFilter(
                  'wards',
                  wardFilters?.filter((w) => w !== wardId),
                )
              }
            />
          );
        })}
      {neighborsFilter.map((orgId) => {
        const neighbor = neighbors.find((lo) => lo.id === orgId);
        if (!neighbor) {
          return null;
        }

        return (
          <FilterChip
            key={orgId}
            color="primary"
            label={`Care home: ${neighbor.name}`}
            onDelete={() =>
              setFilter(
                'neighbors',
                neighborsFilter.filter((lo) => lo !== orgId),
              )
            }
          />
        );
      })}
    </Box>
  );
}

const FilterChip = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    fontWeight: 500,
    height: 28,
  },
}))(Chip);
