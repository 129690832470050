import React from 'react';
import { Redirect, Route, RouteProps, Router, Switch } from 'react-router-dom';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import CssBaseline from '@mui/material/CssBaseline';
import { Toaster } from 'sonner';

import '@/helpers/i18n'; // Ensure i18n translations are loaded
import History from '@/helpers/History';
import { pathWithNext, getNext } from '@/helpers/nextUrl';

import auth from '@/controllers/Auth';

import {
  OffpisteRoute,
  MaterialStylesRoute,
  SuperAdminRoute,
  CustomRouteProps,
  RestrictedRoute,
} from '@/components/ProtectedRoute';

import { feebrisTheme } from './theme';

// Helpers
import AuthorizedApolloClientProvider from '@/AuthorizedApolloClientProvider';
import { useMeActingOrganizationFeature } from '@/hooks/useAuth';

// Pages
import VirtualWard from '@/pages/VirtualWard';
import Login from '@/pages/Login';
import { ExpiredShareToken } from '@/pages/ExpiredShareToken';
import ForgotPassword from '@/pages/ForgotPassword';
import FirebaseAuthAction from '@/pages/FirebaseAuthAction';
import PatientDetail from '@/pages/PatientDetail';
import ManagePatients, { canAccessManagePatientsPage } from '@/pages/ManagePatients';
import { ManageWardRoutes, canAccessManageWardRoutes } from '@/pages/ManageWards';
import { AccountManagement } from '@/pages/AccountManagement';
import CheckupDetails from '@/pages/CheckupDetails';
import { AdminOrganizations, AdminOrganization } from '@/pages/Admin';
import Settings from '@/pages/Settings';
import NotFound from '@/pages/NotFound';
import Terms from '@/pages/Terms';
import PatientsWelcome from '@/pages/Welcome/PatientsWelcome';

// Layouts
import LoginLayout from '@/layouts/LoginLayout';
import FirebaseAuthActionLayout from '@/layouts/FirebaseAuthActionLayout';
import TermsLayout from '@/layouts/TermsLayout';

// Special components
import AutomaticLogoutAlert from '@/components/AutomaticLogoutAlert';
import MaintenanceBanner from '@/components/MaintenanceBanner';

import '@/styles/app.scss';
import ModalProvider from 'mui-modal-provider';
import {
  IntegrationsScreen,
  canAccessIntegrationEventsPage,
} from './pages/Integrations/Integrations';
import { ManagePathwayRoutes } from './pages/ManagePathways';
import { canAccessManageUsersPage } from './pages/Settings/Users';
import { QuicksilvaError } from './pages/QuicksilvaError';
import { VersionUpdateBanner } from './components/VersionUpdateBanner';
import { LinkIdentity } from './pages/LinkIdentity/LinkIdentity';

const ShareRoute = (props: RouteProps) => {
  auth.captureShareToken();

  return (
    <Route {...props}>
      <Redirect to={getNext('/')} />
    </Route>
  );
};

const LoginRoute = ({ component: Component, ...rest }: CustomRouteProps) => {
  const queryParams = new URLSearchParams(window.location.search);

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (auth.isLoggedIn()) {
          if (queryParams.has('externalAuthIdentity')) {
            return (
              <Redirect
                to={{
                  pathname: `/link-identity`,
                  search: `?next=${getNext('/')}`,
                  state: { encodedExternalAuthIdentity: queryParams.get('externalAuthIdentity') },
                }}
              />
            );
          } else {
            return <Redirect to={getNext('/')} />;
          }
        }
        return (
          <LoginLayout>
            <Component {...matchProps} />
          </LoginLayout>
        );
      }}
    />
  );
};

const LoggedOutRoute = ({ component: Component, ...rest }: CustomRouteProps) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        return (
          <LoginLayout>
            <Component {...matchProps} />
          </LoginLayout>
        );
      }}
    />
  );
};

const TermsRoute = ({ component: Component, ...rest }: CustomRouteProps) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (!auth.isLoggedIn()) {
          return <Redirect to={pathWithNext('/login')} />;
        }
        if (auth.me('actingOrganization.acceptedTerms')) {
          return <Redirect to={getNext('/')} />;
        }
        return (
          <TermsLayout>
            <Component {...matchProps} />
          </TermsLayout>
        );
      }}
    />
  );
};

/**
 * This is intended to be a completely public route, even logged in users can get here.
 */
const FirebaseAuthActionRoute = ({ component: Component, ...rest }: CustomRouteProps) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        return (
          <FirebaseAuthActionLayout>
            <Component {...matchProps} />
          </FirebaseAuthActionLayout>
        );
      }}
    />
  );
};

const App = () => {
  const displayAutomaticLogoutAlertAfter = useMeActingOrganizationFeature('automaticLogoutAlert');

  const SuperProvider = composeWrappers([
    ({ children }) => <React.StrictMode>{children}</React.StrictMode>,
    ({ children }) => <AuthorizedApolloClientProvider>{children}</AuthorizedApolloClientProvider>,
    ({ children }) => (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={feebrisTheme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    ),
    ({ children }) => <ConfirmProvider>{children}</ConfirmProvider>,
    ({ children }) => <HelmetProvider>{children}</HelmetProvider>,
  ]);

  return (
    <SuperProvider>
      <CssBaseline />
      <Helmet titleTemplate="Feebris | %s" defaultTitle="Feebris" />
      <Toaster
        richColors
        position="bottom-center"
        style={{
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
      />
      <Router history={History}>
        {/**
         * The modal provider needs access to the router context for any Link elements
         * that are rendered inside a modal.
         */}
        <ModalProvider legacy={true}>
          <Switch>
            <ShareRoute exact path="/share" />
            <LoginRoute label="login" exact path="/login" component={Login} />
            <LoginRoute
              label="forgot-password"
              exact
              path="/forgot-password"
              component={ForgotPassword}
            />
            <LoginRoute
              label="expired-share-token"
              exact
              path="/expiredShareToken"
              component={ExpiredShareToken}
            />
            <LoggedOutRoute exact path="/quicksilva-error" component={QuicksilvaError} />
            <TermsRoute label="terms" path="/terms" component={Terms} />
            <OffpisteRoute label="welcome" exact path="/welcome" component={PatientsWelcome} />
            <MaterialStylesRoute
              label="virtual-ward"
              path="/virtual-ward"
              component={VirtualWard}
            />
            <FirebaseAuthActionRoute
              label="firebase-auth-action"
              exact
              path="/firebase/auth/action"
              component={FirebaseAuthAction}
            />
            <OffpisteRoute
              label="checkup-details"
              path="/patient/:patientId/checkup/:checkupId"
              component={CheckupDetails}
            />
            <OffpisteRoute
              label="patient-details"
              path="/patient/:patientId/:tabName?"
              component={PatientDetail}
            />
            <RestrictedRoute
              canAccess={canAccessManagePatientsPage}
              label="patients"
              path="/patients"
              component={ManagePatients}
            />
            <RestrictedRoute
              canAccess={canAccessManageWardRoutes}
              label="wards"
              path="/wards"
              component={ManageWardRoutes}
            />
            <MaterialStylesRoute
              label="pathways"
              path="/pathways"
              component={ManagePathwayRoutes}
            />
            <RestrictedRoute
              canAccess={canAccessIntegrationEventsPage}
              label="integrations"
              path="/integrations"
              component={IntegrationsScreen}
            />
            <MaterialStylesRoute label="account" path="/account" component={AccountManagement} />
            <RestrictedRoute
              canAccess={canAccessManageUsersPage}
              label="settings"
              path="/settings/:tabName?"
              component={Settings}
            />
            <SuperAdminRoute
              label="admin-organization"
              path="/admin/:organizationId/:tabName?"
              component={AdminOrganization}
            />
            <SuperAdminRoute label="admin" path="/admin" component={AdminOrganizations} />
            <Route exact path="/">
              <Redirect to="/virtual-ward" />
            </Route>
            <OffpisteRoute label="link-identity" path="/link-identity" component={LinkIdentity} />
            <MaterialStylesRoute label="notFound" path="*" component={NotFound} />
          </Switch>
        </ModalProvider>
      </Router>
      {!auth.isShareTokenUser() &&
      displayAutomaticLogoutAlertAfter &&
      displayAutomaticLogoutAlertAfter > 0 ? (
        <AutomaticLogoutAlert displayAlertAfter={displayAutomaticLogoutAlertAfter} />
      ) : null}
      <MaintenanceBanner />
      <VersionUpdateBanner />
    </SuperProvider>
  );
};

// See: https://github.com/kolodny/react-compose-wrappers/blob/master/index.ts
type Wrapper = React.FunctionComponent<{ children: React.ReactNode }>;
const composeWrappers = (wrappers: Wrapper[]): Wrapper => {
  return wrappers.reduceRight((Acc, Current) => {
    // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
    return (props) => React.createElement(Current, null, React.createElement(Acc, props as any));
  });
};

export default App;
