import React, { useMemo } from 'react';
import { Paper, Typography, Button, Box } from '@mui/material';
import { PageContainer } from '@/components/PageContainer';
import { getNext } from '@/helpers/nextUrl';
import { Link, Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useLinkExternalAuthIdentityMutation } from '@/generated/graphql';
import { toast } from 'sonner';
import { useHistory } from 'react-router-dom';

export const LINK_EXTERNAL_IDENTITY = gql`
  mutation LinkExternalAuthIdentity($externalAuthIdentity: ExternalAuthIdentityInput!) {
    linkExternalAuthIdentity(externalAuthIdentity: $externalAuthIdentity)
  }
`;

export type ExternalAuthIdentity = {
  externalUserId: string;
  externalUserOrgId: string;
  from: string;
};

export type LinkIdentityProps = {
  // We get to this component via react-router's redirect and the way data is passed via react-router's redirect
  // causes the data to be put into a 'location.state' object
  location: { state: { encodedExternalAuthIdentity: string | null } };
};

export const LinkIdentity = ({
  location: {
    state: { encodedExternalAuthIdentity },
  },
}: LinkIdentityProps) => {
  const decodedExternalAuthIdentity = useMemo(
    () =>
      encodedExternalAuthIdentity ? decodeExternalAuthIdentity(encodedExternalAuthIdentity) : null,
    [encodedExternalAuthIdentity],
  );

  const history = useHistory();
  const nextPage = getNext();

  const [linkExternalAuthIdentity, { loading }] = useLinkExternalAuthIdentityMutation({
    onCompleted: () => {
      toast.success('Identity linked successfully');
      history.replace(nextPage);
    },
    onError: () => {
      toast.error('There was a problem linking your identity');
    },
  });

  // We're only supporting Quicksilva currently so just being defensive
  return decodedExternalAuthIdentity && decodedExternalAuthIdentity.from === 'Quicksilva' ? (
    <PageContainer>
      <Box padding={2}>
        <Paper sx={{ width: '100%', maxWidth: '1044px', margin: '0 auto' }}>
          <Box padding={2}>
            <Typography color="primary.dark" component="h1" variant="h5" mb={2}>
              Link your Feebris login to the Quicksilva Toolbar
            </Typography>

            <Typography mb={1}>
              After linking your account, subsequent access to patient records will not require you
              to manually provide login details.
            </Typography>
            <Typography mb={3}>This can be undone in your account settings.</Typography>

            <Box display="flex" gap={2}>
              <Link to={nextPage} replace={true}>
                <Button size={'large'} variant="outlined" disabled={loading}>
                  Skip
                </Button>
              </Link>
              <Button
                size={'large'}
                disabled={loading}
                variant="contained"
                onClick={async () =>
                  await linkExternalAuthIdentity({
                    variables: {
                      externalAuthIdentity: {
                        externalOrgId: decodedExternalAuthIdentity.externalUserOrgId,
                        externalUserId: decodedExternalAuthIdentity.externalUserId,
                        type: decodedExternalAuthIdentity.from,
                      },
                    },
                  })
                }>
                Link Account
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </PageContainer>
  ) : (
    <Redirect to="/" />
  );
};

const decodeExternalAuthIdentity = (
  encodedExternalAuthIdentity: string,
): ExternalAuthIdentity | null => {
  try {
    const parsedJson = JSON.parse(window.atob(encodedExternalAuthIdentity));

    if (
      !parsedJson ||
      !parsedJson.externalUserId ||
      !parsedJson.externalUserOrgId ||
      !parsedJson.from
    ) {
      return null;
    }

    return parsedJson as ExternalAuthIdentity;
  } catch {
    return null;
  }
};
