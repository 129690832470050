import React from 'react';

import { gql } from '@apollo/client';
import { Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink, Redirect, Route, Switch, useParams } from 'react-router-dom';

import { useGetWardByIdQuery } from '@/generated/graphql';

import { WardHeader } from './WardHeader';
import { WardPatients } from './WardPatients';
import { WardStaff } from './WardStaff';
import { Helmet } from 'react-helmet-async';
import { PageContainer } from '@/components/PageContainer';

export const GET_WARD_BY_ID = gql`
  query GetWardById($id: ID!) {
    ward(id: $id) {
      id
      ...WardHeaderInfo
    }
  }
`;

export default function ManageWard() {
  const { wardId } = useParams<{ wardId: string }>();
  const classes = useStyles();

  const {
    data,
    loading: fetchingWards,
    refetch,
  } = useGetWardByIdQuery({
    variables: { id: wardId },
  });

  const isLoading = fetchingWards && !data; // Don't show loading state if we have data

  return (
    <div>
      <Helmet>
        <title>Manage Ward</title>
      </Helmet>
      <WardHeader
        name={data?.ward.name}
        numberOfPatients={data?.ward.numberOfPatients}
        numberOfStaff={data?.ward.numberOfStaff}
        isLoading={(isLoading || !data) as never}
      />
      <PageContainer>
        <Box role="tablist" paddingBottom={2}>
          <Button
            component={NavLink}
            to={`/wards/${wardId}/patients`}
            role="tab"
            id="manage-ward-patients-tab"
            aria-controls="manage-ward-patients-tabpanel"
            variant="contained"
            color="primary"
            disableElevation
            className={classes.tabButton}>
            Patients
          </Button>
          <Button
            component={NavLink}
            to={`/wards/${wardId}/staff`}
            role="tab"
            id="manage-ward-staff-tab"
            aria-controls="manage-ward-staff-tabpanel"
            variant="contained"
            color="primary"
            disableElevation
            className={classes.tabButton}>
            Staff
          </Button>
        </Box>
        <div role="tabpanel">
          <Switch>
            <Route path="/wards/:wardId/patients">
              <div
                role="tabpanel"
                id="manage-ward-patients-tabpanel"
                aria-labelledby="manage-ward-patients-tab">
                <WardPatients
                  wardId={wardId}
                  wardName={`${data?.ward.name}`}
                  onRemoved={() => refetch()}
                />
              </div>
            </Route>
            <Route path="/wards/:wardId/staff">
              <div
                role="tabpanel"
                id="manage-ward-staff-tabpanel"
                aria-labelledby="manage-ward-staff-tab">
                <WardStaff
                  wardId={wardId}
                  wardName={data?.ward?.name ?? 'ward'}
                  onAssigned={() => refetch()}
                  onRemoved={() => refetch()}
                />
              </div>
            </Route>
            <Redirect to="/wards/:wardId/patients" />
          </Switch>
        </div>
      </PageContainer>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  tabButton: {
    fontSize: theme.typography.body1.fontSize,
    marginRight: theme.spacing(2),
    padding: theme.spacing(0.5, 2.5),
    borderRadius: theme.shape.borderRadius * 8,
    background: 'transparent',
    color: theme.palette.primary.dark,
    textTransform: 'none',
    transition: theme.transitions.create(['background', 'color']),
    '&:hover': {
      background: theme.palette.grey.A100,
    },
    '&.active': {
      color: theme.palette.background.paper,
      background: theme.palette.primary.dark,
    },
  },
}));
