'use strict';

/**
 * Given thresholds and measurements will return an activity score breakdown to measure deterioration
 *
 * Usage:
 *
 *   ```
 *   const scores = activityScoreCalculator(
 *     DEFAULT_ACTIVITY_THRESHOLDS,
 *     {
 *       gaitSpeed: 80,
 *     }
 *   );
 *
 *   // scores will now be:
 *   // {
 *   //   gaitSpeedScore: 1,
 *   // }
 *   ```
 */

function activityScoreCalculator(thresholds, measurements) {
  const scores = {
    gaitSpeedScore: scoreByBands(thresholds.gaitSpeed, measurements.gaitSpeed, 'gaitSpeed'),
  };

  return !Object.values(scores).every((v) => v === null) ? scores : null;
}

function scoreByBands(bands, value, measurementName) {
  if (value === null || value === undefined) {
    return null;
  }

  for (const band of bands) {
    const inLow = band.low ? value >= band.low : true;
    const inHigh = band.high ? value <= band.high : true;

    if (inLow && inHigh) {
      return band.score;
    }
  }

  throw Error(
    `Unable to score ${measurementName} value ${value} as it does not fit within bands ${bands}`,
  );
}

/**
 * DO NOT CHANGE, these are values reflecting the gait speed bands from Pacsana
 */
const DEFAULT_ACTIVITY_THRESHOLDS = {
  gaitSpeed: [
    { low: 85, score: 0 }, // "high" is unbounded
    { high: 85, low: 50, score: 1 },
    { high: 50, low: 40, score: 2 },
    { high: 40, score: 3 }, // "low" is unbounded
  ],
};

module.exports = {
  activityScoreCalculator,
  DEFAULT_ACTIVITY_THRESHOLDS,
};
