import React, { useMemo, useState } from 'react';

import clsx from 'clsx';
import { formatRelative } from 'date-fns';
import { Box, Popover, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import { Alert, AlertTitle } from '@mui/material';

import {
  BatteryStatus,
  VirtualWardPatientActivityMonitoringSessionFragment,
} from '@/generated/graphql';
import { isDefined } from '@/helpers/isDefined';

type MetricsState = {
  noMetricsForPreviousDay: boolean;
  zeroesAcrossMetricsForPreviousDay: boolean;
};

type ActivityMonitoringSessionState = {
  sessionCreatedAt: string;
  latestMetricsDate: Maybe<string>;
  latestEventDate: Maybe<string>;
  battery: Maybe<BatteryStatus>;
  metrics: MetricsState;
  kitId: string;
};

const getActivityMonitoringOverviewState = (
  activityMonitoringSession: VirtualWardPatientActivityMonitoringSessionFragment,
): ActivityMonitoringSessionState => {
  const { status, createdAt, pacsanaUserName } = activityMonitoringSession;

  return {
    kitId: pacsanaUserName,
    sessionCreatedAt: createdAt,
    latestMetricsDate: status?.latestMetricsDate,
    latestEventDate: status?.latestEventDate,
    metrics: {
      noMetricsForPreviousDay: status.noMetricsForPreviousDay,
      zeroesAcrossMetricsForPreviousDay: status.zeroesAcrossMetricsForPreviousDay,
    },
    battery: status?.batteryStatus,
  };
};

export function ActivityMonitoringSessionStatus({
  activityMonitoringSession,
}: {
  activityMonitoringSession: VirtualWardPatientActivityMonitoringSessionFragment;
}) {
  const classes = usePopoverStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const state = useMemo(
    () => getActivityMonitoringOverviewState(activityMonitoringSession),
    [activityMonitoringSession],
  );

  return (
    <>
      <PopoverIcon
        setPopoverAnchorEl={setAnchorEl}
        battery={state.battery}
        metrics={state.metrics}
      />
      <Popover
        className={classes.statePopover}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus>
        <Box margin={2}>
          {state.battery && <BatteryWarning battery={state.battery} />}
          <MetricsWarnings metrics={state.metrics} />
          <Box marginTop={2}>
            <Typography className={classes.popoverTitle}>
              Session started at:{' '}
              <Typography component="span" variant="body1">
                {formatRelative(new Date(state.sessionCreatedAt), new Date())}
              </Typography>
            </Typography>
            <Typography className={classes.popoverTitle}>
              Kit ID:{' '}
              <Typography component="span" variant="body1">
                {state.kitId}
              </Typography>
            </Typography>
            {isDefined(state.latestMetricsDate) && (
              <Typography className={classes.popoverTitle}>
                Last daily metric:{' '}
                <Typography component="span" variant="body1">
                  {formatRelative(new Date(state.latestMetricsDate), new Date())}
                </Typography>
              </Typography>
            )}
            {isDefined(state.latestEventDate) && (
              <Typography className={classes.popoverTitle}>
                Last activity alert:{' '}
                <Typography component="span" variant="body1">
                  {formatRelative(new Date(state.latestEventDate), new Date())}
                </Typography>
              </Typography>
            )}
            {isDefined(state.battery) && <BatteryInfo battery={state.battery} />}
          </Box>
        </Box>
      </Popover>
    </>
  );
}

const PopoverIcon = ({
  setPopoverAnchorEl,
  battery,
  metrics,
}: {
  setPopoverAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  battery: Maybe<BatteryStatus>;
  metrics: MetricsState;
}) => {
  const iconStyles = useIconStyles();

  const severityClass = useMemo(() => {
    if (battery === BatteryStatus.EndOfLife || metrics.noMetricsForPreviousDay) {
      return iconStyles.dangerState;
    }

    if (battery === BatteryStatus.Expiring || metrics.zeroesAcrossMetricsForPreviousDay) {
      return iconStyles.warningState;
    }

    return iconStyles.positiveState;
  }, [battery, metrics, iconStyles]);

  const stateIcon = useMemo(() => {
    if (battery === BatteryStatus.EndOfLife) {
      return <BatteryAlertIcon className={iconStyles.statusIcon} />;
    }

    if (metrics.noMetricsForPreviousDay) {
      return <WifiOffIcon className={iconStyles.statusIcon} />;
    }

    if (battery === BatteryStatus.Expiring) {
      return <BatteryAlertIcon className={iconStyles.statusIcon} />;
    }

    if (metrics.zeroesAcrossMetricsForPreviousDay) {
      return <DirectionsWalkIcon className={iconStyles.statusIcon} />;
    }

    return <DirectionsWalkIcon className={iconStyles.statusIcon} />;
  }, [battery, metrics, iconStyles.statusIcon]);

  return (
    <Box
      data-testid="activity-monitoring-popover"
      className={clsx(severityClass, iconStyles.statusContainer)}
      flexShrink={0}
      onMouseEnter={(e) => setPopoverAnchorEl(e.currentTarget)}
      onMouseLeave={() => setPopoverAnchorEl(null)}>
      {stateIcon}
    </Box>
  );
};

const MetricsWarnings = ({ metrics }: { metrics: MetricsState }) => {
  const popoverStyles = usePopoverStyles();

  const { noMetricsForPreviousDay, zeroesAcrossMetricsForPreviousDay } = metrics;

  if (noMetricsForPreviousDay) {
    return (
      <Box className={popoverStyles.popoverContainer}>
        <Alert severity="error" className={popoverStyles.popoverWarning}>
          <AlertTitle>No vitals were recorded for the previous day</AlertTitle>
          <Typography>Daily activity monitoring vitals are not available.</Typography>
          <Typography>Please, contact the Feebris Support Team (support@feebris.com)</Typography>
        </Alert>
      </Box>
    );
  }

  if (zeroesAcrossMetricsForPreviousDay) {
    return (
      <Box className={popoverStyles.popoverContainer}>
        <Alert severity="warning" className={popoverStyles.popoverWarning}>
          <AlertTitle>The patient appeared inactive for the previous day</AlertTitle>
          <Typography>
            Please advise the patient to ensure the device is connected and their internet
            connection is working.
          </Typography>
        </Alert>
      </Box>
    );
  }

  return <></>;
};

const BatteryWarning = ({ battery }: { battery: BatteryStatus }) => {
  const popoverStyles = usePopoverStyles();

  if (battery === BatteryStatus.Offline) {
    return (
      <Box marginTop={2} className={popoverStyles.popoverContainer}>
        <Alert severity="warning" className={popoverStyles.popoverWarning}>
          <AlertTitle>Battery status unknown</AlertTitle>
          <Typography>The bracelet is offline.</Typography>
          <Typography>
            Please advise the patient to ensure the device is connected and their internet
            connection is working.
          </Typography>
        </Alert>
      </Box>
    );
  }

  if (battery === BatteryStatus.Expiring) {
    return (
      <Box marginTop={2} className={popoverStyles.popoverContainer}>
        <Alert severity="warning" className={popoverStyles.popoverWarning}>
          <AlertTitle>Battery approaching depletion</AlertTitle>
          <Typography>The battery of the device is approaching depletion.</Typography>
          <Typography>The device will soon need returning.</Typography>
        </Alert>
      </Box>
    );
  }

  if (battery === BatteryStatus.EndOfLife) {
    return (
      <Box marginTop={2} className={popoverStyles.popoverContainer}>
        <Alert severity="error" className={popoverStyles.popoverWarning}>
          <AlertTitle>Battery depleted</AlertTitle>
          <Typography>The battery of the device is depleted.</Typography>
          <Typography>Please return the device at your earliest convenience.</Typography>
        </Alert>
      </Box>
    );
  }

  return <></>;
};

const BatteryInfo = ({ battery }: { battery: BatteryStatus }) => {
  const deviceBatteryStyles = useDeviceBatteryStyles();

  const BatteryData = (): {
    icon: JSX.Element;
    healthText: string;
  } => {
    switch (battery) {
      case BatteryStatus.EndOfLife:
        return {
          icon: (
            <BatteryAlertIcon
              className={clsx(
                deviceBatteryStyles.popoverBatteryIcon,
                deviceBatteryStyles.criticalBatteryIcon,
              )}
            />
          ),
          healthText: 'Depleted',
        };
      case BatteryStatus.Expiring:
        return {
          icon: (
            <Battery20Icon
              className={clsx(
                deviceBatteryStyles.popoverBatteryIcon,
                deviceBatteryStyles.warningBatteryIcon,
              )}
            />
          ),
          healthText: 'Expiring',
        };
      case BatteryStatus.Okay:
        return {
          icon: (
            <BatteryFullIcon
              className={clsx(
                deviceBatteryStyles.popoverBatteryIcon,
                deviceBatteryStyles.healthyBatteryIcon,
              )}
            />
          ),
          healthText: 'Healthy',
        };
      case BatteryStatus.Offline:
        return {
          icon: (
            <BatteryUnknownIcon
              className={clsx(
                deviceBatteryStyles.popoverBatteryIcon,
                deviceBatteryStyles.unknownBatteryIcon,
              )}
            />
          ),
          healthText: 'Offline',
        };
    }
  };

  const batteryData = BatteryData();

  return (
    <Box>
      <Typography gutterBottom className={deviceBatteryStyles.popoverDeviceBattery}>
        <span className={deviceBatteryStyles.label}>Battery: </span>
        {batteryData.healthText}
        {batteryData.icon}
      </Typography>
    </Box>
  );
};

const useIconStyles = makeStyles((theme) => ({
  statusIcon: {
    // Optically center the icon (true center is slightly off)
    marginBottom: theme.spacing(0.25),
    fontSize: 'small',
  },
  positiveState: {
    color: '#325e50',
    backgroundColor: '#3fca82',
    borderColor: '#325e50',
  },
  warningState: {
    backgroundColor: '#F9E1A7',
    color: '#433b14',
    borderColor: '#D39A61',
  },
  dangerState: {
    backgroundColor: '#efbebe',
    color: '#5b2d2d',
    borderColor: '#DB5757',
  },
  statusContainer: {
    display: 'flex',
    marginRight: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    borderRadius: 21,
    borderWidth: '1px',
    borderStyle: 'solid',
    fontWeight: 500,
    lineHeight: 1,
    fontSize: theme.typography.pxToRem(18),
  },
}));

const usePopoverStyles = makeStyles((theme) => ({
  checkupDate: {
    fontSize: theme.typography.pxToRem(14),
  },
  statePopover: {
    pointerEvents: 'none',
    marginTop: theme.spacing(1.5),
  },
  popoverTitle: {
    fontWeight: 500,
  },
  label: {
    marginTop: theme.spacing(2),
    fontWeight: 500,
  },
  popoverWarning: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  popoverContainer: {
    maxWidth: 650,
  },
}));

const useDeviceBatteryStyles = makeStyles((theme) => ({
  popoverDeviceBattery: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  popoverBatteryIcon: {
    lineHeight: 1,
  },
  criticalBatteryIcon: {
    color: '#DB5757',
  },
  unknownBatteryIcon: {
    color: '#6E6E6E',
  },
  warningBatteryIcon: {
    color: '#D39A61',
  },
  healthyBatteryIcon: {
    color: theme.palette.success.main,
  },
  label: {
    fontWeight: 500,
    marginRight: theme.spacing(0.5),
  },
}));
