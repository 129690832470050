import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Breadcrumbs, Link, Skeleton, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import TabPanel from '@/components/TabPanel';
import AdminUsers from './AdminUsers';
import { AdminCarePathwayRoutes } from './AdminCarePathways';
import { PatientSearch } from './PatientSearch';
import { gql } from '@apollo/client';
import { useOrganizationAdminQueryInternal } from '@/generated/graphql-internal';
import { OrganizationSettings } from './OrganizationSettings';
import { OrganizationFeatureFlags } from '@/hooks/useAuth';

export const QUERY_ORGANIZATION = gql`
  fragment OrganizationAdminView on Organization {
    id
    name
    type
    features
    roles {
      name
      isSystemRole
    }
  }

  query OrganizationAdmin($id: ID!) {
    organization(id: $id) {
      ...OrganizationAdminView
    }
  }
`;

export default function AdminOrganization() {
  const { organizationId, tabName } = useParams<{ organizationId: string; tabName: string }>();
  const classes = useStyles();

  const getDefaultTabNumber = useCallback(() => {
    switch (tabName) {
      default:
      case 'users':
        return 0;
      case 'carepathways':
        return 1;
      case 'patients':
        return 2;
      case 'settings':
        return 3;
    }
  }, [tabName]);

  const [tabNumber, setTabNumber] = useState(getDefaultTabNumber());

  const { data, loading } = useOrganizationAdminQueryInternal({
    variables: { id: organizationId },
  });

  const organization = data?.organization;
  const loadingFirstTime = loading && !organization;

  // Reset tab number when tabName changes outside of button clicks (e.g. when the URL changes)
  useEffect(() => {
    setTabNumber(getDefaultTabNumber());
  }, [getDefaultTabNumber]);

  return (
    <div>
      <Helmet>
        <title>{organization?.name}</title>
      </Helmet>
      <div className={classes.header}>
        <div className={classes.tableHeaderBar}>
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
            <Link color="inherit" to="/admin" component={RouterLink}>
              Feebroid Admin
            </Link>
            <Link
              color="inherit"
              to={`/admin/${organizationId}`}
              component={RouterLink}
              aria-current="page">
              {loadingFirstTime ? <Skeleton width={100} /> : organization?.name}
            </Link>
          </Breadcrumbs>
        </div>
        <Tabs
          className={classes.tabs}
          value={tabNumber}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, newValue) => setTabNumber(newValue)}>
          <Tab label="Users" to={`/admin/${organizationId}/users`} component={RouterLink} />
          <Tab
            label="Care Pathways"
            to={`/admin/${organizationId}/carepathways`}
            component={RouterLink}
          />
          <Tab label="Patients" to={`/admin/${organizationId}/patients`} component={RouterLink} />
          <Tab label="Settings" to={`/admin/${organizationId}/settings`} component={RouterLink} />
        </Tabs>
      </div>
      <div>
        {loadingFirstTime ? null : (
          <>
            <TabPanel value={tabNumber} index={0}>
              <AdminUsers organization={organization} />
            </TabPanel>
            <TabPanel value={tabNumber} index={1}>
              <AdminCarePathwayRoutes organizationFeatures={organization?.features} />
            </TabPanel>
            <TabPanel value={tabNumber} index={2}>
              <PatientSearch />
            </TabPanel>
            <TabPanel value={tabNumber} index={3}>
              <OrganizationSettings
                organizationId={organizationId}
                features={organization?.features as OrganizationFeatureFlags}
              />
            </TabPanel>
          </>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.paper,
  },
  tableHeaderBar: {
    margin: theme.spacing(3, 2, 1, 3),
    display: 'flex',
  },
  tabs: {
    borderBottom: `solid 1px ${theme.palette.action.selected}`,
  },
}));
