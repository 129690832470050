import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AdminCarePathway } from './AdminCarePathway';
import { AdminCarePathways } from './AdminCarePathways';
import { OrganizationInternal } from '@/generated/graphql-internal';

interface AdminCarePathwayRoutesProps {
  organizationFeatures: OrganizationInternal['features'];
}

export function AdminCarePathwayRoutes({ organizationFeatures }: AdminCarePathwayRoutesProps) {
  return (
    <Switch>
      <Route
        path="/admin/:organizationId/carepathways/:carePathwayId"
        component={() => <AdminCarePathway organizationFeatures={organizationFeatures} />}
      />
      <Route path="/admin/:organizationId/carepathways" component={AdminCarePathways} />
    </Switch>
  );
}
