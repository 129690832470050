import React from 'react';

import { Box } from '@mui/material';
import { AccountSecurityCard } from './AccountSecurity';
import { QuicksilvaIntegrationCard } from './QuicksilvaIntegration';
import { useMeActingOrganizationFeature, useMePermission } from '@/hooks/useAuth';
import { PageTitle } from '@/components/PageTitle';
import auth from '@/controllers/Auth';
import UserProfile from './UserProfile';

export function AccountManagement() {
  const hasEhrIntegrationPermission = useMePermission('link_ehr_integration_identity');
  const ehrIntegrationsEnabled = useMeActingOrganizationFeature('ehrIntegrations');

  return (
    <Box margin={3}>
      <PageTitle title="Account Management" subtitle="Configure your profile and settings" />
      <UserProfile />
      {!auth.isShareTokenUser() ? <AccountSecurityCard marginTop={3} /> : null}
      {ehrIntegrationsEnabled && hasEhrIntegrationPermission && (
        <QuicksilvaIntegrationCard marginTop={3} />
      )}
    </Box>
  );
}
