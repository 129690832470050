import React, { useCallback } from 'react';

import { gql } from '@apollo/client';
import { useConfirm } from 'material-ui-confirm';
import { useRegisterAsApproverMutation } from '@/generated/graphql';
import { toast } from 'sonner';
import { Box, Typography } from '@mui/material';
import { Alert } from '@mui/material';

interface RegisterAsApproverProps {
  integrationId: string;
  onRegistered: () => void;
}

export const REGISTER_AS_APPROVER_MUTATION = gql`
  mutation RegisterAsApprover($integrationId: ID!) {
    registerAsEhrIntegrationAutoApprover(ehrIntegrationId: $integrationId) {
      id
      authorizedAutomaticApprover {
        id
        email
      }
    }
  }
`;

const TOAST_ID = 'register-auto-approver-toast';

export function useRegisterAsApprover({ integrationId, onRegistered }: RegisterAsApproverProps) {
  const confirm = useConfirm();

  const [registerAsApproverMutation] = useRegisterAsApproverMutation({
    variables: {
      integrationId,
    },
    onCompleted: () => {
      toast.success('Successfully registered as approver', {
        id: TOAST_ID,
        duration: 4000,
      });
      onRegistered();
    },
    onError: () => {
      toast.error('Failed to register as approver', { id: TOAST_ID, duration: 4000 });
    },
  });

  return useCallback(async () => {
    try {
      await confirm({
        title: 'Confirm automatic approval registration',
        confirmationButtonProps: {
          variant: 'contained',
          color: 'primary',
        },
        confirmationText: 'Register',
        description: (
          <Box>
            <Alert severity="info">
              <Typography gutterBottom>
                Registering as an approver will allow the Quicksilva toolbar to automatically
                approve the integrations as your Quicksilva user.
              </Typography>
              <Typography>
                You can unregister as an approver at any time by clicking the &quot;Unregister as
                approver&quot; button.
              </Typography>
            </Alert>
          </Box>
        ),
      });

      toast.loading('Registering as approver', {
        id: TOAST_ID,
        duration: Infinity,
      });

      registerAsApproverMutation();
    } catch {
      // the user cancelled the dialog
    }
  }, [confirm, registerAsApproverMutation]);
}
