import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import clsx from 'clsx';
import { generatePassphrase } from '@/helpers/passphrase';

const useStyles = makeStyles((theme) => ({
  password: {
    border: '2px dashed #000',
    borderRadius: theme.spacing(1),
    backgroundColor: '#f3fbfd',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  regenerateButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    marginTop: -24, // TODO: How to calculate button height dynamically (ie: 48px / 2)?
  },
}));

export default function PassphraseGenerator({
  password,
  setPassword,
  regenerateEnabled,
  buttonText,
}) {
  const classes = useStyles();
  buttonText = buttonText ?? 'Generate Your New Password';

  // see: https://stackoverflow.com/a/987376/806988
  const selectAllText = (e) => {
    if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(e.target);
      range.select();
    } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(e.target);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  return (
    <div className={classes.password}>
      {password ? (
        <Typography onClick={selectAllText}>
          <span className="e2e__password">{password}</span>
          {regenerateEnabled && (
            <IconButton
              aria-label="regenerate"
              className={clsx(classes.regenerateButton, 'e2e__regeneratepasswordbutton')}
              onClick={() => setPassword(generatePassphrase())}
              size="large">
              <RefreshIcon />
            </IconButton>
          )}
        </Typography>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setPassword(generatePassphrase())}
          className="e2e__generatepasswordbutton">
          {buttonText}
        </Button>
      )}
    </div>
  );
}
