import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import auth from '@/controllers/Auth';
import api from '@/controllers/Api';
import posthog from '@/helpers/posthog';

const AUTOMATIC_LOGOUT_TIMEOUT = 5 * 60 * 1000;

export default function AutomaticLogoutAlert(props) {
  const [idTokenRefreshCounter, setIdTokenRefreshCounter] = useState(0);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    auth.idTokenRefreshedCallback = () => setIdTokenRefreshCounter((count) => count + 1);
  }, []);

  // This effect makes the alert window open if auth.idTokenRefreshedCallback hasn't been called
  // recently enough. We use a react state, idTokenRefreshCounter, as a lazy way to trigger the
  // effect at the correct times
  useEffect(() => {
    // Hide the alert any time idTokenRefreshCounter is incremented because the id token was clearly
    // just refreshed
    setOpen(false);
    const timer = setTimeout(() => {
      if (auth.isLoggedIn()) {
        posthog.capture('display_automatic_logout_alert');
        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'User is inactive, displaying AutomaticLogoutAlert',
          level: 'info',
        });
        setOpen(true);
      }
    }, props.displayAlertAfter);
    return () => clearTimeout(timer);
  }, [idTokenRefreshCounter, props.displayAlertAfter]);

  // This effect invokes `auth.signout` when the alert window stays open for too long without
  // the user choosing an option
  useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => {
        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'User is did not response to AutomaticLogoutAlert',
          level: 'info',
        });
        auth.signout();
      }, AUTOMATIC_LOGOUT_TIMEOUT);
    }
    return () => clearTimeout(timer);
  }, [open]);

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{t('Your session is about to end')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(
            'You have been inactive for a while. For your security, we\'ll automatically sign you out. Choose "Stay signed in" to continue or "Sign out" if you\'re done',
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => auth.signout()} variant="text">
          {t('Sign out')}
        </Button>
        <Button onClick={() => api.didStayLoggedIn()} variant="contained" autoFocus>
          {t('Stay signed in')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
