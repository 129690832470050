import React from 'react';

import { Route, useRouteMatch } from 'react-router-dom';
import ManageWard from './ManageWard';
import ManageWards from './ManageWards';
import { RestrictedRouteTester } from '@/components/ProtectedRoute';

export const canAccessManageWardRoutes: RestrictedRouteTester = ({ featureFlags, permissions }) =>
  featureFlags?.wards === true && permissions['view_wards'];

export function ManageWardRoutes() {
  const { path } = useRouteMatch();

  return (
    <>
      <Route path={`${path}`} exact component={ManageWards} />
      <Route path={`${path}/:wardId`} component={ManageWard} />
    </>
  );
}
