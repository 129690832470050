import React, { ReactNode } from 'react';

interface TabPanelProps {
  children: ReactNode;
  value: number | string;
  index: number | string;
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index ? children : null}
    </div>
  );
}
