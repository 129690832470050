import * as d3 from 'd3';
import _ from 'lodash';
import localeFormatting from '@/helpers/LocaleFormatting';

// Finds the corresponding X axis label for the position of the mouse when navigating through the chart.
// D3 does not have an .invertExtent() function for scale bands.
export function scaleXBandPosition(event, scale) {
  const domain = scale.domain();
  const range = scale.range();
  const step = scale.step();
  const padding = scale.padding();
  const bandwidth = scale.bandwidth();
  const rangePoints = d3.range(step * padding + bandwidth / 2, range[1] - step * padding, step);
  return domain[d3.bisectCenter(rangePoints, parseFloat(d3.pointer(event)[0]))];
}

// Finds the corresponding Y axis label for the position of the mouse when navigating through the chart.
// D3 does not have an .invertExtent() function for scale bands.
export function scaleYBandPosition(event, scale) {
  const domain = scale.domain().reverse();
  const range = scale.range().reverse();
  const step = scale.step();
  const padding = scale.padding();
  const bandwidth = scale.bandwidth();
  const rangePoints = d3.range(step * padding + bandwidth / 2, range[1] - step * padding, step);
  return domain[d3.bisectCenter(rangePoints, parseFloat(d3.pointer(event)[1]))];
}

// Returns an array with the range of each of the score bands with the format [lowerBound, upperBound].
export function calculateLegendBands(legendDomain, thresholdDomain) {
  let valuesForRanges = [];
  valuesForRanges.push(legendDomain[0]);
  valuesForRanges = valuesForRanges.concat(thresholdDomain);
  valuesForRanges.push(legendDomain[1]);

  const rectRanges = [];
  for (let i = 0; i < valuesForRanges.length - 1; i++) {
    rectRanges.push([valuesForRanges[i], valuesForRanges[i + 1]]);
  }

  return rectRanges;
}

// Divides the legend domain in separate bins and classifies the input data for each of those.
// Returns an array of bins and the corresponding measurement values for each.
export function calculateBins(data, vitalSelector, legendDomain, thresholdDomain, yAxisStepSize) {
  const axisDivisions = calculateAxisDivisions(legendDomain, thresholdDomain, yAxisStepSize);
  const binFunction = d3.bin().domain(legendDomain).thresholds(axisDivisions);
  // Classify the input measurements in each of the bins.
  const bins = binFunction(d3.map(data, (checkup) => _.get(checkup, vitalSelector, null)));

  // Upper limit of the bin in D3 is exclusive, so we need to
  // delete the last bin and move the corresponding values to the previous one.
  const lastBin = bins.pop();
  if (lastBin.length > 0) {
    for (const value of lastBin) {
      bins[bins.length - 1].push(value);
    }
  }

  return bins;
}

export function calculateCircleYPosition(value, bins, yScale, legendScale) {
  for (let i = 0; i < bins.length; i++) {
    if (bins[i].includes(value)) {
      return yScale(i) + yScale.bandwidth() / 2;
    }
  }

  const bin = value < legendScale.domain()[0] ? 0 : bins.length - 1;
  return yScale(bin) + yScale.bandwidth() / 2;
}

export function calculateScore(value, colorBands, thresholdScale) {
  return colorBands.indexOf(thresholdScale(value));
}

export function calculateAxisDates(checkups, maxDates) {
  const dates = d3.map(
    checkups.filter((checkup) => !_.isUndefined(checkup)),
    (checkup) => localeFormatting.formatCheckupTimeShortWithoutYearAndWeekDay(checkup.endedAt),
  );
  const datesLeft = maxDates - dates.length;
  for (let additionalCheckup = 1; additionalCheckup <= datesLeft; additionalCheckup++) {
    dates.push(`Next check-up ${additionalCheckup}`);
  }
  return dates;
}

// Custom function to divide the legend domain in different bins given a best-effort step size
// but taking into account threshold values to limit the score bands.
export function calculateAxisDivisions(legendDomain, thresholdDomain, yAxisStepSize) {
  // We add a delta because the D3 range method does not contain the upper bound.
  const delta = 0.001;
  const axisDivisions = d3.range(legendDomain[0], legendDomain[1] + delta, yAxisStepSize);

  // Replace closest values of the array with threshold values of the legend
  // to ensure a single score band is contained for a bin.
  const replaceableValues = [...axisDivisions];
  replaceableValues.shift();
  replaceableValues.pop();

  for (const threshold of thresholdDomain) {
    // The inserted threshold value cannot contain the lower bound of the next bin.
    const thresholdValue = threshold - delta;
    const closestIndex = d3.bisectCenter(replaceableValues, thresholdValue);
    const valueToReplace = replaceableValues[closestIndex];
    const indexToReplace = axisDivisions.indexOf(valueToReplace);
    axisDivisions[indexToReplace] = thresholdValue;
    replaceableValues.splice(closestIndex, 1);
  }

  axisDivisions.sort((a, b) => a - b);
  return axisDivisions;
}
