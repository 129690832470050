import React from 'react';

import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Helmet } from 'react-helmet-async';

interface PageTitleProps {
  title: string;
  subtitle: string;
  /**
   * The title to set in the browser tab. If not provided, the `title` prop will be used.
   */
  titleMeta?: string;
  marginBottom?: number;
}

/**
 * This component is used to display a page title and subtitle.
 * It is used in the header of pages, to introduce some context to the page.
 *
 * It also sets the page title in the browser tab.
 */
export function PageTitle({ title, subtitle, marginBottom = 3, titleMeta }: PageTitleProps) {
  const classes = useStyles();

  return (
    <Box marginBottom={marginBottom}>
      <Helmet>
        <title>{titleMeta ?? title}</title>
      </Helmet>
      <Typography component="h2" variant="h5" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1" className={classes.subTitle}>
        {subtitle}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.dark,
  },
  subTitle: {
    color: theme.palette.grey[600],
  },
}));
