import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';

import { withErrorBoundary } from '@/components/ErrorBoundary';

import logo from '@/images/logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    height: '100vh',
  },
  logo: {
    width: 60,
    marginLeft: -40,
    marginTop: '10%',
  },
  content: {
    width: 500,
    marginTop: 60,
  },
}));

interface FirebaseAuthActionLayoutProps {
  children: React.ReactNode;
}

function FirebaseAuthActionLayout({ children }: FirebaseAuthActionLayoutProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.logo} src={logo} alt="Feebris logo, a chameleon" />
      <Card className={classes.content} elevation={0}>
        {children}
      </Card>
    </div>
  );
}

export default withErrorBoundary(FirebaseAuthActionLayout);
