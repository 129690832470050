import React, { useEffect } from 'react';

import { gql } from '@apollo/client';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { toast } from 'sonner';
import * as Yup from 'yup';

import { useUpdatePatientContactDetailsMutation } from '@/generated/graphql';

import { muiFormikGetFieldProps } from '@/helpers/formik';
import { getMutationErrors } from '@/AuthorizedApolloClientProvider';
import { isDefined } from '@/helpers/isDefined';

import { useAdmitPatientJourneyContext } from '../types';

export const UPDATE_CONTACT_DETAILS_MUTATION = gql`
  mutation UpdatePatientContactDetails($patientId: ID!, $newContact: ContactDetailsInput!) {
    updatePatientContactDetails(patientId: $patientId, newContact: $newContact) {
      id
      telephone
      address {
        address
        postcode
      }
    }
  }
`;

const contactDetailsFormSchema = Yup.object().shape({
  telephone: Yup.string().required('Required'),
  address: Yup.object().shape({
    address: Yup.string().required('Required'),
    postcode: Yup.string().required('Required'),
  }),
});

export function AddContactDetailsStep() {
  const {
    currentJourneyState: { patient },
    setIsSubmitting,
    gotoNextStep,
  } = useAdmitPatientJourneyContext();

  const [updateContactDetails, { loading: isUpdatingContact, error }] =
    useUpdatePatientContactDetailsMutation({
      onCompleted: (data) => {
        gotoNextStep({
          patient: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...patient!,
            telephone: data.updatePatientContactDetails.telephone,
            address: isDefined(data.updatePatientContactDetails.address)
              ? {
                  address: data.updatePatientContactDetails.address.address,
                  postcode: data.updatePatientContactDetails.address.postcode,
                }
              : null,
          },
          contactUpdated: true,
        });
      },
      onError: () => {
        toast.error('Failed to update contact details');
      },
    });

  const formik = useFormik({
    validationSchema: contactDetailsFormSchema,
    initialValues: {
      telephone: patient?.telephone ?? '',
      address: {
        address: patient?.address?.address ?? '',
        postcode: patient?.address?.postcode ?? '',
      },
    },
    onSubmit: (values) => {
      // Shouldn't happen, but just in case
      if (!patient) {
        return;
      }

      const hasChanged =
        values.telephone !== patient.telephone ||
        values.address.address !== patient.address?.address ||
        values.address.postcode !== patient.address?.postcode;

      if (!hasChanged) {
        gotoNextStep();
        return;
      }

      updateContactDetails({
        variables: {
          patientId: patient.id,
          newContact: {
            telephone: values.telephone,
            address: {
              address: values.address.address,
              postcode: values.address.postcode,
            },
          },
        },
      });
    },
  });

  const { argErrors } = getMutationErrors(error);
  const getFieldProps = muiFormikGetFieldProps(
    formik,
    argErrors?.patient,
    contactDetailsFormSchema,
  );

  useEffect(() => {
    setIsSubmitting(isUpdatingContact);
  }, [isUpdatingContact, setIsSubmitting]);

  return (
    <Box marginTop={0.5}>
      <Typography variant="body2" color="textSecondary">
        Please provide contact information to ensure the patient can receive calls from the care
        team and the kit can be collected after discharge.
      </Typography>
      <form id="contactDetailsForm" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Address"
              variant="outlined"
              placeholder={'e.g. 123a Fake Street, London'}
              InputLabelProps={{ shrink: true }}
              // fix typings for dot access...
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              {...getFieldProps('address.address' as any)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Postcode"
              variant="outlined"
              placeholder={'e.g. SW1A 1AA'}
              InputLabelProps={{ shrink: true }}
              // fix typings for dot access...
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              {...getFieldProps('address.postcode' as any)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              {...getFieldProps('telephone')}
              placeholder="Enter phone number"
              label="Phone Number"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
