import React from 'react';

import { gql } from '@apollo/client';
import { Chip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WarningIcon from '@mui/icons-material/Warning';
import PhoneIcon from '@mui/icons-material/Phone';
import AlarmIcon from '@mui/icons-material/Alarm';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import TimeAgo from 'react-timeago';
import clsx from 'clsx';

import { Alert, AlertChipFieldsFragment, AlertType, Patient } from '@/generated/graphql';
import { useAlertModal } from './AlertModal';
import { formatAlertName } from '../formatters';

export const ALERT_CHIP_FRAGMENT = gql`
  fragment AlertChipFields on Alert {
    _aggregate {
      count
    }
    id
    type
    status
    startedAt
    alertRule {
      id
      name
      description
    }
    checkupScheduleEvent {
      id
      checkupExpectedAt
      checkupSchedule {
        id
        tolerance {
          early
          late
        }
      }
    }
    pacsanaEvent {
      eventId
      eventKey
    }
  }
`;

interface AlertChipProps {
  alert: AlertChipFieldsFragment;
  patient: Pick<Patient, 'id' | 'firstName' | 'lastName' | 'numSimilarNames'>;
  refresh: () => void;
}

export function AlertChip({ alert, patient, refresh }: AlertChipProps) {
  const classes = useStyles();

  const count =
    alert._aggregate?.count && alert._aggregate.count > 1 ? alert._aggregate.count : null;

  const { showAlertModal } = useAlertModal({
    alert: alert as Alert,
    patient,
    refreshData: refresh,
  });

  const alertName = formatAlertName(alert);

  return (
    <Chip
      key={alert.id}
      variant="outlined"
      label={
        <>
          <Typography display="inline" variant="body2" className={classes.chipAlertName}>
            {alertName}
          </Typography>

          <Typography display="inline" variant="body2" className={classes.chipStartedAt}>
            {alert.startedAt && <TimeAgo date={alert.startedAt} />}
          </Typography>
          {count && (
            <span aria-label={`${count} occurrences of ${alertName}`} className={classes.count}>
              {count > 99 ? '99+' : count}
            </span>
          )}
        </>
      }
      icon={
        alert.type
          ? {
              [AlertType.AlertRule]: <WarningIcon className={classes.icon} fontSize="small" />,
              [AlertType.CheckupScheduleEvent]: (
                <AlarmIcon className={classes.icon} fontSize="small" />
              ),
              [AlertType.PatientCallbackRequest]: (
                <PhoneIcon className={classes.icon} fontSize="small" />
              ),
              [AlertType.PacsanaEvent]: (
                <DirectionsWalkIcon className={classes.icon} fontSize="small" />
              ),
            }[alert.type]
          : undefined
      }
      onClick={() => {
        showAlertModal();
      }}
      className={clsx(classes.chip, classes.chipAlert)}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.25),
    height: '28px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.grey[400],
    '& > .MuiChip-label': {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0.5, 0.5, 1),
    },
  },
  count: {
    display: 'inline-flex',
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
    minWidth: '20px',
    height: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '1',
    fontSize: theme.typography.pxToRem(12),
    padding: theme.typography.pxToRem(3),
    fontWeight: 500,
  },
  icon: {
    // Specificity hack
    '&.MuiChip-icon': {
      color: theme.palette.error.main,
      marginLeft: theme.spacing(0.75),
    },
  },
  chipAlert: {
    borderRadius: theme.spacing(2),
    fontWeight: 400,
  },
  chipAlertName: {
    color: theme.palette.error.main,
    fontWeight: 500,
  },
  chipStartedAt: {
    marginLeft: theme.spacing(0.5),
  },
}));
