import React from 'react';
import { gql } from '@apollo/client';
import { Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportantOutlined';

import { AlertRuleDisplayFragment } from '@/generated/graphql';

interface PathwayAlertsProps {
  alertRules: AlertRuleDisplayFragment[];
}

export function PathwayAlerts({ alertRules }: PathwayAlertsProps) {
  const classes = useStyles();

  return (
    <Paper className={classes.paperRoot}>
      <Typography gutterBottom className={classes.subtitle}>
        <NotificationImportantIcon />
        Alert Rules{' '}
        <Typography component="span" variant="body2">
          ({alertRules.length} configured)
        </Typography>
      </Typography>
      {alertRules.length ? (
        <>
          {alertRules.map((alertRule) => (
            <AlertRuleDisplay key={alertRule.id} alertRule={alertRule} />
          ))}
        </>
      ) : (
        <Typography variant="body2" gutterBottom>
          No configured alerts for this pathway.
        </Typography>
      )}
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 500,
  },
  fakeThresholdsContainer: {
    maxWidth: '755px',
  },
}));

export const ALERT_RULE_DISPLAY_FRAGMENT = gql`
  fragment AlertRuleDisplay on AlertRule {
    id
    name
    description
    condition
  }
`;

function AlertRuleDisplay({ alertRule }: { alertRule: AlertRuleDisplayFragment }) {
  const classes = useAlertRuleDisplayStyles();

  return (
    <div className={classes.root}>
      <Typography gutterBottom className={classes.title}>
        <span>{alertRule.name}</span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span className={classes.label}>Description:</span> {alertRule.description}
      </Typography>
      <Typography component="div" variant="body2" gutterBottom>
        <span className={classes.label}>Trigger Condition:</span>{' '}
        <pre className={classes.condition}>{alertRule.condition}</pre>
      </Typography>
    </div>
  );
}

const useAlertRuleDisplayStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 500,
    color: theme.palette.primary.dark,
  },
  condition: {
    display: 'inline-block',
    margin: 0,
    textWrap: 'balance',
  },
  label: {
    fontWeight: 500,
  },
}));
