import React, { useCallback } from 'react';
import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { gql } from '@apollo/client';
import {
  PatientDetailsFragment,
  useTransferPatientToWardMutation,
  Ward,
} from '@/generated/graphql';
import { useModal } from 'mui-modal-provider';
import _ from 'lodash';
import { useFormik } from 'formik';
import { toast } from 'sonner';
import { muiFormikGetFieldProps } from '@/helpers/formik';
import * as Yup from 'yup';

type TransferWardModalPatient = Pick<PatientDetailsFragment, 'id' | 'firstName' | 'lastName'> & {
  currentWardId: string;
};

type TransferWardModalWard = Pick<Ward, 'id' | 'name'>;

interface TransferWardModalProps {
  patient: TransferWardModalPatient;
  wards: TransferWardModalWard[];
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
}

interface TransferWardFormValues {
  wardId: string | undefined;
  patientName: string;
}

const transferWardSchema = (currentAdmissionWardId: string, currentAdmissionPatientName: string) =>
  Yup.object<TransferWardFormValues>().shape({
    wardId: Yup.string()
      .required('Ward selection required')
      .notOneOf(
        [currentAdmissionWardId],
        'Target ward must be different to current ward admission',
      ),
    patientName: Yup.string()
      .required('Patient name must be confirmed')
      .test(
        'name-comparison',
        'Patient name must match',
        (name) =>
          name.trim().localeCompare(currentAdmissionPatientName, undefined, {
            sensitivity: 'accent',
          }) === 0,
      ),
  });

export function TransferWardModal({
  patient,
  wards,
  open,
  onClose,
  onComplete,
}: TransferWardModalProps) {
  const [transferWardMutation] = useTransferPatientToWardMutation({
    onCompleted: () => {
      onComplete();
      onClose();
    },
    onError: () => toast.error('An error occured when transferring the patient to the ward'),
  });

  const handleSubmit = async (values: TransferWardFormValues) => {
    if (values.wardId) {
      await transferWardMutation({
        variables: {
          patientId: patient.id,
          wardId: values.wardId,
        },
      });
    }
  };

  const patientFullName = `${patient.firstName.trim()} ${patient.lastName.trim()}`;

  const formik = useFormik({
    initialValues: {
      wardId: undefined,
      patientName: '',
    },
    validationSchema: transferWardSchema(patient.currentWardId, patientFullName),
    onSubmit: handleSubmit,
  });

  const getFieldProps = muiFormikGetFieldProps(formik);

  const formId = 'transferPatientToWardForm';

  return (
    <form id={formId} onSubmit={formik.handleSubmit}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          Transfer {patient.firstName} {patient.lastName}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography py={1}>
              Please select a new ward for {patient.firstName} {patient.lastName}
            </Typography>

            <Alert severity="info" sx={{ marginTop: 1, marginBottom: 3 }}>
              Transferring the patient to another ward may impact their visibility for other staff
              members depending upon staff ward assignments.
            </Alert>

            <Autocomplete
              fullWidth
              options={wards}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => formik.setFieldValue('wardId', value?.id || '')}
              noOptionsText="No wards found"
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...getFieldProps('wardId', { fireOnChange: false })}
                  autoFocus
                  placeholder="Select a ward for transfer"
                  label="Ward"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />

            <TextField
              fullWidth
              sx={{ marginTop: 3 }}
              label="Confirm patient name"
              value={formik.values.patientName}
              {...getFieldProps('patientName')}
              type="string"
              variant="outlined"
              placeholder={patientFullName}
              helperText="Please type the patient's full name to confirm"
              required
              InputLabelProps={{ shrink: true }}
              inputProps={{
                'aria-label': 'Patient transfer confirm patient name',
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button
            type="submit"
            form={formId}
            disabled={!formik.isValid}
            color="primary"
            variant="contained">
            Transfer
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export const TRANSFER_WARD = gql`
  mutation TransferPatientToWard($patientId: ID!, $wardId: ID!) {
    transferPatientToWard(patientId: $patientId, wardId: $wardId) {
      patient {
        id
      }
    }
  }
`;

export const useTransferWardModal = ({ onComplete }: { onComplete: () => void }) => {
  const { showModal } = useModal();

  const showTransferWardModal = useCallback(
    (patient: TransferWardModalPatient, wards: TransferWardModalWard[]) => {
      function hide() {
        modal.hide();
      }
      const modal = showModal(
        TransferWardModal,
        {
          patient,
          wards,
          onClose: () => hide(),
          onComplete,
        },
        {
          destroyOnClose: true,
        },
      );
      return modal;
    },
    [onComplete, showModal],
  );

  return { showTransferWardModal };
};
