import React, { useMemo, useCallback } from 'react';

import _ from 'lodash';
import { gql } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useFormik } from 'formik';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { Alert, Autocomplete } from '@mui/material';
import { toast } from 'sonner';

import {
  useStartPacsanaSessionMutation,
  useGetPacsanaKitsWithActiveSessionQuery,
} from '@/generated/graphql';

import { getMutationErrors } from '@/AuthorizedApolloClientProvider';
import { muiFormikGetFieldProps } from '@/helpers/formik';

export const GET_PACSANA_KITS_WITH_ACTIVE_SESSION_QUERY = gql`
  query GetPacsanaKitsWithActiveSession {
    pacsanaKitsWithActiveSession {
      kitId
      activeSession {
        id
        patient {
          firstName
          lastName
        }
      }
    }
  }
`;

export const START_SESSION_QUERY = gql`
  mutation StartPacsanaSession($pacsanaUserName: String!, $patientId: ID!) {
    startPacsanaSession(pacsanaUserName: $pacsanaUserName, patientId: $patientId) {
      id
    }
  }
`;

interface StartActivityMonitoringSessionModalProps extends DialogProps {
  patientId: string;
  onAdded: () => void;
  onCancel: () => void;
}

export function StartActivityMonitoringSessionModal({
  open,
  patientId,
  onAdded,
  onCancel,
}: StartActivityMonitoringSessionModalProps) {
  const classes = useStyles();

  const { data: kitsData, loading: kitsLoading } = useGetPacsanaKitsWithActiveSessionQuery({
    onError: () => toast.error('An error occurred when fetching kits for selection'),
  });

  const sortedKits = useMemo(
    () => _.sortBy(kitsData?.pacsanaKitsWithActiveSession ?? [], (k) => k?.kitId),
    [kitsData?.pacsanaKitsWithActiveSession],
  );

  const [
    startActivityMonitoringSession,
    { loading: isSubmitting, error: startActivityMonitoringSessionError },
  ] = useStartPacsanaSessionMutation({
    onCompleted: () => {
      onAdded();
    },
    onError: () => {
      toast.error('An error occurred when starting the activity monitoring session');
    },
  });

  const { argErrors } = getMutationErrors(startActivityMonitoringSessionError);

  const formik = useFormik({
    initialValues: {
      kitId: '',
    },
    onSubmit: async (values) => {
      await startActivityMonitoringSession({
        variables: {
          patientId,
          pacsanaUserName: values.kitId,
        },
      });
    },
  });

  const getFieldProps = muiFormikGetFieldProps(formik, argErrors);
  const kitIdFieldProps = getFieldProps('kitId', { fireOnChange: false });

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      aria-labelledby="start-activity-monitoring-session-dialog__title">
      <DialogTitle>Start activity monitoring</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Alert severity="info">
            Once a session is started, the patient will start receiving:
            <ul className={classes.informationList}>
              <li>Alerts related to their activity and mobility</li>
              <li>Values for the active and exercise minutes in the previous calendar day</li>
              <li>
                Value of maximum gait speed over the last 7 calendar days (generated on day 8 and
                each day thereafter)
              </li>
            </ul>
          </Alert>
          <Autocomplete
            className={classes.select}
            loading={kitsLoading}
            options={sortedKits}
            getOptionLabel={(option) =>
              option.activeSession
                ? `${option.kitId} - Currently assigned  to ${option.activeSession.patient.firstName} ${option.activeSession.patient.lastName}`
                : `${option.kitId}`
            }
            getOptionDisabled={(option) => Boolean(option.activeSession)}
            isOptionEqualToValue={(option, value) => option.kitId === value.kitId}
            onChange={(_, value) => formik.setFieldValue('kitId', value?.kitId || '')}
            renderInput={(params) => (
              <TextField
                {...params}
                {...kitIdFieldProps}
                placeholder="Select a Kit ID"
                label="Kit ID"
                InputLabelProps={{
                  shrink: true,
                }}
                required
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
            Start monitoring
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  select: {
    marginTop: theme.spacing(2),
  },
  informationList: {
    padding: 0,
    marginLeft: theme.spacing(2),
    marginBottom: 0,
  },
}));

interface UseActivityMonitoringSessionModalProps {
  onAdded?: () => void;
}

export const useStartActivityMonitoringSessionModal = ({
  onAdded,
}: UseActivityMonitoringSessionModalProps = {}) => {
  const { showModal } = useModal();

  const showStartActivityMonitoringSessionModal = useCallback(
    (patientId: string) => {
      const modal: ShowFnOutput<StartActivityMonitoringSessionModalProps> = showModal(
        StartActivityMonitoringSessionModal,
        {
          patientId,
          onAdded: () => {
            onAdded?.();
            modal.hide();
          },
          onCancel: () => modal.hide(),
        },
        { destroyOnClose: true },
      );

      return modal;
    },
    [onAdded, showModal],
  );

  return {
    showStartActivityMonitoringSessionModal,
  };
};
