import { useState } from 'react';
import { useEvent } from 'react-use';

/**
 * Check if the window is currently visible or not.
 *
 * This is useful for components that want to pause or stop certain actions when the window is not visible,
 * such as polling for updates or animations.
 *
 * @returns {boolean} Whether the window is currently visible or not
 */
export function useWindowVisible() {
  const [visible, setVisible] = useState(true);

  const onVisibilityChange = ({ target }: Event) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setVisible((target && !(target as any)['hidden']) ?? false);

  useEvent('visibilitychange', onVisibilityChange, window);

  return visible;
}
