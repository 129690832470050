import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {
    palette: typeof palette & Theme['palette'];
  }
}

// We'll create the theme with the palette first, this will allow us to use the palette in the overrides
// and then we'll create the theme with the overrides
const themeWithPalette = createTheme({
  palette: {
    // Feebris colours
    // see: https://app.abstract.com/projects/af946b80-a6f8-11e8-9d7a-bf5bcfecf32f/branches/master/commits/latest/files/9544BA12-81DF-4434-8124-C8295C0FEF80/layers/D6D7B59E-9590-435F-A954-A63A9380E876?collectionId=3f9069b4-623a-4686-99ca-a050a6db087c&collectionLayerId=c1b06051-0e6d-4e0c-96b6-e810121bd816&mode=build&selected=1998659781-FC52517A-1F61-4817-B520-358680EEDDF3
    primary: {
      main: '#009ac9',
      dark: '#204957',
    },
    secondary: {
      main: '#fd7f00',
    },
    error: {
      main: '#cc3333',
    },
    success: {
      main: '#00c76c',
    },
    grey: {
      500: '#7D9493',
    },
    background: {
      default: '#fafafa',
    },
    common: {
      black: '#222',
      white: '#fff',
    },
    text: {
      primary: '#212427',
    },
  },
});

// Destructure the palette from the theme, just to make the code cleaner
const { palette } = themeWithPalette;

// We need to compose the with the palette and the overrides
export const feebrisTheme = createTheme(themeWithPalette, {
  typography: {
    fontWeightBold: 500,
    fontSize: 14,
    body1: { fontSize: '1rem' },
    body2: { fontSize: '0.875rem' },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: { border: '1px solid transparent', borderRadius: 12 },
        standardInfo: { borderColor: '#2196f3' },
        standardSuccess: { borderColor: '#00c744' },
        standardWarning: { borderColor: '#ff9800' },
        standardError: { borderColor: '#cc3333' },
        action: { alignItems: 'flex-end' },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          cursor: 'default',
          borderWidth: '1px',
          borderStyle: 'solid',
          '&:focus-visible': {
            outline: '2px solid #009ac9',
          },
          borderColor: palette.grey[400],
          color: palette.text.primary,
        },
        outlined: {
          backgroundColor: palette.common.white,
        },
        colorPrimary: {
          backgroundColor: palette.primary.main,
          borderColor: palette.primary.main,
          color: '#fff',
        },
        colorSuccess: {
          backgroundColor: '#afe1c0',
          borderColor: '#356356',
          color: '#1d3730',
        },
        colorWarning: {
          backgroundColor: '#F9E1A7',
          color: '#362E08',
          borderColor: '#D39A61',
        },
        colorError: {
          backgroundColor: '#efbebe',
          color: '#450808',
          borderColor: '#DB5757',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { marginLeft: 4 },
      },
    },
    MuiAppBar: { defaultProps: { elevation: 1 } },
    MuiTextField: {
      defaultProps: { variant: 'standard' },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary.main,
          },
          '&.Mui-readOnly': {
            backgroundColor: 'hsla(197 34% 94%)',
            color: 'rgba(0, 0, 0, 0.67)',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            input: {
              cursor: 'default',
            },
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          color: palette.primary.dark,
          borderColor: palette.grey[500],
          '&.Mui-selected': {
            backgroundColor: palette.primary.dark,
            color: palette.common.white,
            '&:hover': {
              backgroundColor: palette.grey[500],
            },
          },
        },
      },
    },
    MuiSelect: { defaultProps: { variant: 'standard' } },
    MuiPaper: {
      styleOverrides: {
        rounded: { borderRadius: 12 },
        elevation1: { boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)' },
        elevation2: { boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)' },
        elevation3: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.2)' },
      },
    },
    MuiDialogContent: {
      styleOverrides: { root: { paddingTop: 8, '&:first-child': { paddingTop: 8 } } },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: palette.primary.dark,
          fontSize: '1.25rem',
          paddingTop: 16,
          paddingBottom: 8,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: { root: { padding: 16 } },
    },
    MuiToolbar: {
      styleOverrides: {
        gutters: {
          paddingLeft: 16,
          paddingRight: 16,
          '@media (min-width: 600px)': { paddingLeft: 16, paddingRight: 16 },
        },
      },
    },
    MuiLink: { defaultProps: { underline: 'hover' } },
    MuiList: {
      styleOverrides: { root: { display: 'flex', flexDirection: 'column', gap: 2, padding: 8 } },
    },
    MuiListItem: { styleOverrides: { root: { borderRadius: 6 } } },
    MuiListItemText: { styleOverrides: { root: { color: palette.primary.dark } } },
    MuiListItemIcon: { styleOverrides: { root: { color: palette.primary.dark, minWidth: 40 } } },
    MuiMenuItem: { styleOverrides: { root: { borderRadius: 6 } } },
    MuiTableRow: { styleOverrides: { root: { fontSize: '0.875rem' } } },
    MuiTabs: {
      defaultProps: {
        textColor: 'inherit',
        variant: 'scrollable',
        scrollButtons: 'auto',
      },
      styleOverrides: {
        scrollButtonsHideMobile: { display: 'flex' },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '@media (min-width: 600px)': {
            minWidth: 120,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: { disableElevation: true, color: 'primary' },
      styleOverrides: {
        root: {
          marginTop: 0,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
          textTransform: 'none',
          borderRadius: 8,
          whiteSpace: 'nowrap',
        },
        sizeLarge: {
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 24,
          paddingRight: 24,
          fontSize: '1rem',
        },
        sizeSmall: {
          paddingTop: 6,
          paddingBottom: 6,
          paddingLeft: 12,
          paddingRight: 12,
          fontSize: '0.875rem',
        },
        text: {
          marginTop: 0,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
          textTransform: 'none',
          borderRadius: 8,
        },
        textPrimary: {
          color: palette.primary.dark,
        },
        outlinedPrimary: {
          color: palette.primary.dark,
          borderColor: palette.primary.dark,
          '&:hover': {
            borderColor: palette.primary.dark,
          },
        },
        containedPrimary: {
          backgroundColor: palette.primary.dark,
          '&:disabled': {
            color: palette.common.white,
            backgroundColor: palette.grey[500],
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.875rem',
          borderRadius: 8,
        },
      },
    },
  },
} satisfies ThemeOptions);
