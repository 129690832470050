import React from 'react';
import { gql } from '@apollo/client';

import { useGetWardsQuery } from '@/generated/graphql';

import WardList from './components/WardList';

import { useAddWardModal } from './components/AddWardModal';
import { useDeleteWardModal } from './components/DeleteWardModal';
import { useEditWardModal } from './components/EditWardModal';
import { PageContainer } from '@/components/PageContainer';
import { PageTitle } from '@/components/PageTitle';

export const GET_WARDS = gql`
  query GetWards {
    wards {
      ...ManageWardItem
    }
  }
`;

export default function ManageWards() {
  const { data, loading, refetch, error } = useGetWardsQuery();

  const { showAddWardModal } = useAddWardModal({ onAdded: () => refetch() });
  const { showEditWardModal } = useEditWardModal({ onUpdated: () => refetch() });
  const { showDeleteWardModal } = useDeleteWardModal({ onDeleted: () => refetch() });

  return (
    <PageContainer>
      <PageTitle title="Manage Wards" subtitle="Create wards and assign staff" />
      <WardList
        wards={data?.wards ?? []}
        isLoading={loading}
        onRefresh={refetch}
        loadError={error}
        onAdd={showAddWardModal}
        onEdit={showEditWardModal}
        onDelete={showDeleteWardModal}
      />
    </PageContainer>
  );
}
