import React from 'react';

import '@/styles/components/blocks/block-pictures.scss';
import { CheckupDetailsFragment } from '@/generated/graphql';

function BlockPictures(props: { checkup: CheckupDetailsFragment }) {
  const picture = props.checkup.picture;
  if (!picture) return null;
  return (
    <div className="block block--pictures">
      <div className="block__title">Pictures</div>
      <div className="block__inner">
        <div className="block__picture">
          <img src={picture.url} alt="" />
        </div>
        <p>{picture.caption}</p>
      </div>
    </div>
  );
}

export default BlockPictures;
