import React from 'react';

import { Box } from '@mui/material';
import { PathwayHeader } from './PathwayHeader';
import { gql } from '@apollo/client';
import { useManageCarePathwayQuery } from '@/generated/graphql';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { ErrorDisplay } from '@/components/ErrorDisplay';
import { PathwayAlerts } from '@/components/CarePathway/PathwayAlerts';
import { PathwayCheckupTypes } from '@/components/CarePathway/PathwayCheckupTypes';
import { PathwayIntegrations } from './PathwayIntegrations';
import { Helmet } from 'react-helmet-async';
import { PageContainer } from '@/components/PageContainer';

export const GET_PATHWAY = gql`
  query ManageCarePathway($id: ID!) {
    me {
      areQuicksilvaPreApprovedMessagesSetup
    }
    carePathway(id: $id) {
      ...ManageCarePathwayItem
      checkupTypes {
        ...CheckupTypeDisplay
      }
      alertRules {
        ...AlertRuleDisplay
      }
      integrations {
        ...IntegrationDisplay
      }
    }
  }
`;

export function ManagePathway() {
  const { pathwayId } = useParams<{ pathwayId: string }>();

  const { data, loading, error, refetch } = useManageCarePathwayQuery({
    variables: { id: pathwayId },
    skip: !pathwayId,
  });

  return (
    <Box>
      <Helmet>
        <title>Pathway Management</title>
      </Helmet>
      <PathwayHeader isLoading={loading} pathwayName={data?.carePathway?.name} />
      {error && <ErrorDisplay message="Failed to load pathway" retry={() => refetch()} />}
      <Box>
        <Switch>
          <Route path="/pathways/:pathwayId/alerts">
            <PageContainer
              role="tabpanel"
              id="manage-carepathway-alerts-tabpanel"
              aria-labelledby="manage-carepathway-alerts-tab">
              <PathwayAlerts alertRules={data?.carePathway?.alertRules ?? []} />
            </PageContainer>
          </Route>
          <Route path="/pathways/:pathwayId/checkuptypes">
            <PageContainer
              role="tabpanel"
              id="manage-carepathway-checkuptypes-tabpanel"
              aria-labelledby="manage-carepathway-checkuptypes-tab">
              <PathwayCheckupTypes checkupTypes={data?.carePathway?.checkupTypes ?? []} />
            </PageContainer>
          </Route>
          <Route path="/pathways/:pathwayId/integrations">
            <PageContainer
              role="tabpanel"
              id="manage-carepathway-integrations-tabpanel"
              aria-labelledby="manage-carepathway-integrations-tab">
              <PathwayIntegrations
                loading={loading}
                pathwayId={pathwayId}
                onUpdated={() => refetch()}
                integrations={data?.carePathway?.integrations ?? []}
                areQuicksilvaPreApprovedMessagesSetup={
                  data?.me?.areQuicksilvaPreApprovedMessagesSetup ?? false
                }
              />
            </PageContainer>
          </Route>
          <Redirect exact to="/pathways/:pathwayId/checkuptypes" />
        </Switch>
      </Box>
    </Box>
  );
}
