import React, { useCallback } from 'react';
import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { gql } from '@apollo/client';
import {
  CarePathway,
  PatientDetailsFragment,
  useTransferPatientToCarePathwayMutation,
} from '@/generated/graphql';
import { useModal } from 'mui-modal-provider';
import _ from 'lodash';
import { useFormik } from 'formik';
import { toast } from 'sonner';
import { muiFormikGetFieldProps } from '@/helpers/formik';
import * as Yup from 'yup';

type TransferCarePathwayModalPatient = Pick<
  PatientDetailsFragment,
  'id' | 'firstName' | 'lastName'
> & { currentCarePathwayId: string };

type TransferCarePathwayModalCarePathway = Pick<CarePathway, 'id' | 'name'>;

interface TransferCarePathwayModalProps {
  patient: TransferCarePathwayModalPatient;
  carePathways: TransferCarePathwayModalCarePathway[];
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
}

interface TransferCarePathwayFormValues {
  carePathwayId: string | undefined;
  patientName: string;
}

const transferCarePathwaySchema = (
  currentAdmissionCarePathwayId: string,
  currentAdmissionPatientName: string,
) =>
  Yup.object<TransferCarePathwayFormValues>().shape({
    carePathwayId: Yup.string()
      .required('Care pathway selection required')
      .notOneOf(
        [currentAdmissionCarePathwayId],
        'Target care pathway must be different to current care pathway admission',
      ),
    patientName: Yup.string()
      .required('Patient name must be confirmed')
      .test(
        'name-comparison',
        'Patient name must match',
        (name) =>
          name.trim().localeCompare(currentAdmissionPatientName, undefined, {
            sensitivity: 'accent',
          }) === 0,
      ),
  });

export function TransferCarePathwayModal({
  patient,
  carePathways,
  open,
  onClose,
  onComplete,
}: TransferCarePathwayModalProps) {
  const [transferCarePathwayMutation] = useTransferPatientToCarePathwayMutation({
    onCompleted: () => {
      onComplete();
      onClose();
    },
    onError: () =>
      toast.error('An error occured when transferring the patient to the care pathway'),
  });

  const handleSubmit = async (values: TransferCarePathwayFormValues) => {
    if (values.carePathwayId) {
      await transferCarePathwayMutation({
        variables: {
          patientId: patient.id,
          carePathwayId: values.carePathwayId,
        },
      });
    }
  };

  const patientFullName = `${patient.firstName.trim()} ${patient.lastName.trim()}`;

  const formik = useFormik({
    initialValues: {
      carePathwayId: undefined,
      patientName: '',
    },
    validationSchema: transferCarePathwaySchema(patient.currentCarePathwayId, patientFullName),
    onSubmit: handleSubmit,
  });

  const getFieldProps = muiFormikGetFieldProps(formik);

  const formId = 'transferPatientToWardForm';

  return (
    <form id={formId} onSubmit={formik.handleSubmit}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Transfer {patientFullName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography py={1}>Please select a new care pathway for {patientFullName}</Typography>

            <Alert severity="info" sx={{ marginTop: 1, marginBottom: 3 }}>
              Transferring the patient to another care pathway will impact their Feebris care plan
              which includes thresholds, alerts and scheduling.
            </Alert>

            <Autocomplete
              fullWidth
              options={carePathways}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => formik.setFieldValue('carePathwayId', value?.id || '')}
              noOptionsText="No wards found"
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...getFieldProps('carePathwayId', { fireOnChange: false })}
                  autoFocus
                  placeholder="Select a care pathway for transfer"
                  label="Care Pathway"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />

            <TextField
              fullWidth
              sx={{ marginTop: 3 }}
              label="Confirm patient name"
              value={formik.values.patientName}
              {...getFieldProps('patientName')}
              type="string"
              variant="outlined"
              placeholder={patientFullName}
              helperText="Please type the patient's full name to confirm"
              required
              InputLabelProps={{ shrink: true }}
              inputProps={{
                'aria-label': 'Patient transfer confirm patient name',
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button
            type="submit"
            form={formId}
            disabled={!formik.isValid}
            color="primary"
            variant="contained">
            Transfer
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export const TRANSFER_CAREPATHWAY = gql`
  mutation TransferPatientToCarePathway($patientId: ID!, $carePathwayId: ID!) {
    transferPatientToCarePathway(patientId: $patientId, carePathwayId: $carePathwayId) {
      patient {
        id
      }
    }
  }
`;

export const useTransferCarePathwayModal = ({ onComplete }: { onComplete: () => void }) => {
  const { showModal } = useModal();

  const showTransferCarePathwayModal = useCallback(
    (
      patient: TransferCarePathwayModalPatient,
      carePathways: TransferCarePathwayModalCarePathway[],
    ) => {
      function hide() {
        modal.hide();
      }
      const modal = showModal(
        TransferCarePathwayModal,
        {
          patient,
          carePathways,
          onClose: () => hide(),
          onComplete,
        },
        {
          destroyOnClose: true,
        },
      );
      return modal;
    },
    [onComplete, showModal],
  );

  return { showTransferCarePathwayModal };
};
