import React from 'react';

import { withErrorBoundary } from '@/components/ErrorBoundary';

interface LoginLayoutProps {
  children: React.ReactNode;
}

const LoginLayout = ({ children }: LoginLayoutProps) => (
  <div className="mui_offpiste">
    <div className="mui_offpiste__inner">{children}</div>
  </div>
);

export default withErrorBoundary(LoginLayout);
