import React from 'react';

import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import clsx from 'clsx';

import {
  CheckupTileConfig,
  CheckupTiles,
  GenericCheckupTileConfig,
  SymptomsQuestionnaireTileConfig,
  StethoscopeTileConfig,
  QuestionnaireSectionType,
  CheckupTileRule,
  PulseOximeterTileConfig,
} from '@/generated/graphql';

import { isDefined } from '@/helpers/isDefined';
import { WithoutTypeNames, omitTypenameDeep } from '@/AuthorizedApolloClientProvider';

interface CheckupTilesConfigProps {
  checkupTiles: CheckupTiles;
  onUpdate: (newTileConfig: CheckupTiles) => void;
}

/**
 * When querying via GraphQL, the `__typename` field is included in the response.
 * We want a version of the CheckupTiles type that does not include this field for
 * use in this component.
 */
type TileKeys = keyof WithoutTypeNames<CheckupTiles>;

export function CheckupTilesConfig({ checkupTiles, onUpdate }: CheckupTilesConfigProps) {
  const handleUpdate = (key: TileKeys, newTileConfig: CheckupTileConfig) => {
    onUpdate({
      ...checkupTiles,
      [key]: newTileConfig,
    });
  };

  /**
   * Since we want to dynamically render each tile config section, we need to remove the `__typename` field
   */
  const tilesWithoutTypeName = omitTypenameDeep(checkupTiles);

  const hasAnyActiveTiles = Object.values(tilesWithoutTypeName).some(
    (tileConfig) => tileConfig && tileConfig.rule !== 'disabled',
  );

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Tile Configuration
      </Typography>
      {!hasAnyActiveTiles && (
        <Box marginBottom={2}>
          <Alert severity="warning">
            No tiles are active. Any checkups of this type will record no vitals.
          </Alert>
        </Box>
      )}
      {Object.entries(tilesWithoutTypeName).map(([tileKey, tileConfig]) => {
        return (
          <CheckupTileConfigView
            key={tileKey}
            tileKey={tileKey as TileKeys}
            onUpdate={(config) => handleUpdate(tileKey as TileKeys, config)}
            config={tileConfig}
          />
        );
      })}
    </div>
  );
}

interface TileSpecificConfigProps<TTileConfig extends CheckupTileConfig> {
  config: TTileConfig;
  onUpdate: (newTileConfig: TTileConfig) => void;
}

interface CheckupTileConfigProps<TTileConfig extends CheckupTileConfig> {
  tileKey: TileKeys;
  config: TTileConfig | null;
  onUpdate: (newTileConfig: TTileConfig) => void;
}

const DEFAULT_TILE_CONFIG: CheckupTileConfig = {
  rule: CheckupTileRule.Disabled,
};

function CheckupTileConfigView<TTileConfig extends CheckupTileConfig>({
  tileKey,
  config,
  onUpdate,
}: CheckupTileConfigProps<TTileConfig>) {
  const classes = useCheckupTileConfigViewStyles();

  const handleRuleUpdate = (rule: CheckupTileRule) => {
    onUpdate({
      ...(config ?? DEFAULT_TILE_CONFIG),
      rule,
    } as TTileConfig);
  };

  const handleAdditionalConfigUpdate = (newConfig: GenericCheckupTileConfig) => {
    onUpdate({
      ...(config ?? DEFAULT_TILE_CONFIG),
      ...newConfig,
    } as TTileConfig);
  };

  const TileSpecificConfig =
    tileKey in ADDITIONAL_CONFIG_SECTIONS ? ADDITIONAL_CONFIG_SECTIONS[tileKey] : null;

  const showTileSpecificConfig =
    isDefined(TileSpecificConfig) &&
    ('alwaysShow' in TileSpecificConfig
      ? (TileSpecificConfig.alwaysShow as boolean) == true || config?.rule !== 'disabled'
      : config && config.rule !== 'disabled');

  return (
    <div
      className={clsx(classes.root, {
        [classes.required]: config?.rule === 'required',
        [classes.optional]: config?.rule === 'optional',
        [classes.disabled]: !config?.rule || config.rule === 'disabled',
      })}>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography
          className={clsx(classes.tileName, {
            [classes.tileNameRequired]: config?.rule === 'required',
            [classes.tileNameOptional]: config?.rule === 'optional',
            [classes.tileNameDisabled]: !config?.rule || config.rule === 'disabled',
          })}>
          {TILE_NAME_MAP[tileKey] ?? tileKey}
        </Typography>
        <FormControl variant="filled" size="small">
          <Select
            value={config?.rule || 'disabled'}
            variant="outlined"
            onChange={({ target: { value } }) => handleRuleUpdate(value as CheckupTileRule)}>
            <MenuItem value="required">Required</MenuItem>
            <MenuItem value="optional">Optional</MenuItem>
            <MenuItem value="disabled">Disabled</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {showTileSpecificConfig && (
        <Box marginTop={1}>
          <TileSpecificConfig config={config} onUpdate={handleAdditionalConfigUpdate} />
        </Box>
      )}
    </div>
  );
}

/**
 * This object maps tile keys to their respective names.
 */
const TILE_NAME_MAP: Record<TileKeys, string> = {
  symptomsQuestionnaire: 'Soft Signs Question Pages',
  bloodPressureCuff: 'Blood Pressure',
  consciousness: 'Consciousness',
  glucose: 'Glucose',
  picture: 'Picture',
  pulseOximeter: 'Oxygen Saturation (Pulse Oximeter)',
  respiratoryRate: 'Breathing Rate',
  stethoscope: 'Breath Sounds (Stethoscope)',
  temperature: 'Temperature',
  weight: 'Weight',
};

const AdditionalPulseOximeterConfig = ({
  config,
  onUpdate,
}: TileSpecificConfigProps<PulseOximeterTileConfig>) => {
  const handleSetManualEntryUpdate = (manualEntry: boolean) => {
    onUpdate({
      ...(config ?? DEFAULT_TILE_CONFIG),
      manualEntry,
    } as PulseOximeterTileConfig);
  };

  const handleSetUsePlethAIUpdate = (usePlethAI: boolean) => {
    onUpdate({
      ...(config ?? DEFAULT_TILE_CONFIG),
      usePlethAI,
    } as PulseOximeterTileConfig);
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={config?.manualEntry ?? false}
            color="primary"
            onChange={({ target: { checked } }) => handleSetManualEntryUpdate(checked)}
          />
        }
        label="Use manual entry"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={config?.usePlethAI ?? false}
            color="primary"
            onChange={({ target: { checked } }) => handleSetUsePlethAIUpdate(checked)}
          />
        }
        label="Use PlethAI"
      />
    </Box>
  );
};

const AdditionalBloodPressureCuffConfig = ({
  config,
  onUpdate,
}: TileSpecificConfigProps<PulseOximeterTileConfig>) => {
  const handleSetManualEntryUpdate = (manualEntry: boolean) => {
    onUpdate({
      ...(config ?? DEFAULT_TILE_CONFIG),
      manualEntry,
    } as PulseOximeterTileConfig);
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={config?.manualEntry ?? false}
            color="primary"
            onChange={({ target: { checked } }) => handleSetManualEntryUpdate(checked)}
          />
        }
        label="Use manual entry"
      />
    </Box>
  );
};

const AdditionalStethoscopeConfig = ({
  config,
  onUpdate,
}: TileSpecificConfigProps<StethoscopeTileConfig>) => {
  const handleForceOnAbnormalSpO2Update = (forceOnAbnormalSpO2: boolean) => {
    onUpdate({
      ...(config ?? DEFAULT_TILE_CONFIG),
      forceOnAbnormalSpO2,
    } as StethoscopeTileConfig);
  };

  const handleForceOnRespiratorySoftSignsUpdate = (forceOnRespiratorySoftSigns: boolean) => {
    onUpdate({
      ...(config ?? DEFAULT_TILE_CONFIG),
      forceOnRespiratorySoftSigns,
    } as StethoscopeTileConfig);
  };

  const handleForceOnAbnormalRespiratoryRateUpdate = (forceOnAbnormalRespiratoryRate: boolean) => {
    onUpdate({
      ...(config ?? DEFAULT_TILE_CONFIG),
      forceOnAbnormalRespiratoryRate,
    } as StethoscopeTileConfig);
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={config?.forceOnAbnormalSpO2 ?? false}
            color="primary"
            onChange={({ target: { checked } }) => handleForceOnAbnormalSpO2Update(checked)}
          />
        }
        label="Force on abnormal SpO2"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={config?.forceOnRespiratorySoftSigns ?? false}
            color="primary"
            onChange={({ target: { checked } }) => handleForceOnRespiratorySoftSignsUpdate(checked)}
          />
        }
        label="Force on respiratory soft signs"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={config?.forceOnAbnormalRespiratoryRate ?? false}
            color="primary"
            onChange={({ target: { checked } }) =>
              handleForceOnAbnormalRespiratoryRateUpdate(checked)
            }
          />
        }
        label="Force on abnormal respiratory rate"
      />
    </Box>
  );
};
AdditionalStethoscopeConfig.alwaysShow = true;

const AdditionalSymptomsQuestionnaireConfig = ({
  onUpdate,
  config,
}: TileSpecificConfigProps<SymptomsQuestionnaireTileConfig>) => {
  const classes = useSymptomsQuestionnaireConfigStyles();

  const isSectionTypeActive = (sectionType: QuestionnaireSectionType) =>
    config?.questionnaireSections?.includes(sectionType) ?? false;

  const handleSectionTypeToggle = (sectionType: QuestionnaireSectionType) => {
    const newQuestionnaireSections = config?.questionnaireSections?.includes(sectionType)
      ? config.questionnaireSections.filter((st) => st !== sectionType)
      : [...(config?.questionnaireSections ?? []), sectionType];

    onUpdate({
      ...(config ?? DEFAULT_TILE_CONFIG),
      questionnaireSections: newQuestionnaireSections,
    } as SymptomsQuestionnaireTileConfig);
  };

  return (
    <Box display="flex" flexWrap="wrap">
      {Object.entries(QuestionnaireSectionType).map(([sectionKey, sectionType]) => (
        <Chip
          className={classes.sectionType}
          color={isSectionTypeActive(sectionType) ? 'primary' : 'default'}
          key={sectionType}
          label={sectionKey}
          onClick={() => handleSectionTypeToggle(sectionType)}
        />
      ))}
    </Box>
  );
};

const useSymptomsQuestionnaireConfigStyles = makeStyles((theme) => ({
  sectionType: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
  },
  activeSectionType: {
    borderColor: theme.palette.primary.main,
  },
}));

/**
 * This object maps tile keys to their respective additional configuration sections.
 */
const ADDITIONAL_CONFIG_SECTIONS: Partial<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<TileKeys, React.FC<TileSpecificConfigProps<any>>>
> = {
  symptomsQuestionnaire: AdditionalSymptomsQuestionnaireConfig,
  stethoscope: AdditionalStethoscopeConfig,
  bloodPressureCuff: AdditionalBloodPressureCuffConfig,
  pulseOximeter: AdditionalPulseOximeterConfig,
};

const useCheckupTileConfigViewStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius * 2,
    background: theme.palette.grey[100],
    border: `2px solid ${theme.palette.grey[300]}`,
    transition: theme.transitions.create(['border-color']),
  },
  required: {
    borderColor: theme.palette.success.main,
  },
  optional: {
    borderColor: theme.palette.warning.main,
  },
  disabled: {
    borderColor: theme.palette.grey[300],
  },
  tileName: {
    fontWeight: 500,
  },
  tileNameRequired: {
    color: theme.palette.success.dark,
  },
  tileNameOptional: {
    color: theme.palette.warning.dark,
  },
  tileNameDisabled: {
    color: theme.palette.grey[700],
  },
}));
