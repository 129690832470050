import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { FormControlLabel, Typography } from '@mui/material';
import { getNext } from '@/helpers/nextUrl';
import api from '@/controllers/Api';
import auth from '@/controllers/Auth';
import * as Sentry from '@sentry/browser';
import PermissionsImage from './images/Images_Permissions.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(15, 0, 0, 0),
  },
  paper: {
    backgroundImage: `url(${PermissionsImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    minHeight: '19vw',
  },
  header: {
    padding: theme.spacing(5, 0, 0, 5),
  },
  description: {
    padding: theme.spacing(5, 0, 0, 5),
  },
  acceptButton: {
    padding: theme.spacing(5, 0, 5, 5),
  },
  errorText: {
    padding: theme.spacing(0, 40, 5, 0),
  },
}));

const Terms = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = async () => {
    try {
      const response = await api.acceptTerms();
      if (!response.errors || !response.data.createAcceptedTerms) {
        await auth.refreshMeData();
        props.history.replace(getNext('/'));
      }
      // Not coding for a failure path intentionally but recording in Sentry if there is an error
    } catch (error) {
      Sentry.captureException(error);
      console.log('An error occurred while saving terms:', error);
    }
  };

  const errorPart = () => {
    return (
      <Grid container direction="row" className={classes.description}>
        <Typography className={classes.errorText}>
          In order to continue to use the Feebris platform, it’s necessary for your organisation
          admin or manager to login to the Feebris portal and accept the Feebris Platform Terms.
          Please contact <Link href="mailto:support@feebris.com">support@feebris.com</Link> if you
          need help.
        </Typography>
      </Grid>
    );
  };

  const acceptTermsPart = () => {
    return (
      <>
        <Grid container direction="row" className={classes.description}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                color="primary"
                onChange={handleChange}
                className="e2e__acceptcheckbox"
              />
            }
            label={
              <Typography>
                I agree, on behalf of the Licensee, to be bound by Feebris'{' '}
                <Link
                  href="https://www.feebris.com/platform-terms"
                  target="_blank"
                  rel="noopener noreferrer">
                  Platform Terms
                </Link>{' '}
                to use the Feebris product.
              </Typography>
            }
          />
        </Grid>
        <Grid item className={classes.acceptButton}>
          <Button
            variant="contained"
            color="primary"
            disabled={!checked}
            onClick={handleSubmit}
            className="e2e__acceptbutton">
            I Accept
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={11} className={classes.container}>
        <Paper elevation={3} className={classes.paper}>
          <Grid item className={classes.header}>
            <Typography variant="h4">Welcome to Feebris</Typography>
          </Grid>
          {auth.me('permissions.accept_terms', false) ? acceptTermsPart() : errorPart()}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Terms;
