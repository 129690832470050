import React from 'react';

import { Tooltip } from '@mui/material';
import { format, formatDistance } from 'date-fns';

export function TimeAgo({ dateString }: { dateString: string | null | undefined }) {
  const maybeDate = newDateOrNull(dateString);
  return (
    <>
      {maybeDate ? (
        <Tooltip title={format(maybeDate, 'MMM do yyyy, HH:mm a')}>
          <span>{formatDistance(maybeDate, new Date())} ago</span>
        </Tooltip>
      ) : (
        <span>No data</span>
      )}
    </>
  );
}

function newDateOrNull(dateString: string | null | undefined): Date | null {
  return dateString ? new Date(dateString) : null;
}
