import React from 'react';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LibraryAdd from '@mui/icons-material/LibraryAdd';

const MoreActionsMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <>
      <IconButton
        sx={{ marginLeft: 1 }}
        aria-label="more"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        sx={{ marginTop: 1 }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MenuItem
          onClick={() => {
            props.setOpen(true);
            setAnchorEl(null);
          }}>
          <ListItemIcon>
            <LibraryAdd fontSize="small" />
          </ListItemIcon>
          Bulk Add Patients
        </MenuItem>
      </Menu>
    </>
  );
};

export default MoreActionsMenu;
