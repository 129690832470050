import { List, ListItem, ListItemIcon, ListItemText, ListSubheader, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import React from 'react';

const AddOwnGPMenu = ({ setAddPracticeOpen }) => {
  const { t } = useTranslation();

  return (
    <List
      subheader={<ListSubheader>Don&apos;t see your {t('GP Practice')} listed?</ListSubheader>}
      // Need to perform the same hack that the Autocomplete ListBoxComponent does internally
      // to allow our own click handler (the button) to fire
      // see: https://github.com/mui/material-ui/blob/77a8daeed0e8efa67c6d86f3cc40239dc6eb3d94/packages/material-ui-lab/src/useAutocomplete/useAutocomplete.js#L987
      onMouseDown={(event) => event.preventDefault()}>
      <MenuItem className="e2e__addgpbutton" onClick={() => setAddPracticeOpen(true)}>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary={`Add ${t('GP Practice')}`} />
      </MenuItem>
    </List>
  );
};

export default AddOwnGPMenu;
