import _ from 'lodash';
import { TextFieldProps } from '@mui/material';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';

interface GetFieldPropsOptions {
  fireOnChange?: boolean;
  defaultHelperText?: string;
}

const defaultGetFieldPropsOptions: GetFieldPropsOptions = {
  fireOnChange: true,
};

function isFieldRequired<TFormValues extends FormikValues>(
  schema: Yup.ObjectSchema<TFormValues>,
  name: keyof TFormValues,
  values: TFormValues,
) {
  const schemaDescription = schema.describe({
    value: values,
  });

  /** When fields are nested in a yup schema, any child fields are in under `fields`
   * E.g. `patient.fields.address.fields.postcode`
   *
   * This path mapping allows us to use the same object path as lodash get
   * E.g. `patient.address.postcode`
   */
  const namePath = name.toString().split('.').join('.fields.');
  const fieldDescription = _.get(schemaDescription.fields, namePath);

  if (!fieldDescription) {
    return false;
  }

  return (
    'nullable' in fieldDescription &&
    !fieldDescription.nullable &&
    'optional' in fieldDescription &&
    !fieldDescription.optional
  );
}

export const muiFormikGetFieldProps =
  <TFormValues extends FormikValues>(
    formik: ReturnType<typeof useFormik<TFormValues>>,
    argErrors?: Record<string, unknown> | undefined,
    schema?: Yup.ObjectSchema<TFormValues> | undefined,
  ) =>
  (name: keyof TFormValues, options?: GetFieldPropsOptions): TextFieldProps => {
    const defaultedOptions = { ...defaultGetFieldPropsOptions, ...options };

    return {
      name: name.toString(),
      value: _.get(formik.values, name),
      onChange: defaultedOptions.fireOnChange ? formik.handleChange : () => undefined,
      error: _.has(formik.errors, name) || _.has(argErrors, name),
      required: schema ? isFieldRequired(schema, name, formik.values) : false,
      helperText:
        (_.get(formik.errors, name) as string) ||
        _.get(argErrors, name) ||
        defaultedOptions.defaultHelperText,
    };
  };
