import React from 'react';

import { PatientAdmissionFilter } from '@/generated/graphql';
import { Stack, Button, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface AdmissionFilterTabsProps {
  admissionFilter: PatientAdmissionFilter;
  onFilterChange: (filter: PatientAdmissionFilter) => void;
}

export function AdmissionFilterTabs({ admissionFilter, onFilterChange }: AdmissionFilterTabsProps) {
  return (
    <Stack
      direction="row"
      gap={1}
      marginBottom={2}
      role="tablist"
      aria-label="admission status filter">
      <AdmissionFilterTab
        active={admissionFilter === PatientAdmissionFilter.ActiveAdmission}
        onClick={() => onFilterChange(PatientAdmissionFilter.ActiveAdmission)}
        label="Admitted"
        tooltip="Patients currently admitted to a ward"
      />
      <AdmissionFilterTab
        active={admissionFilter === PatientAdmissionFilter.RecentlyDischarged}
        onClick={() => onFilterChange(PatientAdmissionFilter.RecentlyDischarged)}
        label="Recently Discharged"
        tooltip="Patients discharged within the last 30 days"
      />
      <AdmissionFilterTab
        active={admissionFilter === PatientAdmissionFilter.All}
        onClick={() => onFilterChange(PatientAdmissionFilter.All)}
        label="All"
        tooltip="All patients"
      />
    </Stack>
  );
}

interface AdmissionFilterTabProps {
  active: boolean;
  onClick: () => void;
  label: string;
  tooltip?: string;
}

export function AdmissionFilterTab({ active, onClick, label, tooltip }: AdmissionFilterTabProps) {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip} enterDelay={800}>
      <Button
        className={`${classes.tabButton} ${active ? classes.active : ''}`}
        onClick={onClick}
        role="tab"
        aria-selected={active}
        title={tooltip}>
        {label}
      </Button>
    </Tooltip>
  );
}

const useStyles = makeStyles((theme) => ({
  tabButton: {
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(0.5, 2.5),
    borderRadius: theme.shape.borderRadius * 8,
    background: 'transparent',
    color: theme.palette.primary.dark,
    textTransform: 'none',
    transition: theme.transitions.create(['background', 'color']),
  },
  active: {
    color: theme.palette.background.paper,
    background: theme.palette.primary.dark,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
}));
