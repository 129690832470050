const {
  NEWSCalculator,
  computeRiskLevelAndTotalScore,
  DEFAULT_NEWS_THRESHOLDS,
} = require('./NEWSCalculator');

const {
  activityScoreCalculator,
  DEFAULT_ACTIVITY_THRESHOLDS,
} = require('./activityScoreCalculator');

const DEFAULT_EWS_THRESHOLDS = {
  ...DEFAULT_NEWS_THRESHOLDS,
  ...DEFAULT_ACTIVITY_THRESHOLDS,
};

module.exports = {
  NEWSCalculator,
  computeRiskLevelAndTotalScore,
  activityScoreCalculator,
  DEFAULT_EWS_THRESHOLDS,
};
