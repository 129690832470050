import React from 'react';

import { Stack, Typography } from '@mui/material';

import SuccessImage from '@/components/svgs/SuccessCircle.svg';

import { useAddUserJourneyContext } from '../types';
import { UserName } from '@/components/UserName';

export function SuccessStep() {
  const { currentJourneyState, handleStep } = useAddUserJourneyContext();

  // This step is a no-op, it just displays a success message
  handleStep(() => true);

  return (
    <Stack alignItems="center">
      <img src={SuccessImage} alt="Success" />
      <Typography
        variant="h6"
        gutterBottom
        color="textSecondary"
        marginTop={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}>
        {/* NOTE: Normally we need to pass the userActingOrganization prop to the UserName component
                  but since this user was created inside this Journey there is no need. We know for
                  sure the loggedInUser is allowed to see details of currentJourneyState.user,
                  because loggedInUser just created currentJourneyState.user! */}
        <UserName user={currentJourneyState.user} /> has been successfully added to the
        organisation.
      </Typography>
      <Typography variant="body1" color="textSecondary" align="center" marginBottom={3}>
        If the user did not already have an account, they will receive an email to set up their
        password and gain access to patients within the organisation.
      </Typography>
    </Stack>
  );
}
