import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Box,
} from '@mui/material';
import auth from '@/controllers/Auth';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function EnableTwoFactorAuthModal(props: {
  onClose: () => void;
  onSuccess?: () => void;
  onError: () => void;
  open: boolean;
}) {
  const [password, setPassword] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [reauthenticating, setReauthenticating] = useState(false);

  const close = () => {
    setPassword(undefined);
    setIncorrectPassword(false);
    props.onClose();
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      if (!password) {
        return;
      }

      setReauthenticating(true);
      const result = await auth.Reauthenticate(password);

      if (result === 'IncorrectPassword') {
        setIncorrectPassword(true);
      } else if (result === 'Success') {
        props.onSuccess && props.onSuccess();
      }
    } catch {
      props.onError();
    } finally {
      setReauthenticating(false);
    }
  };

  const passwordFormId = 'passwordForm';

  return (
    <Dialog
      open={props.open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      maxWidth="sm"
      fullWidth>
      <DialogTitle id="alert-dialog-title">Please confirm your details</DialogTitle>

      <DialogContent>
        <Box marginBottom={1}>
          <Typography>
            You&apos;ll need to confirm your password before you can setup two-factor
            authentication.
          </Typography>
        </Box>

        <Box marginBottom={2}>
          <form id={passwordFormId}>
            <TextField
              id="password"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              aria-label="Password"
              placeholder="Password"
              name="password"
              autoFocus
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              error={incorrectPassword}
              helperText={incorrectPassword ? 'Check your password and try again' : undefined}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      size="large">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          form={passwordFormId}
          disabled={!password || reauthenticating}
          onClick={async (e) => await handleSubmit(e)}
          variant="contained"
          color="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
