import { initializeApp, FirebaseApp as FirebaseAppType } from 'firebase/app';

export default class FirebaseApp {
  static #instance: FirebaseAppType | undefined;

  static getInstance(): FirebaseAppType {
    FirebaseApp.#instance =
      FirebaseApp.#instance ||
      initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      });
    return FirebaseApp.#instance;
  }
}
