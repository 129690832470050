import React from 'react';

import { gql } from '@apollo/client';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
} from '@mui/material';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { Alert } from '@mui/material';
import { toast } from 'sonner';

import { useEndContinuousMonitoringSessionMutation } from '@/generated/graphql';

export const END_CONTINUOUS_MONITORING_SESSION_QUERY = gql`
  mutation EndContinuousMonitoringSession($patientId: ID!) {
    endContinuousMonitoringSession(patientId: $patientId) {
      id
    }
  }
`;

interface EndMonitoringSessionModalProps extends DialogProps {
  patientId: string;
  onAdded: () => void;
  onCancel: () => void;
}

export function EndMonitoringSessionModal({
  open,
  patientId,
  onAdded,
  onCancel,
}: EndMonitoringSessionModalProps) {
  const [endContinuousMonitoringSession, { loading: isSubmitting }] =
    useEndContinuousMonitoringSessionMutation({
      onCompleted: () => {
        onAdded();
      },
      onError: () => {
        toast.error('An error occurred when ending the continuous monitoring session');
      },
    });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    endContinuousMonitoringSession({
      variables: {
        patientId,
      },
    });
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      aria-labelledby="end-monitoring-session-dialog__title">
      <DialogTitle>End continuous monitoring</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Alert severity="info">
            Once the session ends, the patient data will not be collected anymore. Start a new
            session if the patient needs to be continuously monitored again.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
            End continuous monitoring
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

interface UseMonitoringSessionModalProps {
  onAdded?: () => void;
}

export const useEndMonitoringSessionModal = ({ onAdded }: UseMonitoringSessionModalProps = {}) => {
  const { showModal } = useModal();

  return {
    showEndMonitoringSessionModal: (patientId: string) => {
      const modal: ShowFnOutput<EndMonitoringSessionModalProps> = showModal(
        EndMonitoringSessionModal,
        {
          patientId,
          onAdded: () => {
            onAdded?.();
            modal.hide();
          },
          onCancel: () => modal.hide(),
        },
        { destroyOnClose: true },
      );

      return modal;
    },
  };
};
