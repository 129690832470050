import React, { useMemo, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GetAppIcon from '@mui/icons-material/GetApp';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import XLSX from 'xlsx';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import feebrisPatientsBulkUploadTemplate from '@/assets/Feebris-Patients-Bulk-Upload-Template.xlsx';
import { toast } from 'sonner';

import MaterialTable from '@material-table/core';
import { isDefined } from '@/helpers/isDefined';
import {
  useAdmitPatientMutation,
  useCreatePatientMutation,
  useGetAdmissionWardAndCarePathwayOptionsQuery,
} from '@/generated/graphql';
import { ApolloError } from '@apollo/client';
import { stripSpaces } from '@/helpers/stripSpaces';
import { useMeActingOrganizationFeature } from '@/hooks/useAuth';
import { GraphQLError } from 'graphql';
import { useKeyPressEvent } from 'react-use';

const useStyles = makeStyles((theme) => ({
  uploadBox: {
    border: '2px dashed #000',
    borderRadius: theme.spacing(1),
    backgroundColor: '#f3fbfd',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  errorList: {
    maxHeight: '40vh',
    overflowY: 'auto',
  },
}));

interface PatientSpreadsheetRow {
  'First Name': string;
  'Last Name': string;
  'Birth Date (DD/MM/YYYY)': string;
  'Gender (male/female)': string;
  Address: string;
  Postcode: string;
  'Pre-Existing Conditions (comma separated)': string;
  'NHS Number': string;
}

interface BulkUploadPatient {
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: string;
  address: {
    address: string;
    postcode: string;
  } | null;
  preExistingConditions: string;
  nationalIdentifierValue: string | null;
}

interface PatientBulkUploadModalProps {
  open: boolean;
  onClose: () => void;
  reloadPatients: () => void;
}

enum PatientBulkUploadStatus {
  Pending = 'Pending',
  Creating = 'Submitted',
  Created = 'Created',
  ErrorCreating = 'ErrorCreating',
  AlreadyExists = 'AlreadyExists',
  Admitting = 'Admitting',
  Admitted = 'Admitted',
  ErrorAdmitting = 'ErrorAdmitting',
}

interface CreatePatientResult {
  key: string;
  status: PatientBulkUploadStatus;
  errors?: string[];
}

export default function PatientBulkUploadModal({
  open,
  onClose,
  reloadPatients,
}: PatientBulkUploadModalProps) {
  const wardsFeatureEnabled = useMeActingOrganizationFeature('wards');

  const classes = useStyles();
  const { t } = useTranslation();
  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const [newResidents, setNewResidents] = useState<BulkUploadPatient[]>([]);
  const [createResults, setCreateResults] = useState<CreatePatientResult[]>([]);

  const [selectedWardId, setSelectedWardId] = useState<string | null>(null);
  const [selectedCarePathwayId, setSelectedCarePathwayId] = useState<string | null>(null);

  const { data: wardsAndCarePathways, loading: loadingWardsAndCarePathways } =
    useGetAdmissionWardAndCarePathwayOptionsQuery({
      skip: !wardsFeatureEnabled,
      onError: () =>
        toast.error('An error occurred when fetching wards and care pathways for selection'),
    });

  const sortedWards = useMemo(
    () => _.sortBy(wardsAndCarePathways?.wards ?? [], (w) => w.name),
    [wardsAndCarePathways?.wards],
  );

  const sortedCarePathways = useMemo(
    () => _.sortBy(wardsAndCarePathways?.carePathways ?? [], (cp) => cp.name),
    [wardsAndCarePathways?.carePathways],
  );

  const shouldShowAdmissionDetails = Boolean(
    wardsFeatureEnabled && sortedWards.length > 0 && sortedCarePathways.length > 0,
  );

  const canSubmit =
    newResidents.length > 0 &&
    !loadingWardsAndCarePathways &&
    (shouldShowAdmissionDetails ? Boolean(selectedWardId && selectedCarePathwayId) : true);

  const getResult = (patient: BulkUploadPatient) =>
    createResults.find((r) => r.key === patient.firstName + patient.lastName + patient.birthDate);

  // immutable update
  const setResult = (
    patient: BulkUploadPatient,
    status: PatientBulkUploadStatus,
    errors?: string[],
  ) => {
    setCreateResults((prev) => {
      const newResults = prev.filter(
        (r) => r.key !== patient.firstName + patient.lastName + patient.birthDate,
      );
      newResults.push({
        key: patient.firstName + patient.lastName + patient.birthDate,
        status,
        errors,
      });
      return newResults;
    });
  };

  const [createPatient] = useCreatePatientMutation({
    context: {
      batch: true,
    },
  });

  const [admitPatient] = useAdmitPatientMutation({
    context: {
      batch: true,
    },
  });

  const clearUploadedFiles = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.value = '';
    }
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return false;
    }
    const [file] = e.target.files;
    if (!file) {
      return false;
    }
    const reader = new window.FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      setCreateResults([]);
      if (!e.target?.result) {
        clearUploadedFiles();
        toast.error('An error occurred while reading the file. Please try again.');
        return;
      }
      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const spreadsheetRows = XLSX.utils.sheet_to_json<PatientSpreadsheetRow>(
        workbook.Sheets['Patients'],
      );

      if (spreadsheetRows.length > 0) {
        setNewResidents(spreadsheetRows.map(mapSpreadsheetToBulkUploadPatient));
      } else {
        clearUploadedFiles();
        toast.error(
          'That file does not appear to contain any data. Please check the file and try again.',
        );
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleClose = () => {
    clearUploadedFiles();
    setNewResidents([]);
    setCreateResults([]);
    setSelectedCarePathwayId(null);
    setSelectedWardId(null);
    reloadPatients();
    return onClose();
  };

  const handleSave = async () => {
    try {
      await Promise.all(
        newResidents.map(async (patient) => {
          const response = await createPatient({
            onCompleted: ({ patient: createdPatient }) => {
              setResult(patient, PatientBulkUploadStatus.Created);

              const patientId = createdPatient.id;

              if (shouldShowAdmissionDetails && selectedWardId && selectedCarePathwayId) {
                admitPatient({
                  variables: {
                    patientId: patientId,
                    wardId: selectedWardId,
                    carePathwayId: selectedCarePathwayId,
                  },
                  onCompleted: () => {
                    setResult(patient, PatientBulkUploadStatus.Admitted);
                  },
                  onError: (admitError) => {
                    setResult(
                      patient,
                      PatientBulkUploadStatus.ErrorCreating,
                      humanGraphQLError(admitError),
                    );
                  },
                });
              }
            },
            onError: (createError) => {
              setResult(
                patient,
                containsPatientAlreadyExistsError(createError)
                  ? PatientBulkUploadStatus.AlreadyExists
                  : PatientBulkUploadStatus.ErrorCreating,
                humanGraphQLError(createError),
              );
            },
            variables: {
              patient: {
                type: 'elderly',
                firstName: patient.firstName,
                lastName: patient.lastName,
                birthDate: patient.birthDate,
                gender: patient.gender,
                address: patient.address,
                preExistingConditions: patient.preExistingConditions,
                // We kept the spaces for the nhsNumber in the UI, but we need to strip them before sending it to the server
                nationalIdentifierValue: patient.nationalIdentifierValue
                  ? stripSpaces(patient.nationalIdentifierValue)
                  : undefined,
              },
            },
          });

          return response;
        }),
      );
    } catch (err) {
      console.error(err);
    }
  };

  const anyPatientsHaveNhsNumber = newResidents.some((patient) =>
    isDefined(patient.nationalIdentifierValue),
  );

  const allSuccess =
    createResults.length > 0 &&
    createResults.every((result) =>
      [
        PatientBulkUploadStatus.Created,
        PatientBulkUploadStatus.AlreadyExists,
        PatientBulkUploadStatus.Admitted,
      ].includes(result.status),
    );

  const anyInProgress = createResults.some((result) =>
    [PatientBulkUploadStatus.Creating, PatientBulkUploadStatus.Admitting].includes(result.status),
  );

  const anyErrors = createResults.some((result) =>
    [PatientBulkUploadStatus.ErrorCreating].includes(result.status),
  );

  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
        <DialogTitle id="form-dialog-title">Upload Patients Data</DialogTitle>
        <DialogContent>
          {newResidents.length === 0 && (
            <>
              <Typography variant="h6" color="primary.dark">
                Select Patients
              </Typography>
              <Typography color="textSecondary">
                Add multiple patients in your care organisation by using our import template.
                <Button
                  size="small"
                  color="primary"
                  startIcon={<GetAppIcon />}
                  href={feebrisPatientsBulkUploadTemplate}
                  download="Feebris-Patients-Bulk-Upload-Template.xlsx">
                  Download Template
                </Button>
              </Typography>
              <Box className={classes.uploadBox}>
                <input
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  style={{ display: 'none' }}
                  ref={uploadInputRef}
                  id="file-upload"
                  type="file"
                  onChange={handleUpload}
                />
                <label htmlFor="file-upload">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}>
                    Select Import File
                  </Button>
                </label>
              </Box>
            </>
          )}
          {newResidents.length > 0 && (
            <>
              <Typography variant="h6" color="primary.dark">
                Imported Patients
              </Typography>
              <Typography color="textSecondary">
                Review the patients you have imported and click &quot;Save to Organization&quot; to
                complete the import.
              </Typography>
              <MaterialTable
                sx={{ marginTop: 2 }}
                columns={[
                  { title: 'First Name', field: 'firstName' },
                  { title: 'Last Name', field: 'lastName' },
                  // format the date to be more human readable
                  {
                    title: 'Birth Date',
                    field: 'birthDate',
                    type: 'date',
                    render: ({ birthDate }) =>
                      t('DATE_SHORT', {
                        val: new Date(birthDate),
                        // We use formatParams to force a timezone of UTC with the underlying Intl.DateTimeFormat
                        // function. This ensures that birthdates remain timezone agnostic, which is what most
                        // people expect, culturally.
                        // see: https://www.i18next.com/translation-function/formatting#datetime
                        formatParams: { val: { timeZone: 'UTC' } },
                      }),
                  },
                  {
                    title: 'Gender',
                    field: 'gender',
                  },
                  {
                    title: 'NHS number',
                    field: 'nhsNumberResponseDetails.nhsNumber',
                    hidden: !anyPatientsHaveNhsNumber,
                  },
                  {
                    title: 'Status',
                    render: (row) => <PatientCreateResult patient={row} result={getResult(row)} />,
                  },
                ]}
                components={{
                  Container: (props) => <div {...props} />,
                }}
                data={newResidents}
                title={null}
                options={{ search: false, paging: false, sorting: false, toolbar: false }}
              />
            </>
          )}
          {shouldShowAdmissionDetails && (
            <Grid marginTop={1} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary.dark">
                  Select Ward and Care Pathway
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Select an initial ward and care pathway to assign to all patients being uploaded
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  loading={loadingWardsAndCarePathways}
                  options={sortedWards}
                  readOnly={createResults.length > 0}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(_, value) => setSelectedWardId(value?.id || null)}
                  noOptionsText="No wards found"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select a Ward"
                      label="Ward"
                      variant="outlined"
                      InputProps={{ readOnly: createResults.length > 0, ...params.InputProps }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  loading={loadingWardsAndCarePathways}
                  options={sortedCarePathways}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, value) => setSelectedCarePathwayId(value?.id || null)}
                  noOptionsText="No care pathways found"
                  readOnly={createResults.length > 0}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select a Care Pathway"
                      label="Care Pathway"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ readOnly: createResults.length > 0, ...params.InputProps }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          {!allSuccess && (
            <>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                disabled={!canSubmit || anyInProgress}>
                {anyErrors ? t('Retry') : t('Save to Organization')}
              </Button>
            </>
          )}
          {allSuccess && (
            <Button disabled={anyInProgress} onClick={handleClose}>
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

const patientFieldLabels: Record<keyof BulkUploadPatient, string> = {
  firstName: 'First Name',
  lastName: 'Last Name',
  birthDate: 'Birth Date',
  address: 'Address',
  gender: 'Gender',
  nationalIdentifierValue: 'NHS Number',
  preExistingConditions: 'Pre-Existing Conditions',
};

/**
 * Scoop up the human readable error message from the graphQLErrors and return it
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const humanGraphQLError = (error: Record<string, any>): string[] | undefined => {
  const argErrors = error.graphQLErrors
    .flatMap(
      (err: GraphQLError) =>
        err.extensions.invalidArgs &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.values(err.extensions.invalidArgs as Record<string, Record<string, any>>).flatMap(
          (value) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Object.entries(value as any).flatMap(
              ([key, value]) =>
                `${patientFieldLabels[key as keyof BulkUploadPatient] ?? key}: ${value}`,
            ),
        ),
    )
    .filter(isDefined);

  if (!argErrors) {
    return error.message ? [error.message] : ['Unknown error'];
  }

  return argErrors;
};

const containsPatientAlreadyExistsError = (error: ApolloError) => {
  return error.graphQLErrors.some((err) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const patient = _.get(err as any, 'extensions.invalidArgs.patient') as
      | Record<string, string>
      | undefined;
    if (patient?.firstName === 'A patient with that First Name already exists') {
      return true;
    }
    return false;
  });
};

interface PatientCreateResultProps {
  patient: BulkUploadPatient;
  result: CreatePatientResult | undefined;
}

function PatientCreateResult({ result }: PatientCreateResultProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.FocusEvent<HTMLElement, Element> | React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useKeyPressEvent('Escape', handlePopoverClose);

  const color = useMemo(
    () =>
      ((
        {
          [PatientBulkUploadStatus.Pending]: 'default',
          [PatientBulkUploadStatus.Creating]: 'default',
          [PatientBulkUploadStatus.Created]: 'success',
          [PatientBulkUploadStatus.ErrorCreating]: 'error',
          [PatientBulkUploadStatus.AlreadyExists]: 'warning',
          [PatientBulkUploadStatus.Admitting]: 'default',
          [PatientBulkUploadStatus.Admitted]: 'success',
          [PatientBulkUploadStatus.ErrorAdmitting]: 'error',
        } as const
      )[result?.status ?? PatientBulkUploadStatus.Pending]),
    [result],
  );

  const label = useMemo(
    () =>
      ({
        [PatientBulkUploadStatus.Pending]: 'Pending',
        [PatientBulkUploadStatus.Creating]: 'Creating Patient',
        [PatientBulkUploadStatus.Created]: 'Created',
        [PatientBulkUploadStatus.ErrorCreating]: 'Error Creating',
        [PatientBulkUploadStatus.AlreadyExists]: 'Already Exists',
        [PatientBulkUploadStatus.Admitting]: 'Admitting',
        [PatientBulkUploadStatus.Admitted]: 'Admitted',
        [PatientBulkUploadStatus.ErrorAdmitting]: 'Error Admitting',
      }[result?.status ?? PatientBulkUploadStatus.Pending]),
    [result],
  );

  const isError = [
    PatientBulkUploadStatus.ErrorCreating,
    PatientBulkUploadStatus.ErrorAdmitting,
  ].includes(result?.status ?? PatientBulkUploadStatus.Pending);

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        disableAutoFocus
        disableRestoreFocus
        disableEnforceFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          pointerEvents: 'none',
          marginTop: 1,
        }}
        onClose={handlePopoverClose}>
        <Box padding={2} maxWidth={600}>
          <Box>
            <Typography variant="body2" fontWeight={500} color={isError ? 'error' : 'primary.dark'}>
              {label}
            </Typography>
          </Box>
          {result?.status === PatientBulkUploadStatus.AlreadyExists && (
            <>
              <Typography variant="body2" gutterBottom>
                A patient matching these details already exists in your organization.
              </Typography>
              <Typography variant="body2" gutterBottom>
                No new patient was created, and the existing patient was not updated.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Any selected ward or care pathway details will be skipped for this patient.
              </Typography>
            </>
          )}
          {isError ? (
            <Box marginTop={1}>
              {result?.errors?.map((error, index) => (
                <Typography key={index} variant="body2" gutterBottom>
                  {error}
                </Typography>
              ))}
            </Box>
          ) : null}
        </Box>
      </Popover>
      <Chip
        title="Patient Status, click for more details"
        label={label}
        color={color}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onFocus={handlePopoverOpen}
        onBlur={handlePopoverClose}
        tabIndex={0}
      />
    </>
  );
}

// see this issue to know why this is here
// https://github.com/SheetJS/sheetjs/issues/1223
function excelDateToISODate(excelDateNumber: number | string) {
  if (!_.isNumber(excelDateNumber)) {
    return excelDateNumber;
  }
  const date = new Date(Math.round((excelDateNumber - 25569) * 86400 * 1000));
  return date.toISOString();
}

function mapSpreadsheetToBulkUploadPatient(residents: PatientSpreadsheetRow): BulkUploadPatient {
  return {
    firstName: residents['First Name']?.trim() ?? '',
    lastName: residents['Last Name']?.trim() ?? '',
    gender: residents['Gender (male/female)']?.trim() ?? '',
    nationalIdentifierValue: residents['NHS Number']?.trim() ?? '',
    birthDate: excelDateToISODate(residents['Birth Date (DD/MM/YYYY)'])?.trim() ?? '',
    address:
      residents['Address']?.trim() && residents['Postcode']?.trim()
        ? {
            address: residents['Address']?.trim(),
            postcode: residents['Postcode']?.trim(),
          }
        : null,
    preExistingConditions: residents['Pre-Existing Conditions (comma separated)']?.trim() ?? null,
  };
}
