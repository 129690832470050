import React, { Component } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import withTheme from '@mui/styles/withTheme';
import withStyles from '@mui/styles/withStyles';
import { Button, Typography } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import localeFormatting from '@/helpers/LocaleFormatting';
import {
  scaleXBandPosition,
  scaleYBandPosition,
  calculateLegendBands,
  calculateBins,
  calculateCircleYPosition,
  calculateAxisDates,
} from '@/helpers/d3Charts';
import { isDefined } from '@/helpers/isDefined';

const useStyles = () => ({
  titleBar: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: 35,
    '& span': {
      flexGrow: 1,
    },
  },
  buttonContainer: {
    paddingRight: 32,
  },
  restartButton: {
    minWidth: 140,
    fontSize: 11,
  },
});

class VitalSignChart extends Component {
  state = {
    hasDrawn: false,
  };

  constructor(props) {
    super(props);

    this.vitalSignChart = React.createRef();
  }

  /**
   * Checks if the chart can be drawn.
   *
   * The chart can be drawn if:
   * - It hasn't been drawn yet.
   * - The legend domain is defined.
   * - There is at least one defined value in the checkup data.
   * - The EWS thresholds are defined.
   * - The NEWS thresholds are defined.
   *
   * NOTE: This isn't a complete list of props that are required for the chart to be drawn, but
   * they're the ones which are likely to be missing when the chart can't be drawn.
   *
   * This is very much a hack, ideally the component would be refactored to use a more
   * reactive approach to rendering the chart when props change.
   */
  get canDraw() {
    return (
      !this.state.hasDrawn &&
      this.props.defaultLegendDomain &&
      this.props.ewsThresholds &&
      this.props.newsThresholds
    );
  }

  prepareData() {
    this.unitFormatter = d3.format(this.props.unitFormat);
    this.extraFormatter = this.props.extraFormatter ?? ((v) => v);

    // Array to keep track of the threshold values
    // that are not being dragged in the legend.
    this.others = [];

    // D3 elements
    // SVG
    this.svg = null;
    this.margin = { top: 0, right: 300, bottom: 200, left: 80 };

    // Chart and axis section
    this.chartGroup = null;
    this.chartGroupPosition = { x: 0, y: 50 };

    // Legends
    this.legendPadding = 35;
    this.legendSeparation = 120;
    this.legendScale = null;
    this.legendGroup = null;
    this.ewsThresholdPreviousDomain = null;
    this.ewsThresholdScale = null;
    this.ewsLegendAxis = null;
    this.ewsLegendRects = null;
    this.ewsPreviousLegendRects = null;
    this.ewsPreviousLegendScoreNumbers = null;
    this.ewsLegendBoxes = null;
    this.ewsPreviousLegendBoxes = null;
    this.newsThresholdScale = null;
    this.newsLegendRects = null;
    this.newsThresholdScaleDomain = null;

    // Y axis
    this.yScale = null;
    this.yAxis = null;
    this.yAxisGroup = null;
    this.yAxisLabels = null;
    this.yAxisLabelSelected = null;
    this.bins = null;

    // X axis
    this.axisDates = calculateAxisDates(this.props.checkupData, 10);
    this.xScale = null;
    this.xAxisLabels = null;
    this.xAxisLabelSelected = null;

    /**
     * When rounding the threshold values down, we need to ensure at least an extra step size is subtracted
     * to ensure the last threshold band is rendered in the chart. We actually allow for two step sizes of leeway so
     * that the user can drag the last threshold band further down the chart during customisation.
     *
     * Additionally we need to check if the value is lower than the step size to avoid negative values.
     *
     * Without this, there will not be a valid domain in the legend scale to render the last threshold band.
     * @example the yAxisStepSize is 10, and the last threshold is 30, we need to render a row for 20-30 (which will be labelled < 30).
     * @example the yAxisStepSize is 10, the last threshold is 5, we need to render a row for 0-10 (which will be labelled < 10).
     */
    const roundDownStep = (value) =>
      Math.max(
        Math.floor(value / this.props.yAxisStepSize) * this.props.yAxisStepSize -
          this.props.yAxisStepSize * 2,
        this.props.yAxisStepSize,
      );

    /**
     * When rounding the threshold values up, we need to ensure at least a full extra step size is added
     * to ensure the first threshold band is rendered in the chart. We actually allow for two step sizes of leeway so
     * that the user can drag the first threshold band further up the chart during customisation.
     *
     * We first round down the value to the nearest step size, then add the step size to ensure we render the last threshold band in the chart.
     * @example the step size is 10 and the value is 70, we need to render a row for 70-80 (which will be labelled >= 70).
     * @example the step size is 10 and the value is 71, we need to render a row for 70-80 (which will be labelled >= 70).
     */
    const roundUpStep = (value) =>
      Math.floor(value / this.props.yAxisStepSize) * this.props.yAxisStepSize +
      this.props.yAxisStepSize * 2;

    /**
     * The minimum value of the legend domain is the minimum value of the EWS thresholds, NEWS thresholds and the default legend domain.
     * This ensures that when thresholds are lower than the default legend domain, the legend domain starts at the lowest threshold.
     *
     * When using provided thresholds, we need to round down the value to the nearest step size
     * to ensure we render the last threshold band in the chart.
     */
    const minThreshold = [
      this.props.ewsThresholds ? roundDownStep(_.last(this.props.ewsThresholds).high) : undefined,
      this.props.newsThresholds ? roundDownStep(_.last(this.props.newsThresholds).high) : undefined,
      this.props.defaultLegendDomain[0],
    ]
      .filter(isDefined)
      .reduce((a, b) => Math.min(a, b));

    /**
     * The maximum value of the legend domain is the maximum value of the EWS thresholds, NEWS thresholds and the default legend domain.
     * This ensures that when thresholds are higher than the default legend domain, the legend domain ends at the highest threshold.
     *
     * When using provided thresholds, we need to round up the value to the nearest full step size to
     * ensure we render the first threshold band in the chart.
     */
    const maxThreshold = [
      this.props.ewsThresholds ? roundUpStep(_.first(this.props.ewsThresholds).low) : undefined,
      this.props.newsThresholds ? _.first(this.props.newsThresholds).low : undefined,
      this.props.defaultLegendDomain[1],
    ]
      .filter(isDefined)
      .reduce((a, b) => Math.max(a, b));

    this.legendDomain = [minThreshold, maxThreshold];

    // Chart
    this.tileSize = 20;
    const rows = (this.legendDomain[1] - this.legendDomain[0]) / this.props.yAxisStepSize;
    this.gridWidth = this.axisDates.length * this.tileSize;
    this.gridHeight = this.tileSize * rows;
    this.tooltip = null;
    this.tiles = null;
    this.circles = null;
  }

  componentDidMount() {
    // Check we have the necessary data to draw the chart on mount (otherwise it will be drawn on update)
    if (this.canDraw) {
      this.prepareData();
      this.drawChart();
    }
  }

  componentDidUpdate() {
    if (this.props.isEditModeSelected) {
      this.ewsThresholdPreviousDomain = this.ewsThresholdScale.domain();
    }

    // Check we have the necessary data to draw the chart on update, and that it hasn't been drawn yet
    if (this.canDraw) {
      this.prepareData();
      this.drawChart();
    }
  }

  drawChart() {
    this.svg = d3
      .select(this.vitalSignChart.current)
      .append('svg')
      .attr('width', this.gridWidth + this.margin.left + this.margin.right)
      .attr('height', this.gridHeight + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    this.installButtonDropShadowFilter(this.svg);

    this.chartGroup = this.svg
      .append('g')
      .attr(
        'transform',
        'translate(' + this.chartGroupPosition.x + ',' + this.chartGroupPosition.y + ')',
      );

    this.legendScale = d3.scaleLinear().domain(this.legendDomain).range([this.gridHeight, 0]);

    this.legendGroup = this.chartGroup
      .append('g')
      .attr('transform', 'translate(' + (this.gridWidth + 50) + ',' + 0 + ')');

    this.initializeEwsVariables(this.props.ewsThresholds, this.props.colorBands);
    this.initializeNewsVariables(this.props.newsThresholds, this.props.colorBands);

    if (this.props.isEditModeSelected) {
      this.drawEwsLegend(this.unitFormatter);
      this.drawNewsLegend(this.unitFormatter, this.props.colorBands);
    }

    this.drawYAxis();

    this.bins = calculateBins(
      this.props.checkupData,
      this.props.vitalSelector,
      this.legendScale.domain(),
      this.ewsThresholdScale.domain(),
      this.props.yAxisStepSize,
    );

    this.updateYAxis(this.bins, this.yScale, this.yAxis, this.yAxisGroup);

    this.drawXAxis();

    this.tooltip = d3
      .select(this.vitalSignChart.current)
      .append('div')
      .style('opacity', 0)
      .style('position', 'absolute')
      .attr('class', 'tooltip')
      .style('background-color', 'white')
      .style('border', 'solid')
      .style('border-width', '2px')
      .style('border-radius', '5px')
      .style('padding', '10px')
      .style('width', '200px');

    let gridData = [];
    for (const row of this.yScale.domain()) {
      for (const column of this.xScale.domain()) {
        gridData.push({ row: row, column: column });
      }
    }

    this.tiles = this.chartGroup
      .selectAll('.tile')
      .data(gridData)
      .enter()
      .append('rect')
      .attr('class', 'tile')
      .attr('x', (d) => this.xScale(d.column))
      .attr('y', (d) => this.yScale(d.row))
      .attr('width', this.xScale.bandwidth())
      .attr('height', this.yScale.bandwidth())
      .style('stroke-width', 1)
      .style('stroke', 'gray')
      .style('stroke-dasharray', '1 2')
      .style('opacity', 0.8)
      .on('mouseover', this.mouseoverTileListener.bind(this))
      .on('mouseleave', this.mouseleaveTileListener.bind(this));

    this.updateTilesColor(this.props.isEditModeSelected, this.bins, this.ewsThresholdScale);

    this.circles = this.chartGroup
      .selectAll('.tile .circle')
      .data(this.props.checkupData)
      .enter()
      .filter((d) => _.get(d, this.props.vitalSelector))
      .append('circle')
      .attr('class', 'circle')
      .attr(
        'cx',
        (checkup) =>
          this.xScale(
            localeFormatting.formatCheckupTimeShortWithoutYearAndWeekDay(checkup.endedAt),
          ) +
          this.xScale.bandwidth() / 2,
      )
      .attr('r', this.yScale.bandwidth() / 5)
      .style('fill', 'black')
      .style('stroke-width', 1)
      .style('stroke', 'black')
      .style('opacity', 0.8)
      .on('mouseover', this.mouseoverCircleListener.bind(this))
      .on('mousemove', this.mousemoveCircleListener.bind(this))
      .on('mouseleave', this.mouseleaveCircleListener.bind(this));

    this.updateCirclesPosition(this.bins, this.yScale, this.legendScale, this.props.vitalSelector);

    this.setState({ hasDrawn: true });
  }

  // see: https://gist.github.com/mef79/e05ad2632c62b0df0c0159973b5cea61
  installButtonDropShadowFilter(svg) {
    const defs = svg.append('defs');
    var filter = defs
      .append('filter')
      .attr('id', 'drop-shadow')
      // these values are larger than necessary to account for highly offset shadows
      // they can be decreased to the maximum expected area around the element
      .attr('y', '-100%')
      .attr('x', '-100%')
      .attr('height', '300%')
      .attr('width', '300%');

    // an SVG gaussian blur based on the shape of the element
    filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 1);

    // offset the blur by dx and dy pixels
    filter.append('feOffset').attr('dx', 0).attr('dy', 1).attr('result', 'offsetblur');

    // apply the color to the filter
    filter
      .append('feFlood')
      .attr('flood-color', '#373737')
      .attr('flood-opacity', '1')
      .attr('result', 'colorblur');

    // combine the effects of the offset and the color filters
    filter
      .append('feComposite')
      .attr('in', 'colorblur')
      .attr('in2', 'offsetblur')
      .attr('operator', 'in');

    // shows both the original element and the drop shadow
    var feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
  }

  initializeEwsVariables(thresholds, colorBands) {
    /* eslint-disable no-unused-vars */
    // Variables have been created to improve readability
    const ewsThresholdScaleDomain = thresholds
      .filter((band) => band.low)
      .map((band) => band.low)
      .sort((a, b) => a - b);

    const ewsThresholdScaleRange = thresholds.map((band) => colorBands[band.score]).reverse();

    this.ewsThresholdScale = d3
      .scaleThreshold()
      .domain(ewsThresholdScaleDomain)
      .range(ewsThresholdScaleRange);
  }

  initializeNewsVariables(thresholds, colorBands) {
    /* eslint-disable no-unused-vars */
    // Variables have been created to improve readability
    this.newsThresholdScaleDomain = thresholds
      .filter((band) => band.low)
      .map((band) => band.low)
      .sort((a, b) => a - b);

    const newsThresholdScaleRange = thresholds.map((band) => colorBands[band.score]).reverse();

    this.newsThresholdScale = d3
      .scaleThreshold()
      .domain(this.newsThresholdScaleDomain)
      .range(newsThresholdScaleRange);
  }

  drawEwsLegend(unitFormatter) {
    /* eslint-disable no-unused-vars */
    // Variables have been created to improve readability
    const legendContainer = this.legendGroup
      .append('rect')
      .attr('x', -5)
      .attr('y', -45)
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('height', this.gridHeight + 65)
      .attr('width', '100px')
      .attr('stroke', 'gray')
      .attr('stroke-width', 1)
      .style('fill', 'white');

    const ewsLegendTitle = this.legendGroup
      .append('text')
      .attr('transform', 'translate(' + (this.legendPadding + 9) + ',' + -15 + ')')
      .style('font-size', '12px')
      .style('font-weight', 'bold')
      .style('text-anchor', 'middle')
      .text('EWS');

    this.ewsLegendAxis = (domain) => {
      return d3
        .axisRight(this.legendScale)
        .tickSizeInner(35)
        .tickSizeOuter(0)
        .tickValues(domain)
        .tickFormat((d) => this.extraFormatter(unitFormatter(d)));
    };

    this.ewsLegendBoxes = this.legendGroup.append('g').selectAll('.legendBox');

    this.ewsLegendRects = this.legendGroup.append('g').selectAll('.legendRect');

    this.legendGroup
      .append('g')
      .style('font-size', 12)
      .style('font-weight', 'bold')
      .attr('class', 'ewsAxisLegend')
      .attr('transform', 'translate(' + this.legendPadding + ',' + 0 + ')')
      .call(this.ewsLegendAxis(this.ewsThresholdScale.domain()))
      .select('.domain')
      .style('opacity', 0);

    this.updateEwsLegendRectangles(
      this.ewsThresholdScale,
      this.legendScale,
      this.ewsLegendRects,
      this.props.colorBands,
      0,
      this.legendPadding,
    );
    this.updateEwsLegendTickValues(
      this.legendGroup,
      this.ewsThresholdScale,
      this.dragLegendTick,
      this.props.theme,
    );
    this.updateEwsLegendTickValueBoxes(
      this.ewsLegendBoxes,
      this.legendScale,
      this.ewsThresholdScale,
      this.legendPadding,
    );
  }

  drawNewsLegend(unitFormatter, colorBands) {
    /* eslint-disable no-unused-vars */
    // Variables have been created to improve readability
    const legendContainer = this.legendGroup
      .append('rect')
      .attr('x', -5)
      .attr('y', -45)
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('height', this.gridHeight + 65)
      .attr('width', '100px')
      .attr('transform', 'translate(' + this.legendSeparation + ',' + 0 + ')')
      .attr('stroke', 'gray')
      .attr('stroke-width', 1)
      .style('fill', 'white');

    const newsLegendTitle = this.legendGroup
      .append('text')
      .attr(
        'transform',
        'translate(' + (this.legendSeparation + 10 + this.legendPadding) + ',' + -15 + ')',
      )
      .style('font-size', '12px')
      .style('font-weight', 'bold')
      .style('text-anchor', 'middle')
      .text(this.props.newsName);

    const newsLegendAxis = d3
      .axisRight(this.legendScale)
      .tickSizeInner(24)
      .tickSizeOuter(0)
      .tickValues(this.newsThresholdScaleDomain)
      .tickFormat((d) => unitFormatter(d));

    const newsLegendAxisGroup = this.legendGroup
      .append('g')
      .style('font-size', 10)
      .attr(
        'transform',
        'translate(' + (this.legendSeparation + this.legendPadding) + ',' + 0 + ')',
      )
      .attr('class', 'newsAxisLegend')
      .call(newsLegendAxis);

    newsLegendAxisGroup.select('.domain').style('opacity', 0);

    newsLegendAxisGroup
      .selectAll('.tick line')
      .style('stroke', (d) => this.newsThresholdScale(d))
      .style('stroke-width', '2');

    this.newsLegendRects = this.legendGroup.append('g').selectAll('legendRect');

    this.updateNewsLegendRectangles(
      this.newsThresholdScale,
      this.legendScale,
      this.newsLegendRects,
      colorBands,
      this.legendPadding,
    );
  }

  drawYAxis() {
    this.yScale = d3.scaleBand().range([this.gridHeight, 0]).padding(0.05);

    this.yAxis = d3.axisLeft(this.yScale).tickSize(0);

    this.yAxisGroup = this.chartGroup
      .append('g')
      .style('font-size', 10)
      .attr('class', 'yAxis')
      .attr('transform', 'translate(' + -8 + ', 0)');
  }

  drawXAxis() {
    this.xScale = d3.scaleBand().domain(this.axisDates).range([0, this.gridWidth]).padding(0.05);

    const xAxis = d3
      .axisBottom(this.xScale)
      .tickFormat((d) => (d.includes('Next check-up') ? '' : d))
      .tickSize(0);

    const xAxisGroup = this.chartGroup
      .append('g')
      .style('font-size', 10)
      .attr('class', 'xAxis')
      .attr('transform', 'translate(0,' + (this.gridHeight + 8) + ')');

    this.xAxisLabels = xAxisGroup
      .call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)');

    // Hide axis line
    xAxisGroup.select('.domain').style('opacity', 0);
  }

  dragLegendTick = d3
    .drag()
    .on('start', (event, d) => {
      this.others = [];
      this.ewsThresholdScale.domain().forEach((v) => {
        if (v === d) {
          return;
        }
        this.others.push(v);
      });
    })
    .on('drag', (event, d) => {
      const xMin = this.legendScale.domain()[0];
      const xMax = this.legendScale.domain()[1];

      let newValue = this.legendScale.invert(event.y);
      if (newValue <= xMin) {
        newValue = xMin + this.props.legendAxisStepSize;
      } else if (xMax <= newValue) {
        newValue = xMax - this.props.legendAxisStepSize;
      }

      let newDomain = this.others.slice();
      newDomain.push(newValue);
      newDomain = d3.sort(newDomain);
      this.updateBinsAndLegendDomain(newDomain);
      this.updateLegendGraphicsAndTiles();
    })
    .on('end', (event, d) => {
      // We need to force rounded up values on the axis to avoid wrong displays.
      const newDomain = this.ewsThresholdScale
        .domain()
        .map((threshold) => parseFloat(this.unitFormatter(threshold)));
      this.updateAllElements(newDomain, this.props.ewsThresholds);
    });

  updateBinsAndLegendDomain(newDomain) {
    this.ewsThresholdScale.domain(newDomain);

    // Calculate new Y axis bins
    this.bins = calculateBins(
      this.props.checkupData,
      this.props.vitalSelector,
      this.legendScale.domain(),
      this.ewsThresholdScale.domain(),
      this.props.yAxisStepSize,
    );

    // Update legend axis visuals
    this.legendGroup.select('g .ewsAxisLegend').call(this.ewsLegendAxis(newDomain));
  }

  updateLegendGraphicsAndTiles() {
    this.updateEwsLegendRectangles(
      this.ewsThresholdScale,
      this.legendScale,
      this.ewsLegendRects,
      this.props.colorBands,
      0,
      this.legendPadding,
    );
    this.updateEwsLegendTickValues(
      this.legendGroup,
      this.ewsThresholdScale,
      this.dragLegendTick,
      this.props.theme,
    );
    this.updateEwsLegendTickValueBoxes(
      this.ewsLegendBoxes,
      this.legendScale,
      this.ewsThresholdScale,
      this.legendPadding,
    );
    this.updateTilesColor(this.props.isEditModeSelected, this.bins, this.ewsThresholdScale);
  }

  updateAxisGraphicsAndCircles() {
    this.updateYAxis(this.bins, this.yScale, this.yAxis, this.yAxisGroup);
    this.updateCirclesPosition(
      this.bins,
      this.yScale,
      this.legendScale,
      this.props.vitalSelector,
      true,
    );
  }

  updateThresholdsOnParent(thresholds) {
    this.props.updateEWSThresholds(
      this.ewsThresholdScale.domain(),
      thresholds,
      this.props.thresholdSelector,
    );
  }

  updateAllElements(newDomain, thresholds) {
    this.updateBinsAndLegendDomain(newDomain);
    this.updateLegendGraphicsAndTiles();
    this.updateAxisGraphicsAndCircles();
    this.updateThresholdsOnParent(thresholds);
  }

  updateNewsLegendRectangles(thresholdScale, legendScale, legendRects, colorBands, legendPadding) {
    const legendBands = calculateLegendBands(legendScale.domain(), thresholdScale.domain());
    const legendBand = legendRects.data(legendBands);

    legendBand
      .enter()
      .append('rect')
      .attr('class', 'legendRect')
      .attr('transform', 'translate(' + (this.legendSeparation + legendPadding) + ',' + 0 + ')')
      .attr('x', 0)
      .attr('y', (d) => legendScale(d[1]))
      .attr('height', (d) => legendScale(d[0]) - legendScale(d[1]))
      .attr('width', 18)
      .style('fill', (d) => thresholdScale(d[0]));

    const legendScoreNumber = legendRects.data(legendBands);

    legendScoreNumber
      .enter()
      .append('text')
      .attr('class', 'scoreNumber')
      .attr('transform', 'translate(' + (this.legendSeparation + legendPadding) + ',' + 0 + ')')
      .style('font-size', '11px')
      .attr('x', 10)
      .attr('y', (d) => (legendScale(d[1]) + legendScale(d[0])) / 2)
      .style('text-anchor', 'middle')
      .style('alignment-baseline', 'middle')
      .style('font-weight', 'bold')
      .text((d) => {
        // If there is no space to display the score number in the legend, remove.
        if (Math.abs(legendScale(d[1]) - legendScale(d[0])) < 18) {
          return;
        }
        return colorBands.indexOf(thresholdScale(d[0]));
      });
  }

  // Recalculates and updates the rectangles that represent each of the score bands of the legend.
  updateEwsLegendRectangles(
    thresholdScale,
    legendScale,
    legendRects,
    colorBands,
    legendIndex,
    legendPadding,
  ) {
    if (this.ewsPreviousLegendRects !== null && this.ewsPreviousLegendScoreNumbers !== null) {
      this.ewsPreviousLegendRects.remove();
      this.ewsPreviousLegendScoreNumbers.remove();
    }

    const legendBands = calculateLegendBands(legendScale.domain(), thresholdScale.domain());
    const legendBand = legendRects.data(legendBands);

    this.ewsPreviousLegendRects = legendBand
      .enter()
      .append('rect')
      .attr('class', 'legendRect')
      .attr(
        'transform',
        'translate(' + (this.legendSeparation * legendIndex + legendPadding) + ',' + 0 + ')',
      )
      .attr('x', 0)
      .attr('y', (d) => legendScale(d[1]))
      .attr('height', (d) => legendScale(d[0]) - legendScale(d[1]))
      .attr('width', 18)
      .style('fill', (d) => thresholdScale(d[0]));

    const legendScoreNumber = legendRects.data(legendBands);

    this.ewsPreviousLegendScoreNumbers = legendScoreNumber
      .enter()
      .append('text')
      .attr('class', 'scoreNumber')
      .attr(
        'transform',
        'translate(' + (this.legendSeparation * legendIndex + legendPadding) + ',' + 0 + ')',
      )
      .style('font-size', '11px')
      .attr('x', 10)
      .attr('y', (d) => (legendScale(d[1]) + legendScale(d[0])) / 2)
      .style('text-anchor', 'middle')
      .style('alignment-baseline', 'middle')
      .style('font-weight', 'bold')
      .text((d) => {
        // If there is no space to display the score number in the legend, remove.
        if (Math.abs(legendScale(d[1]) - legendScale(d[0])) < 18) {
          return;
        }
        return colorBands.indexOf(thresholdScale(d[0]));
      });
  }

  updateEwsLegendTickValues(legendGroup, thresholdScale, dragLegendTick, theme) {
    const legendTicks = legendGroup.selectAll('.ewsAxisLegend .tick');

    legendTicks.selectAll('rect').remove();
    legendTicks.each(function () {
      d3.select(this)
        .append('rect')
        .attr('width', 26)
        .attr('height', 8)
        .attr('rx', 2)
        .attr('transform', 'translate(-4, -8)')
        .style('fill', theme.palette.primary.main)
        .style('filter', 'url(#drop-shadow');
    });
    legendTicks.selectAll('line').remove();
    legendTicks.selectAll('text').attr('transform', 'translate(0, -3.5)');

    legendTicks.style('cursor', 'ns-resize').call(dragLegendTick);

    legendTicks
      .selectAll('line')
      .style('stroke', (d) => thresholdScale(d))
      .style('stroke-width', '2');
  }

  updateEwsLegendTickValueBoxes(legendBoxes, legendScale, thresholdScale, legendPadding) {
    if (this.ewsPreviousLegendBoxes !== null) {
      this.ewsPreviousLegendBoxes.remove();
    }

    this.ewsPreviousLegendBoxes = legendBoxes
      .data(thresholdScale.domain())
      .enter()
      .append('rect')
      .attr('class', 'legendBox')
      .attr('transform', 'translate(' + (26 + legendPadding) + ',' + -13.5 + ')')
      .attr('x', 0)
      .attr('y', (d) => legendScale(d))
      .attr('height', 20)
      .attr('width', 45)
      .attr('filter', 'url(#drop-shadow)')
      .style('fill', (d) => thresholdScale(d))
      .style('rx', 5)
      .style('ry', 5);
  }

  updateYAxis(bins, yScale, yAxis, yAxisGroup) {
    // Domain of the axis corresponds to the bin index.
    yScale.domain(Array.from(Array(bins.length).keys()));

    this.yAxisLabels = yAxisGroup
      .call(yAxis)
      .selectAll('text')
      .text((i) => {
        if (i === 0) {
          return `< ${this.extraFormatter(this.unitFormatter(bins[i].x1))}`;
        } else if (i === yScale.domain().length - 1) {
          return `>= ${this.extraFormatter(this.unitFormatter(bins[i].x0))}`;
        } else {
          // We remove a unit to the upper bound of the bin for not having repeated values between rows.
          // calculateCircleYPosition at d3Charts.js takes this into account.
          return `${this.extraFormatter(this.unitFormatter(bins[i].x0))} - ${this.extraFormatter(
            this.unitFormatter(bins[i].x1 - this.props.legendAxisStepSize),
          )}`;
        }
      });

    // Hide axis line
    yAxisGroup.select('.domain').style('opacity', 0);
  }

  updateTilesColor(isEditModeSelected, bins, thresholdScale) {
    this.tiles.style('fill', (d) =>
      isEditModeSelected ? thresholdScale(bins[d.row].x1) : this.props.grayColorBands[0],
    );
  }

  updateCirclesPosition(bins, yScale, legendScale, vitalSelector, transition = false) {
    if (transition) {
      this.circles
        .transition()
        .attr('cy', (d, i) =>
          calculateCircleYPosition(_.get(d, vitalSelector), bins, yScale, legendScale),
        );
    } else {
      this.circles.attr('cy', (d, i) =>
        calculateCircleYPosition(_.get(d, vitalSelector), bins, yScale, legendScale),
      );
    }
  }

  // Finds the corresponding axis labels for the position of the mouse when navigating through the chart.
  findSelectedAxisLabels(event, xScale, yScale, xAxisLabels, yAxisLabels) {
    const xAxisPosition = scaleXBandPosition(event, xScale);
    const yAxisPosition = scaleYBandPosition(event, yScale);

    this.xAxisLabelSelected = xAxisLabels.filter((d) => d === xAxisPosition);
    this.yAxisLabelSelected = yAxisLabels.filter((d) => d === yAxisPosition);
  }

  updateAxisLabelsFontWeight(bold) {
    if (bold) {
      this.xAxisLabelSelected.style('font-weight', 'bold');
      this.yAxisLabelSelected.style('font-weight', 'bold');
    } else {
      this.xAxisLabelSelected.style('font-weight', 'normal');
      this.yAxisLabelSelected.style('font-weight', 'normal');
    }
  }

  mouseoverTileListener(event) {
    d3.select(event.target)
      .style('stroke', 'black')
      .style('stroke-width', '3')
      .style('stroke-dasharray', '')
      .style('opacity', 1);

    this.findSelectedAxisLabels(
      event,
      this.xScale,
      this.yScale,
      this.xAxisLabels,
      this.yAxisLabels,
    );
    this.updateAxisLabelsFontWeight(true);
  }

  mouseleaveTileListener(event) {
    d3.select(event.target)
      .style('stroke', 'gray')
      .style('stroke-width', '1')
      .style('stroke-dasharray', '1 2')
      .style('opacity', 0.8);

    this.updateAxisLabelsFontWeight(false);
  }

  mouseoverCircleListener(event) {
    this.tooltip.style('opacity', 1);

    d3.select(event.target).style('stroke', 'black').style('stroke-width', '4').style('opacity', 1);

    this.findSelectedAxisLabels(
      event,
      this.xScale,
      this.yScale,
      this.xAxisLabels,
      this.yAxisLabels,
    );
    this.updateAxisLabelsFontWeight(true);
  }

  mousemoveCircleListener(event, circleCheckup) {
    // Updates variable at parent to synchronize with table
    this.props.setHoverCheckupIndex(
      this.props.checkupData.findIndex((checkup) => checkup.id === circleCheckup.id),
    );

    this.tooltip
      .style('left', d3.pointer(event, this)[0] + 'px')
      .style('top', d3.pointer(event, this)[1] + 20 + 'px')
      .style('font-size', '12px')
      .style('line-height', 1.5)
      .html(`<b>Date:</b> ${localeFormatting.formatCheckupTimeLongWithoutWeekDay(
      circleCheckup.endedAt,
    )}<br>
                       <b>Value:</b> ${this.extraFormatter(
                         _.get(circleCheckup, this.props.vitalSelector),
                       )}<br>
                       <b>Score band:</b> ${this.selectScoreBand()}<br>`);
  }

  selectScoreBand() {
    return _.get(this.props.checkupData[this.props.hoverCheckupIndex], this.props.ewsScoreSelector);
  }

  mouseleaveCircleListener(event) {
    this.tooltip.style('opacity', 0);

    d3.select(event.target).style('stroke', 'black').style('stroke-width', '1');

    this.updateAxisLabelsFontWeight(false);
    this.props.setHoverCheckupIndex(null);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.titleBar}>
          <div>
            <Typography variant="h6">{this.props.title}</Typography>
            <span>{this.props.subtitle}</span>
          </div>
          <span></span>
          {this.props.isEditModeSelected && (
            <div className={classes.buttonContainer}>
              <Button
                className={classes.restartButton}
                size="small"
                variant="contained"
                color="primary"
                startIcon={<RotateLeftIcon />}
                onClick={() => {
                  this.initializeEwsVariables(this.props.newsThresholds, this.props.colorBands);
                  this.updateAllElements(this.newsThresholdScaleDomain, this.props.newsThresholds);
                }}>
                Reset to {this.props.newsName}
              </Button>
            </div>
          )}
        </div>
        <div ref={this.vitalSignChart} />
      </>
    );
  }
}

export default withStyles(useStyles)(withTheme(VitalSignChart));
