import wordlist from '@/assets/eff_large_wordlist.json';

export function generatePassphrase(numWords = 3) {
  const newPassword = [];
  for (let i = 0; i < numWords; i++) {
    const aWord = wordlist[Math.floor(Math.random() * wordlist.length)];
    newPassword.push(aWord);
  }
  return newPassword.join('-');
}
