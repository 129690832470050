export function pathWithNext(path) {
  // Only add the '?next=' query param when we are on a meaningful page to redirect to
  if (!['/', '/login', '/terms', '/checkups'].includes(window.location.pathname)) {
    const queryStringParams = window.location.search ? `&${window.location.search.slice(1)}` : '';

    return `${path}?next=${getNext(window.location.pathname)}${queryStringParams}`;
  } else {
    return path;
  }
}

export function getNext(defaultPath = '/') {
  const urlParams = new URLSearchParams(window.location.search);
  const next = urlParams.has('next') ? urlParams.get('next') : '';
  const nextIsSafe = next.startsWith('/');
  return next && nextIsSafe ? next : defaultPath;
}
