import React, { useState } from 'react';
import { Box, BoxProps, Button, CircularProgress, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import auth, { MfaSetup } from '@/controllers/Auth';
import { gql } from '@apollo/client';
import { toast } from 'sonner';
import {
  useUnenrollMyTwoFactorAuthMutation,
  useMarkMyEmailAsVerifiedMutation,
  useGetTotpUserEnrolmentQuery,
} from '@/generated/graphql';
import EnableTwoFactorAuthModal from './components/EnableTwoFactorAuthModal';
import Loading from '@/components/Loading';
import ReauthenticationModal from './components/ReauthenticationModal';
import { Alert } from '@mui/material';

export const GET_TOTP_ENROLLMENT_MFA = gql`
  query getTotpUserEnrolment {
    me {
      email
      enrolledInTotpMfa
    }
  }
`;

export const MUTATION_UNENROLL_MFA = gql`
  mutation unenrollMyTwoFactorAuth {
    unenrollMyTwoFactorAuth
  }
`;

export const MUTATION_VERIFY_EMAIL = gql`
  mutation markMyEmailAsVerified {
    markMyEmailAsVerified
  }
`;

export function AccountSecurityCard(props: BoxProps) {
  const classes = useStyles();
  const { data, refetch, loading: getTotpUserEnrolmentLoading } = useGetTotpUserEnrolmentQuery();
  const mfaEnabled = data?.me?.enrolledInTotpMfa;
  const email = data?.me?.email;

  const [mfaSetup, setMfaSetup] = useState<MfaSetup>();
  const [reauthenticationModalOpen, setReauthenticationModalOpen] = useState(false);
  const [enableTwoFactorAuthModalOpen, setEnableTwoFactorAuthModalOpen] = useState(false);
  const [loadingMfaSetup, setLoadingMfaSetup] = useState(false);

  const [unenrollFromTwoFactor, { loading: unenrollingTwoFactor }] =
    useUnenrollMyTwoFactorAuthMutation({
      onError: () => toast.error('An error occurred when removing two-factor authentication.'),
    });

  const [markMyEmailAsVerified] = useMarkMyEmailAsVerifiedMutation({
    onError: () => toast.error('There was a problem setting up two-factor authentication.'),
  });

  const startMfaSetup = async () => {
    if (mfaEnabled) {
      return;
    }

    setLoadingMfaSetup(true);

    try {
      // We have to ensure the user's email is verified in order to setup MFA
      // We have a lot of users who use fake email addresses so we manually do this.
      await markMyEmailAsVerified();

      const mfaSetup = await auth.StartMfaSetup();

      if (mfaSetup === 'RequiresReauthentication') {
        setReauthenticationModalOpen(true);
      } else {
        setMfaSetup(mfaSetup);
        setEnableTwoFactorAuthModalOpen(true);
      }
    } catch {
      toast.error('There was a problem setting up two-factor authentication.');
    } finally {
      setLoadingMfaSetup(false);
    }
  };

  return (
    <Loading showLoading={getTotpUserEnrolmentLoading}>
      <Box {...props} maxWidth="1024px" alignSelf="left">
        <Paper>
          <Box padding={3}>
            <Typography variant="h6" className={classes.heading}>
              Manage Two-Factor Authentication
            </Typography>

            <Typography className={classes.explanationText}>
              Two-Factor Authentication adds another layer of security to your account by requiring
              an additional code to login.
            </Typography>
            <Typography className={classes.explanationText}>
              {'You can get this code via an authentication app (such as '}
              <a
                href="https://www.microsoft.com/en-gb/security/mobile-authenticator-app"
                target="blank">
                Microsoft Authenticator
              </a>
              {') on your phone.'}
            </Typography>

            {mfaEnabled && (
              <>
                <Box marginY={3}>
                  <Typography className={classes.explanationText}>
                    Now that you have set up Two-Factor Authentication, the additional code will be
                    required each time you log in.
                  </Typography>
                  <Typography className={classes.explanationText}>
                    We would recommend that you only disable Two-Factor Authentication if you need
                    to switch to another authenticator app or phone.
                  </Typography>
                </Box>
                <Alert className={classes.alert} severity="info">
                  If you disable Two-Factor Authentication, you will be returned to the log in
                  screen.
                </Alert>
                <Box display="flex" alignItems="center" marginTop={2}>
                  <Button
                    variant="contained"
                    disabled={unenrollingTwoFactor}
                    className={classes.disableButton}
                    onClick={async () => {
                      await unenrollFromTwoFactor();

                      // manually signout to avoid confusing user experience
                      await auth.signout();
                    }}>
                    Disable two-factor authentication
                  </Button>
                  {unenrollingTwoFactor && <CircularProgress size={24} />}
                </Box>
              </>
            )}
            {mfaEnabled === false && (
              <Box display="flex" alignItems="center" marginTop={2}>
                <Button
                  disabled={loadingMfaSetup}
                  variant="contained"
                  className={classes.setupButton}
                  color="primary"
                  onClick={async () => {
                    await startMfaSetup();
                  }}>
                  Enable two-factor authentication
                </Button>
                {loadingMfaSetup && <CircularProgress size={24} />}
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
      {mfaSetup && email && (
        <EnableTwoFactorAuthModal
          open={enableTwoFactorAuthModalOpen}
          email={email}
          onClose={async () => {
            setEnableTwoFactorAuthModalOpen(false);
            await refetch();
          }}
          onError={() => toast.error('There was a problem setting up two-factor authentication.')}
          mfaSetup={mfaSetup}
        />
      )}
      <ReauthenticationModal
        open={reauthenticationModalOpen}
        onSuccess={async () => {
          setReauthenticationModalOpen(false);
          await startMfaSetup();
        }}
        onClose={async () => {
          setReauthenticationModalOpen(false);
        }}
        onError={() => toast.error('There was a problem setting up two-factor authentication.')}
      />
    </Loading>
  );
}

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2),
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  explanationText: {
    marginBottom: theme.spacing(1),
  },
  setupButton: {
    marginRight: theme.spacing(1),
  },
  disableButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));
