import React, { useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Collapse,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useConfirm } from 'material-ui-confirm';
import Moment from 'moment';

import {
  useGetAlertsQuery,
  GetAlertsQuery,
  Alert,
  Patient,
  AlertStatus,
  AlertType,
  AlertRuleTypes,
} from '@/generated/graphql';
import api from '@/controllers/Api';
import auth from '@/controllers/Auth';
import localeFormatting from '@/helpers/LocaleFormatting';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { toast } from 'sonner';
import { Alert as MuiAlert } from '@mui/material';
import _ from 'lodash';
import { SimilarNamesWarning } from '@/components/SimilarNamesWarning';
import { formatAlertName } from '../formatters';

export const GET_ALERTS = gql`
  query GetAlerts(
    $patientId: ID!
    $alertRuleId: ID
    $checkupScheduleId: ID
    $pacsanaEventId: Int
    $type: String
  ) {
    alerts(
      patientId: $patientId
      includeRecentlyClosed: true
      alertRuleId: $alertRuleId
      checkupScheduleId: $checkupScheduleId
      pacsanaEventId: $pacsanaEventId
      type: $type
    ) {
      id
      type
      status
      startedAt
      alertRule {
        id
        name
        type
        description
      }
      checkupScheduleEvent {
        checkupExpectedAt
        checkupSchedule {
          id
          tolerance {
            early
            late
          }
        }
      }
      checkup {
        id
        endedAt
      }
      continuousMonitoring {
        bucketEndAt
      }
      pacsanaEvent {
        eventKey
      }
    }
  }
`;

interface AlertModalProps {
  alert: Alert;
  onClose: () => void;
  open: boolean;
  patient: Pick<Patient, 'id' | 'firstName' | 'lastName' | 'numSimilarNames'>;
  refreshData: () => void;
}

export default function AlertModal(props: AlertModalProps) {
  const { data, loading } = useGetAlertsQuery({
    variables: {
      patientId: props.patient.id,
      alertRuleId: props.alert.alertRule?.id,
      checkupScheduleId: props.alert.checkupScheduleEvent?.checkupSchedule.id,
      pacsanaEventId: props.alert.pacsanaEvent?.eventId,
      type: props.alert.type?.toString(),
    },
    // Because the alert query returns a different shape depending on the aggregate flag
    // it's confusing the cache from the Virtual Ward page...
    fetchPolicy: 'no-cache',
    onError: () => toast.error('An error occurred while loading alerts'),
  });

  const [closedAlerts, openAlerts] = useMemo(
    () => _.partition(data?.alerts ?? [], (alert) => alert.status === AlertStatus.Closed),
    [data?.alerts],
  );

  const classes = useStyles();
  const { t } = useTranslation();
  const confirm = useConfirm();

  const [isRecentlyClosedExpanded, toggleRecentlyClosedOpen] = useState(false);

  const toggleIsExplainerExpanded = () => {
    toggleRecentlyClosedOpen((prev) => !prev);
  };

  const canResolve = Boolean(auth.me('permissions.set_alert_status') || auth.me('isFeebroid'));

  const onClose = () => {
    props.refreshData();
    props.onClose();
  };

  const confirmResolve = async () => {
    try {
      await confirm({
        title: 'Confirm alert resolution',
        description: (
          <>
            <MuiAlert severity="warning">
              <Typography gutterBottom variant="body2">
                This will resolve all open alert triggers of this alert{' '}
                <strong>({formatAlertName(props.alert)})</strong> against the selected patient{' '}
                <strong>
                  (
                  {
                    <SimilarNamesWarning patient={props.patient}>
                      {props.patient.firstName} {props.patient.lastName}
                    </SimilarNamesWarning>
                  }
                  )
                </strong>{' '}
                and close this alert for <strong>all users</strong>.
              </Typography>
              <Typography variant="body2">
                New alert triggers will <strong>reopen</strong> this alert.
              </Typography>
            </MuiAlert>
          </>
        ),
        confirmationText: `Resolve ${openAlerts?.length} Open Triggers`,
        confirmationButtonProps: {
          variant: 'contained',
          color: 'primary',
        },
      });
      await resolve();
    } catch {
      // The confirmation was (probably) cancelled
    }
  };

  const resolve = async () => {
    await api.batchSetAlertStatus(
      openAlerts.map((alert) => ({ id: alert.id, status: AlertStatus.Closed })),
    );
    onClose();
  };

  function switchByAlertType<T>(
    alertRuleCase: T,
    checkupScheduleEventCase: T,
    patientRequestCallbackCase: T,
    pacsanaEventCase: T,
  ): T {
    return {
      [AlertType.AlertRule]: alertRuleCase,
      [AlertType.CheckupScheduleEvent]: checkupScheduleEventCase,
      [AlertType.PatientCallbackRequest]: patientRequestCallbackCase,
      [AlertType.PacsanaEvent]: pacsanaEventCase,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    }[props.alert.type!];
  }

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth>
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h6" className={classes.titleType}>
          {switchByAlertType(
            props.alert.alertRule?.name,
            t('Missed Checkup'),
            'Patient callback request',
            t(`PacsanaWebhookEventIds.${props.alert.pacsanaEvent?.eventKey}.short`),
          )}{' '}
          Alerts for {props.patient.lastName}, {props.patient.firstName}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.sectionTitle} variant="subtitle2" gutterBottom>
                {t('Why was this alert triggered?')}
              </Typography>
              <Typography>
                {switchByAlertType(
                  props.alert.alertRule?.description,
                  t('The patient missed their scheduled checkup(s)'),
                  'Patient callback request issued by patient',
                  t(`PacsanaWebhookEventIds.${props.alert.pacsanaEvent?.eventKey}.long`),
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.sectionTitle} variant="subtitle2">
                Open alert triggers ({openAlerts?.length})
              </Typography>
              {openAlerts.length === 0 && !loading && (
                <Typography variant="body2">{t('All alerts resolved')}</Typography>
              )}
              {openAlerts.length > 0 &&
                switchByAlertType(
                  <AlertsFromAlertRuleTable
                    patient={props.patient}
                    alerts={openAlerts}
                    loading={loading}
                  />,
                  <AlertsFromCheckupScheduleTable
                    patient={props.patient}
                    alerts={openAlerts}
                    loading={loading}
                  />,
                  <AlertsFromPatientCallbackRequestTable
                    patient={props.patient}
                    alerts={openAlerts}
                    loading={loading}
                  />,
                  <AlertsFromPacsanaEventTable
                    patient={props.patient}
                    alerts={openAlerts}
                    loading={loading}
                  />,
                )}
            </Grid>
            <Grid item xs={12}>
              {closedAlerts.length > 0 && (
                <>
                  <Link
                    role="button"
                    color="primary.main"
                    underline="none"
                    sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 0.5 }}
                    aria-controls="recentlyClosed"
                    aria-expanded={isRecentlyClosedExpanded}
                    tabIndex={0}
                    onKeyDown={({ key }) => {
                      if (['Enter', ' '].includes(key)) {
                        toggleIsExplainerExpanded();
                      }
                    }}
                    onClick={() => {
                      toggleIsExplainerExpanded();
                    }}>
                    <Typography variant="subtitle2" className={classes.sectionTitle}>
                      Closed in the last 3 hours ({closedAlerts?.length})
                    </Typography>
                    {isRecentlyClosedExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Link>
                  <Collapse in={isRecentlyClosedExpanded} id="recentlyClosed">
                    {switchByAlertType(
                      <AlertsFromAlertRuleTable
                        patient={props.patient}
                        alerts={closedAlerts}
                        loading={loading}
                      />,
                      <AlertsFromCheckupScheduleTable
                        patient={props.patient}
                        alerts={closedAlerts}
                        loading={loading}
                      />,
                      <AlertsFromPatientCallbackRequestTable
                        patient={props.patient}
                        alerts={closedAlerts}
                        loading={loading}
                      />,
                      <AlertsFromPacsanaEventTable
                        patient={props.patient}
                        alerts={closedAlerts}
                        loading={loading}
                      />,
                    )}
                  </Collapse>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Close
        </Button>
        {canResolve && (
          <Button onClick={confirmResolve} variant="contained" color="primary">
            Resolve
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

interface AlertsTableProps {
  patient: Pick<Patient, 'id'>;
  alerts: GetAlertsQuery['alerts'];
  loading: boolean;
}

function AlertsFromAlertRuleTable(props: AlertsTableProps) {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Alerted At</TableCell>
            <TableCell>
              {props.alerts?.[0].alertRule?.type === AlertRuleTypes.NewCheckup
                ? 'During Checkup'
                : 'From Continuous Monitoring Data Received At'}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.loading ? (
            <TableRow>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
            </TableRow>
          ) : (
            props.alerts.map((alert) => (
              <TableRow key={alert.id}>
                <TableCell>
                  {alert.startedAt
                    ? t('DATETIME_SHORT', { val: new Date(alert.startedAt) })
                    : t('N/A')}
                </TableCell>
                <TableCell>
                  {alert.type === AlertType.PatientCallbackRequest && (
                    <Typography variant="body2">Patient callback request</Typography>
                  )}
                  {alert.alertRule?.type === AlertRuleTypes.ContinuousMonitoring && (
                    <Link
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      to={`/patient/${props.patient.id}`}
                      component={RouterLink}
                      rel="noopener noreferrer">
                      <Typography variant="body2">
                        {localeFormatting.formatCheckupTimeShortWithoutYearAndWeekDay(
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          alert.continuousMonitoring!.bucketEndAt,
                        )}
                      </Typography>
                    </Link>
                  )}
                  {alert.alertRule?.type === AlertRuleTypes.NewCheckup && (
                    <Link
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      to={`/patient/${props.patient.id}/checkup/${alert.checkup!.id}`}
                      component={RouterLink}
                      rel="noopener noreferrer">
                      <Typography variant="body2">
                        {localeFormatting.formatCheckupTimeShortWithoutYearAndWeekDay(
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          alert.checkup!.endedAt,
                        )}
                      </Typography>
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function AlertsFromCheckupScheduleTable(props: AlertsTableProps) {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Alerted At</TableCell>
            <TableCell>Check-up Was Expected Between</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.loading ? (
            <TableRow>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
            </TableRow>
          ) : (
            props.alerts.map((alert) => (
              <TableRow key={alert.id}>
                <TableCell>
                  {alert.startedAt
                    ? t('DATETIME_SHORT', { val: new Date(alert.startedAt) })
                    : t('N/A')}
                </TableCell>
                <TableCell>
                  {t('DATETIME_SHORT', {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    val: Moment(alert.checkupScheduleEvent!.checkupExpectedAt).subtract(
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      alert.checkupScheduleEvent!.checkupSchedule.tolerance.early,
                      'seconds',
                    ),
                  })}{' '}
                  and{' '}
                  {t('DATETIME_SHORT', {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    val: Moment(alert.checkupScheduleEvent!.checkupExpectedAt).add(
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      alert.checkupScheduleEvent!.checkupSchedule.tolerance.late,
                      'seconds',
                    ),
                  })}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function AlertsFromPatientCallbackRequestTable(props: AlertsTableProps) {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Alert Started</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.loading ? (
            <TableRow>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
            </TableRow>
          ) : (
            props.alerts.map((alert) => (
              <TableRow key={alert.id}>
                <TableCell>
                  {alert.startedAt
                    ? t('DATETIME_SHORT', { val: new Date(alert.startedAt) })
                    : t('N/A')}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function AlertsFromPacsanaEventTable(props: AlertsTableProps) {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Alerted At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.loading ? (
            <TableRow>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
            </TableRow>
          ) : (
            props.alerts.map((alert) => (
              <TableRow key={alert.id}>
                <TableCell>
                  {alert.startedAt
                    ? t('DATETIME_SHORT', { val: new Date(alert.startedAt) })
                    : t('N/A')}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    color: theme.palette.primary.main,
  },
  titleType: {
    textTransform: 'capitalize',
  },
}));

type UseAlertModalProps = Pick<AlertModalProps, 'refreshData' | 'patient' | 'alert'>;

export const useAlertModal = ({ alert, patient, refreshData }: UseAlertModalProps) => {
  const { showModal } = useModal();

  return {
    showAlertModal: () => {
      const modal: ShowFnOutput<AlertModalProps> = showModal(
        AlertModal,
        {
          alert,
          patient,
          refreshData,
          onClose: () => modal.hide(),
        },
        { destroyOnClose: true },
      );

      return modal;
    },
  };
};
