import auth from '@/controllers/Auth';

export default class Intercom {
  static hasBooted = false;
  static hasInitialized = false;

  static ensureInitialized() {
    if (this.hasInitialized) {
      return;
    }
    this.hasInitialized = true;

    // Lifted from the Intercom documentation
    // see: https://developers.intercom.com/installing-intercom/docs/basic-javascript#step-1-include-intercom-js-library
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + process.env.REACT_APP_INTERCOM_APP_ID;
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }

  /**
   * A proxy to the window.Intercom function that dynamically loads Intercom if the user is not
   * using loginAs
   */
  static Intercom() {
    if (auth.isLoginAs()) {
      return;
    }
    this.ensureInitialized();
    return window.Intercom.apply(null, arguments);
  }

  static boot(userInfo) {
    this.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      api_base: process.env.REACT_APP_INTERCOM_API_BASE,
      ...userInfo,
    });
  }

  static update(me) {
    const userInfo = {
      email: me.email,
      user_id: me.id,
      created_at: dateToUnixTimestamp(new Date(me.createdAt)),
      user_hash: me.intercomUserHash,
    };

    if (!this.hasBooted) {
      this.hasBooted = true;
      this.boot(userInfo);
    } else {
      this.Intercom('update', userInfo);
    }
  }

  static shutdown() {
    if (this.hasBooted) {
      this.Intercom('shutdown');
    }
    this.hasBooted = false; // Intercom seems to need to boot again after a shutdown..
  }
}

function dateToUnixTimestamp(date) {
  return Math.floor(new Date(date).getTime() / 1000);
}
