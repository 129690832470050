import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { Stack, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { NationalIdentifierType, NationalIdentifierDisplayFragment } from '@/generated/graphql';

import NhsNumberResponseDetailsDialog from './NhsNumberResponseDetailsDialog';
import { isDefined } from '@/helpers/isDefined';

export const NATIONAL_IDENTIFIER_DISPLAY_FRAGMENT = gql`
  fragment NationalIdentifierDisplay on NationalIdentifier {
    type
    value
  }
`;

interface NationalIdentifierDisplayProps {
  nationalIdentifier: Maybe<NationalIdentifierDisplayFragment>;
  patientId: string;
  /**
   * If there are any details to show, this icon will be shown next to the national identifier
   */
  showDetailsIcon?: 'always' | 'hover';
}

export default function NationalIdentifierDisplay({
  nationalIdentifier,
  patientId,
  showDetailsIcon = 'always',
}: NationalIdentifierDisplayProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);

  const showNhsNumberResponseDetailsDialog = (e: React.UIEvent) => {
    setShowDetailsDialog(true);
    // Prevent the click event from bubbling up to the parent element (e.g. expanding virtual ward patient rows)
    e.stopPropagation();
  };

  const closeNhsNumberResponseDetailsDialog = () => {
    setIsHovered(false);
    setShowDetailsDialog(false);
  };

  // We only support showing details for NHS numbers
  // this is the only type that has details in the database as of 03/10/2024
  const isNhsNumber = nationalIdentifier?.type === NationalIdentifierType.NhsNumber;
  const shouldShowNhsDetailsIcon =
    isNhsNumber && (showDetailsIcon === 'always' || (showDetailsIcon === 'hover' && isHovered));

  const formattedNationalIdentifier = formatNationalIdentifier(nationalIdentifier);

  return (
    <>
      <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <Stack direction="row" alignItems="center" whiteSpace="nowrap" spacing={0.5}>
          {isDefined(formattedNationalIdentifier) ? (
            <Typography variant="body2">{formattedNationalIdentifier}</Typography>
          ) : (
            <Typography variant="body2" color="InactiveCaptionText">
              &mdash;
            </Typography>
          )}
          {shouldShowNhsDetailsIcon && (
            <Tooltip title="Show details">
              <InfoIcon
                tabIndex={0}
                role="button"
                aria-label="Show NHS number details"
                fontSize="small"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    showNhsNumberResponseDetailsDialog(e);
                  }
                }}
                onClick={showNhsNumberResponseDetailsDialog}
                sx={{ cursor: 'pointer', color: 'grey.600' }}
              />
            </Tooltip>
          )}
        </Stack>
      </div>
      {isNhsNumber && (
        <NhsNumberResponseDetailsDialog
          open={showDetailsDialog}
          onClose={closeNhsNumberResponseDetailsDialog}
          formattedNhsNumber={formattedNationalIdentifier}
          patientId={patientId}
        />
      )}
    </>
  );
}

export const formatNationalIdentifier = (
  nationalIdentifier: Maybe<NationalIdentifierDisplayFragment>,
) => {
  if (!nationalIdentifier) {
    return null;
  }
  switch (nationalIdentifier.type) {
    case NationalIdentifierType.NhsNumber:
      return formatNhsNumber(nationalIdentifier.value);
    case NationalIdentifierType.NorthernIrelandHcn:
      // Actually, this is the same as an NHS number, but we don't have a specific format for it
      return formatNhsNumber(nationalIdentifier.value);
    default:
      return nationalIdentifier.value;
  }
};

export function nationalIdentifierTypeToLabel(
  nationalIdentifierType: Maybe<NationalIdentifierType>,
): string | null {
  if (!nationalIdentifierType) {
    return null;
  }
  switch (nationalIdentifierType) {
    case NationalIdentifierType.NhsNumber:
      return 'NHS Number';
    case NationalIdentifierType.NorthernIrelandHcn:
      return 'Northern Ireland HCN';
    default:
      return nationalIdentifierType;
  }
}

export const formatNhsNumber = (nhsNumber: string | null | undefined) => {
  if (nhsNumber && nhsNumber.length === 10) {
    const firstThree = nhsNumber.substring(0, 3);
    const middleThree = nhsNumber.substring(3, 6);
    const lastFour = nhsNumber.substring(6, 10);
    return `${firstThree} ${middleThree} ${lastFour}`;
  } else {
    return nhsNumber;
  }
};
