import React from 'react';
import clsx from 'clsx';
import { CheckupDetailsFragment } from '@/generated/graphql';

interface Props {
  checkup: CheckupDetailsFragment;
}

function BlockConsciousness({ checkup: { consciousness } }: Props) {
  return (
    <div className={clsx('block block--consciousness', 'e2e__consblock')}>
      <div className="block__title">Consciousness</div>
      <div className="block__inner">
        <div className="block__cols">
          <div>
            <p className="value">{consciousness ?? '-'}</p>
          </div>
        </div>

        {consciousness === 'A' && <p>Fully awake, responds to voice and has motor function.</p>}
        {consciousness === 'V' && (
          <p>Not alert, responds when you talk to them - speaks back, moves eyes or body.</p>
        )}
        {consciousness === 'U' && <p>No eye, voice or motor response.</p>}
        {consciousness === 'P' && <p>Not alert, only responds to a pain stimulus.</p>}
        {consciousness === 'C' && (
          <p>Unable to answer basic questions, appears more disoriented and confused than usual.</p>
        )}
      </div>
    </div>
  );
}

export default BlockConsciousness;
