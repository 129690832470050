import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

interface LoadingButtonProps extends React.ComponentProps<typeof Button> {
  loading: boolean;
}

export default function LoadingButton({ loading, ...rest }: LoadingButtonProps) {
  return (
    <Button
      variant="contained"
      color="primary"
      disabled={loading}
      type="submit"
      fullWidth
      {...rest}
      endIcon={
        !loading ? rest.endIcon : <CircularProgress sx={{ color: 'white' }} size={22} />
      }></Button>
  );
}
