import Moment from 'moment';

import i18next from './i18n';
import FeebrisFormatter from 'feebris-formatting';

export class DateTimeFormatter {
  formatCheckupTimeLongWithWeekDay(date) {
    return Moment(date).format('dddd, MMMM Do YYYY, h:mm:ss a');
  }

  formatCheckupTimeLongWithoutWeekDay(date) {
    return Moment(date).format('MMM Do YYYY, h:mm a');
  }

  formatCheckupTimeShort(date) {
    return Moment(date).format('ddd, MMM Do YYYY, h:mm a');
  }

  formatCheckupTimeShortWithoutYearAndWeekDay(date) {
    return Moment(date).format('MMM DD, h:mm a');
  }

  formatNhsNumberRetrievalTime(date) {
    return Moment(date).format('MMMM Do YYYY, h:mm:ss a');
  }

  static toLocaleDateTime = (dateString) =>
    new Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }).format(new Date(dateString));
}

const dateTimeFormatter = new DateTimeFormatter();
export default dateTimeFormatter;

export const feebrisFormatter = new FeebrisFormatter(() => i18next.language === 'en-US');
