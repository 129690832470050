import React, { useMemo } from 'react';

import { Box, Button } from '@mui/material';
import MaterialTableWithIcons from '@/components/MaterialTableWithIcons';
import { Action, Column, MTableAction } from '@material-table/core';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ScheduleIcon from '@mui/icons-material/Schedule';

import { AdminCheckupTypeItemFragmentInternal } from '@/generated/graphql-internal';

import { useAddCheckupTypeModal } from './AddCheckupTypeModal';
import { useUpdateCheckupTypeModal } from './UpdateCheckupTypeModal';
import { useDeleteCheckupTypeModal } from './useDeleteCheckupTypeModal';
import { useSetCheckupTypeScheduleModal } from './SetCheckupTypeScheduleModal';

interface CheckupTypesProps {
  carePathwayId: string;
  pathwayPublishedAt: Maybe<Date | string>;
  checkupTypes: AdminCheckupTypeItemFragmentInternal[] | undefined;
  loading: boolean;
  onCheckupTypeAdded: () => void;
  onCheckupTypeUpdated: () => void;
  onCheckupTypeDeleted: () => void;
}

export function CheckupTypes({
  loading,
  carePathwayId,
  pathwayPublishedAt,
  checkupTypes,
  onCheckupTypeAdded,
  onCheckupTypeUpdated,
  onCheckupTypeDeleted,
}: CheckupTypesProps) {
  const { showAddCheckupTypeModal } = useAddCheckupTypeModal({
    carePathwayId,
    onAdded: () => {
      onCheckupTypeAdded();
    },
  });

  const { showUpdateCheckupTypeModal } = useUpdateCheckupTypeModal({
    onUpdated: () => {
      onCheckupTypeUpdated();
    },
  });

  const { showDeleteCheckupTypeModal } = useDeleteCheckupTypeModal({
    onDeleted: () => {
      onCheckupTypeDeleted();
    },
  });

  const { showSetCheckupTypeScheduleModal } = useSetCheckupTypeScheduleModal({
    onUpdated: () => {
      onCheckupTypeUpdated();
    },
  });

  const actions: Action<AdminCheckupTypeItemFragmentInternal>[] = [
    {
      icon: 'ADD_HACK',
      tooltip: 'Add check-up type',
      isFreeAction: true,
      onClick: () => {
        showAddCheckupTypeModal();
      },
    },
    {
      icon: () => <EditIcon />,
      tooltip: 'Edit check-up type',
      onClick: (_event, rowData) => {
        showUpdateCheckupTypeModal({
          checkupType: rowData as AdminCheckupTypeItemFragmentInternal,
          pathwayPublishedAt,
        });
      },
    },
    {
      icon: () => <DeleteIcon />,
      tooltip: 'Delete check-up type',
      onClick: (_event, rowData) => {
        showDeleteCheckupTypeModal({
          checkupType: rowData as AdminCheckupTypeItemFragmentInternal,
        });
      },
    },
    {
      icon: () => <ScheduleIcon />,
      tooltip: 'Set check-up type schedule',
      onClick: (_event, rowData) => {
        showSetCheckupTypeScheduleModal({
          checkupType: rowData as AdminCheckupTypeItemFragmentInternal,
        });
      },
    },
  ];

  const columns = useMemo(
    () =>
      [
        {
          field: 'id',
          title: 'ID',
        },
        { title: 'Name', field: 'name' },
        { field: 'createdAt', title: 'Created', type: 'datetime' },
        { field: 'updatedAt', title: 'Updated', type: 'datetime' },
      ] as Column<AdminCheckupTypeItemFragmentInternal>[],
    [],
  );

  return (
    <Box>
      <MaterialTableWithIcons
        title="Check-up Types"
        isLoading={loading}
        data={checkupTypes ?? []}
        actions={actions}
        columns={columns}
        components={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Action: (props: any) => {
            if (props.action.icon === 'ADD_HACK') {
              return (
                <Box marginLeft={2}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={props.action.onClick}>
                    {props.action.tooltip}
                  </Button>
                </Box>
              );
            }
            return (
              <MTableAction
                {...props}
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.stopPropagation();
                  props.onClick();
                }}
              />
            );
          },
        }}
      />
    </Box>
  );
}
