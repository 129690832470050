import React from 'react';

import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useModal } from 'mui-modal-provider';
import { useFormik } from 'formik';
import { WithoutTypeNames, getMutationErrors } from '@/AuthorizedApolloClientProvider';
import { gql } from '@apollo/client';

import { muiFormikGetFieldProps } from '@/helpers/formik';

import { useAddCheckupTypeMutationInternal } from '@/generated/graphql-internal';
import { CheckupTiles } from '@/generated/graphql';
import { CheckupTilesConfig } from './CheckupTilesConfig';

export const ADD_CHECKUP_TYPE_MUTATION = gql`
  mutation AddCheckupType($pathwayId: ID!, $checkupType: CheckupTypeInput!) {
    addCheckupType(pathwayId: $pathwayId, checkupType: $checkupType) {
      id
      name
      createdAt
    }
  }
`;

interface AddCheckupTypeModalProps extends DialogProps {
  carePathwayId: string;
  onCancel: () => void;
  onAdded: () => void;
}

const formSchema = Yup.object().shape({
  name: Yup.string().required('Care Pathway name is required'),
  heading: Yup.string().required('Heading is required'),
  subheading: Yup.string().required('Subheading is required'),
  description: Yup.string().required('Description is required'),
  checkupTiles: Yup.object<CheckupTiles>().required('Checkup Tiles is required'),
});

type FormValues = Yup.InferType<typeof formSchema>;

/**
 * NB: Since the UI for tile config is semi-dynamic, we need to have a default value for each tile.
 * By using Required<WithoutTypeNames<CheckupTiles>>, we can ensure that we have a default value for each tile,
 * even if the GraphQL schema changes.
 */
const DEFAULT_CHECKUP_TILES: Required<WithoutTypeNames<CheckupTiles>> = {
  symptomsQuestionnaire: null,
  consciousness: null,
  bloodPressureCuff: null,
  pulseOximeter: null,
  respiratoryRate: null,
  stethoscope: null,
  temperature: null,
  weight: null,
  glucose: null,
  picture: null,
};

export function AddCheckupTypeModal({
  open,
  onCancel,
  onAdded,
  carePathwayId,
}: AddCheckupTypeModalProps) {
  const classes = useStyles();

  const [addCheckupTypeMutation, { loading: isSubmitting, error: createCheckupTypeError }] =
    useAddCheckupTypeMutationInternal({
      onCompleted: () => {
        onAdded();
      },
      onError: () => undefined,
    });

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      heading: '',
      subheading: '',
      description: '',
      checkupTiles: DEFAULT_CHECKUP_TILES,
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      addCheckupTypeMutation({
        variables: {
          pathwayId: carePathwayId,
          checkupType: {
            name: values.name,
            heading: values.heading,
            subheading: values.subheading,
            description: values.description,
            checkupTiles: values.checkupTiles,
          },
        },
      });
    },
  });

  const { argErrors } = getMutationErrors(createCheckupTypeError);
  const getFieldProps = muiFormikGetFieldProps(formik, argErrors);

  return (
    <Dialog open={open} fullWidth maxWidth="md" aria-labelledby="add-checkuptype-dialog-title">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="add-checkuptype-dialog-title">Add Check-up Type</DialogTitle>
        <DialogContent>
          <TextField
            id="name"
            label="Check-up Type Name"
            disabled={isSubmitting}
            placeholder='e.g. "COPD-Morning"'
            variant="standard"
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoFocus
            className={classes.textField}
            {...getFieldProps('name')}
          />
          <TextField
            id="heading"
            label="Check-up button heading"
            disabled={isSubmitting}
            placeholder='e.g. "COPD Morning"'
            variant="standard"
            InputLabelProps={{ shrink: true }}
            fullWidth
            className={classes.textField}
            {...getFieldProps('heading')}
          />
          <TextField
            id="subheading"
            label="Check-up button subheading"
            disabled={isSubmitting}
            placeholder='e.g. "Morning"'
            variant="standard"
            InputLabelProps={{ shrink: true }}
            fullWidth
            className={classes.textField}
            {...getFieldProps('subheading')}
          />
          <TextField
            id="description"
            label="Check-up button description"
            disabled={isSubmitting}
            placeholder='e.g. "Morning check-up for COPD patients"'
            variant="standard"
            InputLabelProps={{ shrink: true }}
            fullWidth
            className={classes.textField}
            {...getFieldProps('description')}
          />
          <CheckupTilesConfig
            checkupTiles={formik.values['checkupTiles']}
            onUpdate={(newTileConfig) =>
              formik.handleChange({ target: { name: 'checkupTiles', value: newTileConfig } })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

interface UseAddCheckupTypeModalProps {
  carePathwayId: string;
  onCancel?: () => void;
  onAdded: () => void;
}

export function useAddCheckupTypeModal({
  carePathwayId,
  onCancel,
  onAdded,
}: UseAddCheckupTypeModalProps) {
  const { showModal } = useModal();

  return {
    showAddCheckupTypeModal: () => {
      const modal = showModal(
        AddCheckupTypeModal,
        {
          carePathwayId,
          onCancel: () => {
            onCancel?.();
            modal.hide();
          },
          onAdded: () => {
            onAdded();
            modal.hide();
          },
        },
        { destroyOnClose: true },
      );
    },
  };
}

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
}));
