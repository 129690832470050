import React, { useMemo, useState } from 'react';

import clsx from 'clsx';
import { formatRelative, differenceInMinutes } from 'date-fns';
import { Box, Popover, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import Battery20Icon from '@mui/icons-material/Battery20';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery80Icon from '@mui/icons-material/Battery80';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import { Alert, AlertTitle } from '@mui/material';

import { VirtualWardPatientContinuousMonitoringWithSessionFragment } from '@/generated/graphql';

import { isDefined } from '@/helpers/isDefined';

const CRITICAL_BATTERY_THRESHOLD = 10;
const LOW_BATTERY_THRESHOLD = 30;
const MODERATE_BATTERY_THRESHOLD = 50;
const HIGH_BATTERY_THRESHOLD = 80;

const LOW_GAP_THRESHOLD = 31;
const CRITICAL_GAP_THRESHOLD = 91;

const LEVEL = { HEALTHY: 0, LOW: 1, CRITICAL: 2 };

type ContinuousMonitoringSessionState = {
  lastUpdated: string;
  gapLevel: number;
  batteryLevel: number;
};

const getContinuousMonitoringOverviewState = (
  continuousMonitoringWithSession: VirtualWardPatientContinuousMonitoringWithSessionFragment,
): ContinuousMonitoringSessionState => {
  const { continuousMonitoringSession, continuousMonitoring } = continuousMonitoringWithSession;

  let gap;
  const lastUpdated = isDefined(continuousMonitoring)
    ? continuousMonitoring.bucketEndAt
    : continuousMonitoringSession.createdAt;
  const lastFrom = differenceInMinutes(new Date(), new Date(lastUpdated));

  if (lastFrom < LOW_GAP_THRESHOLD) {
    gap = LEVEL.HEALTHY;
  } else {
    gap = lastFrom > CRITICAL_GAP_THRESHOLD ? LEVEL.CRITICAL : LEVEL.LOW;
  }

  let battery = LEVEL.HEALTHY;
  if (isDefined(continuousMonitoring)) {
    const batteryLevels = continuousMonitoring.battery
      ? [
          continuousMonitoring.battery.ecg,
          continuousMonitoring.battery.spo2,
          continuousMonitoring.battery.temperature,
        ]
      : [];

    if (batteryLevels.some((b) => isDefined(b) && b < LOW_BATTERY_THRESHOLD)) {
      battery = batteryLevels.some((b) => isDefined(b) && b < CRITICAL_BATTERY_THRESHOLD)
        ? LEVEL.CRITICAL
        : LEVEL.LOW;
    } else {
      battery = LEVEL.HEALTHY;
    }
  }

  return {
    lastUpdated: lastUpdated,
    gapLevel: gap,
    batteryLevel: battery,
  };
};

export function ContinuousMonitoringSessionStatus({
  continuousMonitoringWithSession: continuousMonitoringWithSession,
}: {
  continuousMonitoringWithSession: VirtualWardPatientContinuousMonitoringWithSessionFragment;
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const state = useMemo(
    () => getContinuousMonitoringOverviewState(continuousMonitoringWithSession),
    [continuousMonitoringWithSession],
  );

  const { continuousMonitoringSession, continuousMonitoring } = continuousMonitoringWithSession;

  const stateClass = useMemo(() => {
    switch (Math.max(state.gapLevel, state.batteryLevel)) {
      case LEVEL.CRITICAL:
        return classes.dangerContinuousState;
      case LEVEL.LOW:
        return classes.warningContinuousState;
      case LEVEL.HEALTHY:
        return classes.positiveContinuousState;
    }
  }, [
    state,
    classes.dangerContinuousState,
    classes.positiveContinuousState,
    classes.warningContinuousState,
  ]);

  const stateIcon = useMemo(() => {
    const isBatteryLevel = chooseBatteryLevel(state.gapLevel, state.batteryLevel);
    if (isBatteryLevel) {
      if (state.batteryLevel === LEVEL.HEALTHY) {
        return <WifiTetheringIcon className={classes.statusIcon} />;
      } else {
        return state.batteryLevel === LEVEL.CRITICAL ? (
          <BatteryAlertIcon className={classes.statusIcon} />
        ) : (
          <Battery20Icon className={classes.statusIcon} />
        );
      }
    } else {
      return state.gapLevel === LEVEL.HEALTHY ? (
        <WifiTetheringIcon className={classes.statusIcon} />
      ) : (
        <WifiOffIcon className={classes.statusIcon} />
      );
    }
  }, [state, classes.statusIcon]);

  const stateBatteryWarning = useMemo(() => {
    return (
      <Box className={classes.popoverContainer}>
        <Alert
          severity={state.batteryLevel === LEVEL.CRITICAL ? 'error' : 'warning'}
          className={classes.popoverWarning}>
          <AlertTitle>
            {state.batteryLevel === LEVEL.CRITICAL ? 'Critical battery level' : 'Low battery level'}
          </AlertTitle>
          <Typography>One or more of the devices has a low battery.</Typography>
          <Typography>
            Please advise the patient to charge the devices at their earliest convenience.
          </Typography>
        </Alert>
      </Box>
    );
  }, [state, classes.popoverWarning, classes.popoverContainer]);

  const stateGapWarning = useMemo(() => {
    return (
      <Box className={classes.popoverContainer}>
        <Alert
          severity={state.gapLevel === LEVEL.CRITICAL ? 'error' : 'warning'}
          className={classes.popoverWarning}>
          <AlertTitle>
            {state.gapLevel === LEVEL.CRITICAL ? 'Major gap in data' : 'Minor gap in data'}
          </AlertTitle>
          <Typography>
            Please advise the patient to check that the devices are connected to the app and their
            internet connection is working.
          </Typography>
        </Alert>
      </Box>
    );
  }, [state, classes.popoverWarning, classes.popoverContainer]);

  return (
    <>
      <Box
        data-testid="continuous-monitoring-popover"
        className={clsx(classes.statusContainer, stateClass)}
        flexShrink={0}
        onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}>
        {stateIcon}
      </Box>
      <Popover
        className={classes.continuousStatePopover}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus>
        <Box margin={2}>
          <Typography className={classes.popoverTitle} gutterBottom>
            Data last captured{' '}
            <Typography component="span" variant="body2">
              ({formatRelative(new Date(state.lastUpdated), new Date())})
            </Typography>
          </Typography>
          <Box marginTop={2}>
            {state.gapLevel !== LEVEL.HEALTHY && stateGapWarning}
            {continuousMonitoring && (
              <Box marginTop={2}>
                {state.batteryLevel !== LEVEL.HEALTHY && stateBatteryWarning}
                <Box>
                  <DeviceBatteryStatus
                    batteryLevel={continuousMonitoring?.battery?.ecg}
                    device="ECG Device"
                  />
                  <DeviceBatteryStatus
                    batteryLevel={continuousMonitoring?.battery?.spo2}
                    device="Pulse Oximeter"
                  />
                  <DeviceBatteryStatus
                    batteryLevel={continuousMonitoring?.battery?.temperature}
                    device="Thermometer"
                  />
                </Box>
              </Box>
            )}
            <Typography className={classes.popoverTitle}>
              Kit ID:{' '}
              <Typography component="span" variant="body1">
                {continuousMonitoringSession.vivalinkSubjectId}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

const chooseBatteryLevel = (gapLevel: number, batteryLevel: number): boolean =>
  batteryLevel > gapLevel;

const formatBatteryLevel = (batteryLevel: number) =>
  Intl.NumberFormat(undefined, {
    style: 'percent',
    maximumFractionDigits: 0,
  }).format(batteryLevel / 100);

interface DeviceBatteryStatusProps {
  batteryLevel: number | null | undefined;
  device: string;
}

const DeviceBatteryStatus = ({ batteryLevel, device }: DeviceBatteryStatusProps) => {
  const classes = useDeviceBatteryStyles();

  if (!isDefined(batteryLevel)) {
    return null;
  }

  const batteryIcon = () => {
    if (batteryLevel < CRITICAL_BATTERY_THRESHOLD) {
      return (
        <BatteryAlertIcon
          className={clsx(classes.popoverBatteryIcon, classes.criticalBatteryIcon)}
        />
      );
    }

    if (batteryLevel < LOW_BATTERY_THRESHOLD) {
      return (
        <Battery20Icon className={clsx(classes.popoverBatteryIcon, classes.warningBatteryIcon)} />
      );
    }

    if (batteryLevel < MODERATE_BATTERY_THRESHOLD) {
      return (
        <Battery50Icon className={clsx(classes.popoverBatteryIcon, classes.warningBatteryIcon)} />
      );
    }

    if (batteryLevel < HIGH_BATTERY_THRESHOLD) {
      return (
        <Battery80Icon className={clsx(classes.popoverBatteryIcon, classes.healthyBatteryIcon)} />
      );
    }

    return (
      <BatteryFullIcon className={clsx(classes.popoverBatteryIcon, classes.healthyBatteryIcon)} />
    );
  };

  return (
    <Typography gutterBottom className={classes.popoverDeviceBattery}>
      <span className={classes.label}>{device}:</span> {formatBatteryLevel(batteryLevel)}
      {batteryIcon()}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  statusIcon: {
    // Optically center the icon (true center is slightly off)
    marginBottom: theme.spacing(0.25),
    fontSize: 'small',
  },
  statusContainer: {
    display: 'flex',
    marginRight: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    borderRadius: 21,
    border: '1px solid transparent',
    color: theme.palette.grey[600],
    fontWeight: 500,
    lineHeight: 1,
    fontSize: theme.typography.pxToRem(18),
  },
  checkupDate: {
    fontSize: theme.typography.pxToRem(14),
  },
  positiveContinuousState: {
    color: '#325e50',
    backgroundColor: '#3fca82',
    borderColor: '#325e50',
  },
  warningContinuousState: {
    backgroundColor: '#F9E1A7',
    color: '#433b14',
    borderColor: '#D39A61',
  },
  dangerContinuousState: {
    backgroundColor: '#efbebe',
    color: '#5b2d2d',
    borderColor: '#DB5757',
  },
  continuousStatePopover: {
    pointerEvents: 'none',
    marginTop: theme.spacing(1.5),
  },
  popoverTitle: {
    fontWeight: 500,
  },
  label: {
    marginTop: theme.spacing(2),
    fontWeight: 500,
  },
  popoverWarning: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  popoverContainer: {
    maxWidth: 650,
  },
}));

const useDeviceBatteryStyles = makeStyles((theme) => ({
  popoverDeviceBattery: {
    display: 'flex',
    alignItems: 'center',
  },
  popoverBatteryIcon: {
    lineHeight: 1,
  },
  criticalBatteryIcon: {
    color: '#DB5757',
  },
  warningBatteryIcon: {
    color: '#D39A61',
  },
  healthyBatteryIcon: {
    color: theme.palette.success.main,
  },
  label: {
    fontWeight: 500,
    marginRight: theme.spacing(0.5),
  },
}));
