import React from 'react';

import { gql } from '@apollo/client';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { Alert } from '@mui/material';
import { toast } from 'sonner';

import { useEndPacsanaSessionMutation } from '@/generated/graphql';

export const END_PACSANA_SESSION_QUERY = gql`
  mutation EndPacsanaSession($patientId: ID!) {
    endPacsanaSession(patientId: $patientId) {
      id
    }
  }
`;

interface EndActivityMonitoringSessionModalProps extends DialogProps {
  patientId: string;
  onAdded: () => void;
  onCancel: () => void;
}

export function EndActivityMonitoringSessionModal({
  open,
  patientId,
  onAdded,
  onCancel,
}: EndActivityMonitoringSessionModalProps) {
  const classes = useStyles();
  const [endActivityMonitoringSession, { loading: isSubmitting }] = useEndPacsanaSessionMutation({
    onCompleted: () => {
      onAdded();
    },
    onError: () => {
      toast.error('An error occurred when ending the activity monitoring session');
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    endActivityMonitoringSession({
      variables: {
        patientId,
      },
    });
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      aria-labelledby="end-activity-monitoring-session-dialog__title">
      <DialogTitle>End activity monitoring</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Alert severity="info" className={classes.info}>
            <Typography variant="body2">
              Once the session is ended, the patient will no longer receive alerts, active/exercise
              minutes, or gait speed.
            </Typography>
          </Alert>
          <Alert severity="warning">
            <Typography variant="body2">
              <strong>CAUTION</strong>: if you start a new session after ending this one, you will
              need to wait another 8 days to receive a gait speed for the patient.
            </Typography>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
            End activity monitoring
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  info: {
    marginBottom: theme.spacing(2),
  },
}));

interface UseActivityMonitoringSessionModalProps {
  onAdded?: () => void;
}

export const useEndActivityMonitoringSessionModal = ({
  onAdded,
}: UseActivityMonitoringSessionModalProps = {}) => {
  const { showModal } = useModal();

  return {
    showEndActivityMonitoringSessionModal: (patientId: string) => {
      const modal: ShowFnOutput<EndActivityMonitoringSessionModalProps> = showModal(
        EndActivityMonitoringSessionModal,
        {
          patientId,
          onAdded: () => {
            onAdded?.();
            modal.hide();
          },
          onCancel: () => modal.hide(),
        },
        { destroyOnClose: true },
      );

      return modal;
    },
  };
};
