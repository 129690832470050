import React from 'react';

import { gql } from '@apollo/client';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import { toast } from 'sonner';

import { useRemoveStaffFromWardMutation, WardStaffItemFragment } from '@/generated/graphql';

export const REMOVE_STAFF_FROM_WARD = gql`
  mutation RemoveStaffFromWard($wardId: ID!, $userId: ID!) {
    removeStaffFromWard(wardId: $wardId, userId: $userId)
  }
`;

interface RemoveStaffModalProps extends DialogProps {
  wardId: string;
  wardStaff: WardStaffItemFragment;
  onRemoved: () => void;
  onCancel: () => void;
}

export function RemoveStaffModal({
  open,
  wardStaff: { user },
  wardId,
  onRemoved,
  onCancel,
}: RemoveStaffModalProps) {
  const [remove, { loading: isRemoving }] = useRemoveStaffFromWardMutation({
    variables: { wardId, userId: user.id },
    onCompleted: () => onRemoved(),
    onError: () => toast.error('An error occurred when removing the staff member from the ward'),
  });

  return (
    <Dialog open={open}>
      <DialogTitle>Remove {user.firstName} from the ward</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to remove {user.firstName} {user.lastName} from the ward?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={isRemoving}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" disabled={isRemoving} onClick={() => remove()}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface UseRemoveStaffModalProps {
  wardId: string;
  onRemoved: () => void;
}

export const useRemoveStaffModal = ({ onRemoved, wardId }: UseRemoveStaffModalProps) => {
  const { showModal } = useModal();

  return {
    showRemoveStaffModal: (wardStaff: WardStaffItemFragment) => {
      const modal: ShowFnOutput<RemoveStaffModalProps> = showModal(
        RemoveStaffModal,
        {
          wardId,
          wardStaff,
          onRemoved: () => {
            onRemoved();
            modal.hide();
          },
          onCancel: () => modal.hide(),
        },
        { destroyOnClose: true },
      );

      return modal;
    },
  };
};
