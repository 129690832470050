import { useJourneyModal } from '@/components/JourneyModal';
import { useAuthMe, useMeActingOrganizationFeature } from '@/hooks/useAuth';

import { SuccessStep } from './steps/SuccessStep';
import { AddUserJourneyContext, AddUserJourneyStep } from './types';
import { AddUserStep } from './steps/AddUserStep';
import { AssignWardsStep } from './steps/AssignWardsStep';
interface UseAddUserJourneyProps {
  onClose?: () => void;
}

export function useAddUserJourney({ onClose }: UseAddUserJourneyProps) {
  const hasWardFeature = useMeActingOrganizationFeature('wards');
  const actingOrganizationName = useAuthMe('actingOrganization.name', 'your organization');

  return useJourneyModal<AddUserJourneyContext, AddUserJourneyStep>({
    title: `Add user to ${actingOrganizationName}`,
    cancelConfirmationProps: {
      title: 'Cancel adding user?',
      description: `Are you sure you want to cancel this process, the user has been added to the system but not assigned to any wards.
      If you do not want to add the user, you can remove them from the system in the user management section.`,
    },
    onClose,
    steps: {
      addUser: {
        component: AddUserStep,
        stepLabel: 'Add user',
        stepHeading: 'Add user',
        nextButtonLabel: 'Next',
        controlsForm: 'userCreateForm',
        closeWithoutWarning: true,
        subLabel: ({ user }) => (user ? 'Added' : undefined),
      },
      assignWards: {
        component: AssignWardsStep,
        skip: !hasWardFeature,
        hidden: !hasWardFeature,
        stepLabel: 'Assign wards',
        nextButtonLabel: 'Assign selected wards',
      },
      success: {
        component: SuccessStep,
        closeWithoutWarning: true,
        stepLabel: 'Success',
        hidden: 'always',
        nextButtonLabel: 'Close',
        nextButtonProps: {
          variant: 'text',
        },
      },
    },
    defaultJourneyState: {
      userHasName: null,
      user: null,
      userDetailsUpdated: false,
    },
  });
}
