import React, { useMemo } from 'react';

import { Button } from '@mui/material';
import { gql } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ManageCarePathwayItemFragment, useManageCarePathwaysQuery } from '@/generated/graphql';

import MaterialTableWithIcons from '@/components/MaterialTableWithIcons';
import { Column } from '@material-table/core';
import { ErrorDisplay } from '@/components/ErrorDisplay';
import { PageTitle } from '@/components/PageTitle';
import { PageContainer } from '@/components/PageContainer';

export const QUERY_PATHWAYS = gql`
  fragment ManageCarePathwayItem on CarePathway {
    id
    name
    createdAt
    updatedAt
    organization {
      id
    }
  }

  query ManageCarePathways {
    carePathways {
      ...ManageCarePathwayItem
    }
  }
`;

export function ManagePathways() {
  const { data, loading, error } = useManageCarePathwaysQuery();

  const columns = useMemo(
    () =>
      [
        {
          field: 'id',
          hidden: true,
        },
        {
          title: 'Name',
          field: 'name',
        },
        { field: 'createdAt', title: 'Created', type: 'datetime' },
        { field: 'updatedAt', title: 'Updated', type: 'datetime' },
        {
          field: 'id',
          width: 'auto',
          align: 'right',
          render: (data) => (
            <Button
              component={RouterLink}
              style={{ lineHeight: 1 }}
              to={`/pathways/${data.id}`}
              size="small"
              color="primary"
              endIcon={<ChevronRightIcon />}>
              View Pathway
            </Button>
          ),
        },
      ] as Column<ManageCarePathwayItemFragment>[],
    [],
  );

  return (
    <PageContainer>
      <PageTitle title="Care Pathways" subtitle="View and manage your care pathways" />
      <MaterialTableWithIcons
        title={null}
        columns={columns}
        data={data?.carePathways ?? []}
        isLoading={loading}
        localization={{
          body: {
            emptyDataSourceMessage: error ? (
              <ErrorDisplay showIcon message="Failed to load pathways" />
            ) : (
              'No care pathways found'
            ),
          },
        }}
      />
    </PageContainer>
  );
}
