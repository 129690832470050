import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import LoadingButton from '@/components/LoadingButton';

import '@/styles/login.scss';

import auth, { AdditionalLoginSteps, MfaRequiredSignInResult } from '@/controllers/Auth';

import {
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Link as MaterialUiLink,
  Box,
  Paper,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import PhoneIcon from '@mui/icons-material/PhoneIphone';

import { Alert } from '@mui/material';

import logo from '@/images/logo.png';
import { CookieBanner } from '@/components/CookieBanner';
import Loading from '@/components/Loading';

type LoginStep = 'PageLoad' | 'TokenLogin' | 'UsernamePassword' | 'OneTimePassword';

const Login = () => {
  const classes = useLoginStyles();

  const [currentLoginStep, setCurrentLoginStep] = useState<LoginStep>('PageLoad');
  const [oneTimePasswordSignInResult, setOneTimePasswordSignInResult] =
    useState<MfaRequiredSignInResult>();

  useEffect(() => {
    const autoSignIn = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('linkedIdentityToken')) {
        setCurrentLoginStep('TokenLogin');

        try {
          await auth.signInWithToken(urlParams.get('linkedIdentityToken') as string);
        } catch {
          // we swallow the error so that legitimate users can still login
          setCurrentLoginStep('UsernamePassword');
        }
      } else {
        setCurrentLoginStep('UsernamePassword');
      }
    };

    autoSignIn();
  }, []);

  return (
    <div className="login">
      <img src={logo} alt="Feebris Logo" />
      <Paper className={classes.loginPaper} elevation={3}>
        {currentLoginStep === 'PageLoad' && <Loading showLoading={true} />}
        {currentLoginStep === 'TokenLogin' && (
          <>
            <Box mb={3}>
              <Typography component="h1" variant="h5">
                Logging in to Feebris
              </Typography>
            </Box>
            <Loading showLoading={true} />
          </>
        )}
        {currentLoginStep === 'UsernamePassword' && (
          <UsernamePasswordForm
            handleOneTimePasswordResult={(signInResult) => {
              setCurrentLoginStep('OneTimePassword');
              setOneTimePasswordSignInResult(signInResult);
            }}
          />
        )}
        {currentLoginStep === 'OneTimePassword' && oneTimePasswordSignInResult && (
          <OneTimePasswordForm
            signInResult={oneTimePasswordSignInResult}
            handleTimeout={() => {
              setCurrentLoginStep('UsernamePassword');
              alert(
                'Verification timed out. Please login with your username and password again to retry.',
              );
            }}
          />
        )}
      </Paper>
      <CookieBanner />
    </div>
  );
};

const useLoginStyles = makeStyles((theme) => ({
  loginPaper: {
    width: '100%',
    maxWidth: 520,
    padding: theme.spacing(4),
    marginTop: theme.spacing(3),
  },
}));

const UsernamePasswordForm = ({
  handleOneTimePasswordResult,
}: {
  handleOneTimePasswordResult: (result: MfaRequiredSignInResult) => void;
}) => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();

  const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const signInResult = await auth.signin(username.trim(), password);

      switch (signInResult.additionalLoginSteps) {
        case AdditionalLoginSteps.OneTimePassword: {
          handleOneTimePasswordResult(signInResult);
          break;
        }
        default:
        case AdditionalLoginSteps.None: {
          await signInResult.finaliseSignIn();
          break;
        }
      }
    } catch (e) {
      alert(`Please check the details you've entered`);
      setLoading(false);
    }
  };

  return (
    <>
      <Typography component="h1" variant="h5" className={classes.heading}>
        Log in to Feebris
      </Typography>

      <form>
        <fieldset>
          <TextField
            className={classes.loginInput}
            fullWidth
            type="text"
            aria-label="Email"
            placeholder="Email"
            name="username"
            variant="outlined"
            value={username}
            InputProps={{
              startAdornment: <EmailIcon />,
            }}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            className={classes.loginInput}
            fullWidth
            aria-label="Password"
            placeholder="Password"
            name="password"
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            InputProps={{
              startAdornment: <LockIcon />,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    size="large">
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </fieldset>
        <LoadingButton
          onClick={async (e) => await handleSubmit(e)}
          loading={loading}
          className="e2e__loginbutton"
          endIcon={<ArrowForwardIcon />}
          size="large">
          Log in
        </LoadingButton>

        <MaterialUiLink
          component={Link}
          to="/forgot-password"
          className={classes.forgotPasswordLink}>
          Forgot your password?
        </MaterialUiLink>
      </form>
    </>
  );
};

const OneTimePasswordForm = ({
  signInResult,
  handleTimeout,
}: {
  signInResult: MfaRequiredSignInResult;
  handleTimeout: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [oneTimePassword, setOneTimePassword] = useState('');
  const [showCannotAccessApp, setShowCannotAccessApp] = useState(false);
  const classes = useStyles();

  const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await signInResult.finaliseSignIn(oneTimePassword);
      if (result === 'Timeout') {
        handleTimeout();
      }
    } catch {
      alert(`Please check the details you've entered`);
      setLoading(false);
    }
  };

  return (
    <>
      <Typography component="h1" variant="h5" className={classes.heading}>
        Authenticate your account
      </Typography>

      <Typography variant="body1" className={classes.subHeading}>
        Protecting your data is our top priority
      </Typography>

      <Typography variant="body1" className={classes.text}>
        Open the two-factor authentication app on your device and generate a unique code to verify
        your identity.
      </Typography>
      <form>
        <fieldset>
          <TextField
            className={classes.loginInput}
            fullWidth
            aria-label="6-digit code"
            placeholder="6-digit code"
            type="text"
            autoFocus
            name="totp"
            autoComplete="one-time-password"
            variant="outlined"
            value={oneTimePassword}
            InputProps={{
              startAdornment: <PhoneIcon />,
            }}
            onChange={(e) => setOneTimePassword(e.target.value)}
          />
        </fieldset>
        <LoadingButton
          onClick={async (e) => await handleSubmit(e)}
          loading={loading}
          className="e2e__verifybutton"
          endIcon={<CheckIcon />}
          size="large">
          Verify
        </LoadingButton>
      </form>
      <Box display="flex" flexDirection="column" marginTop={2}>
        <MaterialUiLink
          className={classes.lostAccessLink}
          role="button"
          tabIndex={0}
          onKeyDown={({ key }) => key === 'Enter' && setShowCannotAccessApp((current) => !current)}
          onClick={() => {
            setShowCannotAccessApp((current) => !current);
          }}>
          {"Can't access your authenticator app?"}
        </MaterialUiLink>
        {showCannotAccessApp && (
          <Alert className={classes.lostAccessAlert} severity="info">
            {
              "If you can't access your authenticator app, please contact an admin user within your organisation"
            }
          </Alert>
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.dark,
  },
  subHeading: {
    marginBottom: theme.spacing(2),
    fontWeight: 500,
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  loginInput: {
    marginBottom: theme.spacing(1.5),
    '& .MuiInputBase-adornedStart > .MuiSvgIcon-root': {
      color: theme.palette.grey[500],
    },
    '& .MuiInputBase-adornedStart.Mui-focused  > .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  forgotPasswordLink: {
    display: 'block',
    fontSize: '1rem',
    marginTop: theme.spacing(2),
  },
  lostAccessLink: {
    cursor: 'pointer',
    userSelect: 'none',
  },
  lostAccessAlert: {
    marginTop: theme.spacing(1),
  },
}));

export default Login;
