import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

import { EhrIntegrationStatus, EhrIntegrationResponse } from '@/generated/graphql';
import { Popover, Box, Typography, Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NEWS_SCORE_PALETTE } from '@/styles/NEWSColors';
import { Alert } from '@mui/material';

interface StatusChipProps {
  status: EhrIntegrationStatus;
  response: Maybe<EhrIntegrationResponse>;
}

const statusText: Record<EhrIntegrationStatus, string> = {
  [EhrIntegrationStatus.Pending]: 'Pending',
  [EhrIntegrationStatus.Success]: 'Success',
  [EhrIntegrationStatus.Error]: 'Error',
  [EhrIntegrationStatus.Submitted]: 'Submitted',
  [EhrIntegrationStatus.ActionRequired]: 'Action Required',
  [EhrIntegrationStatus.Skipped]: 'Skipped',
  [EhrIntegrationStatus.Rejected]: 'Rejected',
};

const statusDescription: Record<EhrIntegrationStatus, string> = {
  [EhrIntegrationStatus.Pending]: 'Waiting for integration event to be processed',
  [EhrIntegrationStatus.Success]: 'Integration event was successful',
  [EhrIntegrationStatus.Error]: 'Integration event failed',
  [EhrIntegrationStatus.Submitted]: 'Integration event has been submitted',
  [EhrIntegrationStatus.ActionRequired]: 'Action required from user',
  [EhrIntegrationStatus.Skipped]: 'Integration event was skipped',
  [EhrIntegrationStatus.Rejected]: 'Integration event was rejected by a user',
};

export function StatusChip({ status, response }: StatusChipProps) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.FocusEvent<HTMLElement, Element> | React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // map the status to an alert color
  const color = useMemo(() => {
    switch (status) {
      case EhrIntegrationStatus.Pending:
      case EhrIntegrationStatus.Submitted:
        return 'default';
      case EhrIntegrationStatus.Success:
        return 'success';
      case EhrIntegrationStatus.ActionRequired:
      case EhrIntegrationStatus.Rejected:
        return 'warning';
      case EhrIntegrationStatus.Error:
        return 'error';
    }
  }, [status]);

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        disableAutoFocus
        disableRestoreFocus
        disableEnforceFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ pointerEvents: 'none', marginTop: '8px' }}
        onClose={handlePopoverClose}>
        <Box padding={2}>
          <Typography variant="body2" fontWeight={500}>
            {statusDescription[status]}
          </Typography>
          {[
            EhrIntegrationStatus.Error,
            EhrIntegrationStatus.ActionRequired,
            EhrIntegrationStatus.Rejected,
          ].includes(status) && response ? (
            <Box marginTop={1}>
              <Typography variant="body2">
                <strong>Response:</strong> {response.message}
              </Typography>
            </Box>
          ) : null}
          {status === EhrIntegrationStatus.Error && response ? (
            <Box marginTop={1}>
              <Alert severity="error">
                Contact Feebris support for more information about this error.
              </Alert>
            </Box>
          ) : null}
        </Box>
      </Popover>
      <Chip
        label={statusText[status]}
        color={color}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onFocus={handlePopoverOpen}
        onBlur={handlePopoverClose}
        tabIndex={0}
        className={clsx({
          [classes.rejected]: status === EhrIntegrationStatus.Rejected,
        })}
      />
    </>
  );
}

const useStyles = makeStyles({
  rejected: {
    ...NEWS_SCORE_PALETTE[2],
  },
});
