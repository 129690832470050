import React, { useCallback } from 'react';

import { gql } from '@apollo/client';
import { useConfirm } from 'material-ui-confirm';
import { useRemoveAutoApproverMutation } from '@/generated/graphql';
import { toast } from 'sonner';
import { Box, Typography } from '@mui/material';
import { Alert } from '@mui/material';

interface RemoveAutoApproverMutation {
  integrationId: string;
  onRemoved: () => void;
}

export const REMOVE_AUTO_APPROVER_MUTATION = gql`
  mutation RemoveAutoApprover($integrationId: ID!) {
    removeEhrIntegrationAutoApprover(ehrIntegrationId: $integrationId) {
      id
    }
  }
`;

const TOAST_ID = 'remove-auto-approver-toast';

export function useRemoveAutoApprover({ integrationId, onRemoved }: RemoveAutoApproverMutation) {
  const confirm = useConfirm();

  const [removeAutoApproverMutation] = useRemoveAutoApproverMutation({
    variables: {
      integrationId,
    },
    onCompleted: () => {
      toast.success('Successfully removed auto approval', {
        id: TOAST_ID,
        duration: 4000,
      });
      onRemoved();
    },
    onError: () => {
      toast.error('Failed to remove auto approval', { id: TOAST_ID, duration: 4000 });
    },
  });

  return useCallback(async () => {
    try {
      await confirm({
        title: 'Confirm removal of automatic approval',
        confirmationText: 'Remove',
        confirmationButtonProps: {
          variant: 'contained',
          color: 'error',
        },
        description: (
          <Box>
            <Alert severity="warning">
              <Typography gutterBottom>
                Removing automatic approval will require you to manually approve the integrations
                from the Quicksilva toolbar.
              </Typography>
            </Alert>
          </Box>
        ),
      });

      toast.loading('Removing automatic approval', {
        id: TOAST_ID,
        duration: Infinity,
      });

      removeAutoApproverMutation();
    } catch {
      // the user cancelled the dialog
    }
  }, [confirm, removeAutoApproverMutation]);
}
