import React from 'react';

import '@/styles/components/blocks/block-notes.scss';

class BlockNotes extends React.Component {
  render() {
    return (
      <div className="block block--notes">
        <div className="block__title">Notes</div>
        <div className="block__inner">
          {this.props.notes.map(({ id, text }) => (
            <p key={id}>{text}</p>
          ))}
        </div>
      </div>
    );
  }
}

export default BlockNotes;
