import React from 'react';

import { useConfirm } from 'material-ui-confirm';
import { gql } from '@apollo/client';
import {
  AdminAlertRulesItemFragmentInternal,
  useDeleteAlertRuleMutationInternal,
} from '@/generated/graphql-internal';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const DELETE_ALERT_RULE_MUTATION = gql`
  mutation DeleteAlertRule($alertRuleId: ID!) {
    deleteAlertRule(id: $alertRuleId)
  }
`;

interface UseDeleteAlertRuleProps {
  onDeleted: () => void;
}

interface UseDeleteAlertRuleOptions {
  alertRule: AdminAlertRulesItemFragmentInternal;
}

export function useDeleteAlertRuleModal({ onDeleted }: UseDeleteAlertRuleProps) {
  const [deleteAlertRuleMutation] = useDeleteAlertRuleMutationInternal({
    onError: () => undefined,
    onCompleted: () => {
      onDeleted();
    },
  });
  const { t } = useTranslation();

  const confirm = useConfirm();

  return {
    showDeleteAlertRuleModal: async ({ alertRule }: UseDeleteAlertRuleOptions) => {
      try {
        await confirm({
          title: `Confirm "${alertRule.name}" rule deletion`,
          content: (
            <Alert severity="warning">
              {t(
                'Deleting "{{name}}" will immediately stop this alert from triggering for all users in Feebris.',
                { name: alertRule.name },
              )}
            </Alert>
          ),
          confirmationButtonProps: {
            variant: 'contained',
            color: 'error',
          },
          confirmationText: 'Delete',
        });
        await deleteAlertRuleMutation({
          variables: {
            alertRuleId: alertRule.id,
          },
        });
      } catch {
        // material-ui-confirm when the user cancels the dialog
      }
    },
  };
}
