import React from 'react';

import * as Yup from 'yup';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useModal } from 'mui-modal-provider';
import { useFormik } from 'formik';
import { gql } from '@apollo/client';

import {
  AdminCheckupTypeItemFragmentInternal,
  useUpdateCheckupTypeMutationInternal,
} from '@/generated/graphql-internal';
import { CheckupTiles } from '@/generated/graphql';

import { getMutationErrors } from '@/AuthorizedApolloClientProvider';
import { muiFormikGetFieldProps } from '@/helpers/formik';

import { CheckupTilesConfig } from './CheckupTilesConfig';
import { isDefined } from '@/helpers/isDefined';

export const UPDATE_CHECKUP_TYPE_MUTATION = gql`
  mutation UpdateCheckupType($checkupTypeId: ID!, $checkupType: CheckupTypeUpdateInput!) {
    updateCheckupType(checkupTypeId: $checkupTypeId, checkupType: $checkupType) {
      id
      name
      createdAt
    }
  }
`;

interface UpdateCheckupTypeModalProps extends DialogProps {
  checkupType: AdminCheckupTypeItemFragmentInternal;
  pathwayPublishedAt: Maybe<Date | string>;
  onCancel: () => void;
  onUpdated: () => void;
}

const formSchema = Yup.object().shape({
  name: Yup.string().required('Care Pathway name is required'),
  heading: Yup.string().required('Heading is required'),
  subheading: Yup.string().required('Subheading is required'),
  description: Yup.string().required('Description is required'),
  checkupTiles: Yup.object<CheckupTiles>().required('Checkup Tiles is required'),
});

type FormValues = Yup.InferType<typeof formSchema>;

export function UpdateCheckupTypeModal({
  open,
  onCancel,
  onUpdated,
  checkupType,
  pathwayPublishedAt,
}: UpdateCheckupTypeModalProps) {
  const classes = useStyles();

  const isPathwayPublished = isDefined(pathwayPublishedAt);

  const [updateCheckupTypeMutation, { loading: isSubmitting, error: createCheckupTypeError }] =
    useUpdateCheckupTypeMutationInternal({
      onCompleted: () => {
        onUpdated();
      },
      onError: () => undefined,
    });

  const formik = useFormik<FormValues>({
    initialValues: {
      name: checkupType.name,
      heading: checkupType.heading,
      subheading: checkupType.subheading,
      description: checkupType.description,
      checkupTiles: checkupType.checkupTiles,
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      updateCheckupTypeMutation({
        variables: {
          checkupTypeId: checkupType.id,
          checkupType: {
            name: values.name,
            heading: values.heading,
            subheading: values.subheading,
            description: values.description,
            checkupTiles: values.checkupTiles,
          },
        },
      });
    },
  });

  const { argErrors } = getMutationErrors(createCheckupTypeError);
  const getFieldProps = muiFormikGetFieldProps(formik, argErrors);

  const checkupTilesMutationError = isDefined(argErrors?.checkuptype?.checkupTiles)
    ? JSON.stringify(argErrors?.checkuptype?.checkupTiles)
    : undefined;

  return (
    <Dialog open={open} fullWidth maxWidth="md" aria-labelledby="update-checkuptype-dialog-title">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="update-checkuptype-dialog-title">Update Check-up Type</DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Metadata
          </Typography>
          <TextField
            id="name"
            label="Check-up Type Name"
            disabled={isPathwayPublished}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoFocus
            className={classes.textField}
            {...getFieldProps('name')}
            helperText={
              isPathwayPublished
                ? 'Cannot update name of check-up type in published care pathway'
                : undefined
            }
          />
          <TextField
            id="heading"
            label="Check-up button heading"
            disabled={isSubmitting}
            placeholder='e.g. "COPD Morning"'
            variant="standard"
            InputLabelProps={{ shrink: true }}
            fullWidth
            className={classes.textField}
            {...getFieldProps('heading')}
          />
          <TextField
            id="subheading"
            label="Check-up button subheading"
            disabled={isSubmitting}
            placeholder='e.g. "Morning"'
            variant="standard"
            InputLabelProps={{ shrink: true }}
            fullWidth
            className={classes.textField}
            {...getFieldProps('subheading')}
          />
          <TextField
            id="description"
            label="Check-up button description"
            disabled={isSubmitting}
            placeholder='e.g. "Morning check-up for COPD patients"'
            variant="standard"
            InputLabelProps={{ shrink: true }}
            fullWidth
            className={classes.textField}
            {...getFieldProps('description')}
          />
          <CheckupTilesConfig
            checkupTiles={formik.values['checkupTiles']}
            onUpdate={(newTileConfig) =>
              formik.handleChange({ target: { name: 'checkupTiles', value: newTileConfig } })
            }
          />
          {checkupTilesMutationError && <Alert severity="error">{checkupTilesMutationError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

interface UseUpdateCheckupTypeModalProps {
  onCancel?: () => void;
  onUpdated: () => void;
}

export function useUpdateCheckupTypeModal({ onCancel, onUpdated }: UseUpdateCheckupTypeModalProps) {
  const { showModal } = useModal();

  return {
    showUpdateCheckupTypeModal: ({
      checkupType,
      pathwayPublishedAt,
    }: {
      checkupType: AdminCheckupTypeItemFragmentInternal;
      pathwayPublishedAt: Maybe<Date | string>;
    }) => {
      const modal = showModal(
        UpdateCheckupTypeModal,
        {
          checkupType,
          pathwayPublishedAt,
          onCancel: () => {
            onCancel?.();
            modal.hide();
          },
          onUpdated: () => {
            onUpdated();
            modal.hide();
          },
        },
        { destroyOnClose: true },
      );
    },
  };
}

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
}));
