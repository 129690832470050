import { useState } from 'react';

interface UseLocalStorageOptions<TValue> {
  excludeKeys?: (keyof TValue)[];
  dataMigration?: (arg0: TValue) => TValue;
}

export function useLocalStorage<TValue>(
  key: string,
  initialValue: TValue,
  { excludeKeys, dataMigration }: UseLocalStorageOptions<TValue> = {},
): [TValue, (value: TValue | ((current: TValue) => TValue)) => void] {
  const [storedValue, setStoredValue] = useState<TValue>(() => {
    // try to get from local storage first, then fallback to initialValue
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      const result = item ? JSON.parse(item) : initialValue;
      // TODO: Actually this wasn't a great idea. It would be better to store the version number
      //       of the portal inside the local storage blob then when we rehydrate we can compare
      //       against the current portal version number and bust the cache if the two versions
      //       don't match.
      if (dataMigration) {
        return dataMigration({ ...result });
      }
      return result;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value: TValue | ((current: TValue) => TValue)) => {
    // Mostly this fails when using browsers in private mode, so we supress the error
    // and fallback to in memory storage
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);

      const valueToStoreWithoutSkippedKeys =
        excludeKeys && valueToStore && typeof valueToStore === 'object'
          ? Object.keys(valueToStore).reduce(
              (acc, key) =>
                excludeKeys.includes(key as keyof TValue)
                  ? acc
                  : {
                      ...acc,
                      [key]: (valueToStore as Record<string, unknown>)[key],
                    },
              {},
            )
          : valueToStore;

      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStoreWithoutSkippedKeys));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
}
