import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
  createContext,
  useCallback,
} from 'react';

import { ApolloError, gql } from '@apollo/client';
import { toast } from 'sonner';

import {
  useGetVirtualWardFilterValuesQuery,
  useGetVirtualWardPatientsQuery,
  UserWardFragment,
  VirtualWardPatientItemFragment,
  SortDirection,
  VirtualWardPatientsSortField,
  UserNeighborFragment,
  usePatientsInWatchListQuery,
} from '@/generated/graphql';

import { useDebounce } from '@/hooks/useDebounce';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useMeActingOrganizationFeature } from '@/hooks/useAuth';
import { useIsActingOrgType } from '@/hooks/useIsOrgType';
import { useWindowVisible } from '@/hooks/useWindowVisible';

interface VirtualWardContextProps {
  userWards: UserWardFragment[];
  neighbors: UserNeighborFragment[];
  patientFetchError: ApolloError | undefined;
  patients: VirtualWardPatientItemFragment[] | undefined;
  watchListPatients: VirtualWardPatientItemFragment[] | undefined;
  totalPatients: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  lastRefreshed: number | undefined;
  refresh: () => void;
  isLoadingFilterValues: boolean;
  /**
   * This is true when the cache is empty or invalidated.
   * This avoids flashing loaders when a simple refresh is issued and simply updates the list when it's ready.
   */
  isLoadingPatientsFirstTime: boolean;
  /**
   * This is true whenever a request is in flight, even if the cache is still valid. This is used for a11y.
   */
  isLoadingPatients: boolean;
  pollingInterval: number;
  togglePolling: () => void;
  filters: VirtualWardFilters;
  setFilter: <TFilterKey extends keyof VirtualWardFilters>(
    name: TFilterKey,
    value: VirtualWardFilters[TFilterKey],
  ) => void;
  sortConfig: readonly SortFacetState[];
  toggleSortFacet: (facet: VirtualWardPatientsSortField) => void;
  setFacetPriority: (facet: VirtualWardPatientsSortField, priority: number) => void;
  removeFilter: <TFilterKey extends keyof VirtualWardFilters>(name: TFilterKey) => void;
  resetFilters: () => void;
  resetSort: () => void;
}

export interface VirtualWardFilters {
  nameOrNhsNumber: string;
  checkupsSinceHoursAgo: number | null;
  /**
   * The 'all' value means all wards the user is a member of, rather than a specific list of wards.
   * This makes it easier to handle cases where a user previously wanted to see all wards and since then has joined a new ward.
   *
   * The 'null' value means the user has not yet made a selection.
   * This is used to distinguish between the user having no wards and the user having purposefully selected no wards.
   *
   * The 'string[]' value means the user has selected specific wards.
   */
  wards: 'all' | string[] | null;
  neighbors: string[];
}

export const CHECKUPS_WITHIN_LAST_OPTIONS = [4, 24, 24 * 3, 24 * 7, 24 * 30];

const DEFAULT_FILTERS: VirtualWardFilters = {
  nameOrNhsNumber: '',
  checkupsSinceHoursAgo: 3 * 24,
  wards: [],
  neighbors: [],
};

const VirtualWardContext = createContext<VirtualWardContextProps>(
  // This will never be used without a provider, but we need to provide a default value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  {} as any as VirtualWardContextProps,
);

export const GET_USER_FILTERS = gql`
  fragment UserWard on Ward {
    id
    name
  }

  fragment UserNeighbor on NeighborOrganization {
    id
    name
  }

  query GetVirtualWardFilterValues($isActingOrgPractice: Boolean!, $wardsEnabled: Boolean!) {
    me {
      wards @include(if: $wardsEnabled) {
        ...UserWard
      }
      actingOrganization {
        neighbors @include(if: $isActingOrgPractice) {
          ...UserNeighbor
        }
      }
    }
  }
`;

export const GET_VIRTUAL_WARD_PATIENTS = gql`
  fragment VirtualWardPatientItem on Patient {
    id
    firstName
    lastName
    numSimilarNames
    birthDate
    nationalIdentifier {
      ...NationalIdentifierDisplay
    }
    onWatchList
    wardAdmission {
      ward {
        id
        name
      }
      carePathway {
        id
        name
      }
    }
    latestCheckup {
      ...VirtualWardPatientCheckup
    }
    latestContinuousMonitoring {
      ...VirtualWardPatientContinuousMonitoringWithSession
    }
    activityMonitoringSession {
      ...VirtualWardPatientActivityMonitoringSession
    }
    alerts(status: "open", aggregate: true) {
      ...AlertChipFields
    }
  }

  query GetVirtualWardPatients(
    $wardIds: [ID!]!
    $neighborIds: [ID!]
    $take: Int
    $skip: Int
    $nameOrNhsNumber: String
    $withCheckupsInLastHours: Int
    $sort: [VirtualWardPatientsSortInput]
  ) {
    virtualWardPatients(
      wardIds: $wardIds
      neighborIds: $neighborIds
      take: $take
      skip: $skip
      nameOrNhsNumber: $nameOrNhsNumber
      withCheckupsInLastHours: $withCheckupsInLastHours
      sort: $sort
    ) {
      patients {
        ...VirtualWardPatientItem
      }
      total
    }
  }

  query PatientsInWatchList {
    patientsInWatchList {
      ...VirtualWardPatientItem
    }
  }
`;

interface SortFacetState {
  facet: VirtualWardPatientsSortField;
  direction: SortDirection;
  enabled: boolean;
  readonly?: boolean;
}

const DEFAULT_SORT_CONFIG: SortFacetState[] = [
  {
    facet: VirtualWardPatientsSortField.AlertCount,
    direction: SortDirection.Desc,
    enabled: true,
  },
  {
    facet: VirtualWardPatientsSortField.EwsScore,
    direction: SortDirection.Desc,
    enabled: true,
  },
  {
    facet: VirtualWardPatientsSortField.LatestVitals,
    direction: SortDirection.Desc,
    enabled: true,
    readonly: true,
  },
];

const DEFAULT_REFRESH_INTERVAL = 60_000 * 5; // 5 minutes

export function VirtualWardContextProvider({ children }: { children: React.ReactNode }) {
  const [lastRefreshed, setLastRefreshed] = useState<number>();
  const [pollingInterval, setPollingInterval] = useLocalStorage<number>(
    'virtual_ward_refreshInterval',
    DEFAULT_REFRESH_INTERVAL,
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('virtual_ward_pageSize', 10);
  const [sortConfig, setSortConfig] = useLocalStorage<SortFacetState[]>(
    'virtual_ward_sort',
    DEFAULT_SORT_CONFIG,
  );

  const virtualWardAllPatientsLegacyBehavior = useMeActingOrganizationFeature(
    'virtualWardAllPatientsLegacyBehavior',
  );

  // TODO: FEP-3041, Once all organizations have wards, this feature flag should be removed.
  /**
   * This is handling the change in the feature flag for showing all patients.
   *
   * `virtualWardAllPatientsLegacyBehavior` is a temporary feature flag to allow us to
   * show all patients when no wards are selected.
   */
  const defaultFiltersWithFeatureFlags = useMemo(() => {
    const defaultFilters = { ...DEFAULT_FILTERS };
    if (virtualWardAllPatientsLegacyBehavior) {
      defaultFilters.wards = 'all';
    }
    return defaultFilters;
  }, [virtualWardAllPatientsLegacyBehavior]);

  const [filters, setFilters] = useLocalStorage(
    'virtual_ward_filters',
    defaultFiltersWithFeatureFlags,
    {
      excludeKeys: ['nameOrNhsNumber'],
      // TODO: Remove this once we feel confident everyone's local storage is updated
      dataMigration: (loadedFilters: VirtualWardFilters) => {
        // Default to all wards when there's already state from before we added the wards filtering here
        if (loadedFilters.wards === undefined) {
          loadedFilters.wards = virtualWardAllPatientsLegacyBehavior ? 'all' : [];
          window.localStorage.setItem('virtual_ward_filters', JSON.stringify(loadedFilters));
        }
        // When the feature is disabled, we need to remove the 'all' value from the wards
        if (loadedFilters.wards === 'all' && !virtualWardAllPatientsLegacyBehavior) {
          loadedFilters.wards = [];
          window.localStorage.setItem('virtual_ward_filters', JSON.stringify(loadedFilters));
        }
        // We may have removed some options from the checkups since filter, so we need to reset it
        if (
          loadedFilters.checkupsSinceHoursAgo &&
          !CHECKUPS_WITHIN_LAST_OPTIONS.includes(loadedFilters.checkupsSinceHoursAgo)
        ) {
          loadedFilters.checkupsSinceHoursAgo =
            defaultFiltersWithFeatureFlags.checkupsSinceHoursAgo;
          window.localStorage.setItem('virtual_ward_filters', JSON.stringify(loadedFilters));
        }

        if (loadedFilters.neighbors === undefined) {
          loadedFilters.neighbors = [];
          window.localStorage.setItem('virtual_ward_filters', JSON.stringify(loadedFilters));
        }

        return loadedFilters;
      },
    },
  );

  const debouncedFilters = useDebounce(filters, 500);
  const enabledSortFacets = useMemo(() => sortConfig.filter((sort) => sort.enabled), [sortConfig]);

  const isActingOrgPractice = useIsActingOrgType('practice');
  const wardsEnabled = useMeActingOrganizationFeature('wards', false);

  const { data: filterValues, loading: isLoadingFilterValues } = useGetVirtualWardFilterValuesQuery(
    {
      variables: {
        isActingOrgPractice,
        wardsEnabled,
      },
      context: { batch: true },
      onError: () =>
        toast.error(
          "Failed to load wards you've been assigned to, this may affect your ward filters",
        ),
    },
  );
  const userWards = useMemo(() => filterValues?.me?.wards || [], [filterValues]);
  const neighbors = useMemo(
    () => filterValues?.me?.actingOrganization.neighbors ?? [],
    [filterValues],
  );

  const setFilter = useCallback(
    <TFilterKey extends keyof VirtualWardFilters>(
      name: TFilterKey,
      value: VirtualWardFilters[TFilterKey],
    ) => {
      setFilters((current) => ({ ...current, [name]: value }));
    },
    [setFilters],
  );

  // Reset the page when the filters or sort change
  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedFilters, sortConfig]);

  /**
   * This validates the combination of filters and the user's wards, and updates the filters if necessary.
   */
  const validateFilters = (filters: VirtualWardFilters) => {
    if (!isLoadingFilterValues) {
      /**
       * The user previously chose to see all wards, but they have no wards assigned.
       * We set this to null instead of [] so we can distinguish between the user having no wards
       * and the user having purposefully selected no wards.
       */
      if (filters.wards === 'all' && userWards.length === 0) {
        setFilter('wards', null);
        return;
      }

      // The user has never selected any wards, so we default to all wards
      if (filters.wards === null && userWards.length > 0) {
        /**
         * When this feature is enabled, we specifically select all wards, since the empty state will be all patients.
         * When is't disabled, an empty selection of wards will still filter by wards they're a member of, so checking them all is redundant.
         */
        const allWardsValue = virtualWardAllPatientsLegacyBehavior ? 'all' : [];

        setFilter('wards', allWardsValue);
        return;
      }

      // The user has selected specific wards, but they may have been removed from some of them
      if (Array.isArray(filters.wards)) {
        setFilter(
          'wards',
          filters.wards.filter((wardId) => userWards.some((uw) => uw.id === wardId)),
        );
      }

      if (Array.isArray(filters.neighbors)) {
        setFilter(
          'neighbors',
          // Remove any organizations which are no longer linked
          filters.neighbors.filter((orgId) => neighbors.some((lo) => lo.id === orgId)),
        );
      }
    }
  };

  // Respond to changes in the user's wards, and remove or change any filters that no longer apply
  useLayoutEffect(() => {
    validateFilters(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when userWards changes
  }, [userWards]);

  const togglePolling = () => {
    setPollingInterval((current) => (current ? 0 : DEFAULT_REFRESH_INTERVAL));
  };

  const resetFilters = () => {
    setFilters(defaultFiltersWithFeatureFlags);
    // Trigger the validation to run, since the filters have changed
    validateFilters(defaultFiltersWithFeatureFlags);
  };

  const resetSort = () => {
    setSortConfig(DEFAULT_SORT_CONFIG);
  };

  const removeFilter = <TFilterKey extends keyof VirtualWardFilters>(name: TFilterKey) => {
    setFilters((current) => ({ ...current, [name]: null }));
  };

  const toggleSortFacet = (facet: VirtualWardPatientsSortField) => {
    setSortConfig((current) =>
      current.map((sort) => {
        if (sort.facet === facet) {
          return {
            ...sort,
            enabled: !sort.enabled,
          };
        }
        return sort;
      }),
    );
  };

  const setFacetPriority = (facet: VirtualWardPatientsSortField, priority: number) => {
    // jumble up the array to be in the right order
    setSortConfig((current) => {
      const newSortConfig = [...current];
      const currentSortIndex = newSortConfig.findIndex((sort) => sort.facet === facet);
      const [sort] = newSortConfig.splice(currentSortIndex, 1);
      newSortConfig.splice(priority, 0, sort);
      return newSortConfig;
    });
  };

  const {
    data: virtualWardPatients,
    previousData: previousVirtualWardPatients,
    loading: isLoadingPatients,
    error: patientFetchError,
    refetch,
    startPolling,
    stopPolling,
  } = useGetVirtualWardPatientsQuery({
    variables: {
      // The server doesn't support 'all' as a ward ID, so we need to expand it here
      wardIds: filters.wards === 'all' ? userWards.map((uw) => uw.id) : filters.wards ?? [],
      neighborIds: filters.neighbors,
      take: pageSize,
      skip: (currentPage - 1) * pageSize,
      sort: enabledSortFacets.map((sort) => ({
        field: sort.facet,
        direction: sort.direction,
      })),
      nameOrNhsNumber: debouncedFilters.nameOrNhsNumber,
      withCheckupsInLastHours: debouncedFilters.checkupsSinceHoursAgo,
    },
    onCompleted: () => setLastRefreshed(Date.now()),
    onError: () => toast.error('An error occurred when fetching patients'),
    notifyOnNetworkStatusChange: true,
    skip: isLoadingFilterValues,
  });

  const {
    data: watchListPatients,
    previousData: previousWatchListPatients,
    loading: isLoadingWatchList,
    refetch: refetchWatchList,
  } = usePatientsInWatchListQuery();

  const wrappedRefetch = useCallback(async () => {
    await Promise.all([refetchWatchList(), refetch()]);
  }, [refetch, refetchWatchList]);

  /**
   * We want to show the loading state only when the cache is empty or invalidated.
   * This avoids flashing loaders when a simple refresh is issued and simply updates the list when it's ready.
   * When the cache is invalidated by changing paging or any filters, we don't want to show loaders.
   */
  const isLoadingPatientsFirstLoad = isLoadingPatients && !virtualWardPatients;

  const visible = useWindowVisible();

  useEffect(() => {
    // When the window is visible, start polling if the interval is set
    if (visible && pollingInterval) {
      startPolling(pollingInterval);

      // If we're overdue for a refresh (because the window wasn't visible), do it now
      if (lastRefreshed && Date.now() - lastRefreshed > pollingInterval) {
        wrappedRefetch();
      }
    }
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling, lastRefreshed, pollingInterval, visible, wrappedRefetch]);

  return (
    <VirtualWardContext.Provider
      value={{
        userWards,
        neighbors,
        // use the previous data to avoid a flash of empty state
        patients:
          virtualWardPatients?.virtualWardPatients?.patients ??
          previousVirtualWardPatients?.virtualWardPatients?.patients,
        watchListPatients:
          watchListPatients?.patientsInWatchList ?? previousWatchListPatients?.patientsInWatchList,
        patientFetchError,
        totalPatients:
          // use the previous data to avoid a flash of empty state
          virtualWardPatients?.virtualWardPatients?.total ??
          previousVirtualWardPatients?.virtualWardPatients?.total ??
          0,
        lastRefreshed,
        refresh: wrappedRefetch,
        isLoadingFilterValues,
        isLoadingPatientsFirstTime: isLoadingPatientsFirstLoad,
        isLoadingPatients: isLoadingPatients || isLoadingWatchList,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
        pollingInterval,
        togglePolling,
        filters,
        setFilter,
        resetFilters,
        resetSort,
        removeFilter,
        sortConfig,
        toggleSortFacet,
        setFacetPriority,
      }}>
      {children}
    </VirtualWardContext.Provider>
  );
}

export const useVirtualWardContext = () => React.useContext(VirtualWardContext);
