import React from 'react';
import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const NoResults = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      marginTop={5}>
      <div>
        <svg
          width="128"
          height="128"
          viewBox="0 0 128 128"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="64" cy="64" r="64" fill="#E6E6E6" />
          <path
            d="M88 59.5C88 73.5343 76.4088 85 62 85C47.5912 85 36 73.5343 36 59.5C36 45.4657 47.5912 34 62 34C76.4088 34 88 45.4657 88 59.5Z"
            stroke="#B1B1B1"
            strokeWidth="6"
          />
          <path
            d="M93.8786 99.6754C95.0501 100.847 96.9496 100.847 98.1212 99.6754C99.2928 98.5038 99.2928 96.6043 98.1212 95.4328L93.8786 99.6754ZM75.8103 81.6072L93.8786 99.6754L98.1212 95.4328L80.053 77.3645L75.8103 81.6072Z"
            fill="#B1B1B1"
          />
          <path
            d="M55 53L68.125 67M68.125 53L55 67"
            stroke="#B1B1B1"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <Typography variant="h5" className={classes.message}>
        No patients found
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  message: {
    marginTop: theme.spacing(2),
    color: theme.palette.grey[600],
  },
}));
