import { useJourneyModalContext } from '@/components/JourneyModal';
import { FormattableUserFragment, User } from '@/generated/graphql';

export interface AddUserJourneyContext {
  userHasName: boolean | null;
  userDetailsUpdated: boolean;
  user: Maybe<Pick<User, 'id' | 'isSelfCare'> & FormattableUserFragment>;
}

export type AddUserJourneyStep = 'addUser' | 'assignWards' | 'success';

export const useAddUserJourneyContext = () =>
  useJourneyModalContext<AddUserJourneyContext, AddUserJourneyStep>();
