import React, { useMemo } from 'react';

import { useFormik } from 'formik';
import _ from 'lodash';
import { toast } from 'sonner';
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Box,
  TextField,
  FormLabel,
  FormHelperText,
  Typography,
  Stack,
} from '@mui/material';
import * as Yup from 'yup';
import { gql } from '@apollo/client';

import { useGetAdmissionWardAndCarePathwayOptionsQuery } from '@/generated/graphql';

import { muiFormikGetFieldProps } from '@/helpers/formik';
import { useMeActingOrganizationFeature } from '@/hooks/useAuth';

import { useAdmitPatientJourneyContext } from '../types';

const admissionDetailsFormSchema = Yup.object().shape({
  wardId: Yup.string().required('Required'),
  carePathwayId: Yup.string().required('Required'),
  isSelfCare: Yup.boolean().required('Required'),
});

export const QUERY_WARDS_AND_CARE_PATHWAYS = gql`
  query GetAdmissionWardAndCarePathwayOptions {
    wards {
      id
      name
    }
    carePathways {
      id
      name
    }
  }
`;

export function AdmissionDetailsStep() {
  const {
    gotoNextStep,
    handleStep,
    currentJourneyState: { pendingWardAdmission },
  } = useAdmitPatientJourneyContext();
  const hasSelfCareFeature = useMeActingOrganizationFeature('selfCare');

  const { data, loading } = useGetAdmissionWardAndCarePathwayOptionsQuery({
    onError: () =>
      toast.error('An error occurred when fetching wards and care pathways for selection'),
  });

  handleStep(() => false);

  const sortedWards = useMemo(() => _.sortBy(data?.wards ?? [], (w) => w.name), [data?.wards]);

  const sortedCarePathways = useMemo(
    () => _.sortBy(data?.carePathways ?? [], (cp) => cp.name),
    [data?.carePathways],
  );

  const formik = useFormik({
    initialValues: {
      wardId: pendingWardAdmission?.wardId ?? '',
      carePathwayId: pendingWardAdmission?.carePathwayId ?? '',
      isSelfCare: hasSelfCareFeature ? pendingWardAdmission?.isSelfCare : false,
    },
    validationSchema: admissionDetailsFormSchema,
    onSubmit: async (values) => {
      gotoNextStep({
        pendingWardAdmission: {
          wardId: values.wardId,
          wardName: sortedWards.find((w) => w.id === values.wardId)?.name ?? '',
          carePathwayId: values.carePathwayId,
          carePathwayName:
            sortedCarePathways.find((cp) => cp.id === values.carePathwayId)?.name ?? '',
          isSelfCare: values.isSelfCare ?? false,
        },
      });
    },
  });

  const getFieldProps = muiFormikGetFieldProps(formik, undefined, admissionDetailsFormSchema);

  return (
    <div>
      <Box marginBottom={3}>
        <Typography variant="body2" color="textSecondary">
          Select a ward to allocate the patient to a care team, and a care pathway to set-up their
          Feebris care plan, including thresholds, alerts and scheduling.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit} id="wardAdmissionForm" noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              loading={loading}
              options={sortedWards}
              getOptionLabel={(option) => option.name}
              defaultValue={sortedWards.find((w) => w.id === pendingWardAdmission?.wardId) ?? null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => formik.setFieldValue('wardId', value?.id || '')}
              noOptionsText="No wards found"
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...getFieldProps('wardId', { fireOnChange: false })}
                  autoFocus
                  placeholder="Select a Ward"
                  label="Ward"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              loading={loading}
              options={sortedCarePathways}
              getOptionLabel={(option) => option.name}
              defaultValue={
                sortedCarePathways.find((w) => w.id === pendingWardAdmission?.carePathwayId) ?? null
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => formik.setFieldValue('carePathwayId', value?.id || '')}
              noOptionsText="No care pathways found"
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...getFieldProps('wardId', { fireOnChange: false })}
                  placeholder="Select a Care Pathway"
                  label="Care Pathway"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Grid>
          {hasSelfCareFeature && (
            <Grid item xs={12}>
              <FormLabel component="legend" id="selfCareAdmissionToggle" sx={{ marginBottom: 0.5 }}>
                <Typography variant="body2">Will this patient have their own kit?</Typography>
              </FormLabel>
              <ToggleButtonGroup
                color="primary"
                exclusive
                fullWidth
                id="isSelfCareToggle"
                aria-labelledby="selfCareAdmissionToggle"
                value={formik.values.isSelfCare}
                onChange={(_, value) => formik.setFieldValue('isSelfCare', value)}>
                <ToggleButton value={true}>
                  <Stack
                    paddingX={{
                      xs: 0,
                      sm: 6,
                      lg: 8,
                    }}>
                    <Typography variant="body1">Yes</Typography>
                    <Typography variant="caption">
                      The patient will have their own kit for collection of their data
                    </Typography>
                  </Stack>
                </ToggleButton>
                <ToggleButton value={false}>
                  <Stack
                    paddingX={{
                      xs: 0,
                      sm: 6,
                      lg: 8,
                    }}>
                    <Typography variant="body1">No</Typography>
                    <Typography variant="caption">
                      Staff will have a shared kit for collection of multiple patient&apos;s data
                    </Typography>
                  </Stack>
                </ToggleButton>
              </ToggleButtonGroup>
              {formik.touched.isSelfCare && formik.errors.isSelfCare && (
                <FormHelperText error>{formik.errors.isSelfCare}</FormHelperText>
              )}
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
}
