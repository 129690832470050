import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import api from '@/controllers/Api';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

export default function AddPracticeDialog({ open, onClose, onCreatePractice }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      organization: {
        name: '',
        telephone: '',
        address: {
          address: '',
          postcode: '',
        },
      },
      user: {
        email: '',
        firstName: '',
        lastName: '',
      },
    },
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      setSubmitting(true);
      const response = await api.createPractice(values);
      setSubmitting(false);

      if (response.errors) {
        setErrors(response.errors[0].extensions.invalidArgs);
      } else {
        resetForm();
        onCreatePractice(response.data.createPractice);
      }
    },
  });

  const resetAndClose = () => {
    // TODO: Confirm if formik.dirty?
    formik.resetForm();
    return onClose();
  };

  const muiFormikGetFieldProps = (name) => {
    return {
      ...formik.getFieldProps(name),
      error: _.has(formik.errors, name),
      helperText: _.get(formik.errors, name),
    };
  };

  return (
    <Dialog
      open={open}
      onClose={resetAndClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add {t('GP Practice')}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={`${t('GP Practice')} Name`}
                autoFocus
                required
                className={classes.textField}
                {...muiFormikGetFieldProps('organization.name')}
              />
              <TextField
                fullWidth
                type="tel"
                label="Telephone"
                className={classes.textField}
                {...muiFormikGetFieldProps('organization.telephone')}
              />
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Address"
                required
                className={classes.textField}
                {...muiFormikGetFieldProps('organization.address.address')}
              />
              <TextField
                fullWidth
                label="Postcode"
                required
                className={classes.textField}
                {...muiFormikGetFieldProps('organization.address.postcode')}
              />
              <Typography>Contact Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    required
                    className={classes.textField}
                    {...muiFormikGetFieldProps('user.firstName')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    required
                    className={classes.textField}
                    {...muiFormikGetFieldProps('user.lastName')}
                  />
                </Grid>
              </Grid>
              <TextField
                fullWidth
                label="Email Address"
                required
                className={classes.textField}
                {...muiFormikGetFieldProps('user.email')}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" paragraph>
                Important
              </Typography>
              <Typography paragraph>
                By adding this {t('GP Practice')} to the list, you will be able to assign them to
                individual patients.
              </Typography>
              <Typography paragraph>
                A welcome email with instructions about how to get onboarded onto the Feebris
                platform will be sent to the email you specify. Please use the individual email of a
                &nbsp;{t('GP Practice')} and not a generic one (eg. reception desk).
              </Typography>
              <Typography paragraph>
                Please ensure that the details are accurate, to the best of your knowledge.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetAndClose}>Cancel</Button>
          <Button type="submit" color="primary" variant="contained" className="e2e__addgpsubmit">
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
