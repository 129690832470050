import React from 'react';

import History from '@/helpers/History';

import '@/styles/login.scss';

import auth from '@/controllers/Auth';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import logo from '@/images/logo.png';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: '',
      reminderSent: false,
    };
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async handlePasswordReset(e) {
    e.preventDefault();
    auth
      .reset(this.state.email)
      .then(() => this.setState({ reminderSent: true }))
      .catch((error) => this.setState({ error: error.message }));
  }

  handleBackToLogin(e) {
    e.preventDefault();
    History.push('/login');
  }

  render() {
    return (
      <div className="login">
        <img src={logo} alt="" />
        <div className="login__inner">
          {this.state.reminderSent ? this.renderBackToLoginForm() : this.renderPasswordResetForm()}
        </div>
      </div>
    );
  }

  renderPasswordResetForm() {
    return (
      <form noValidate onSubmit={(e) => this.handlePasswordReset(e)}>
        <fieldset>
          <Typography paragraph>
            Enter your email address and we will send you a password reset link.
          </Typography>
          <TextField
            className="login__input"
            fullWidth
            type="email"
            placeholder="Enter your email address"
            name="email"
            variant="outlined"
            autoFocus={true}
            value={this.state.email}
            error={!!this.state.error}
            helperText={this.state.error}
            onChange={(e) => this.handleChange(e)}
            required
          />
        </fieldset>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          style={{ marginBottom: 8 }}>
          Send password reset email
        </Button>
        <Button href="/login" fullWidth>
          Cancel
        </Button>
      </form>
    );
  }

  renderBackToLoginForm() {
    return (
      <form onSubmit={(e) => this.handleBackToLogin(e)}>
        <Typography paragraph>
          Check your email for a link to reset your password. If it doesn&apos;t appear within a few
          minutes check your spam folder.
        </Typography>
        <Button variant="contained" color="primary" type="submit" fullWidth>
          Return to login
        </Button>
      </form>
    );
  }
}

export default ForgotPassword;
