import React from 'react';

import { Box, Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Users from './Users';
import ApiKeys from './ApiKeys';
import PatientSharing from './PatientSharing';
import { Link, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMeActingOrganizationFeature, useMePermission } from '@/hooks/useAuth';

const Settings = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const canViewIntegrationApiKeys = useMePermission('view_integration_api_keys');
  const canViewShareTokens = useMePermission('view_share_tokens');
  const integrationApi = useMeActingOrganizationFeature('integrationApi');
  const cleoIntegrationApi = useMeActingOrganizationFeature('cleoIntegrationApi');

  const integrationApiEnabled = integrationApi && canViewIntegrationApiKeys;
  // TODO: This should really be it's own feature switch like "patientSharing" or "shareTokens"
  const patientSharingEnabled = integrationApiEnabled && cleoIntegrationApi && canViewShareTokens;

  const { tabName } = useParams<{
    patientId: string;
    tabName: 'users' | undefined;
  }>();

  return (
    <Box>
      <Box className={classes.tabContainer}>
        <Typography variant="h5" className={classes.title}>
          {t('Organisation Settings')}
        </Typography>
        <Tabs value={tabName} indicatorColor="primary">
          <Tab
            label={t('Users')}
            value="users"
            component={Link}
            to="/settings/users"
            id="organisation-users-tab"
            aria-controls="organisation-users-tabpanel"
          />
          {integrationApiEnabled ? (
            <Tab
              label={t('API Keys')}
              value="api-keys"
              component={Link}
              to="/settings/api-keys"
              id="organisation-api-keys-tab"
              aria-controls="organisation-api-keys-tabpanel"
            />
          ) : null}
          {patientSharingEnabled ? (
            <Tab
              label={t('Patient Record Sharing')}
              value="patient-sharing"
              component={Link}
              to="/settings/patient-sharing"
              id="organisation-patient-sharing-tab"
              aria-controls="organisation-patient-sharing-tabpanel"
            />
          ) : null}
        </Tabs>
      </Box>
      <Box margin={3} role="tabpanel">
        <Switch>
          <Route path="/settings/users">
            <div id="organisation-users-tabpanel" aria-labelledby="organisation-users-tab">
              <Users />
            </div>
          </Route>
          {integrationApiEnabled ? (
            <Route path="/settings/api-keys">
              <div id="organisation-api-keys-tabpanel" aria-labelledby="organisation-api-keys-tab">
                <ApiKeys />
              </div>
            </Route>
          ) : null}
          {patientSharingEnabled ? (
            <Route path="/settings/patient-sharing">
              <div
                id="organisation-patient-sharing-tabpanel"
                aria-labelledby="organisation-patient-sharing-tab">
                <PatientSharing />
              </div>
            </Route>
          ) : null}
          <Redirect to="/settings/users" />
        </Switch>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.dark,
    margin: theme.spacing(0, 2, 0.5, 2),
  },
  tabContainer: {
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export default Settings;
