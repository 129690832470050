import React from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Paper,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PatientDetailsFragment } from '@/generated/graphql';

import PatientDischargeDownloadButton from './PatientDischargeDownloadButton';
import { useCustomReportModal } from './CustomReportModal';
import gql from 'graphql-tag';
import { PageContainer } from '@/components/PageContainer';
import { UserName } from '@/components/UserName';
import _ from 'lodash';
import { useAdmissionHistories } from './useAdmissionHistories';

interface ReportsTab {
  patient: PatientDetailsFragment;
}

export const ADMISSION_EVENT_OVERVIEW_FRAGMENT = gql`
  fragment AdmissionEventOverview on AdmissionEvent {
    admissionId
    createdAt
    createdBy {
      ...FormattableUser
    }
    ward {
      name
      organization {
        id
        name
      }
    }
    WardId
    type
    carePathway {
      name
    }
  }
`;

export default function ReportsTab({ patient }: ReportsTab) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { showCustomReportModal } = useCustomReportModal({
    patientId: patient.id,
  });

  const admissionHistories = useAdmissionHistories(patient.admissionEvents);

  return (
    <PageContainer>
      <Box marginY={2}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            startIcon={<CloudDownloadIcon />}
            onClick={() => showCustomReportModal()}>
            Download Custom Report
          </Button>
        </Box>
      </Box>
      <Box marginBottom={2}>
        <Alert severity="info">
          Discharge summaries are generated when a patient is discharged from a ward. You can
          download a custom report by clicking the &quot;download custom report&quot; button above.
        </Alert>
      </Box>
      <Paper>
        <Box padding={2} paddingBottom={1}>
          <Typography variant="h6" gutterBottom>
            Discharge Reports
          </Typography>
        </Box>
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Admitted</TableCell>
                <TableCell>Discharged</TableCell>
                <TableCell>Discharged By</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admissionHistories.length ? (
                admissionHistories.map((admissionHistory, key) => (
                  <TableRow key={key} className={classes.tableRow}>
                    <TableCell>
                      {t('DATETIME_SHORT', {
                        val: new Date(`${admissionHistory.admission.createdAt}`),
                      })}
                    </TableCell>
                    <TableCell>
                      {t('DATETIME_SHORT', {
                        val: new Date(`${admissionHistory.discharge?.createdAt}`),
                      })}
                    </TableCell>
                    <TableCell>
                      {admissionHistory.discharge?.createdBy ? (
                        <UserName
                          user={admissionHistory.discharge?.createdBy}
                          userActingOrganization={admissionHistory.discharge?.organization}
                        />
                      ) : (
                        'Unknown'
                      )}
                    </TableCell>
                    <TableCell className={classes.buttonCol}>
                      <PatientDischargeDownloadButton
                        patientId={patient.id}
                        startDate={new Date(admissionHistory.admission.createdAt)}
                        // We aren't including the current admission, so we can safely assume that the discharge date is set
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        endDate={new Date(admissionHistory.discharge!.createdAt)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                    No ward discharges for this patient
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </PageContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  buttonCol: {
    textAlign: 'right',
  },
  tableRow: {
    borderTop: '1px solid',
    paddingTop: theme.spacing(1),
  },
  infoIcon: {
    color: '#009ac9',
    verticalAlign: 'middle',
    marginRight: 3,
  },
}));
