import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    '&:clickable': {
      backgroundColor: theme.palette.warning.light,
    },
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    },
    '&:focus': {
      backgroundColor: theme.palette.warning.light,
    },
  },
  error: {
    backgroundColor: theme.palette.error.light,
    '&:clickable': {
      backgroundColor: theme.palette.error.light,
    },
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
    '&:focus': {
      backgroundColor: theme.palette.error.light,
    },
    fontWeight: 400,
  },
  success: {
    backgroundColor: theme.palette.success.light,
    '&:clickable': {
      backgroundColor: theme.palette.success.light,
    },
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
    '&:focus': {
      backgroundColor: theme.palette.success.light,
    },
  },
}));

const ChipWithTooltip = (props) => {
  const classes = useStyles();
  return (
    <Tooltip title={props.tooltip}>
      <Chip
        className={clsx(classes.chip, classes[props.type])}
        icon={props.icon}
        label={props.label}
        onClick={props.onClick}
        variant={props.variant}
      />
    </Tooltip>
  );
};

export default ChipWithTooltip;
