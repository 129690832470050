import { useRef, useEffect } from 'react';

/**
 * Hook to call a function at a regular interval.
 *
 * This is useful for components that want to perform an action at a regular interval,
 * such as polling for updates.
 *
 * @param callback function to be called at each interval
 * @param delay time in milliseconds to wait between each call (null to stop the interval)
 */
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => {
      savedCallback.current();
    }, delay);

    return () => {
      clearInterval(id);
    };
  }, [delay]);
}
