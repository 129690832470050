import React, { useMemo } from 'react';
import { gql } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Typography } from '@mui/material';
import { toast } from 'sonner';
import {
  useGetPacsanaDailyMetricsQuery,
  ActivityMonitoringMetricFragment,
  PacsanaScores,
  useGetPacsanaDailyMetricsMetadataQuery,
} from '@/generated/graphql';
import { isDefined } from '@/helpers/isDefined';
import _ from 'lodash';
import { addDays, format, subDays, startOfDay, isSameDay, min } from 'date-fns';
import { COLOR_BANDS } from '@/styles/NEWSColors';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { WithoutTypeNames } from '@/AuthorizedApolloClientProvider';

export const GET_PACSANA_DAILY_METRICS = gql`
  fragment ActivityMonitoringMetric on PacsanaDailyMetrics {
    id
    activityDate
    exerciseMinutes
    activeMinutes
    gaitSpeed
    scores {
      gaitSpeedScore
    }
  }

  query GetPacsanaDailyMetrics($patientId: ID!, $startDate: Date, $endDate: Date) {
    pacsanaDailyMetricsForPatient(patientId: $patientId, startDate: $startDate, endDate: $endDate) {
      ...ActivityMonitoringMetric
    }
  }
`;

export const GET_PACSANA_DAILY_METRICS_METADATA = gql`
  fragment ActivityMonitoringMetadata on PacsanaDailyMetricsMetadata {
    earliestAvailableMetrics
    latestAvailableMetrics
  }

  query GetPacsanaDailyMetricsMetadata($patientId: ID!) {
    pacsanaDailyMetricsMetadata(patientId: $patientId) {
      ...ActivityMonitoringMetadata
    }
  }
`;

// Narrow down the query result to only the metrics we want to potentially display in the table
type DisplayableMetrics = Pick<
  ActivityMonitoringMetricFragment,
  'gaitSpeed' | 'activeMinutes' | 'exerciseMinutes'
>;

const ScoreMap: Record<
  keyof DisplayableMetrics,
  keyof WithoutTypeNames<PacsanaScores> | undefined
> = {
  gaitSpeed: 'gaitSpeedScore',
  activeMinutes: undefined,
  exerciseMinutes: undefined,
};

const TableHeadings: Record<keyof DisplayableMetrics, string> = {
  gaitSpeed: 'Gait Speed (steps/min)',
  activeMinutes: 'Active Minutes',
  exerciseMinutes: 'Exercise Minutes',
};

// Hardcoded list of metrics to display in the table - we can add to this if we want to display more metrics
const MetricsToDisplay: (keyof DisplayableMetrics)[] = [
  'gaitSpeed',
  'activeMinutes',
  'exerciseMinutes',
];

const NumberOfDaysToDisplay = 10;

/**
 * - Filters out metrics that don't have any of the metrics we want to display
 * - Sorts metrics by activityDate date
 * - Pads the metrics with empty data if there are less than 10 days of metrics to display
 */
const prepareMetricsForDisplay = (
  metrics: ActivityMonitoringMetricFragment[],
  metricsToDisplay: (keyof DisplayableMetrics)[],
  displayMetricsTo: Date,
) => {
  const filteredMetrics = metrics.filter((metric) =>
    metricsToDisplay.some((metricToDisplay) => isDefined(metric[metricToDisplay])),
  );

  const days = _.range(0, NumberOfDaysToDisplay, 1)
    .map((day) => subDays(displayMetricsTo, day))
    .map((date) => {
      // We're assuming there is only one daily metric per day
      const metricsForDate = filteredMetrics.find((metric) =>
        isSameDay(date, startOfDay(new Date(metric.activityDate))),
      );

      return (
        metricsForDate ??
        ({
          id: _.uniqueId(),
          activityDate: date.toISOString(),
        } as ActivityMonitoringMetricFragment)
      );
    });

  return _.sortBy(days, ['activityDate'], 'DESC');
};

interface ActivityMonitoringSummaryTableProps {
  patientId: string;
}

export function ActivityMonitoringSummaryTable({ patientId }: ActivityMonitoringSummaryTableProps) {
  const classes = useStyles();

  const [displayMetricsTo, setDisplayMetricsTo] = React.useState<Date>();

  const { data: metadata, loading: metadataLoading } = useGetPacsanaDailyMetricsMetadataQuery({
    variables: {
      patientId,
    },
    onCompleted: (data) => {
      if (isDefined(displayMetricsTo)) {
        return;
      }

      const latestMetrics = data?.pacsanaDailyMetricsMetadata?.latestAvailableMetrics;

      if (isDefined(latestMetrics)) {
        setDisplayMetricsTo(new Date(latestMetrics));
      }
    },
    onError: () => toast.error('An error occurred when fetching continuous monitoring metadata'),
  });

  const { data: metricsData } = useGetPacsanaDailyMetricsQuery({
    skip: metadataLoading,
    variables: {
      patientId,
      startDate: displayMetricsTo
        ? startOfDay(subDays(displayMetricsTo, NumberOfDaysToDisplay)).toISOString()
        : undefined,
      endDate: displayMetricsTo
        ? displayMetricsTo.toISOString()
        : metadata?.pacsanaDailyMetricsMetadata?.latestAvailableMetrics,
    },
    onError: () => toast.error('An error occurred when fetching continuous monitoring data'),
  });

  const latestAvailableMetrics = useMemo(() => {
    return metadata?.pacsanaDailyMetricsMetadata?.latestAvailableMetrics
      ? new Date(metadata.pacsanaDailyMetricsMetadata.latestAvailableMetrics)
      : undefined;
  }, [metadata]);

  const earliestAvailableMetrics = useMemo(() => {
    return metadata?.pacsanaDailyMetricsMetadata?.earliestAvailableMetrics
      ? new Date(metadata.pacsanaDailyMetricsMetadata.earliestAvailableMetrics)
      : undefined;
  }, [metadata]);

  const previousPage = () => {
    if (!isDefined(displayMetricsTo)) {
      return;
    }

    const to = subDays(displayMetricsTo, NumberOfDaysToDisplay);

    setDisplayMetricsTo(to);
  };

  const nextPage = () => {
    if (!isDefined(latestAvailableMetrics) || !isDefined(displayMetricsTo)) {
      return;
    }

    const to = min([addDays(displayMetricsTo, NumberOfDaysToDisplay), latestAvailableMetrics]);

    setDisplayMetricsTo(to);
  };

  const previousPageEnabled = useMemo(() => {
    if (!isDefined(earliestAvailableMetrics) || !isDefined(displayMetricsTo)) {
      return false;
    }

    return earliestAvailableMetrics < subDays(displayMetricsTo, NumberOfDaysToDisplay);
  }, [earliestAvailableMetrics, displayMetricsTo]);

  const nextPageEnabled = useMemo(() => {
    if (!isDefined(latestAvailableMetrics) || !isDefined(displayMetricsTo)) {
      return false;
    }

    return latestAvailableMetrics > displayMetricsTo;
  }, [latestAvailableMetrics, displayMetricsTo]);

  const metrics = useMemo(() => {
    if (!isDefined(displayMetricsTo)) {
      return _.range(0, NumberOfDaysToDisplay, 1).map(
        (__) =>
          ({
            id: _.uniqueId(),
          } as ActivityMonitoringMetricFragment),
      );
    }

    const dailyMetrics = metricsData ? metricsData.pacsanaDailyMetricsForPatient : [];

    return prepareMetricsForDisplay(dailyMetrics, MetricsToDisplay, displayMetricsTo);
  }, [metricsData, displayMetricsTo]);

  return (
    <div className={classes.main}>
      <Typography variant="h6">Patient Activity Monitoring</Typography>
      <table data-testid="patient-activity-monitoring-table" className={classes.table}>
        <tbody>
          <tr>
            <th>Date</th>
            {metrics.map((metric) => (
              <td key={`date-${metric.id}}`}>
                {metric.activityDate && format(new Date(metric.activityDate), 'MMM dd')}
              </td>
            ))}
          </tr>
          {MetricsToDisplay.map((metricToDisplay) => (
            <tr key={`row-${metricToDisplay}`}>
              <th key={`heading-${metricToDisplay}`}>{TableHeadings[metricToDisplay]}</th>

              {metrics.map((metric) => (
                <td
                  key={`${metricToDisplay}-${metric.id}`}
                  style={newsColorStyle(metric, metricToDisplay)}>
                  {metric[metricToDisplay]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={classes.pagination}>
        <IconButton
          aria-label="previous page"
          onClick={previousPage}
          disabled={!previousPageEnabled}>
          <ChevronLeft />
        </IconButton>
        <IconButton aria-label="next page" onClick={nextPage} disabled={!nextPageEnabled}>
          <ChevronRight />
        </IconButton>
      </div>
    </div>
  );
}

function newsColorStyle(
  metricsFragment: ActivityMonitoringMetricFragment,
  metricToDisplay: keyof DisplayableMetrics,
) {
  const scoreToUse = ScoreMap[metricToDisplay];

  if (!isDefined(scoreToUse)) {
    return { backgroundColor: 'white' };
  }

  const ewsScore = metricsFragment.scores?.[scoreToUse];

  return {
    backgroundColor: isDefined(ewsScore) ? COLOR_BANDS[ewsScore] : 'white',
  };
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '12px',
    borderCollapse: 'collapse',
    '& th': {
      fontSize: '12px',
      padding: theme.spacing(1),
      border: 'none',
      textAlign: 'right',
      paddingRight: theme.spacing(2),
      width: theme.spacing(15),
    },
    '& tr': {
      '&:nth-child(1)': {
        whiteSpace: 'nowrap',
        '& td': {
          '&:nth-child(n+2)': {
            '&:nth-last-child(n+1)': {
              border: 'none',
            },
          },
        },
        '& th': {
          '&:nth-child(1)': {
            minWidth: theme.spacing(2),
            maxWidth: theme.spacing(2),
            borderRight: '3px solid black',
          },
        },
      },
      '&:nth-child(2)': {
        borderTop: '3px solid black',
        '& th': {
          '&:nth-child(1)': {
            borderRight: '3px solid black',
          },
        },
      },
      '&:nth-child(9)': {
        borderTop: '3px solid black',
        '& th': {
          '&:nth-child(1)': {
            borderRight: '3px solid black',
          },
        },
      },
      '&:nth-child(10)': {
        borderTop: '3px solid black',
        '& th': {
          '&:nth-child(1)': {
            borderRight: '3px solid black',
          },
        },
      },
      '&:nth-child(11)': {
        '& th': {
          '&:nth-child(1)': {
            borderRight: '3px solid black',
          },
        },
      },
      '&:nth-child(n+2)': {
        '&:nth-child(-n+8)': {
          '& td': {
            border: '1px dotted',
          },
        },
      },
      '&:nth-child(n+3)': {
        '&:nth-child(-n+8)': {
          '& th': {
            '&:nth-child(1)': {
              borderRight: '3px solid black',
            },
          },
        },
      },
    },
    '& td': {
      minWidth: theme.spacing(8),
      maxWidth: theme.spacing(8),
      border: '1px solid #ddd',
      padding: theme.spacing(1),
    },
  },
  spo2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& sub': {
      lineHeight: 2,
    },
  },
  infoIcon: {
    marginRight: theme.spacing(0.5),
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    '& p': {
      padding: 12, // Match sizing of icon buttons
    },
  },
}));
