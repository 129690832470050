import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AssignPracticeAlert = ({ open, onClose, onProceed }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Assign {t('GP Practice')} To Patients?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography gutterBottom>
            By assigning this {t('GP Practice')} to a patient, you will be providing their
            registered staff the ability to access the patient&apos;s check-up records.
          </Typography>
          <Typography>
            The {t('GP Practice')} will only have access to the patients you assign to them and you
            can change this at any point via this same process.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={onProceed}
          color="primary"
          variant="contained"
          autoFocus
          className="e2e__gpassignconfirmsubmit">
          I Understand
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignPracticeAlert;
