import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useFormik } from 'formik';
import { gql } from '@apollo/client';
import _ from 'lodash';
import * as Yup from 'yup';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { toast } from 'sonner';

import {
  EditWardMutation,
  EditWardMutationVariables,
  ManageWardItemFragment,
  useEditWardMutation,
} from '@/generated/graphql';

import { getMutationErrors } from '@/AuthorizedApolloClientProvider';

interface EditWardModalProps extends DialogProps {
  ward: ManageWardItemFragment | undefined;
  onCancel: () => void;
  onUpdated: (values: EditWardMutation) => void;
}

export const EDIT_WARD = gql`
  mutation EditWard($id: ID!, $name: String!) {
    updateWard(id: $id, name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export interface EditWardFormValues {
  name: string;
}

export default function EditWardModal({ open, onCancel, onUpdated, ward }: EditWardModalProps) {
  return (
    <Dialog open={open} aria-labelledby="edit-ward-modal-dialog__title" fullWidth>
      {ward && <EditWardForm onUpdated={onUpdated} onCancel={onCancel} ward={ward} />}
    </Dialog>
  );
}

const schema = Yup.object<EditWardFormValues>().shape({
  name: Yup.string().required('Ward name is required'),
});

interface UpdateWardFormProps {
  ward: ManageWardItemFragment;
  onUpdated: (values: EditWardMutation) => void;
  onCancel: () => void;
}

function EditWardForm({ onUpdated, onCancel, ward }: UpdateWardFormProps) {
  const [updateWardMutation, { loading: isSubmitting, error: createWardError }] =
    useEditWardMutation({
      variables: {
        id: ward.id,
        name: ward.name,
      },
      onCompleted: (data) => {
        onUpdated(data);
      },
      onError: () => toast.error('An error occurred when updating the ward'),
    });

  const handleSubmit = (values: EditWardFormValues) => {
    updateWardMutation({
      variables: {
        name: values.name.trim(),
      } as EditWardMutationVariables,
    });
  };

  const { argErrors } = getMutationErrors(createWardError);

  const formik = useFormik<EditWardFormValues>({
    initialValues: {
      name: ward.name,
    },
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const muiFormikGetFieldProps = (name: keyof EditWardFormValues): TextFieldProps => {
    return {
      name,
      value: _.get(formik.values, name),
      onChange: formik.handleChange,
      error: _.has(formik.errors, name) || _.has(argErrors?.ward, name),
      helperText: _.get(formik.errors, name) || _.get(argErrors?.ward, name),
    };
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogTitle id="edit-ward-modal-dialog__title">Edit {ward.name}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name"
                label="Ward Name"
                placeholder='e.g. "Respiratory Risk Ward 1"'
                variant="standard"
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                autoFocus
                {...muiFormikGetFieldProps('name')}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSubmitting || !formik.dirty}>
          Update Ward
        </Button>
      </DialogActions>
    </form>
  );
}

interface UseEditWardModalProps {
  onUpdated: (values: EditWardMutation) => void;
}

export const useEditWardModal = ({ onUpdated }: UseEditWardModalProps) => {
  const { showModal } = useModal();

  return {
    showEditWardModal: (ward: ManageWardItemFragment) => {
      const modal: ShowFnOutput<EditWardModalProps> = showModal(
        EditWardModal,
        {
          ward: ward,
          onUpdated: (ward) => {
            onUpdated(ward);
            modal.hide();
          },
          onCancel: () => modal.hide(),
        },
        { destroyOnClose: true },
      );
      return modal;
    },
  };
};
