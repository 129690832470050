import React from 'react';
import { IconButton, IconButtonProps } from '@mui/material';

const IconButtonNoPropagation = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ onClick, ...rest }: IconButtonProps, ref) => {
    return (
      <IconButton
        // Reference forwarding to avoid MUI warnings: https://mui.com/material-ui/guides/composition/#caveat-with-refs
        ref={ref}
        onClick={(event) => {
          onClick?.(event);
          event.stopPropagation();
        }}
        {...rest}
        size="large"
      />
    );
  },
);

IconButtonNoPropagation.displayName = 'IconButtonNoPropagation';

export default IconButtonNoPropagation;
