import React, { forwardRef } from 'react';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  LocalHospital,
  SvgIconComponent,
} from '@mui/icons-material';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import { Paper, SvgIconProps } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const makeTableIcon = (AnIcon: SvgIconComponent) =>
  // eslint-disable-next-line react/display-name
  forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => <AnIcon {...props} ref={ref} />);

const tableIcons = {
  Add: makeTableIcon(AddBox),
  Check: makeTableIcon(Check),
  Clear: makeTableIcon(Clear),
  Delete: makeTableIcon(DeleteOutline),
  DetailPanel: makeTableIcon(ChevronRight),
  Edit: makeTableIcon(Edit),
  Export: makeTableIcon(SaveAlt),
  // eslint-disable-next-line react/display-name
  Filter: forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <FilterList fontSize="small" {...props} ref={ref} />
  )),
  FirstPage: makeTableIcon(FirstPage),
  LastPage: makeTableIcon(LastPage),
  NextPage: makeTableIcon(ChevronRight),
  PreviousPage: makeTableIcon(ChevronLeft),
  ResetSearch: makeTableIcon(Clear),
  Search: makeTableIcon(Search),
  SortArrow: makeTableIcon(ArrowDownward),
  ThirdStateCheck: makeTableIcon(Remove),
  ViewColumn: makeTableIcon(ViewColumn),
  // Extra icons we use in customisation on some pages
  LocalHospital: makeTableIcon(LocalHospital),
};

// Poor typing in @material-table/core
// eslint-disable-next-line @typescript-eslint/ban-types
function MaterialTableWithIcons<TRowData extends object>(props: MaterialTableProps<TRowData>) {
  // Disable by default the @material-table/core feature "draggable", it's erratic and buggy
  const classes = useStyles();
  const newProps = { ...props };
  newProps.options = { draggable: false, ...props.options };
  return (
    <MaterialTable
      icons={tableIcons}
      {...newProps}
      components={{
        Container: (props) => <Paper className={classes.tablePaper} {...props} />,
        ...props.components,
      }}
    />
  );
}

export default MaterialTableWithIcons;

export const MaterialTableWithIconsHeaderWithId = (id: string, text: string) => (
  <span id={id}>{text}</span>
);

const useStyles = makeStyles((theme) => ({
  tablePaper: {
    padding: theme.spacing(0, 2),
    borderRadius: theme.shape.borderRadius * 3,
    '& .MuiTableFooter-root .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '& .MuiToolbar-root': {
      minHeight: 0,
      padding: theme.spacing(2, 0),
    },
  },
}));
