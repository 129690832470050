import React from 'react';

import { useConfirm } from 'material-ui-confirm';
import { gql } from '@apollo/client';
import {
  AdminCheckupTypeItemFragmentInternal,
  useDeleteCheckupTypeMutationInternal,
} from '@/generated/graphql-internal';
import { Alert } from '@mui/material';

export const DELETE_CHECKUP_TYPE_MUTATION = gql`
  mutation DeleteCheckupType($checkupTypeId: ID!) {
    deleteCheckupType(checkupTypeId: $checkupTypeId)
  }
`;

interface UseDeleteCheckupTypeProps {
  onDeleted: () => void;
}

interface UseDeleteCheckupTypeOptions {
  checkupType: AdminCheckupTypeItemFragmentInternal;
}

export function useDeleteCheckupTypeModal({ onDeleted }: UseDeleteCheckupTypeProps) {
  const [deleteCheckupTypeMutation] = useDeleteCheckupTypeMutationInternal({
    onError: () => undefined,
    onCompleted: () => {
      onDeleted();
    },
  });

  const confirm = useConfirm();

  return {
    showDeleteCheckupTypeModal: async ({ checkupType }: UseDeleteCheckupTypeOptions) => {
      try {
        await confirm({
          title: `Confirm "${checkupType.name}" deletion`,
          content: (
            <Alert severity="warning">
              When a check-up type is deleted, checkups of that type may no longer be started, but
              those already started and submitted will work as expected.
            </Alert>
          ),
          confirmationButtonProps: {
            variant: 'contained',
            color: 'error',
          },
          confirmationText: 'Delete',
        });
        await deleteCheckupTypeMutation({
          variables: {
            checkupTypeId: checkupType.id,
          },
        });
      } catch {
        //
      }
    },
  };
}
