import React, { useRef, useState } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconBlackFactory from './images/CE/Icon_Black_Factory.svg';
import IconInstructions from './images/CE/Icon_Instructions.svg';
import '@/styles/elements/footer.scss';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import plethAILabel from '@/images/CE/PlethAI-label.png';

type PanelName = 'intendedPurposes' | 'contraindications' | 'warnings' | 'plethAICertification';

function VersionInformationModal(props: DialogProps) {
  const [expanded, setExpanded] = useState<PanelName>();
  const togglePanel = (panel: PanelName) => setExpanded(expanded === panel ? undefined : panel);

  const useStyles = makeStyles((theme) => ({
    container: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(1),
      letterSpacing: 1,
    },
    version: {
      fontWeight: 'bold',
      letterSpacing: 1,
      margin: theme.spacing(1, 0, 0, 0),
    },
    iconRows: {
      margin: theme.spacing(1, 0, 1, 0),
    },
    expansionPanels: {
      margin: theme.spacing(1, 0, 1, 0),
    },
    icon: {
      width: theme.spacing(7),
      height: theme.spacing(5),
      margin: theme.spacing(0, 2, 0, 0),
    },
    limitationBlockTitle: {
      fontWeight: 'bold',
      fontSize: 14,
      letterSpacing: 1,
    },
    certificationImage: {
      width: '75%',
      margin: '0 auto',
      display: 'block',
    },
  }));

  const classes = useStyles();

  return (
    <Dialog {...props}>
      <Grid container justifyContent="center">
        <Grid item xs={11} container className={classes.container} direction="row">
          <Grid item xs={12} container direction="row">
            <Grid item container direction="row">
              <Typography variant="h5" className={classes.title}>
                FeebrisOS
              </Typography>
            </Grid>
            <Grid item xs={12} container>
              <Typography variant="h6" className={classes.version}>
                Version: {process.env.REACT_APP_VERSION}{' '}
                {`(${process.env.REACT_APP_GIT_BRANCH}-${process.env.REACT_APP_GIT_HASH})`}
              </Typography>
            </Grid>
            <Grid item xs={12} container className={classes.iconRows}>
              <img className={classes.icon} src={IconBlackFactory} alt="black factory symbol" />
              <Typography>
                Feebris Ltd
                <br />
                Ground Floor, Cromwell House
                <br />
                15 Andover Road
                <br />
                Winchester
                <br />
                SO23 7BT
                <br />
                United Kingdom
              </Typography>
            </Grid>
            <Grid item xs={12} container className={classes.iconRows}>
              <img className={classes.icon} src={IconInstructions} alt="instructions symbol" />
              <Typography>
                <a
                  href="https://intercom-help.eu/training-guides/en/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Feebris Help and Training Guides
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} container className={classes.expansionPanels}>
              <Accordion
                expanded={expanded === 'intendedPurposes'}
                onChange={() => togglePanel('intendedPurposes')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.limitationBlockTitle}>Intended Purpose</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    FeebrisOS web application is software intended (in situations where intervention
                    is normally not expected to be time critical in order to avoid death, long-term
                    disability or other serious deterioration of health) to enable remote clinical
                    review of captured data and insights to support patient triage, diagnosis and
                    treatment by a qualified medical practitioner.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'contraindications'}
                onChange={() => togglePanel('contraindications')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.limitationBlockTitle}>
                    Contraindications
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography gutterBottom variant="body2">
                    FeebrisOS is not intended to be used:
                    <ul>
                      <li>
                        in circumstances where an accurate and/or timely diagnosis or treatment
                        action is vital to avoid death, long-term disability or other serious
                        deterioration of health;
                      </li>
                      <li>on patients under the age 16;</li>
                      <li>on pregnant women;</li>
                      <li>
                        with sensors that are not certified medical devices against the MDD/MDR or
                        FDA;
                      </li>
                      <li>for confirmation / verification of death;</li>
                      <li>by unqualified lay persons.</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'warnings'}
                onChange={() => togglePanel('warnings')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.limitationBlockTitle}>Warnings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    Read the following warnings carefully before using the software:
                    <ul>
                      <li>
                        The result may be inaccurate if inputs are incorrect. Please ensure that
                        manual inputs are carefully entered, and that any connected medical sensors
                        are used according to their instructions for use.
                      </li>
                      <li>
                        Completing a check-up against the wrong patient could lead to incorrect
                        results and incorrect diagnosis or treatment decisions down the road.
                      </li>
                      <li>
                        The software is not intended to diagnose or treat any health problem or
                        disease. The results are for reference only.
                      </li>
                      <li>
                        The patient may be at risk of health problems or diseases that are not
                        covered by FeebrisOS.
                      </li>
                      <li>Please consult a doctor for any diagnosis or treatment decisions.</li>
                      <li>Update the software whenever an update is available.</li>
                      <li>Do not leave the software logged in and unattended.</li>
                      <li>
                        If photos taken are unclear or ambiguous (for example, if the body part
                        could be misinterpreted) this could lead to incorrect results and incorrect
                        diagnosis or treatment decisions down the road.
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'plethAICertification'}
                onChange={() => togglePanel('plethAICertification')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.limitationBlockTitle}>PlethAI</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="h4" variant="h5" gutterBottom>
                    Intended Purpose
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    PlethAI app analyses photoplethysmogram (PPG) signals from approved pulse
                    oximeters to provide a numerical reading of Breathing Rate (BR) and Pulse Rate
                    (PR) and to identify episodes of irregular heart rhythms suggestive of atrial
                    fibrillation (AFib).
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    PlethAI is intended to be installed into third-party healthcare mobile
                    applications which can input a PPG signal generated by a medically certified
                    pulse oximeter, and can display the output of PlethAI.
                  </Typography>
                  <Typography component="h4" variant="h5" gutterBottom marginTop={2}>
                    Device Description
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    PlethAI is a software library that extracts three features from a
                    photoplethysmography (PPG) signal, generated by a certified pulse oximeter. The
                    three features are:
                    <ol>
                      <li>
                        <strong>Breathing Rate Calculation:</strong> predicts breathing rate, in
                        breaths per minute, of a patient between 10 bpm and 35 bpm
                      </li>
                      <li>
                        <strong>Heart rhythm Detection:</strong> aids in the identification of
                        regular rhythm and irregular rhythms suggestive of atrial fibrillation.
                      </li>
                      <li>
                        <strong>Pulse rate Calculation:</strong> predicts pulse rate, in beats per
                        minute, of a patient between 30 bpm and 180 bpm
                      </li>
                    </ol>
                  </Typography>
                  <Typography component="h4" variant="h5" gutterBottom marginTop={2}>
                    Instructions for Use
                  </Typography>
                  <Link href="https://www.feebris.com/plethai-ifu" target="_blank" rel="noreferrer">
                    Access the full PlethAI Instructions for Use here
                  </Link>
                  <Typography component="h4" variant="h5" gutterBottom marginTop={2}>
                    Certification Label
                  </Typography>
                  <a href={plethAILabel} target="_blank" rel="noreferrer">
                    <img
                      src={plethAILabel}
                      alt="PlethAI Certification"
                      className={classes.certificationImage}
                    />
                  </a>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export const useVersionInformationModal = () => {
  const { showModal } = useModal();

  const modal = useRef<ShowFnOutput<DialogProps> | null>(null);

  return {
    showVersionInformationModal: () => {
      modal.current = showModal(
        VersionInformationModal,
        { onClose: () => modal.current?.hide() },
        { destroyOnClose: true },
      );

      return modal;
    },
  };
};
