import React from 'react';

import { withErrorBoundary } from '@/components/ErrorBoundary';

import Header from './components/Header';

interface TermsLayoutProps {
  children: React.ReactNode;
}

const TermsLayout = ({ children }: TermsLayoutProps) => {
  return (
    <div>
      <Header />
      {children}
    </div>
  );
};

export default withErrorBoundary(TermsLayout);
