import React from 'react';

import { ErrorBoundary } from '@/components/ErrorBoundary';
import { Header } from './components/Header';
import { PatientList } from './components/PatientList';
import { VirtualWardContextProvider } from './VirtualWardContext';

export default function VirtualWard() {
  return (
    <VirtualWardContextProvider>
      <Header />
      <ErrorBoundary>
        <PatientList />
      </ErrorBoundary>
    </VirtualWardContextProvider>
  );
}
