import React from 'react';

import { Box, MenuItem, Select } from '@mui/material';
import { Pagination } from '@mui/material';

import { useVirtualWardContext } from '../VirtualWardContext';

export function PatientListPagination() {
  const { totalPatients, currentPage, setCurrentPage, pageSize, setPageSize } =
    useVirtualWardContext();

  return (
    <Box display="flex" justifyContent="end">
      <Select
        value={pageSize}
        disableUnderline
        displayEmpty
        style={{ fontSize: '14px' }}
        onChange={({ target: { value } }) => setPageSize(value as number)}>
        <MenuItem value={5}>5 Rows</MenuItem>
        <MenuItem value={10}>10 Rows</MenuItem>
        <MenuItem value={25}>25 Rows</MenuItem>
      </Select>
      <Pagination
        count={Math.ceil(totalPatients / pageSize)}
        page={currentPage}
        onChange={(_, page) => setCurrentPage(page)}
        showFirstButton
        showLastButton
      />
    </Box>
  );
}
