/**
 * Test for null or undefined and narrows the type to the non-null type.
 *
 * This is useful for filtering out null or undefined values from arrays, narrowing the
 * resulting type to the non-null elements, which doesn't work correctly with something
 * like `array.filter(Boolean)`.
 *
 * @example
 * const array: (string | undefined | null)[] = ['a', undefined, 'b', null, 'c'];
 * const isDefinedFilter = array.filter(isDefined); // inferred type is (string)[]
 * const booleanFilter = array.filter(Boolean); // inferred type is still (string | undefined | null)[]
 */
export const isDefined = <T>(value: T | undefined | null): value is T =>
  value !== undefined && value !== null;
