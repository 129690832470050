import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import TuneIcon from '@mui/icons-material/Tune';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { useMePermission } from '@/hooks/useAuth';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface EditSaveThresholdsSelectorProps {
  isEditModeSelected: boolean;
  handleOpenWarningDialog: () => void;
  cancelEditMode: () => void;
  saveThresholdsSelection: () => void;
}

const EditSaveThresholdsSelector = ({
  isEditModeSelected,
  handleOpenWarningDialog,
  cancelEditMode,
  saveThresholdsSelection,
}: EditSaveThresholdsSelectorProps) => {
  const classes = useStyles();
  const canCreateEwsThresholds = useMePermission('create_ews_thresholds');

  const editButton = canCreateEwsThresholds ? (
    <Button
      size="small"
      variant="outlined"
      color="primary"
      startIcon={<TuneIcon />}
      onClick={handleOpenWarningDialog}>
      Edit EWS
    </Button>
  ) : null;

  const cancelAndSaveButtons = (
    <>
      <Button size="small" variant="text" startIcon={<CancelIcon />} onClick={cancelEditMode}>
        Cancel
      </Button>
      <Button
        size="small"
        variant="contained"
        color="primary"
        startIcon={<CheckIcon />}
        onClick={saveThresholdsSelection}>
        Save
      </Button>
    </>
  );

  const buttons = isEditModeSelected ? cancelAndSaveButtons : editButton;
  return <div className={classes.buttonContainer}>{buttons}</div>;
};

export default EditSaveThresholdsSelector;
