import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  FormControlLabel,
  Switch,
} from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { MTableEditField, MTableToolbar } from '@material-table/core';
import { useParams } from 'react-router-dom';

import MaterialTableWithIcons from '@/components/MaterialTableWithIcons';
import Loading from '@/components/Loading';
import ResetPasswordModal from '@/components/ResetPasswordModal';
import api from '@/controllers/Api';
import auth from '@/controllers/Auth';
import { PageTitle } from '@/components/PageTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  spacer: {
    flexGrow: 1,
  },
  tableHeaderBar: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  showDeletedSwitch: {
    margin: theme.spacing(0, 2),
  },
}));

export default function AdminUsers(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState(undefined);
  const [createFirebaseUserAlertOpen, setCreateFirebaseUserAlertOpen] = React.useState(false);
  const [selectedUserId, setSelectedUserId] = React.useState(null);
  const [confirmRoleOpen, setConfirmRoleOpen] = React.useState(false);
  const [confirmRole, setConfirmRole] = React.useState(null);
  const [showDeleted, setShowDeleted] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [user, setUser] = useState({});

  const { organizationId } = useParams();

  const refreshData = async () => {
    setData(undefined);
    const response = await api.getUserOrganizationsInternal({ organizationId, showDeleted });
    const flattened = _.map(response.data.userOrganizations, (link) => ({
      ...link.user,
      roles: link.roles,
      lastLoggedInAt: link.lastLoggedInAt,
    }));
    setData(flattened);
  };

  const createFirebaseUser = async () => {
    try {
      await api.createFirebaseUser({ userId: selectedUserId });
      await api.sendWelcomeEmailInternal({
        userId: selectedUserId,
        organizationId: organizationId,
      });
      await refreshData();
    } catch (err) {
      Sentry.captureException(err);
      alert(
        'Error creating Firebase User. Does another user already exist in Firebase with ' +
          'this email address?\n\nSee console for details.',
      );
    }
  };

  useEffect(() => {
    async function inner() {
      try {
        await refreshData();
      } catch (err) {
        //
      }
    }
    inner();
  }, [showDeleted]); // eslint-disable-line react-hooks/exhaustive-deps

  const confirmRoleChange = (email, newValue, oldValue) => {
    return new Promise((resolve, reject) => {
      setConfirmRole({
        resolve,
        reject,
        email,
        newValue,
        oldValue,
      });
      setConfirmRoleOpen(true);
    });
  };

  const resetPasswordClick = (newActiveUser) => {
    setUser(newActiveUser);
    setResetPasswordModalOpen(true);
  };

  const editableWhenEmpty = (field) => (rowData) => rowData[field] || '\u2003';
  const columns = [
    {
      field: 'deletedAt',
      title: 'Deleted?',
      type: 'date',
      editable: 'never',
      hidden: !showDeleted,
      render: ({ deletedAt }) => {
        return !deletedAt ? '' : t('DATE_SHORT', { val: new Date(deletedAt) });
      },
    },
    { field: 'id', title: 'ID', defaultSort: 'desc', editable: 'never' },
    {
      field: 'firebaseUid',
      title: 'Firebase UID',
      defaultSort: 'desc',
      editable: 'never',
      // eslint-disable-next-line react/display-name
      render: (rowData) =>
        rowData.firebaseUid ? (
          <span className="e2e__firebaseuid">{rowData.firebaseUid}</span>
        ) : (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setSelectedUserId(rowData.id);
              setCreateFirebaseUserAlertOpen(true);
            }}>
            Create Firebase User
          </Button>
        ),
    },
    { field: 'email', title: 'Email', editable: 'never' },
    { field: 'firstName', title: 'First Name', render: editableWhenEmpty('firstName') },
    { field: 'lastName', title: 'Last Name', render: editableWhenEmpty('lastName') },
    { field: 'roles', title: 'Roles' },
    {
      field: 'createdAt',
      title: 'Created At',
      type: 'date',
      hidden: true,
      editable: 'never',
      render: ({ createdAt }) => t('DATE_SHORT', { val: new Date(createdAt) }),
    },
    {
      field: 'updatedAt',
      title: 'Updated At',
      type: 'date',
      editable: 'never',
      render: ({ updatedAt }) => {
        return !updatedAt ? '' : t('DATE_SHORT', { val: new Date(updatedAt) });
      },
    },
    {
      field: 'lastLoggedInAt',
      title: 'Last Logged In At',
      type: 'date',
      editable: 'never',
      render: ({ lastLoggedInAt }) => {
        return !lastLoggedInAt ? '' : t('DATE_SHORT', { val: new Date(lastLoggedInAt) });
      },
    },
  ];

  const allRoles = auth.me('actingOrganization.roles', []);
  const systemRoles = allRoles.filter((role) => role.isSystemRole).map((role) => role.name);
  const containsSystemRoles = (roles) => _.intersection(roles, systemRoles).length > 0;
  const organizationRoles = props.organization.roles;

  return (
    <div className={classes.root}>
      <PageTitle
        title="Users"
        subtitle='Reset user passwords, update their roles or impersonate them via the infamous "Login-as"'
      />
      <CreateFirebaseUserAlert
        open={createFirebaseUserAlertOpen}
        onClose={() => {
          setSelectedUserId(null);
          setCreateFirebaseUserAlertOpen(false);
        }}
        onProceed={async () => {
          setSelectedUserId(null);
          setCreateFirebaseUserAlertOpen(false);
          await createFirebaseUser();
          refreshData();
        }}
      />
      <RolesChangeAlert
        open={confirmRoleOpen}
        onClose={() => {
          confirmRole.reject();
          setConfirmRoleOpen(false);
          setConfirmRole(null);
        }}
        onProceed={async () => {
          confirmRole.resolve();
          setConfirmRoleOpen(false);
          setConfirmRole(null);
        }}
        email={_.get(confirmRole, 'email')}
        newValue={_.get(confirmRole, 'newValue')}
        oldValue={_.get(confirmRole, 'oldValue')}
      />
      <ResetPasswordModal
        fetchPasswordResetUrl={() =>
          api.passwordResetUrlInternal({ organizationId: props.organization.id, userId: user.id })
        }
        open={resetPasswordModalOpen}
        onClose={() => setResetPasswordModalOpen(false)}
        user={user}
        onError={(message) => toast.error(message)}
      />
      {/* TODO: Need to add ability to edit users here */}
      <MaterialTableWithIcons
        columns={columns}
        data={data}
        title={null}
        options={{ pageSize: 10, showEmptyDataSourceMessage: data !== undefined }}
        isLoading={!data}
        actions={[
          (rowData) =>
            !rowData.isFeebroid && rowData.firebaseUid && !rowData.deletedAt
              ? {
                  tooltip: `Login as ${rowData.email}`,
                  icon: () => (
                    <Button
                      color="secondary"
                      variant="outlined"
                      size="small"
                      onClick={() => auth.loginAs(organizationId, rowData.id)}
                      className="e2e__loginasbutton"
                      style={{ width: 88 }}>
                      Login-as
                    </Button>
                  ),
                }
              : undefined,
          (rowData) =>
            !rowData.isFeebroid && rowData.firebaseUid && !rowData.deletedAt
              ? {
                  icon: () => <VpnKeyIcon className="e2e__resetuserpasswordbutton" />,
                  tooltip: 'Reset user password',
                  onClick: (event, rowData) => resetPasswordClick(rowData),
                }
              : undefined,
        ]}
        cellEditable={{
          cellStyle: {},
          onCellEditApproved: async (newValue, oldValue, rowData, columnDef) => {
            // Just abort the editing if nothing has changed, this will avoid sending API requests
            // as well as unnecessary confirmation dialogs
            if (newValue === oldValue) {
              return false;
            }
            const params = {};
            params[columnDef.field] = newValue;
            if (columnDef.field === 'roles') {
              try {
                await confirmRoleChange(rowData.email, newValue, oldValue);
              } catch (err) {
                return false;
              }
              await api.updateUserOrganizationInternal({
                userId: rowData.id,
                organizationId: props.organization.id,
                ...params,
              });
            } else {
              await api.updateUserInternal(props.organization.id, { id: rowData.id, ...params });
            }
            // HACK: Surely mutating rowData isn't the right way to update the data without
            //       an AJAX call..
            rowData[columnDef.field] = newValue;
          },
        }}
        components={{
          // eslint-disable-next-line react/display-name
          OverlayLoading: () => {
            return <Loading showLoading />;
          },
          // eslint-disable-next-line react/display-name
          EditField: (props) => {
            if (props.columnDef.field === 'roles') {
              return (
                <FormControl disabled={containsSystemRoles(props?.value)}>
                  <InputLabel id="roles-label">Roles</InputLabel>
                  <Select
                    labelId="roles-label"
                    value={props.value[0]}
                    onChange={(e) => props.onChange([e.target.value])}>
                    {organizationRoles?.map((role) => (
                      <MenuItem key={role.name} value={role.name} disabled={role.isSystemRole}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
            return <MTableEditField {...props} />;
          },
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div className={classes.showDeletedSwitch}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showDeleted}
                      onChange={() => {
                        setShowDeleted(!showDeleted);
                      }}
                      color="secondary"
                    />
                  }
                  label="Show deleted?"
                />
              </div>
            </div>
          ),
        }}
      />
    </div>
  );
}

function CreateFirebaseUserAlert({ open, onClose, onProceed }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Create Firebase User?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography gutterBottom>
            By linking this Feebris User with Firebase they will be sent an invitation to set up
            their account on the Feebris Platform and will gain access to patients of this
            organisation.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onProceed} color="primary" variant="contained" autoFocus>
          I Understand
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RolesChangeAlert({ open, onClose, onProceed, email, oldValue, newValue }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Change User Role?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography gutterBottom>
            Are you sure you want to change the role for user <strong>{email}</strong> from{' '}
            <strong>{oldValue}</strong> to <strong>{newValue}</strong>?
          </Typography>
          <Typography gutterBottom>
            Changing a user&apos;s role will affect level of access they have within the Feebris
            Platform
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onProceed} color="primary" variant="contained" autoFocus>
          I Understand
        </Button>
      </DialogActions>
    </Dialog>
  );
}
