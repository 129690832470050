import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { gql } from '@apollo/client';
import Alert from '@mui/material/Alert';
import { toast } from 'sonner';
import {
  DeletePatientViewFragment,
  useDeletePatientMutation,
  useGetContinuousMonitoringSessionQuery,
  useGetActivityMonitoringSessionQuery,
} from '@/generated/graphql';
import { useModal } from 'mui-modal-provider';
import auth from '@/controllers/Auth';
import _ from 'lodash';

export const DELETE_PATIENT_MODAL_FRAGMENT = gql`
  fragment DeletePatientView on Patient {
    id
    firstName
    lastName
    selfCare {
      organizations {
        id
      }
    }
    wards {
      id
    }
  }
`;

interface DeletePatientModalProps {
  patient: DeletePatientViewFragment | undefined;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const GET_CONTINUOUS_MONITORING_SESSION = gql`
  fragment ContinuousMonitoringSessionItem on ContinuousMonitoringSession {
    id
    organization {
      id
    }
  }

  query GetContinuousMonitoringSession($patientId: ID!) {
    continuousMonitoringSession(patientId: $patientId) {
      ...ContinuousMonitoringSessionItem
    }
  }
`;

export function DeletePatientModal({ patient, open, onClose, onSubmit }: DeletePatientModalProps) {
  const { data: continuousMonitoringSessionData } = useGetContinuousMonitoringSessionQuery({
    variables: { patientId: patient ? patient.id : '' },
    skip: !patient,
    onError: () => toast.error('An error occurred when performing pre-deletion checks'),
  });

  const { data: activityMonitoringSession } = useGetActivityMonitoringSessionQuery({
    variables: { patientId: patient ? patient.id : '' },
    skip: !patient,
    onError: () => toast.error('An error occurred when performing pre-deletion checks'),
  });

  if (!patient) return null;

  const hasContinuousMonitoringSession =
    continuousMonitoringSessionData?.continuousMonitoringSession;

  const hasActivityMonitoringSession = activityMonitoringSession?.pacsanaSession;

  const hasWardAdmissions = patient.wards.length > 0;

  const isSelfCareInActingOrg =
    patient.selfCare &&
    _.some(patient.selfCare.organizations, (org) => org.id === auth.me('actingOrganization.id'));

  const message = isSelfCareInActingOrg
    ? `This action will remove the self-care patient
      ${patient.firstName} ${patient.lastName} from the Feebris platform
      and revoke their log in to the Feebris mobile app. Do you want to proceed?`
    : `This action will remove the patient ${patient.firstName} ${patient.lastName}
      from the Feebris platform. Do you want to proceed?`;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{'Attention'}</DialogTitle>
      <DialogContent>
        {hasContinuousMonitoringSession || hasActivityMonitoringSession ? (
          <>
            <Alert severity="error">
              Cannot remove the patient because they are currently under monitoring
            </Alert>
            <Box marginTop={2}>
              <Typography>
                Please first end their monitoring session before attempting to remove them from the
                platform.
              </Typography>
            </Box>
          </>
        ) : hasWardAdmissions ? (
          <>
            <Alert severity="error">
              Cannot remove the patient because they are currently admitted to a ward
            </Alert>
            <Box marginTop={2}>
              <Typography>
                Please first discharge the patient from all wards before attempting to remove them
                from the platform.
              </Typography>
            </Box>
          </>
        ) : (
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button
          onClick={() => onSubmit()}
          disabled={
            hasWardAdmissions ||
            Boolean(hasContinuousMonitoringSession) ||
            Boolean(hasActivityMonitoringSession)
          }
          color="primary"
          variant="contained"
          autoFocus
          className="e2e__removepatientconfirmsubmit">
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const DELETE_PATIENT = gql`
  mutation DeletePatient($patientId: ID!) {
    deletePatient(patientId: $patientId)
  }
`;

export const useDeletePatientModal = ({ onDelete }: { onDelete: () => void }) => {
  const { showModal } = useModal();
  const [deletePatientMutation] = useDeletePatientMutation();

  const showDeletePatientModal = useCallback(
    (patient: DeletePatientViewFragment) => {
      function hide() {
        modal.hide();
      }
      const modal = showModal(
        DeletePatientModal,
        {
          patient,
          onClose: () => hide(),
          onSubmit: async () => {
            try {
              await deletePatientMutation({
                variables: {
                  patientId: patient.id,
                },
              });
            } catch {
              toast.error('An error occurred when deleting the patient');
            } finally {
              hide();
              onDelete();
            }
          },
        },
        {
          destroyOnClose: true,
        },
      );
      return modal;
    },
    [deletePatientMutation, onDelete, showModal],
  );

  return { showDeletePatientModal };
};
