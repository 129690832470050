import React from 'react';

import { Typography, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import logo from '@/images/logo.png';

export const QuicksilvaError = () => {
  const classes = useStyles();

  return (
    <div className="login">
      <img src={logo} alt="Feebris Logo" />
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5" mb={1}>
          Patient not found
        </Typography>
        <Typography mb={2}>
          The patient you are trying to access is not registered with Feebris.
        </Typography>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    maxWidth: 520,
    padding: theme.spacing(4),
    marginTop: theme.spacing(3),
  },
}));
