import React, { useEffect, useMemo, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Switch,
  TextField,
  Typography,
  Popover,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import clsx from 'clsx';

import {
  EwsThresholdsInternal,
  useSetCarePathwayThresholdsMutationInternal,
} from '@/generated/graphql-internal';
import { gql } from '@apollo/client';
import { getMutationErrors } from '@/AuthorizedApolloClientProvider';

interface CarePathwayThresholdsProps {
  thresholds: EwsThresholdsInternal['thresholds'] | undefined;
  onThresholdsSet: () => void;
  carePathwayId: string;
}

export const SET_CAREPATHWAY_THESHOLDS = gql`
  mutation SetCarePathwayThresholds(
    $id: ID!
    $thresholds: ThresholdsInput
    $overwritePatients: Boolean!
  ) {
    setCarePathwayThresholds(
      id: $id
      thresholds: $thresholds
      overwritePatients: $overwritePatients
    ) {
      id
    }
  }
`;

const formatThresholds = (thresholds: EwsThresholdsInternal['thresholds'] | undefined) =>
  _.isObject(thresholds) ? JSON.stringify(thresholds, null, 2) : thresholds || '{}';

export function CarePathwayThresholds({
  thresholds,
  carePathwayId,
  onThresholdsSet,
}: CarePathwayThresholdsProps) {
  const classes = useStyles();

  const [thresholdsString, setThresholdsString] = useState(formatThresholds(thresholds));
  const [isEditing, setIsEditing] = useState(false);
  const [overwritePatients, setOverwritePatients] = useState(false);

  const [setThresholdsMutation, { loading: isSubmitting, error: setThresholdsError, reset }] =
    useSetCarePathwayThresholdsMutationInternal({
      onError: () => undefined,
      onCompleted: () => {
        onThresholdsSet();
        setIsEditing(false);
        setOverwritePatients(false);
      },
    });

  const isDisabled = isSubmitting || !isEditing;

  const formattedApiError = useMemo(() => {
    if (!setThresholdsError) {
      return null;
    }

    const { argErrors, networkErrors } = getMutationErrors(setThresholdsError);

    if (argErrors?.carepathway?.thresholds) {
      return argErrors.carepathway.thresholds;
    }

    if (networkErrors) {
      return (
        <ul>
          {networkErrors.map((e, i) => (
            <li key={i}>{e.message}</li>
          ))}
        </ul>
      );
    }

    return setThresholdsError.message;
  }, [setThresholdsError]);

  const thresholdFormatError = useMemo(() => {
    try {
      JSON.parse(thresholdsString);
      return null;
    } catch (e) {
      if (e instanceof SyntaxError) {
        return e.message;
      }
      return 'Invalid JSON';
    }
  }, [thresholdsString]);

  useEffect(() => {
    setThresholdsString(formatThresholds(thresholds));
  }, [thresholds]);

  const handleCancel = () => {
    setIsEditing(false);
    setThresholdsString(formatThresholds(thresholds));
    setOverwritePatients(false);
    reset();
  };

  const handleSave = () => {
    if (thresholdFormatError) {
      return;
    }

    const parsedThresholds = JSON.parse(thresholdsString);
    const newThresholds = Object.values(parsedThresholds).length ? parsedThresholds : null;
    const existingThresholds = Object.values(thresholds ?? {}).length ? thresholds : null;

    if (_.isEqual(existingThresholds, newThresholds)) {
      setIsEditing(false);
    } else {
      setThresholdsMutation({
        variables: {
          id: carePathwayId,
          thresholds: newThresholds,
          overwritePatients,
        },
      });
    }
  };

  const OverwritePatientsSwitch = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    return (
      <Box display="flex" alignItems="center" gap={3}>
        <Box
          className={classes.popoverContainer}
          flexShrink={0}
          onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
          onMouseLeave={() => setAnchorEl(null)}>
          <InfoIcon role="button" aria-label="Show overwrite details" fontSize="small" />
        </Box>
        <Popover
          className={classes.popover}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          disableRestoreFocus>
          <Box padding={2} marginRight={1} maxWidth="sm">
            <Typography variant="body2">
              When this setting is enabled, any changes you make to specific vital signs thresholds
              will be applied to all patients under this care pathway. Please note, only the
              thresholds for the vital signs you modify during this edit will be updated. All other
              thresholds will remain unchanged.
            </Typography>
          </Box>
        </Popover>
        <FormControlLabel
          label="Overwrite personal thresholds"
          control={
            <Switch
              checked={overwritePatients}
              onChange={() => setOverwritePatients((is) => !is)}
              color="primary"
            />
          }
        />
      </Box>
    );
  };

  return (
    <Paper className={classes.root}>
      <Box padding={1.5}>
        <Typography variant="h6" gutterBottom>
          Thresholds
        </Typography>
        <TextField
          multiline
          variant="outlined"
          fullWidth
          maxRows={20}
          className={clsx(classes.editor, isDisabled && classes.disabledEditor)}
          onChange={(e) => setThresholdsString(e.target.value)}
          value={thresholdsString}
          error={Boolean(thresholdFormatError) || Boolean(formattedApiError)}
          helperText={thresholdFormatError || formattedApiError}
          disabled={!isEditing}
        />
      </Box>
      <Box display="flex" padding={1.5} justifyContent="flex-end" gap={8}>
        {isEditing ? (
          <>
            <OverwritePatientsSwitch />
            <Button onClick={handleCancel}>Cancel</Button>
            <Button color="primary" variant="contained" onClick={handleSave}>
              Save
            </Button>
          </>
        ) : (
          <Button onClick={() => setIsEditing(true)}>Edit</Button>
        )}
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
  },
  editor: {
    '& textarea': {
      fontFamily: 'monospace',
      fontSize: theme.typography.pxToRem(14),
    },
  },
  disabledEditor: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.grey[100],
    },
    '& textarea': {
      color: theme.palette.grey[800],
    },
  },
  popoverContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  popover: {
    pointerEvents: 'none',
    marginTop: theme.spacing(-2),
  },
}));
