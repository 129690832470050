import React from 'react';

import { Box, Stack, Tooltip, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import Moment from 'moment';
import clsx from 'clsx';

import {
  VirtualWardPatientActivityMonitoringSessionFragment,
  VirtualWardPatientCheckupFragment,
  VirtualWardPatientContinuousMonitoringWithSessionFragment,
} from '@/generated/graphql';

import { isDefined } from '@/helpers/isDefined';
import { newsPaletteForRiskLevel } from '@/styles/NEWSColors';
import { ActivityMonitoringSessionStatus } from './ActivityMonitoringSessionStatus';
import { formatCheckupTypeName } from '../formatters';
import { ContinuousMonitoringSessionStatus } from './ContinuousMonitoringSessionStatus';
import { useTranslation } from 'react-i18next';

interface LatestCheckupInfoProps {
  checkup: Maybe<VirtualWardPatientCheckupFragment>;
  continuousMonitoringWithSession:
    | VirtualWardPatientContinuousMonitoringWithSessionFragment
    | null
    | undefined;
  activityMonitoringSession: Maybe<VirtualWardPatientActivityMonitoringSessionFragment>;
  isContinuousMonitoring: boolean;
}

export function LatestCheckupInfo({
  checkup,
  continuousMonitoringWithSession,
  activityMonitoringSession,
  isContinuousMonitoring,
}: LatestCheckupInfoProps) {
  const classes = useStyles();

  const continuousMonitoring = continuousMonitoringWithSession?.continuousMonitoring;

  return isContinuousMonitoring ? (
    <Stack direction="row" margin={1}>
      <Tooltip title={`EWS total score, coloured by risk level`}>
        <Box
          data-testid="ews-score-continuous"
          className={clsx(classes.newsContainer)}
          style={newsPaletteForRiskLevel(continuousMonitoring?.thresholdScores?.riskLevel ?? 0)}
          flexShrink={0}>
          {continuousMonitoring?.thresholdScores?.totalScore ?? '?'}
        </Box>
      </Tooltip>
      <ContinuousMonitoringInfo
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        continuousMonitoringWithSession={continuousMonitoringWithSession!}
        activityMonitoringSession={activityMonitoringSession}
      />
    </Stack>
  ) : (
    <Stack direction="row" margin={1}>
      <Tooltip title={`EWS total score, coloured by risk level`}>
        <Box
          data-testid="ews-score-intermittent"
          className={clsx(classes.newsContainer)}
          style={newsPaletteForRiskLevel(checkup?.ewsScores?.riskLevel ?? 0)}
          flexShrink={0}>
          {checkup?.ewsScores?.totalScore ?? '?'}
        </Box>
      </Tooltip>
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <CheckupInfo checkup={checkup!} activityMonitoringSession={activityMonitoringSession} />
    </Stack>
  );
}

function CheckupInfo({
  checkup,
  activityMonitoringSession,
}: {
  checkup: VirtualWardPatientCheckupFragment;
  activityMonitoringSession: Maybe<VirtualWardPatientActivityMonitoringSessionFragment>;
}) {
  const classes = useStyles();

  return (
    <Box width={175}>
      <Box display="flex" alignItems="center" gap={6}>
        <Typography className={classes.label}>{formatCheckupTypeName(checkup)}</Typography>
        {isDefined(activityMonitoringSession) && !isDefined(activityMonitoringSession.endedAt) && (
          <ActivityMonitoringSessionStatus activityMonitoringSession={activityMonitoringSession} />
        )}
      </Box>
      <Typography className={classes.checkupDate}>
        {Moment(checkup.endedAt).format('MMM Do YYYY, h:mm a')}
      </Typography>
    </Box>
  );
}

function ContinuousMonitoringInfo({
  continuousMonitoringWithSession,
  activityMonitoringSession,
}: {
  continuousMonitoringWithSession: VirtualWardPatientContinuousMonitoringWithSessionFragment;
  activityMonitoringSession: Maybe<VirtualWardPatientActivityMonitoringSessionFragment>;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { continuousMonitoringSession, continuousMonitoring } = continuousMonitoringWithSession;
  const isSessionEnded = isDefined(continuousMonitoringSession.endedAt);

  return (
    <Box width={175}>
      <Box display="flex" alignItems="center" gap={6}>
        <Typography className={classes.label}>{t('Continuous')}</Typography>
        {!isSessionEnded && (
          <ContinuousMonitoringSessionStatus
            continuousMonitoringWithSession={continuousMonitoringWithSession}
          />
        )}
        {isDefined(activityMonitoringSession) && !isDefined(activityMonitoringSession.endedAt) && (
          <ActivityMonitoringSessionStatus activityMonitoringSession={activityMonitoringSession} />
        )}
      </Box>
      <Typography className={classes.checkupDate}>
        {Moment(
          continuousMonitoring
            ? continuousMonitoring.bucketEndAt
            : continuousMonitoringSession.createdAt,
        ).format('MMM Do YYYY, h:mm a')}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  newsContainer: {
    display: 'flex',
    marginRight: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    width: 42,
    height: 42,
    borderRadius: 21,
    border: '1px solid transparent',
    color: theme.palette.grey[600],
    fontWeight: 500,
    lineHeight: 1,
    fontSize: theme.typography.pxToRem(18),
  },
  checkupDate: {
    fontSize: theme.typography.pxToRem(14),
  },
  label: {
    textTransform: 'capitalize',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
}));
