import React from 'react';

import { Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RefreshIcon from '@mui/icons-material/Refresh';
import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayArrow';

import { useStopwatch } from '@/hooks/useStopwatch';
import { useVirtualWardContext } from '../VirtualWardContext';
import { PageTitle } from '@/components/PageTitle';

const formatTime = (time: number | undefined) => {
  if (time === undefined) {
    return '-';
  }

  const seconds = Math.floor(time / 1000);
  if (seconds < 60) {
    return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  }
};

export function Header() {
  const classes = useStyles();
  const { lastRefreshed, refresh, pollingInterval, togglePolling } = useVirtualWardContext();

  const timeSinceLastUpdate = useStopwatch(lastRefreshed, 250);

  const refreshButtonLabel = pollingInterval
    ? 'Pause automatic refresh'
    : 'Start automatic refresh';

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        flexGrow={1}
        alignItems="flex-end"
        maxWidth={1440}
        width="100%"
        margin="0 auto">
        <Box flexGrow={1}>
          <PageTitle
            title="Virtual Ward Dashboard"
            subtitle="Monitor patients in your wards"
            titleMeta="Virtual Ward"
            marginBottom={0}
          />
        </Box>
        <Box flexGrow={0} display="flex" alignItems="center">
          <Box display="flex" flexDirection="column" marginRight={0.5}>
            <Typography variant="body2" color="primary" align="right">
              Updated
            </Typography>
            <Typography variant="body2">{formatTime(timeSinceLastUpdate)}</Typography>
          </Box>
          <Tooltip title="Refresh now" enterDelay={300}>
            <IconButton
              onClick={() => refresh()}
              aria-label="Refresh patient list now"
              size="large">
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={refreshButtonLabel} enterDelay={300}>
            <div className={classes.automaticRefreshContainer}>
              {timeSinceLastUpdate && pollingInterval ? (
                <CircularProgress
                  size={42}
                  className={classes.automaticRefreshProgress}
                  variant="determinate"
                  value={(timeSinceLastUpdate / pollingInterval) * 100}
                />
              ) : null}
              <IconButton onClick={togglePolling} aria-label={refreshButtonLabel} size="large">
                {pollingInterval ? <PauseIcon /> : <PlayIcon />}
              </IconButton>
            </div>
          </Tooltip>
        </Box>
      </Box>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    zIndex: theme.zIndex.appBar - 1,
    position: 'relative',
    flexDirection: 'row',
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 3),
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: 64,
    },
  },
  title: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  automaticRefreshContainer: {
    position: 'relative',
  },
  automaticRefreshProgress: {
    position: 'absolute',
    top: 3,
    left: 3,
    zIndex: 1,
    pointerEvents: 'none',
  },
}));
