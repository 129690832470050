import React, { useState } from 'react';
import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gql } from '@apollo/client';
import {
  useAddQuicksilvaIdentityMutation,
  useRemoveQuicksilvaIdentityMutation,
  useAreQuicksilvaPreApprovedMessagesSetupQuery,
  useGetExternalAuthIdentitiesQuery,
  ExternalAuthIdentityType,
  useUnlinkQuicksilvaExternalAuthIdentitiesMutation,
} from '@/generated/graphql';
import { isDefined } from '@/helpers/isDefined';
import Loading from '@/components/Loading';
import { toast } from 'sonner';
import { useConfirm } from 'material-ui-confirm';

export const ADD_QUICKSILVA_PRE_APPROVED_IDENTITY = gql`
  mutation addQuicksilvaIdentity($accessCode: String!) {
    addQuicksilvaIdentity(accessCode: $accessCode)
  }
`;

export const REMOVE_QUICKSILVA_PRE_APPROVED_IDENTITY = gql`
  mutation removeQuicksilvaIdentity {
    removeQuicksilvaIdentity
  }
`;

export const GET_QUICKSILVA_PRE_APPROVED_STATUS = gql`
  query areQuicksilvaPreApprovedMessagesSetup {
    me {
      areQuicksilvaPreApprovedMessagesSetup
    }
  }
`;

export const GET_EXTERNAL_AUTH_IDENTITIES = gql`
  query getExternalAuthIdentities {
    me {
      externalAuthIdentities {
        id
        type
      }
    }
  }
`;

export const UNLINK_QUICKSILVA_EXTERNAL_AUTH_IDENTITY = gql`
  mutation unlinkQuicksilvaExternalAuthIdentities($type: ExternalAuthIdentityType!) {
    unlinkExternalAuthIdentities(type: $type)
  }
`;

export function QuicksilvaIntegrationCard(props: BoxProps) {
  const classes = useStyles();

  return (
    <Box {...props} maxWidth="1024px" alignSelf="left">
      <Paper>
        <Box padding={3}>
          <Typography variant="h6" className={classes.heading}>
            Manage Quicksilva Links
          </Typography>
          <PreApprovedMessageSetup />
          <RemoveExternalAuthIdentities />
        </Box>
      </Paper>
    </Box>
  );
}

const RemoveExternalAuthIdentities = () => {
  const classes = useStyles();

  const {
    data,
    refetch,
    loading: gettingExternalAuthIdentities,
  } = useGetExternalAuthIdentitiesQuery();

  const [unlinkIdentities, { loading: unlinkingIdentities }] =
    useUnlinkQuicksilvaExternalAuthIdentitiesMutation({
      onCompleted: async () => {
        await refetch();
        toast.success('Successfully unlinked Quicksilva accounts');
      },
      onError: () => toast.error('There was a problem unlinking accounts'),
    });

  const quicksilvaAuthIdentities = data?.me?.externalAuthIdentities?.filter(
    (x) => x.type === ExternalAuthIdentityType.Quicksilva,
  );
  const hasExternalQuicksilvaIdentities =
    isDefined(quicksilvaAuthIdentities) && quicksilvaAuthIdentities.length > 0;

  return (
    <Box mt={2}>
      <Loading showLoading={gettingExternalAuthIdentities}>
        <Typography fontWeight="bold" mb={1}>
          Linked Accounts
        </Typography>
        {hasExternalQuicksilvaIdentities && (
          <Box mb={1}>
            <Typography mb={2}>
              {`You have linked one or more accounts from your Quicksilva toolbar to your Feebris account to skip login. If you wish to remove these links and require entry of manual login details when coming from the Quicksilva toolbar click the button below.`}
            </Typography>
            <Button
              className={classes.removeButton}
              disabled={unlinkingIdentities}
              onClick={async () =>
                await unlinkIdentities({ variables: { type: ExternalAuthIdentityType.Quicksilva } })
              }
              variant="contained">
              Unlink accounts
            </Button>
          </Box>
        )}

        {!hasExternalQuicksilvaIdentities && (
          <Box mb={1}>
            <Typography mb={1}>
              {`If you link your Quicksilva toolbar to skip future manual logins you can manage that link here.`}
            </Typography>
            <Typography>{`No Quicksilva accounts are currently linked.`}</Typography>
          </Box>
        )}
      </Loading>
    </Box>
  );
};

const PreApprovedMessageSetup = () => {
  const classes = useStyles();
  const [accessCode, setAccessCode] = useState<string>();
  const {
    data,
    refetch,
    loading: getAreQuicksilvaPreApprovedMessagesSetupLoading,
  } = useAreQuicksilvaPreApprovedMessagesSetupQuery();

  const areQuicksilvaPreApprovedMessagesSetup =
    data?.me?.areQuicksilvaPreApprovedMessagesSetup === true;

  const confirm = useConfirm();

  const [
    addQuicksilvaIdentity,
    { loading: addingQuicksilvaIdentity, error: addQuicksilvaIdentityError },
  ] = useAddQuicksilvaIdentityMutation({
    onCompleted: async () => {
      await refetch();
      toast.success('Successfully setup pre-approved messages');
    },
    onError: () => toast.error('There was a setting up pre-approved messages'),
  });

  const [removeQuicksilvaIdentity, { loading: removingQuicksilvaIdentity }] =
    useRemoveQuicksilvaIdentityMutation({
      onCompleted: async () => {
        await refetch();
        toast.success('Successfully unlinked pre-approved messages');
      },
      onError: () => toast.error('There was a problem unlinking pre-approved messages'),
    });

  const handleAddIdentitySubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    // Shouldn't happen; just satisfying TypeScript
    if (!isDefined(accessCode)) {
      return;
    }

    await addQuicksilvaIdentity({ variables: { accessCode } });
  };

  const handleRemoveIdentitySubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      await confirm({
        description: 'Are you sure you want to unlink your Quicksilva identity?',
        confirmationText: 'Unlink',
        confirmationButtonProps: {
          variant: 'contained',
          color: 'error',
        },
      });
      await removeQuicksilvaIdentity();
    } catch {
      // catch the weird error when the 'cancel' button is clicked
      // ¯\_(ツ)_/¯
    }
  };

  const linkQuicksilvaIdentityFormId = 'linkQuicksilvaIdentityForm';
  const unlinkQuicksilvaIdentityFormId = 'unlinkQuicksilvaIdentityForm';

  return (
    <Box mb={4}>
      <Loading showLoading={getAreQuicksilvaPreApprovedMessagesSetupLoading}>
        <Typography fontWeight="bold" mb={1}>
          Pre-approved Messages
        </Typography>

        {areQuicksilvaPreApprovedMessagesSetup === false && (
          <form id={linkQuicksilvaIdentityFormId}>
            <Box marginBottom={1}>
              <Typography>
                {`Setup your Feebris account to pre-approve messages sent to the Quicksilva toolbar.`}
              </Typography>
            </Box>
            <Box marginBottom={3}>
              <TextField
                label="Access token"
                name="accessToken"
                autoComplete="off"
                onChange={(e) => {
                  setAccessCode(e.target.value);
                }}
                error={isDefined(addQuicksilvaIdentityError)}
                helperText={
                  isDefined(addQuicksilvaIdentityError)
                    ? 'Please check your access code and try again'
                    : undefined
                }
              />
            </Box>
            <Box display="flex" alignItems="center" marginTop={2}>
              <Button
                type="submit"
                form={linkQuicksilvaIdentityFormId}
                onClick={async (e) => await handleAddIdentitySubmit(e)}
                disabled={!isDefined(accessCode)}
                variant="contained"
                color="primary">
                Link pre-approved message identity
              </Button>
              {addingQuicksilvaIdentity && <CircularProgress size={24} />}
            </Box>
          </form>
        )}

        {areQuicksilvaPreApprovedMessagesSetup && (
          <form id={unlinkQuicksilvaIdentityFormId}>
            <Box marginBottom={1}>
              <Typography>
                {`Your account has been succesfully setup for pre-approved messages to Quicksilva's toolbar. If you need to pre-approve for a
                  different Quicksilva identity please remove your existing Quicksilva identity first.`}
              </Typography>
            </Box>
            <Box marginBottom={1}>
              <Typography>
                You can unlink your Quicksilva identities from Feebris at any time.
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginTop={2}>
              <Button
                type="submit"
                form={unlinkQuicksilvaIdentityFormId}
                className={classes.removeButton}
                onClick={async (e) => await handleRemoveIdentitySubmit(e)}
                variant="contained">
                Unlink pre-approved message identity
              </Button>
              {removingQuicksilvaIdentity && <CircularProgress size={24} />}
            </Box>
          </form>
        )}
      </Loading>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2),
  },
  removeButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));
