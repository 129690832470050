import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles({
  spinner: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});

interface LoadingProps {
  showLoading: boolean;
  noSpinner?: boolean;
  children?: React.ReactNode | (() => React.ReactNode);
}

export function Loading({ children, showLoading, noSpinner }: LoadingProps): JSX.Element | null {
  const classes = useStyles();

  if (showLoading) {
    return !noSpinner ? (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    ) : null;
  }
  return <>{typeof children === 'function' ? children() : children}</>;
}

export default Loading;
