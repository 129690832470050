import React from 'react';
import localeFormatting, { feebrisFormatter } from '@/helpers/LocaleFormatting';
import { CheckupDetailsFragment } from '@/generated/graphql';

import '@/styles/components/blocks/block-glucose.scss';

interface Props {
  checkup: CheckupDetailsFragment;
}

export default function BlockGlucose({ checkup }: Props) {
  const { glucose, endedAt } = checkup;
  const checkupTime = localeFormatting.formatCheckupTimeLongWithWeekDay(endedAt);

  return (
    <div className="block block--glucose">
      <div className="block__title">Glucose</div>
      <div className="block__inner">
        <div className="block__cols">
          <div>
            {glucose ? (
              <div>
                <p className="value">{feebrisFormatter.formatGlucoseWithUnits(glucose.reading)}</p>
                <p>Time of capture: {glucose.time_of_capture}</p>
                <p>Reading taken: {glucose.before_after_meal.replaceAll('_', ' ')}</p>
                {checkupTime ? <p>Checkup completed at: {checkupTime}</p> : null}
              </div>
            ) : (
              <p className="value">-</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
