import React, { useCallback, useEffect, useState } from 'react';

import { Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import auth from '@/controllers/Auth';
import { useMeActingOrganizationFeature } from '@/hooks/useAuth';

interface PathwayHeaderProps {
  pathwayName: Maybe<string>;
  isLoading: boolean;
}

export function PathwayHeader({ pathwayName, isLoading }: PathwayHeaderProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { pathwayId, tabName } = useParams<{ pathwayId: string; tabName: string }>();

  const getDefaultTabNumber = useCallback(() => {
    switch (tabName) {
      default:
      case 'checkuptypes':
        return 0;
      case 'alerts':
        return 1;
      case 'integrations':
        return 2;
    }
  }, [tabName]);

  const [tabNumber, setTabNumber] = useState(getDefaultTabNumber());

  useEffect(() => {
    setTabNumber(getDefaultTabNumber());
  }, [getDefaultTabNumber]);

  const ehrIntegrationsEnabled = useMeActingOrganizationFeature('ehrIntegrations');

  return (
    <div className={classes.root}>
      <div className={classes.headingContainer}>
        <Typography component="h2" variant="body1" color="primary" className={classes.heading}>
          {t('Pathway Management')}
        </Typography>
        <Typography component="h3" variant="h5" color="textPrimary" className={classes.pathwayName}>
          {isLoading ? <Skeleton width={200} /> : pathwayName}
        </Typography>
      </div>
      <Tabs
        value={tabNumber}
        indicatorColor="primary"
        textColor="primary"
        onChange={(event, newValue) => setTabNumber(newValue)}>
        <Tab
          label="Check-up Types"
          to={`/pathways/${pathwayId}/checkuptypes`}
          id="manage-carepathway-checkuptypes-tab"
          aria-controls="manage-carepathway-checkuptypes-tabpanel"
          component={RouterLink}
        />
        <Tab
          label="Alerts"
          to={`/pathways/${pathwayId}/alerts`}
          id="manage-carepathway-alerts-tab"
          aria-controls="manage-carepathway-alerts-tabpanel"
          component={RouterLink}
        />
        {ehrIntegrationsEnabled && auth.me('permissions.view_ehr_integrations') && (
          <Tab
            label="Integrations"
            to={`/pathways/${pathwayId}/integrations`}
            id="manage-carepathway-integrations-tab"
            aria-controls="manage-carepathway-integrations-tabpanel"
            component={RouterLink}
          />
        )}
      </Tabs>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 1),
  },
  headingContainer: {
    margin: theme.spacing(2, 3, 1, 3),
  },
  pathwayName: {
    color: theme.palette.primary.dark,
    lineHeight: 1,
  },
  heading: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
}));
