import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

interface PageContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

/**
 * A container for page content. Adds padding and centers the content.
 */
export function PageContainer({ children, ...rest }: PageContainerProps) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.pageContainer, rest.className)} {...rest}>
      {children}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    padding: theme.spacing(3),
    maxWidth: '1440px',
    margin: '0 auto',
    width: '100%',
  },
}));
