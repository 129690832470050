import React from 'react';

import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WarningIcon from '@mui/icons-material/Warning';

import { Patient } from '@/generated/graphql';
import { useTranslation } from 'react-i18next';

interface SimilarNamesWarningProps {
  patient: Pick<Patient, 'numSimilarNames'>;
  children: React.ReactNode;
}

export function SimilarNamesWarning(props: SimilarNamesWarningProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const similarNames =
    props.patient.numSimilarNames > 0 ? (
      <Tooltip title={t('SimilarNamesWarning_title', { count: props.patient.numSimilarNames })}>
        <WarningIcon fontSize="small" className={classes.similarNamesDecoration} />
      </Tooltip>
    ) : null;
  return (
    <span className={classes.root}>
      {props.children}
      {similarNames}
    </span>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'row',
  },
  similarNamesDecoration: {
    alignSelf: 'center',
    marginLeft: 6,
  },
}));
