import React from 'react';

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { Alert } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { muiFormikGetFieldProps } from '@/helpers/formik';
import { ReportSection, useDownloadPatientReport } from './useDownloadPatientReport';
import _ from 'lodash';
import { isDefined } from '@/helpers/isDefined';

const formSchema = Yup.object().shape({
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date()
    .required('End date is required')
    .min(Yup.ref('startDate'), 'End date must be after start date'),
  aggregateIntervalHours: Yup.number()
    .nullable()
    .required('Interval is required')
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (val === Number(val) ? val : null)),
  includeVitalsAggregates: Yup.boolean().required(),
  includeSoftSignsAggregates: Yup.boolean().required(),
  includeAlerts: Yup.boolean().required(),
  includeNotes: Yup.boolean().required(),
  includeWardAdmissions: Yup.boolean().required(),
  includeCheckups: Yup.boolean().required(),
});

interface FormValues {
  startDate: string;
  endDate: string;
  aggregateIntervalHours: number;
  includeVitalsAggregates: boolean;
  includeSoftSignsAggregates: boolean;
  includeAlerts: boolean;
  includeNotes: boolean;
  includeWardAdmissions: boolean;
  includeCheckups: boolean;
}

interface CustomReportModalProps extends DialogProps {
  patientId: string;
  onComplete: () => void;
  onCancel: () => void;
}

function CustomReportModal({ patientId, open, onComplete, onCancel }: CustomReportModalProps) {
  const classes = useStyles();

  const { downloadPdf, loading } = useDownloadPatientReport({ patientId });

  const formik = useFormik<FormValues>({
    initialValues: {
      startDate: '',
      endDate: '',
      aggregateIntervalHours: 24,
      includeVitalsAggregates: true,
      includeSoftSignsAggregates: true,
      includeAlerts: true,
      includeNotes: true,
      includeWardAdmissions: true,
      includeCheckups: false,
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      await downloadPdf({
        startDate: new Date(values.startDate),
        endDate: new Date(values.endDate),
        aggregateIntervalHours: values.aggregateIntervalHours,
        sections: [
          values.includeVitalsAggregates ? ReportSection.VitalsAggregates : null,
          values.includeSoftSignsAggregates ? ReportSection.SoftSignsAggregates : null,
          values.includeAlerts ? ReportSection.Alerts : null,
          values.includeNotes ? ReportSection.Notes : null,
          values.includeWardAdmissions ? ReportSection.WardAdmissions : null,
          values.includeCheckups ? ReportSection.Checkups : null,
        ].filter(isDefined),
      });

      onComplete();
    },
  });

  const getFieldProps = muiFormikGetFieldProps(formik);

  const showIntervalSelect =
    formik.values.includeVitalsAggregates || formik.values.includeSoftSignsAggregates;

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.title}>
        <DescriptionIcon fontSize="large" className={classes.titleIcon} />
        <Typography component="h2" variant="h6">
          Download Custom Patient Report
        </Typography>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Typography variant="h6">Report Window</Typography>
          <div className={classes.datesContainer}>
            <TextField
              type="datetime-local"
              label="Report Start"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              {...getFieldProps('startDate')}
            />
            <TextField
              type="datetime-local"
              label="Report End"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              {...getFieldProps('endDate')}
            />
          </div>
          <Box marginTop={3} marginBottom={2}>
            <Typography variant="h6" gutterBottom>
              Report Configuration
            </Typography>

            <div className={classes.additionalConfigContainer}>
              <Typography variant="body2" className={classes.sectionInclusionHeading}>
                Include the following sections in the report:
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.includeWardAdmissions}
                    color="primary"
                    onChange={({ target: { checked } }) =>
                      formik.setFieldValue('includeWardAdmissions', checked)
                    }
                  />
                }
                label="Ward Admissions"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.includeVitalsAggregates}
                    color="primary"
                    onChange={({ target: { checked } }) =>
                      formik.setFieldValue('includeVitalsAggregates', checked)
                    }
                  />
                }
                label="Vitals Summary"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.includeSoftSignsAggregates}
                    color="primary"
                    onChange={({ target: { checked } }) =>
                      formik.setFieldValue('includeSoftSignsAggregates', checked)
                    }
                  />
                }
                label="Symptoms Summary"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.includeAlerts}
                    color="primary"
                    onChange={({ target: { checked } }) =>
                      formik.setFieldValue('includeAlerts', checked)
                    }
                  />
                }
                label="Alerts"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.includeNotes}
                    color="primary"
                    onChange={({ target: { checked } }) =>
                      formik.setFieldValue('includeNotes', checked)
                    }
                  />
                }
                label="Notes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.includeCheckups}
                    color="primary"
                    onChange={({ target: { checked } }) =>
                      formik.setFieldValue('includeCheckups', checked)
                    }
                  />
                }
                label="Full observation list"
              />

              <Collapse in={showIntervalSelect} unmountOnExit>
                <FormControl
                  fullWidth
                  error={_.has(formik.errors, 'aggregateIntervalHours')}
                  className={classes.intervalSelect}>
                  <InputLabel>Summary interval</InputLabel>
                  <Select
                    value={formik.values.aggregateIntervalHours ?? 0}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'aggregateIntervalHours',
                        Number.isSafeInteger(e.target.value) ? e.target.value : 24,
                      )
                    }>
                    <MenuItem value={1}>1 hour</MenuItem>
                    <MenuItem value={4}>4 hours</MenuItem>
                    <MenuItem value={12}>12 hours</MenuItem>
                    <MenuItem value={24}>24 hours</MenuItem>
                  </Select>
                  <FormHelperText>{_.get(formik.errors, 'aggregateIntervalHours')}</FormHelperText>
                </FormControl>
                <Alert severity="info" className={classes.infoAlert}>
                  Your report is split into intervals of this length with aggregates of vitals and
                  symptoms displayed for each interval.
                </Alert>
              </Collapse>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={loading}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={loading}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  titleIcon: {
    color: theme.palette.primary.dark,
  },
  datesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  additionalConfigContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0),
  },
  intervalSelect: {
    marginTop: theme.spacing(1),
    maxWidth: '275px',
  },
  sectionInclusionHeading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
  },
  infoAlert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

interface UseCustomReportModalProps {
  patientId: string;
}

export function useCustomReportModal({ patientId }: UseCustomReportModalProps) {
  const { showModal } = useModal();

  return {
    showCustomReportModal: () => {
      const modal: ShowFnOutput<CustomReportModalProps> = showModal(
        CustomReportModal,
        {
          patientId,
          onCancel: () => modal.hide(),
          onComplete: () => modal.hide(),
        },
        { destroyOnClose: true },
      );

      return modal;
    },
  };
}
