import React from 'react';

import { Button } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { useDownloadPatientReport } from './useDownloadPatientReport';

interface PatientDischargeDownloadButtonProps {
  patientId: string;
  startDate: Date;
  endDate: Date;
}

const PatientDischargeDownloadButton = ({
  patientId,
  startDate,
  endDate,
}: PatientDischargeDownloadButtonProps) => {
  const { downloadPdf } = useDownloadPatientReport({ patientId });

  return (
    <Button
      onClick={() =>
        downloadPdf({
          startDate,
          endDate,
          aggregateIntervalHours: 24,
        })
      }
      size="small"
      variant="outlined"
      color="primary"
      startIcon={<CloudDownloadIcon />}>
      Download
    </Button>
  );
};

export default PatientDischargeDownloadButton;
