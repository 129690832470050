import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { MenuItem, Typography, Button, Popover, Box, TextField, MenuList } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { gql } from '@apollo/client';

import auth from '@/controllers/Auth';
import api from '@/controllers/Api';
import { useDidSwitchActingOrganizationMutation } from '@/generated/graphql';
import { useHistory } from 'react-router-dom';

interface SelectActingOrganizationProps {
  limit?: number;
}

export default function SelectActingOrganization({ limit }: SelectActingOrganizationProps) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [firstRender, setFirstRender] = useState(true);
  const history = useHistory();
  const open = Boolean(anchorEl);

  useEffect(() => {
    setFirstRender(false);
    (async function () {
      if (firstRender || open) {
        const response = (await api.getActingOrganizations()) as Organization[];
        if (Array.isArray(response)) {
          setOrganizations(response);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const id = open ? 'select-acting-organization-popover' : undefined;

  return (
    <>
      {
        // We hide the org switcher unless the user is in at least two organizations. It's confusing to
        // see just one organization in the switcher.
        organizations.length < 2 ? null : (
          <Button
            variant="text"
            color="inherit"
            onClick={(event: React.MouseEvent) => setAnchorEl(event.currentTarget)}
            role="button"
            size="large"
            aria-label="switch organisation"
            endIcon={<ArrowDropDownIcon />}>
            {auth.me('actingOrganization.name')}
          </Button>
        )
      }
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <SelectActingOrganizationForm
          organizations={organizations}
          limit={limit}
          onChoose={() => {
            setAnchorEl(null);
            history.replace('/');
          }}
        />
      </Popover>
    </>
  );
}

interface SelectActingOrganizationFormProps extends SelectActingOrganizationProps {
  organizations: Organization[];
  onChoose: () => void;
}

function SelectActingOrganizationForm({
  organizations,
  limit,
  onChoose,
}: SelectActingOrganizationFormProps) {
  const [search, setSearch] = useState('');
  const classes = useStyles();
  const [didSwitchActingOrganizationMutation] = useDidSwitchActingOrganizationMutation();

  const searchRegex = new RegExp(search.toLowerCase());

  const filteredOrganizations = organizations.filter(
    (organization) =>
      organization.name.toLowerCase().match(searchRegex) ||
      organization.address.address.toLowerCase().match(searchRegex) ||
      organization.address.postcode.toLowerCase().match(searchRegex),
  );

  const chooseActingOrganization = async (newActingOrganizationId: string) => {
    auth.setActingOrganizationId(newActingOrganizationId);
    await didSwitchActingOrganizationMutation();
    await auth.refreshMeData();
    onChoose(); // Callback to inform the parent to close the Popover
  };
  const displayedOrganizations = limit
    ? filteredOrganizations.slice(0, limit)
    : filteredOrganizations;

  return (
    <Box padding={2} style={{ width: 400 }}>
      <Box className={classes.searchField}>
        <TextField
          id="outlined-basic"
          label="Search organisations"
          variant="outlined"
          fullWidth={true}
          onChange={(event) => setSearch(event.target.value)}
        />
      </Box>

      <MenuList className={classes.menuList}>
        {displayedOrganizations.map((organization) => (
          <MenuItem
            className={classes.menuItem}
            key={organization.id}
            aria-label={`switch to ${organization.name}`}
            onClick={() => chooseActingOrganization(organization.id)}>
            <Typography variant="subtitle1" className={classes.organizationName}>
              {organization.name}
            </Typography>
            <Typography variant="body2">
              {organization.address.address} {organization.address.postcode}
            </Typography>
          </MenuItem>
        ))}
      </MenuList>

      {limit && filteredOrganizations.length > limit && (
        <Typography className={classes.moreInfo}>
          {filteredOrganizations.length - limit} more (search to filter)
        </Typography>
      )}
    </Box>
  );
}

export const QUERY_DID_SWITCH_ACTING_ORGANIZATION = gql`
  mutation didSwitchActingOrganization {
    didSwitchActingOrganization
  }
`;

const useStyles = makeStyles((theme) => ({
  searchField: {
    position: 'sticky',
    top: theme.spacing(2),
    background: theme.palette.background.paper,
    opacity: 1,
    zIndex: 1,
    marginBottom: theme.spacing(1),
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    whiteSpace: 'normal',
  },
  organizationName: {
    color: '#0A4B62',
    fontWeight: 500,
  },
  moreInfo: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

interface Organization {
  id: string;
  name: string;
  address: {
    address: string;
    postcode: string;
  };
}
