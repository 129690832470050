import React from 'react';
import _ from 'lodash';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import auth from '@/controllers/Auth';
import { PatientPracticeAssignmentFragment } from '@/generated/graphql';
import { OrganizationSelector } from './OrganizationSelector';

export enum AssignmentType {
  Practice = 'Practice',
}

interface PatientAssignmentToolbarProps {
  assignedPractices: Record<string, PatientPracticeAssignmentFragment[]>;
  setAssignedPractices: (
    assignedPractices: Record<string, PatientPracticeAssignmentFragment[]>,
  ) => void;
  setAddPracticeOpen: (open: boolean) => void;
  setAssignPracticeAlertOpen: (open: boolean) => void;
  // TODO: bit of a lie, we should move and convert the org fetching
  allPractices: PatientPracticeAssignmentFragment[];
  onCancel: () => void;
  assignmentType: AssignmentType | null;
  onAssignmentTypeChange: (assignmentType: AssignmentType | null) => void;
}

export function PatientAssignmentToolbar({
  assignedPractices,
  setAddPracticeOpen,
  setAssignedPractices,
  setAssignPracticeAlertOpen,
  allPractices,
  onCancel,
  assignmentType,
  onAssignmentTypeChange,
}: PatientAssignmentToolbarProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleAssignmentTypeChange = (assignmentType: AssignmentType) => {
    onAssignmentTypeChange(assignmentType);
  };

  const handleSave = () => {
    if (assignmentType === AssignmentType.Practice) {
      setAssignPracticeAlertOpen(true);
    }
  };

  const assignedPracticesValue = _.uniqBy(_.flatten(Object.values(assignedPractices)), (p) => p.id);

  const canSave = React.useMemo(() => {
    if (assignmentType === AssignmentType.Practice) {
      return assignedPracticesValue.length > 0;
    }
    return false;
  }, [assignmentType, assignedPracticesValue]);

  return (
    <div className={classes.actions}>
      {assignmentType === AssignmentType.Practice && (
        <OrganizationSelector
          assignedPractices={assignedPractices}
          onAssignedPracticesChange={setAssignedPractices}
          organizations={allPractices}
          setAddPracticeOpen={setAddPracticeOpen}
          assignedPracticesValue={assignedPracticesValue}
        />
      )}
      {!assignmentType && (
        <>
          {auth.me('permissions.assign_patients') && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleAssignmentTypeChange(AssignmentType.Practice)}>
              Assign {t('GP Practice')}
            </Button>
          )}
        </>
      )}
      <Button
        variant="contained"
        onClick={() => {
          onCancel();
        }}>
        Cancel
      </Button>
      {assignmentType && (
        <Button
          disabled={!canSave}
          variant="contained"
          color="primary"
          onClick={() => handleSave()}
          className="e2e__gpassignsave">
          Save
        </Button>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  actions: {
    // eslint-disable-next-line quote-props
    display: 'flex',
    // eslint-disable-next-line quote-props
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  addPatientButton: {
    height: '100%',
    alignSelf: 'center',
  },
}));
