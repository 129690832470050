import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    marginLeft: 10,
    display: 'flex',
  },
  outerCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    borderWidth: 5,
    border: 'solid',
    borderColor: 'rgba(255, 255, 255, 0.48)',
    width: 60,
    height: 60,
  },
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#fff',
    width: 50,
    height: 50,
  },
  innerCircle: {
    borderRadius: '50%',
    border: 'solid',
    borderWidth: 1,
    borderColor: '#989898',
    backgroundColor: '#d3d3d3',
    width: 40,
    height: 40,
  },
  line: {
    position: 'relative',
    left: -32,
    top: 0,
    width: 5,
    height: 61,
    backgroundColor: '#989898',
    transform: 'rotate(45deg)',
  },
});
const DisabledRiskLevelIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.outerCircle}>
        <div className={classes.circle}>
          <div className={classes.innerCircle} />
        </div>
      </div>
      <div className={classes.line} />
    </div>
  );
};

export default DisabledRiskLevelIcon;
