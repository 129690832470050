/**
 * Colours for different NEWS score bands.
 */
export const NEWS_0 = 'rgb(235,235,235)';
export const NEWS_1 = 'rgb(255,255,198)';
export const NEWS_2 = 'rgb(254,204,187)';
export const NEWS_3 = 'rgb(254,164,156)';

export const NEWS_0_GRAY = 'rgb(235,235,235)';
export const NEWS_1_GRAY = 'rgb(249, 249, 249)';
export const NEWS_2_GRAY = 'rgb(217, 217, 217)';
export const NEWS_3_GRAY = 'rgb(190, 190, 190)';

export const COLOR_BANDS = [NEWS_0, NEWS_1, NEWS_2, NEWS_3];

export const GRAY_COLOR_BANDS = [NEWS_0_GRAY, NEWS_1_GRAY, NEWS_2_GRAY, NEWS_3_GRAY];

export const COLOR_BANDS_FOR_RISK_LEVEL = [
  undefined, // for when riskLevel is undefined (zero isn't possible, it's one-indexed)
  undefined, // riskLevel == 1, don't colour the cell
  NEWS_0, // riskLevel == 2, grey
  NEWS_1, // riskLevel == 3, yellow
  NEWS_2, // riskLevel == 4, orange
  NEWS_3, // riskLevel == 5, red
];

export const COLOR_BANDS_WITH_BLANK_NEWS_0 = [
  undefined, // Skip the NEWS 0 "grey"
  NEWS_1,
  NEWS_2,
  NEWS_3,
];

/**
 * TODO: This kind of steps on the toes of the above, but it's a bit different.
 * The colours here are used for the NEWS score in the new virtual wards page,
 * some of this could be moved into the themes such as warning, error, etc.
 */
export const NEWS_SCORE_PALETTE = {
  0: {
    backgroundColor: '#F9F9F9',
    color: '#525151',
    borderColor: '#E0E0E0',
  },
  1: {
    backgroundColor: '#F9E1A7',
    color: '#362E08',
    borderColor: '#D39A61',
  },
  2: {
    backgroundColor: '#EAC29A',
    color: '#361F08',
    borderColor: '#D39A61',
  },
  3: {
    backgroundColor: '#efbebe',
    color: '#450808',
    borderColor: '#DB5757',
  },
};

export const newsPaletteForScore = (score: number) => {
  const normalizedScore = Math.max(0, Math.min(3, score)) as keyof typeof NEWS_SCORE_PALETTE;

  return NEWS_SCORE_PALETTE[normalizedScore];
};

export const newsPaletteForRiskLevel = (riskLevel: number) => {
  return newsPaletteForScore(Math.max(0, riskLevel - 2));
};
