import { React, useState, useEffect, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  LinearProgress,
} from '@mui/material';
import FileCopy from '@mui/icons-material/FileCopy';

// Two activities here:
// 1. Present a password reset URL for the user
// 2. Display a new "what 3 words" password that will be used to overwrite the user's password
//
// Let's build (1), if that's going okay we can try for (2)
export default function ResetPasswordModal(props) {
  const [resetUrl, setResetUrl] = useState(undefined);
  const textfieldRef = useRef(null);

  const selectAll = () => {
    textfieldRef.current.select();
  };
  const copyToClipboard = () => {
    selectAll();
    document.execCommand('copy');
  };

  const close = () => {
    props.onClose();
  };

  const { user, onError, open } = props;
  useEffect(() => {
    const fetchResetUrl = async () => {
      if (user?.id && open) {
        setResetUrl(undefined);
        // We let the parent control how the fetching is to be done
        const response = await props.fetchPasswordResetUrl();
        // TODO: In testing we found that many repeat fetches from Firebase's passwordResetUrl
        //       API would cause rate limit errors to start appearing. As a short-term solution
        //       we are rendering the errors here, so the user doesn't get too confused. A better
        //       long term solution would be to add a cache on our own graphql passwordResetUrl
        //       handler to ensure we limit how frequently we call firebase for any particular user.
        if (response.errors) {
          onError(response.errors[0].message);
        } else {
          setResetUrl(response.data.passwordResetUrl);
        }
      }
    };
    fetchResetUrl();
    // We only want to fetch the reset URL when the user changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, open]);

  return (
    <Dialog
      open={props.open}
      onClose={close}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth>
      <DialogTitle id="alert-dialog-title">Reset user password</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container>
            <Grid item xs={12}>
              <Typography paragraph>
                The URL below can be used to set or reset the password for {props.user.email}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {resetUrl ? (
                <TextField
                  fullWidth
                  value={resetUrl}
                  label="Password reset URL"
                  helperText=""
                  style={{ display: 'block' }}
                  InputProps={{
                    inputRef: textfieldRef,
                    tabIndex: '1',
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={copyToClipboard}
                          className="e2e__copypasswordreseturltoclipboardbutton"
                          size="large">
                          <FileCopy />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onClick={selectAll}
                  autoFocus
                  variant="filled"
                  readonly
                />
              ) : (
                <LinearProgress />
              )}
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} autoFocus className="e2e__closeresetpasswordmodal">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
