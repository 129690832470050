import React from 'react';

import { Alert, Collapse, Link as MuiLink, Stack } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import useToggle from '@/hooks/useToggle';

export interface ExplainerProps {
  question: string;
  children: React.ReactNode;
  icon?: boolean;
  sx?: SxProps<Theme>;
}

/**
 * Generate a unique id for use in aria-controls
 *
 * This is a hook so that it can be called multiple times in the same component.
 * It follows the API of the `useId` hook from React 18, so once we upgrade we can
 * just replace this with that.
 *
 * @returns A unique id for use in aria-controls
 */
const useId = () => {
  const id = React.useRef(Math.random().toString(36).substring(2, 9));
  return id.current;
};

export default function Explainer({ question, children, sx, icon = true }: ExplainerProps) {
  const [isExplainerOpen, toggleIsExplainerOpen] = useToggle(false);

  const ariaId = useId();
  const ariaContentId = `explainer-${ariaId}-content`;

  return (
    <Stack sx={sx} spacing={0.5}>
      <MuiLink
        role="button"
        color="primary.main"
        sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 0.5 }}
        aria-controls={ariaContentId}
        aria-expanded={isExplainerOpen}
        tabIndex={0}
        onKeyDown={({ key }) => ['Enter', ' '].includes(key) && toggleIsExplainerOpen()}
        onClick={() => {
          toggleIsExplainerOpen();
        }}>
        {icon && <HelpOutlineIcon fontSize="small" />} {question}
      </MuiLink>
      <Collapse id={ariaContentId} in={isExplainerOpen}>
        <Alert severity="info" icon={<HelpOutlineIcon />}>
          {children}
        </Alert>
      </Collapse>
    </Stack>
  );
}
