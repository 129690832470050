import React, { useCallback, useMemo, useRef } from 'react';

import { gql } from '@apollo/client';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import { toast } from 'sonner';

import {
  useAssignStaffToWardMutation,
  useOrganizationUsersQuery,
  WardStaffItemFragment,
} from '@/generated/graphql';

import MaterialTableWithIcons from '@/components/MaterialTableWithIcons';

export const ASSIGN_STAFF_TO_WARD = gql`
  mutation AssignStaffToWard($wardId: ID!, $staffId: ID!) {
    assignStaffToWard(wardId: $wardId, userId: $staffId) {
      assignedAt
    }
  }
`;

export const QUERY_ORGANISATION_USERS = gql`
  query OrganizationUsers {
    userOrganizations {
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

interface AssignStaffModalProps extends DialogProps {
  wardId: string;
  wardName: string;
  wardStaff: WardStaffItemFragment[];
  onAssigned: () => void;
  onCancel: () => void;
}

export default function AssignStaffModal({
  wardId,
  wardName,
  wardStaff,
  onAssigned,
  onCancel,
  ...rest
}: AssignStaffModalProps) {
  const { data: orgUsers, loading: isFetchingUsers } = useOrganizationUsersQuery({
    onError: () => toast.error('An error occurred when fetching users for assignment'),
  });
  const allUsers = useMemo(
    () => orgUsers?.userOrganizations?.map((uo) => uo.user) ?? [],
    [orgUsers],
  );

  return (
    <Dialog fullWidth maxWidth="md" {...rest}>
      <DialogTitle>Assign Staff to {wardName}</DialogTitle>
      <DialogContent>
        <MaterialTableWithIcons
          components={{
            // No shadow on the table
            Container: (props) => <div {...props} />,
          }}
          isLoading={isFetchingUsers}
          data={allUsers}
          columns={[
            { title: 'ID', field: 'id', hidden: true },
            { title: 'First Name', field: 'firstName' },
            { title: 'Last Name', field: 'lastName' },
            { title: 'Email', field: 'email' },
            {
              title: '',
              align: 'right',
              render: (rowData) => (
                <AssignActionButton
                  wardId={wardId}
                  userId={rowData.id}
                  wardStaff={wardStaff}
                  onAssigned={onAssigned}
                />
              ),
            },
          ]}
          options={{
            searchFieldAlignment: 'left',
            showTitle: false,
            pageSize: 5,
            pageSizeOptions: [5],
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

interface AssignActionButtonProps {
  wardId: string;
  userId: string;
  wardStaff: WardStaffItemFragment[];
  onAssigned: () => void;
}

function AssignActionButton({ wardId, userId, wardStaff, onAssigned }: AssignActionButtonProps) {
  const [assign, { loading: isAssigning, data, error }] = useAssignStaffToWardMutation({
    variables: {
      wardId,
      staffId: userId,
    },
    onCompleted: () => {
      onAssigned();
    },
    onError: () => toast.error('An error occurred when assigning staff to the ward'),
  });

  const isAssigned = useMemo(
    () => wardStaff.some((ws) => ws.user.id === userId),
    [wardStaff, userId],
  );

  if (data || isAssigned) {
    return (
      <Button disabled size="small">
        Assigned
      </Button>
    );
  }

  if (isAssigning) {
    return (
      // Make it occupy the same space as the button for less content shifting
      <Box
        minHeight="30.75px"
        marginRight={3}
        display="flex"
        alignItems="center"
        justifyContent="flex-end">
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <Button
      size="small"
      color="primary"
      onClick={() => {
        assign();
      }}>
      {error ? 'Retry' : 'Assign'}
    </Button>
  );
}

interface UseAssignStaffModalProps {
  wardId: string;
  wardName: string;
  wardStaff: WardStaffItemFragment[];
  onAssigned: () => void;
}

export const useAssignStaffModal = ({
  wardId,
  wardName,
  wardStaff,
  onAssigned,
}: UseAssignStaffModalProps) => {
  const { showModal } = useModal();

  const modal = useRef<ShowFnOutput<AssignStaffModalProps> | null>(null);

  return {
    showAssignStaffModal: () => {
      modal.current = showModal(
        AssignStaffModal,
        {
          wardId,
          wardName,
          wardStaff,
          onAssigned: () => {
            onAssigned();
          },
          onCancel: () => modal.current?.hide(),
        },
        { destroyOnClose: true },
      );

      return modal;
    },
    updateProps: useCallback((props: Partial<AssignStaffModalProps>) => {
      modal.current?.update(props);
    }, []),
  };
};
