import React, { useCallback, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import PassphraseGenerator from '@/components/PassphraseGenerator';
import { generatePassphrase } from '@/helpers/passphrase';
import { useSetPasswordForSelfCareUserMutation } from '@/generated/graphql';
import { gql } from '@apollo/client';
import { useModal } from 'mui-modal-provider';

interface SetPasswordModalProps {
  open: boolean;
  user: { id: string; email: string };
  setFlashMessage?: (flash: string) => void;
  onClose?: () => void;
  onExited?: () => void;
}

export const SET_PASSWORD_FOR_SELF_CARE_USER = gql`
  mutation SetPasswordForSelfCareUser($userId: ID!, $password: String!) {
    setPasswordForSelfCareUser(userId: $userId, password: $password)
  }
`;

export default function SetPasswordModal(props: SetPasswordModalProps) {
  const classes = useStyles();
  const [password, setPassword] = useState<null | string>(null);
  const [passwordResetComplete, setPasswordResetComplete] = useState(false);
  const [setPasswordForSelfCareUserMutation] = useSetPasswordForSelfCareUserMutation();

  useEffect(() => {
    setPassword(generatePassphrase());
    setPasswordResetComplete(false);
  }, [props.user]);

  const confirmPasswordReset = async () => {
    if (!password) return; // TODO: shouldn't be able to get here
    const response = await setPasswordForSelfCareUserMutation({
      variables: { userId: props.user.id, password: password },
    });

    if (response.errors) {
      props.setFlashMessage?.(
        response.errors[0]?.message ??
          'An unknown error occurred while attempting to reset the password',
      );
    } else {
      setPasswordResetComplete(true);
    }
  };

  const onClose = () => {
    props.onClose?.();
  };

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      TransitionProps={{
        onExited: () => {
          props.onExited?.();
          // For security, wipe the password state when the modal is closed. For improved UX
          // we execute this when the modal has entirely disappeared from view to prevent flickering
          // of the PassphraseGenerator component
          setPassword(null);
          setPasswordResetComplete(false);
        },
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth>
      <DialogTitle id="alert-dialog-title">Set password for {props.user.email}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container>
            <Grid item xs={12}>
              <PassphraseGenerator
                password={password}
                setPassword={setPassword}
                regenerateEnabled={false}
                buttonText="Generate new password"
              />
              {password && !passwordResetComplete && (
                <>
                  <Typography paragraph>
                    When you are ready, click the button (below) to change the password for{' '}
                    <em>{props.user.email}</em> to the three randomly chosen words displayed above.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={confirmPasswordReset}
                    className={classes.setPasswordButton}>
                    Set As New Password
                  </Button>
                </>
              )}
              {password && passwordResetComplete && (
                <>
                  <Typography paragraph>
                    Password reset complete. You may now use this password (above) to log{' '}
                    <em>{props.user.email}</em> into a Feebris kit.
                  </Typography>
                  <Typography paragraph>
                    Note: this password will no longer be visible once you press the Close button.
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus className="e2e__closeresetpasswordmodal">
          {passwordResetComplete ? 'Close' : 'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((_) => ({
  setPasswordButton: {
    display: 'block',
    margin: '0 auto',
  },
}));

export const useSetPasswordModal = () => {
  const { showModal } = useModal();

  const showSetPasswordModal = useCallback(
    ({ user }: { user: SetPasswordModalProps['user'] }) => {
      function hide() {
        modal.hide();
      }
      const modal = showModal(
        SetPasswordModal,
        {
          user,
          onClose: () => {
            hide();
          },
        },
        { destroyOnClose: true },
      );
    },
    [showModal],
  );

  return {
    showSetPasswordModal,
  };
};
