import {
  Alert,
  AlertRule,
  AlertType,
  Checkup,
  CheckupType,
  PacsanaEvent,
} from '@/generated/graphql';
import i18next from '@/helpers/i18n';

export const formatCheckupsSinceHoursAgo = (v: number | null) => {
  if (v === null) {
    return null;
  }
  if (v <= 24) {
    return `Vitals within last ${v} hours`;
  }
  return `Vitals within last ${v / 24} days`;
};

export function formatAlertName(
  alert: Pick<Alert, 'type'> & {
    alertRule?: Maybe<Pick<AlertRule, 'name'>>;
    pacsanaEvent?: Maybe<Pick<PacsanaEvent, 'eventKey'>>;
  },
) {
  if (alert.type === AlertType.AlertRule) {
    return alert.alertRule?.name ?? 'unknown alert';
  }
  if (alert.type === AlertType.CheckupScheduleEvent) {
    return 'Missed check-up';
  }
  if (alert.type === AlertType.PatientCallbackRequest) {
    return 'Callback Request';
  }
  if (alert.type === AlertType.PacsanaEvent) {
    return i18next.t(`PacsanaWebhookEventIds.${alert.pacsanaEvent?.eventKey}.short`);
  }
  return 'unknown alert';
}

export function formatCheckupTypeName(
  checkup: Pick<Checkup, 'type'> & { checkupType?: Maybe<Pick<CheckupType, 'name'>> },
) {
  const checkupTypeName = checkup.checkupType?.name ?? checkup.type;
  return `${checkupTypeName} ${checkupTypeName.match(/check-*up/i) ? '' : ' Check-up'}`;
}
