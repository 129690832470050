import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
  Grid,
  Alert,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { gql } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { muiFormikGetFieldProps } from '@/helpers/formik';
import { useUpdateCarePathwayMutationInternal } from '@/generated/graphql-internal';
import { getMutationErrors } from '@/AuthorizedApolloClientProvider';
import { useModal } from 'mui-modal-provider';

export const UPDATE_CAREPATHWAY_MUTATION = gql`
  mutation UpdateCarePathway($id: ID!, $name: String!, $published: Boolean!) {
    updateCarePathway(id: $id, name: $name, published: $published) {
      id
      name
      createdAt
      publishedAt
    }
  }
`;

const formSchema = Yup.object().shape({
  name: Yup.string().required('Care Pathway name is required'),
  published: Yup.boolean().required('published is required'),
});

interface EditCarePathwayModalProps extends DialogProps {
  carePathwayId: string;
  name: string;
  publishedAt: Date | null;
  numberOfPatients: number;
  onCancel: () => void;
  onUpdated: () => void;
}

type FormValues = Yup.InferType<typeof formSchema>;

export function EditCarePathwayModal({
  carePathwayId,
  name,
  numberOfPatients,
  publishedAt,
  open,
  onCancel,
  onUpdated,
}: EditCarePathwayModalProps) {
  const [updateCarePathwayMutation, { loading: isSubmitting, error: updateCarePathwayError }] =
    useUpdateCarePathwayMutationInternal({
      onCompleted: () => {
        onUpdated();
      },
      onError: () => undefined,
    });

  const formik = useFormik<FormValues>({
    initialValues: {
      name,
      published: publishedAt !== null,
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      await updateCarePathwayMutation({
        variables: {
          id: carePathwayId,
          name: values.name,
          published: values.published,
        },
      });
    },
  });

  const { argErrors } = getMutationErrors(updateCarePathwayError);
  const getFieldProps = muiFormikGetFieldProps(formik, argErrors);

  const canUpdate = numberOfPatients === 0;

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          Edit Care Pathway
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                checked={formik.values.published}
                onChange={formik.handleChange}
                name="published"
                disabled={!canUpdate}
                aria-label="published"
                color="success"
              />
            }
            label={formik.values.published ? 'Published' : 'Draft'}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {numberOfPatients > 0 && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  This care pathway is in use by <strong>{numberOfPatients} patients</strong>{' '}
                  (active admissions or discharges) and cannot be edited.
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                required
                margin="dense"
                InputProps={{ readOnly: !canUpdate }}
                {...getFieldProps('name')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          {canUpdate && (
            <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
              Save
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}

interface UseEditCarePathwayModalProps {
  onUpdated: () => void;
  onCancel: () => void;
}

export function useEditCarePathwayModal({ onUpdated, onCancel }: UseEditCarePathwayModalProps) {
  const { showModal } = useModal();

  return {
    showEditCarePathwayModal: ({
      carePathwayId,
      name,
      publishedAt,
      numberOfPatients,
    }: Pick<
      EditCarePathwayModalProps,
      'carePathwayId' | 'name' | 'numberOfPatients' | 'publishedAt'
    >) => {
      const modal = showModal(
        EditCarePathwayModal,
        {
          carePathwayId,
          name,
          publishedAt,
          numberOfPatients,
          onUpdated: () => {
            onUpdated();
            modal.hide();
          },
          onCancel: () => {
            onCancel();
            modal.hide();
          },
        },
        { destroyOnClose: true },
      );
    },
  };
}
