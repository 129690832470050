import React, { useEffect } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useModal } from 'mui-modal-provider';
import { RRule } from 'rrule';

import {
  AdminCheckupTypeItemFragmentInternal,
  useSetCheckupTypeScheduleMutationInternal,
} from '@/generated/graphql-internal';

import { getMutationErrors } from '@/AuthorizedApolloClientProvider';
import { muiFormikGetFieldProps } from '@/helpers/formik';
import { gql } from '@apollo/client';
import { addDays, format } from 'date-fns';
import { Alert } from '@mui/material';
import { toast } from 'sonner';

export const SET_CHECKUP_TYPE_SCHEDULE_MUTATION = gql`
  mutation setCheckupTypeSchedule($checkupTypeId: ID!, $schedule: CheckupScheduleInput!) {
    setCheckupTypeSchedule(checkupTypeId: $checkupTypeId, schedule: $schedule) {
      id
    }
  }
`;

interface SetCheckupTypeScheduleModalProps extends DialogProps {
  checkupType: AdminCheckupTypeItemFragmentInternal;
  onCancel: () => void;
  onUpdated: () => void;
}

const formSchema = Yup.object().shape({
  rruleOptions: Yup.string().required('RRule is required'),
  earlyTolerance: Yup.number().required('Early tolerance is required').min(0, 'Must be positive'),
  lateTolerance: Yup.number().required('Late tolerance is required').min(0, 'Must be positive'),
});

type FormValues = Yup.InferType<typeof formSchema>;

/**
 * Convert an RRule string to a JSON string of the options that can be used to
 * recreate the RRule. This allows us to configure the RRule using a JSON editor.
 *
 * We also filter out some options that we don't want to expose to the user, as well as
 * converting the freq string to the corresponding RRule.FREQUENCIES value so that it
 * is easier to read/understand.
 */
const rruleToOptionsJson = (rrule: string | undefined) => {
  const options = rrule ? RRule.fromString(rrule).options : undefined;

  if (!options) {
    return '{}';
  }

  const filteredOptions = {
    freq: RRule.FREQUENCIES[options.freq],
    byweekday: options.byweekday,
    byhour: options.byhour,
    byminute: options.byminute,
    bysetpos: options.bysetpos,
    tzid: options.tzid,
  };

  return JSON.stringify(filteredOptions ?? {}, null, 2);
};

/**
 * Marshal the JSON string of RRule options back to the corresponding RRule options
 * object that can be used to create an RRule. This includes converting the freq value
 * back to the corresponding numeric enum value.
 */
const jsonToRruleOptions = (json: string) => {
  const parsed = JSON.parse(json);

  // Marshal the freq string to the corresponding RRule.FREQUENCIES value
  const frequencyEnumValue = RRule.FREQUENCIES.findIndex((freq) => freq === parsed.freq);

  if (frequencyEnumValue === -1) {
    throw new Error('Invalid frequency');
  }

  // If the freq string is not a valid value, default to DAILY
  return { byhour: [0], byminute: [0], bysecond: [0], ...parsed, freq: frequencyEnumValue };
};

const defaultPreviewStartDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
const defaultPreviewEndDate = format(addDays(new Date(), 7), "yyyy-MM-dd'T'HH:mm:ss");

export function SetCheckupTypeScheduleModal({
  open,
  onCancel,
  onUpdated,
  checkupType,
}: SetCheckupTypeScheduleModalProps) {
  const classes = useStyles();

  const [setCheckupTypeScheduleMutation, { loading: isSubmitting, error: createCheckupTypeError }] =
    useSetCheckupTypeScheduleMutationInternal({
      onCompleted: () => {
        toast.success(`Schedule updated for "${checkupType.name}"`);
        onUpdated();
      },
      onError: () => {
        toast.error('Error updating check-up type schedule');
      },
    });

  const [previewStartDate, setPreviewStartDate] = React.useState<string | null>(
    defaultPreviewStartDate,
  );
  const [previewEndDate, setPreviewEndDate] = React.useState<string | null>(defaultPreviewEndDate);
  const [previewResult, setPreviewResult] = React.useState<Date[] | null>(null);
  const [previewError, setPreviewError] = React.useState<string | null>(null);

  const formik = useFormik<FormValues>({
    initialValues: {
      rruleOptions: rruleToOptionsJson(checkupType.schedule?.rrule),
      earlyTolerance: checkupType.schedule?.tolerance?.early ?? 0,
      lateTolerance: checkupType.schedule?.tolerance?.late ?? 0,
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      setCheckupTypeScheduleMutation({
        variables: {
          checkupTypeId: checkupType.id,
          schedule: {
            ruleOptions: jsonToRruleOptions(values.rruleOptions),
            tolerance: {
              early: values.earlyTolerance,
              late: values.lateTolerance,
            },
          },
        },
      });
    },
  });

  useEffect(() => {
    setPreviewResult(null);
    setPreviewError(null);
  }, [previewStartDate, previewEndDate, formik.values.rruleOptions]);

  const { argErrors } = getMutationErrors(createCheckupTypeError);
  const getFieldProps = muiFormikGetFieldProps(formik, argErrors);

  const handlePreviewClick = async () => {
    try {
      const previewStart = previewStartDate && new Date(previewStartDate);
      const previewEnd = previewEndDate && new Date(previewEndDate);

      if (!previewStart || !previewEnd) {
        return;
      }

      const rrule = new RRule({
        ...jsonToRruleOptions(formik.values.rruleOptions),
        dtstart: previewStart,
      });

      const result = rrule.between(previewStart, previewEnd, true);

      setPreviewResult(result);
    } catch (e) {
      console.error(e);
      setPreviewError('Invalid RRule');
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      aria-labelledby="set-checkuptype-schedul-dialog-title">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="set-checkuptype-schedul-dialog-title">
          Set Check-up Type Schedule
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <TextField
              id="earlyTolerance"
              label="Early Tolerance (seconds)"
              type="number"
              disabled={isSubmitting}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              fullWidth
              className={classes.toleranceInput}
              {...getFieldProps('earlyTolerance')}
            />
            <TextField
              id="lateTolerance"
              label="Late Tolerance (seconds)"
              type="number"
              disabled={isSubmitting}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              fullWidth
              className={classes.toleranceInput}
              {...getFieldProps('lateTolerance')}
            />
            <TextField
              id="rrule"
              label="RRule"
              disabled={isSubmitting}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              fullWidth
              multiline
              className={classes.jsonField}
              {...getFieldProps('rruleOptions')}
            />
          </Box>
          <Box display="flex" flexDirection="column" marginTop={2}>
            <Typography variant="h6" gutterBottom>
              Preview
            </Typography>
            <Alert severity="info">
              The schedule must be previewed and manually verified before setting. The preview will
              all expected check-up times between the provided start and end dates.
            </Alert>
            <Box display="flex" alignItems="flex-end" marginTop={1}>
              <TextField
                type="datetime-local"
                label="Preview Start"
                className={classes.previewDateInput}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ step: 1 }}
                value={previewStartDate}
                onChange={(e) => setPreviewStartDate(e.target.value)}
              />
              <TextField
                type="datetime-local"
                label="Preview End"
                className={classes.previewDateInput}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ step: 1 }}
                value={previewEndDate}
                onChange={(e) => setPreviewEndDate(e.target.value)}
              />
              <Button
                variant="outlined"
                disabled={!previewStartDate || !previewEndDate}
                onClick={() => handlePreviewClick()}>
                Check
              </Button>
            </Box>
            {previewResult?.length ? (
              <Box marginTop={2}>
                {previewResult.map((date) => (
                  <Chip
                    variant="outlined"
                    key={date.toISOString()}
                    label={date.toISOString()}
                    className={classes.previewDateChip}
                  />
                ))}
              </Box>
            ) : null}
            {previewError ? (
              <Box marginTop={2}>
                <Alert severity="error">Invalid recurrence rule options</Alert>
              </Box>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            type="submit"
            color="primary"
            disabled={formik.isSubmitting || previewResult === null}
            variant="contained">
            Set
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  toleranceInput: {
    marginBottom: theme.spacing(2),
    maxWidth: 200,
  },
  previewDateInput: {
    marginRight: theme.spacing(2),
    maxWidth: 220,
  },
  previewDateChip: {
    margin: theme.spacing(0.5),
  },
  jsonField: {
    '& textarea': {
      fontFamily: 'monospace',
      fontSize: theme.typography.pxToRem(14),
    },
  },
}));

interface UseSetCheckupTypeScheduleModalProps {
  onUpdated: () => void;
}

interface UseSetCheckupTypeScheduleModalOptions {
  checkupType: AdminCheckupTypeItemFragmentInternal;
}

export function useSetCheckupTypeScheduleModal({ onUpdated }: UseSetCheckupTypeScheduleModalProps) {
  const { showModal } = useModal();

  return {
    showSetCheckupTypeScheduleModal: ({ checkupType }: UseSetCheckupTypeScheduleModalOptions) => {
      const modal = showModal(SetCheckupTypeScheduleModal, {
        checkupType,
        onUpdated: () => {
          onUpdated();
          modal.hide();
        },
        onCancel: () => {
          modal.hide();
        },
      });
    },
  };
}
