import React from 'react';

import { Typography, Paper, Link as MaterialUiLink } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';

import logo from '@/images/logo.png';

export const ExpiredShareToken = () => {
  const classes = useStyles();

  return (
    <div className="login">
      <img src={logo} alt="Feebris Logo" />
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5" mb={1}>
          Your access link has expired
        </Typography>
        <Typography mb={2}>
          Please request a new one. If you already have a Feebris account,{' '}
          <MaterialUiLink component={Link} to="/login">
            click here
          </MaterialUiLink>{' '}
          to sign in.
        </Typography>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    maxWidth: 520,
    padding: theme.spacing(4),
    marginTop: theme.spacing(3),
  },
}));
