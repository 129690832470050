import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

import auth from '@/controllers/Auth';

import { pathWithNext } from '@/helpers/nextUrl';
import MainLayout from '@/layouts/MainLayout';
import {
  OrganizationFeatureFlags,
  useMeActingOrganizationFeatures,
  useMePermissions,
  useAuthMe,
} from '@/hooks/useAuth';

const ProtectedRoute = ({ render, ...rest }: RouteProps) => {
  if (!auth.isLoggedIn()) {
    return <Redirect to={pathWithNext('/login')} />;
  }
  // HACK: we default acceptedTerms to true to ensure the user does not see a flicker of the terms
  //       page in the case that they are not signed in to Firebase but do have a cached idToken,
  //       ie: onAuthStateChanged hasn't triggered yet.
  if (!auth.me('actingOrganization.acceptedTerms', true)) {
    return <Redirect to={pathWithNext('/terms')} />;
  }
  return <Route {...rest} render={render} />;
};

export interface CustomRouteProps extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  label?: string;
}

export const OffpisteRoute = ({ component: Component, ...rest }: CustomRouteProps) => {
  return (
    <ProtectedRoute
      {...rest}
      render={(matchProps) => {
        return (
          <div className="mui_offpiste">
            <MainLayout>
              <Component {...matchProps} />
            </MainLayout>
          </div>
        );
      }}
    />
  );
};

export const MaterialStylesRoute = ({ component: Component, ...rest }: CustomRouteProps) => {
  return (
    <ProtectedRoute
      {...rest}
      render={(matchProps) => {
        return (
          <MainLayout>
            <Component {...matchProps} />
          </MainLayout>
        );
      }}
    />
  );
};

export const SuperAdminRoute = ({ component: Component, ...rest }: CustomRouteProps) => {
  const isFeebroid = useAuthMe('isFeebroid');

  return (
    <ProtectedRoute
      {...rest}
      render={(matchProps) => {
        if (!isFeebroid) {
          return <Redirect to="/" />;
        }
        return (
          <MainLayout>
            <Component {...matchProps} />
          </MainLayout>
        );
      }}
    />
  );
};

export type RestrictedRouteTester = (props: {
  permissions: Record<string, boolean>;
  featureFlags: OrganizationFeatureFlags | undefined;
}) => boolean;

interface RestrictedRouteProps extends CustomRouteProps {
  canAccess: RestrictedRouteTester;
}

export const RestrictedRoute = ({
  canAccess,
  component: Component,
  ...rest
}: RestrictedRouteProps) => {
  const isRefreshingMeData = auth.isRefreshingMeData;

  const permissions = useMePermissions();
  const featureFlags = useMeActingOrganizationFeatures();

  const canAccessRoute = canAccess({ permissions, featureFlags: featureFlags });

  return (
    <ProtectedRoute
      {...rest}
      render={(matchProps) => {
        // If we are still refreshing the me data, we don't want to show anything
        if (isRefreshingMeData) {
          return (
            <MainLayout>
              <></>
            </MainLayout>
          );
        }
        if (!canAccessRoute) {
          return <Redirect to="/" />;
        }
        return (
          <MainLayout>
            <Component {...matchProps} />
          </MainLayout>
        );
      }}
    />
  );
};
