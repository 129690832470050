import React from 'react';

import { ApolloError, gql } from '@apollo/client';
import { Column, Action, MTableAction } from '@material-table/core';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import auth from '@/controllers/Auth';
import MaterialTableWithIcons from '@/components/MaterialTableWithIcons';
import type { ManageWardItemFragment } from '@/generated/graphql';
import { ErrorDisplay } from '@/components/ErrorDisplay';

export const ManageWardItemFragmentDef = gql`
  fragment ManageWardItem on Ward {
    id
    name
    createdAt
    updatedAt
    numberOfPatients
    numberOfStaff
  }
`;

const columns: Column<ManageWardItemFragment>[] = [
  { field: 'id', hidden: true },
  { field: 'id', title: 'ID', hidden: true },
  { field: 'name', title: 'Name', defaultSort: 'asc' },
  { field: 'numberOfPatients', title: 'Patients', type: 'numeric' },
  { field: 'numberOfStaff', title: 'Staff', type: 'numeric' },
  { field: 'createdAt', title: 'Created', type: 'datetime' },
  { field: 'updatedAt', title: 'Updated', type: 'datetime' },
  {
    field: 'id',
    width: 'auto',
    align: 'right',
    render: (data) => (
      <Button
        component={Link}
        style={{ lineHeight: 1 }}
        to={`/wards/${data.id}`}
        size="small"
        color="primary"
        endIcon={<ChevronRightIcon />}>
        Manage Ward
      </Button>
    ),
  },
];

type ActionWithPermission = Action<ManageWardItemFragment> & { permission?: string };

interface WardListProps {
  wards: ManageWardItemFragment[];
  isLoading: boolean;
  loadError: ApolloError | undefined;
  onEdit: (ward: ManageWardItemFragment) => void;
  onDelete: (ward: ManageWardItemFragment) => void;
  onAdd: () => void;
  onRefresh: () => void;
}

export default function WardList({
  wards,
  isLoading,
  loadError,
  onAdd,
  onEdit,
  onDelete,
}: WardListProps) {
  const { t } = useTranslation();

  const actions: ActionWithPermission[] = [
    {
      permission: 'edit_wards',
      icon: () => <EditIcon />,
      tooltip: t('Edit Ward'),
      onClick: (_, rowData) => {
        onEdit(rowData as ManageWardItemFragment);
      },
    },
    {
      permission: 'delete_wards',
      icon: () => <DeleteIcon />,
      tooltip: t('Delete Ward'),
      onClick: (_, rowData) => {
        onDelete(rowData as ManageWardItemFragment);
      },
    },
    {
      permission: 'create_wards',
      icon: 'CREATE',
      tooltip: t('Add Ward'),
      isFreeAction: true,
      onClick: () => {
        onAdd();
      },
    },
  ];

  return (
    <MaterialTableWithIcons
      actions={actions.filter(
        (action) => !action.permission || auth.me(`permissions.${action.permission}`),
      )}
      title={null}
      localization={{
        body: {
          emptyDataSourceMessage: loadError ? (
            <ErrorDisplay showIcon message="Failed to load wards" />
          ) : (
            <NoWardsMessage />
          ),
        },
      }}
      columns={columns}
      data={wards ?? []}
      isLoading={isLoading}
      components={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Action: (props: any) => {
          if (props.action.icon === 'CREATE') {
            return (
              <Box marginLeft={2}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={props.action.onClick}>
                  {props.action.tooltip}
                </Button>
              </Box>
            );
          }
          return (
            <MTableAction
              {...props}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation();
                props.onClick();
              }}
            />
          );
        },
      }}
    />
  );
}

/**  
 This has to be a component since material-table/core 
 gets confused and renders this multiple times otherwise
*/
const NoWardsMessage = () => {
  return (
    <>
      <Typography gutterBottom>No wards to display</Typography>
      <Typography>Go to the Manage Patients page to admit patients to this ward</Typography>
    </>
  );
};
