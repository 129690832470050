import React, { useState } from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SxProps } from '@mui/system';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface MultiSelectProps<TOption extends Record<string, unknown>> {
  name: string;
  options: TOption[];
  label: string;
  selectedIds: string[];
  onChange: (selectedIds: string[]) => void;
  getOptionLabel: (option: TOption) => string;
  getOptionValue: (option: TOption) => string;
  selectAllLabel: string;
  initialExpanded?: boolean;
  showSelectAll?: boolean;
  sx?: SxProps;
  /**
   * Add an alert within the expander, explaining why the option the user is looking for is not available.
   */
  missingOptionsWarning?: {
    title: string;
    advice: string;
  };
  noOptionsMessage?: string;
}

export function MultiSelect<TOption extends Record<string, unknown>>({
  name,
  options,
  label,
  onChange,
  selectedIds,
  getOptionValue,
  getOptionLabel,
  selectAllLabel,
  initialExpanded,
  showSelectAll,
  sx,
  missingOptionsWarning,
  noOptionsMessage = 'No options available',
}: MultiSelectProps<TOption>) {
  const classes = useStyles();

  const [itemsExpanded, setItemsExpanded] = useState(initialExpanded ?? false);
  const onWardsExpandToggle = () => setItemsExpanded((itemsExpanded) => !itemsExpanded);

  const allSelected = options.length === selectedIds.length;

  return (
    <Box display="flex" flexDirection="column" marginLeft={0.5} width="100%" sx={sx}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {showSelectAll ? (
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                checked={allSelected && options.length > 0}
                disabled={options.length === 0}
                indeterminate={!allSelected && selectedIds.length > 0}
                onChange={() => {
                  if (allSelected) {
                    onChange([]);
                  } else {
                    onChange(options.map(getOptionValue));
                  }
                }}
                name={selectAllLabel}
                color="primary"
              />
            }
            label={selectAllLabel}
          />
        ) : (
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {label}
          </Typography>
        )}
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onWardsExpandToggle();
          }}
          size="small"
          aria-label={itemsExpanded ? `Collapse ${name} list` : `Expand ${name} list`}>
          {itemsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={itemsExpanded} unmountOnExit>
        <Box display="flex" flexDirection="column" marginLeft={2}>
          {options.map((option) => (
            <FormControlLabel
              key={getOptionValue(option)}
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={selectedIds.includes(getOptionValue(option))}
                  onChange={() => {
                    if (selectedIds.includes(getOptionValue(option))) {
                      onChange(selectedIds.filter((id) => id !== getOptionValue(option)));
                    } else {
                      onChange([...selectedIds, getOptionValue(option)]);
                    }
                  }}
                  name={getOptionLabel(option)}
                  color="primary"
                />
              }
              label={getOptionLabel(option)}
            />
          ))}
        </Box>
        {options.length === 0 && (
          <Typography variant="body2" color="textSecondary">
            {noOptionsMessage}
          </Typography>
        )}
        {options.length > 0 && missingOptionsWarning && (
          <Box marginTop={1}>
            <Alert severity="info">
              <AlertTitle>{missingOptionsWarning.title}</AlertTitle>
              {missingOptionsWarning.advice}
            </Alert>
          </Box>
        )}
      </Collapse>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: theme.spacing(0.75),
  },
}));
