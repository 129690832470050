import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';

const useStyles = (theme) => ({
  mainContainer: {},
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& button': {
      marginRight: theme.spacing(2),
    },
  },
});

class CheckupActionSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.mainContainer}>
        <div className={classes.buttonContainer}>
          {this.props.print && (
            <Button size="medium" variant="contained" color="primary" onClick={this.props.print}>
              Print / save as PDF
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(CheckupActionSelector);
