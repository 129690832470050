import React from 'react';
import { CheckupDetailsFragment } from '@/generated/graphql';

interface Props {
  checkup: CheckupDetailsFragment;
}
export default function BlockPulseOx({ checkup }: Props) {
  const hr = checkup?.pulseOxiData?.averageHr;
  const spO2 = checkup.pulseOxiData?.averageSpO2;

  return (
    <div className="block block--pulse-ox">
      <div className="block__title">Pulse Oximeter</div>
      <div className="block__inner">
        <div className="block__cols">
          <div className="e2e__pulseoxblock__hr">
            <p className="title">PR</p>
            <p className="value">{hr ?? '-'}</p>
          </div>
          <div className="e2e__pulseoxblock__spo2">
            <p className="title">SPO2</p>
            <p className="value">{spO2 ?? '-'}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
