import React, { useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FilterIcon from '@mui/icons-material/FilterList';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { CHECKUPS_WITHIN_LAST_OPTIONS, useVirtualWardContext } from '../VirtualWardContext';
import { MultiSelect } from './MultiSelect';
import { formatCheckupsSinceHoursAgo } from '../formatters';
import { useMeActingOrganizationFeature } from '@/hooks/useAuth';
import { useIsActingOrgType } from '@/hooks/useIsOrgType';
import { isDefined } from '@/helpers/isDefined';
import { UserNeighborFragment } from '@/generated/graphql';

export function FilterButton() {
  const { t } = useTranslation();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { filters, setFilter, resetFilters, userWards, isLoadingFilterValues, neighbors } =
    useVirtualWardContext();

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    // Toggle the anchor element, which in turn toggles the popover
    setAnchorEl((anchorEl) => (anchorEl ? null : event.currentTarget));
  };

  const isPractice = useIsActingOrgType('practice');
  const wardsEnabled = useMeActingOrganizationFeature('wards');

  const handleSelectCarehome = (_event: React.SyntheticEvent, value: UserNeighborFragment[]) => {
    setFilter(
      'neighbors',
      value.map((v) => v.id),
    );
  };

  const virtualWardAllPatientsLegacyBehavior = useMeActingOrganizationFeature(
    'virtualWardAllPatientsLegacyBehavior',
  );

  const selectedNeighbors = filters.neighbors
    .map((id) => neighbors.find((n) => n.id === id))
    .filter(isDefined);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        endIcon={<FilterIcon />}
        onClick={handleButtonClick}
        className={clsx(Boolean(anchorEl) && classes.activeFilterButton)}>
        Filter
      </Button>
      <Popover
        className={classes.popover}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Box
          display="flex"
          flexDirection="column"
          padding={2}
          minWidth={{ xs: 300, sm: 500 }}
          maxWidth={500}
          width="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
            <Typography className={classes.title}>Filters</Typography>
            <Button
              variant="text"
              color="primary"
              size="small"
              className={classes.resetButton}
              onClick={() => resetFilters()}>
              Reset Filters
            </Button>
          </Box>
          <TextField
            className={classes.filterRoot}
            value={filters.nameOrNhsNumber}
            onChange={(e) => setFilter('nameOrNhsNumber', e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setAnchorEl(null);
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            variant="outlined"
            placeholder="Name or NHS number"
            size="small"
            InputProps={{
              'aria-label': 'Name or NHS number',
            }}
          />
          <Select
            value={filters.checkupsSinceHoursAgo ?? ''}
            inputProps={{
              'aria-label': 'Filter check-ups since',
              className: classes.withinSelect,
            }}
            variant="outlined"
            disableUnderline
            displayEmpty
            className={classes.filterRoot}
            onChange={({ target: { value } }) => {
              const parsedValue = Number.parseInt(value as string);
              setFilter('checkupsSinceHoursAgo', Number.isNaN(parsedValue) ? null : parsedValue);
            }}>
            {CHECKUPS_WITHIN_LAST_OPTIONS.map((option) => (
              <MenuItem key={option} value={option}>
                {formatCheckupsSinceHoursAgo(option)}
              </MenuItem>
            ))}
            <MenuItem value={''}>{t('All vitals history')}</MenuItem>
          </Select>
          {isPractice ? (
            <Autocomplete
              sx={{ marginVertical: 1 }}
              multiple
              options={neighbors}
              disableCloseOnSelect
              value={selectedNeighbors}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.name}
              onChange={handleSelectCarehome}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  aria-label="Care home"
                  placeholder="Care home"
                />
              )}
            />
          ) : null}
          {!isLoadingFilterValues && wardsEnabled && (
            <MultiSelect
              sx={{ marginTop: 0.5 }}
              name="wards"
              label={t('Wards')}
              noOptionsMessage="No wards found. Check that you are assigned to the correct wards."
              options={userWards}
              showSelectAll={virtualWardAllPatientsLegacyBehavior}
              selectedIds={
                filters.wards === 'all' ? userWards.map((uw) => uw.id) : filters.wards ?? []
              }
              onChange={(selected) => {
                // The 'all' option is only necessary when the virtualWardAllPatientsLegacyBehavior feature is enabled
                if (
                  userWards.length > 0 &&
                  selected.length === userWards.length &&
                  virtualWardAllPatientsLegacyBehavior
                ) {
                  setFilter('wards', 'all');
                  return;
                }

                setFilter('wards', selected);
              }}
              getOptionLabel={(ward) => ward.name}
              getOptionValue={(ward) => ward.id}
              selectAllLabel={t('Your Wards')}
              initialExpanded={true}
              missingOptionsWarning={{
                title: "Don't see a ward you expect?",
                advice:
                  'Check with a manager or administrator that you are assigned to the correct wards.',
              }}
            />
          )}
        </Box>
      </Popover>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  popover: {
    marginTop: theme.spacing(1),
  },
  title: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  activeFilterButton: {
    backgroundColor: theme.palette.common.white,
  },
  filterRoot: {
    marginBottom: theme.spacing(1),
  },
  resetButton: {
    lineHeight: 1,
    padding: theme.spacing(0.5),
    textTransform: 'none',
  },
  withinSelect: {
    padding: theme.spacing(1.5, 2),
  },
}));
