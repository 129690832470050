import React from 'react';

import { Button, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import SuccessImage from '@/components/svgs/SuccessCircle.svg';

import { useAdmitPatientJourneyContext } from '../types';

interface SuccessStepProps {
  /**
   * Whether to show the patient record link
   */
  showPatientRecordLink?: boolean;
}

export function SuccessStep({ showPatientRecordLink }: SuccessStepProps) {
  const {
    handleStep,
    currentJourneyState: { patient, pendingWardAdmission: wardAdmission },
    close,
  } = useAdmitPatientJourneyContext();

  handleStep(() => true);

  return (
    <Stack alignItems="center">
      <img src={SuccessImage} alt="Success" />
      <Typography variant="h6" gutterBottom color="grey.700" marginTop={1}>
        Ward admission successful
      </Typography>
      <Typography variant="body1" color="grey.700" align="center" marginBottom={3}>
        {patient?.firstName} {patient?.lastName} has been admitted to{' '}
        <strong>{wardAdmission?.wardName}</strong> with a care pathway of{' '}
        <strong>{wardAdmission?.carePathwayName}</strong>
      </Typography>
      {showPatientRecordLink ? (
        <Button
          component={RouterLink}
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          onClick={() => close()}
          to={`/patient/${patient?.id}`}>
          Open patient record
        </Button>
      ) : null}
    </Stack>
  );
}
