import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import PatientBulkUploadModal from '@/components/PatientBulkUploadModal';
import confetti from './images/bulkUploadPatients/Background_Confetti.png';
import addResidentsImage from './images/bulkUploadPatients/Images_Add_Patient@2x.png';
import auth from '@/controllers/Auth';

import bulkUploadTemplate from '@/assets/Feebris-Patients-Bulk-Upload-Template.xlsx';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  welcomeBoxContainer: {
    paddingRight: theme.spacing(3),
  },
  welcomeBox: {
    minHeight: theme.spacing(42),
    borderRadius: theme.spacing(2),
    backgroundImage: `url(${confetti})`,
    backgroundSize: '100% auto',
    backgroundPosition: 'bottom right',
  },
  addResidentsBox: {
    minHeight: theme.spacing(42),
    borderRadius: theme.spacing(2),
    backgroundImage: `url(${addResidentsImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: theme.spacing('right', 2, 'bottom', 2),
    backgroundSize: '407px 309px',
  },
  welcomeTextContainer: {
    marginLeft: theme.spacing(2),
  },
  title: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 60,
    fontWeight: 300,
    letterSpacing: -0.5,
  },
  name: {
    color: 'rgb(253, 127, 0)',
    fontSize: 48,
  },
  residentsTextContainer: {
    margin: theme.spacing(4, 0, 0, 2),
  },
  residentsTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 34,
    letterSpacing: 0.25,
  },
  residentsDescription: {
    width: theme.spacing(60),
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 16,
    letterSpacing: 0.5,
    marginTop: theme.spacing(2),
  },
  addResidentsButton: {
    marginLeft: theme.spacing(3),
  },
  downloadTemplateButton: {
    marginLeft: theme.spacing(2),
  },
  downloadTemplateText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 12,
    letterSpacing: 0.4,
    marginLeft: theme.spacing(4),
  },
}));

const PatientsWelcome = () => {
  const classes = useStyles();
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false);

  return (
    <>
      <PatientBulkUploadModal
        open={bulkUploadModalVisible}
        onClose={() => setBulkUploadModalVisible(false)}
        reloadPatients={() => undefined}
      />
      <Grid container className={classes.container} justifyContent="center">
        <Grid item xs={4} className={classes.welcomeBoxContainer}>
          <Card className={classes.welcomeBox} raised elevation={16}>
            <CardContent className={classes.welcomeTextContainer}>
              <Typography className={classes.title}>Welcome</Typography>
              <Typography className={classes.name} variant="h5" component="h2">
                {auth.me('actingOrganization.name')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Card className={classes.addResidentsBox} raised elevation={16}>
            <CardContent className={classes.residentsTextContainer}>
              <Typography className={classes.residentsTitle}>Add Your Patients</Typography>
              <Typography className={classes.residentsDescription} variant="h5" component="h2">
                Import your patients quickly in just two steps: (1) download and fill in our
                template; (2) upload the list onto the system.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                className={clsx(classes.addResidentsButton, 'e2e__openbulkuploadmodalbutton')}
                variant="contained"
                color="primary"
                onClick={() => setBulkUploadModalVisible(true)}>
                Add Patients
              </Button>
            </CardActions>
            <CardActions>
              <Button
                className={classes.downloadTemplateButton}
                color="primary"
                href={bulkUploadTemplate}
                download="Feebris-Patients-Bulk-Upload-Template.xlsx">
                Download Import Template
              </Button>
            </CardActions>
            <Typography className={classes.downloadTemplateText}>
              Use the template to format your data for import
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PatientsWelcome;
